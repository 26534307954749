import React, {Fragment, useEffect, useState} from "react";
import "./_DebitCard.scss";
import Radio from "../FormElements/Radio/Radio";
import CardService from "../../services/cardService";
import { Loader } from "../index";
import { notify } from "react-notify-toast";
import Constants from "../../lib/constants";
import MasterCard from "../Icon/MasterCard";
import VisaCard from "../Icon/VisaCard";
import VerveCard from "../Icon/VerveCard";
import moment from "moment";
import { isBefore } from "date-fns";
const { status, duration } = Constants;

const DebitCard = ({ cardDetails, accountId, loanDuration, setDebitCardError }) => {
    const { maskedPan, expiryDate, type, id, isDefault } = cardDetails;

  const [ loading, setLoading ] = useState(false);
  const [ repDate, setRepDate ] = useState('');

      useEffect(() => {
          if (loanDuration) {
              const duration = loanDuration.split(" ");

              const repaymentDate = moment().add(duration[0], duration[1]);
              setRepDate(repaymentDate)

              const validCard = (cardDetails, repaymentDate) => {
                  let { expiryDate } = cardDetails;
                  const expiryDate_m = expiryDate.toString().substring(0, 2) - 1;
                  const expiryDate_y = expiryDate.toString().substring(3);
                  expiryDate = new Date(expiryDate_y, expiryDate_m);

                  return isBefore(repaymentDate, expiryDate);
              };
              if (!validCard(cardDetails, repaymentDate)) {
                  const error =
                      'Your card will expire before the loan tenure of your request is complete. Please select or add a different card.';

                  setDebitCardError(error)
                  setLoading(false);
              }
          }
      }, [])


  const setDefaultCard = async () => {
      setLoading(true);
      const response = await CardService.setDefaultAccountCard(id, accountId);

        if (response && response.status === 200 && response.data.data != null) {
            const { setDefaultAccountCard } = response.data.data;

            if (loanDuration) {
                const duration = loanDuration.split(" ");
                const repaymentDate = moment().add(duration[0], duration[1]);

                let setDefaultAccountCardExpiryDate = setDefaultAccountCard.expiryDate
                const expiryDate_m = setDefaultAccountCardExpiryDate.toString().substring(0, 2) - 1;
                const expiryDate_y = setDefaultAccountCardExpiryDate.toString().substring(3);
                setDefaultAccountCardExpiryDate = new Date(expiryDate_y, expiryDate_m);

                if (isBefore(repaymentDate, setDefaultAccountCardExpiryDate)) {
                    setDebitCardError('')
                } else {
                    setDebitCardError('Your selected card will expire before the loan tenure of your request is complete. Please select or add a different card.'
                    )
                    setLoading(false);
                    return;
                }

            }

            if (setDefaultAccountCard && setDefaultAccountCard.status === "active") {
          notify.show(
              "Your primary card has been updated.",
              status.SUCCESS,
              duration.LONG
          );
          setLoading(false);
          return;
      }
    }

    setLoading(false);
      notify.show(
          "There was an error updating your Primary card. Please, try again later.",
          status.ERROR,
          duration.LONG
      );
  };

  return (
      <Fragment>
          <section className='debit-card'>
              <section className='debit-card__top'>
                  <section className='debit-card__status'>
                      <Radio
                          name='defaultCard'
                          onChange={() => setDefaultCard()}
                          defaultChecked={isDefault}
                          label={"Repayment Card"}
                      />
                  </section>
              </section>
              <section className='debit-card__pan'>
                  <span className='debit-card__pan-mask' />
                  <span className='debit-card__pan-mask' />
                  <span className='debit-card__pan-mask' />
                  <span>{maskedPan.slice(-4)}</span>
              </section>
              <section className='debit-card__bottom'>
                  <section className='debit-card__expiry'>
                      Expires: <em>{expiryDate.replace("-", "/")}</em>
                  </section>
                  <section className='debit-card__type'>
                      {(() => {
                          switch (type) {
                              case "mastercard":
                                  return <MasterCard />;
                              case "visa":
                                  return <VisaCard />;
                              case "verve":
                                  return <VerveCard />;
                              default:
                                  return "";
                          }
                      })()}
                  </section>
              </section>
          </section>
          {loading && <Loader />}
      </Fragment>
  );
};

export default DebitCard;
