import gql from 'graphql-tag';

export const fragments = {
  user: gql`
    fragment UserDetails on User {
      id
      firstName
      lastName
      email
      phone
      status
      isEmailConfirmed
      isPhoneConfirmed
      bvnStatus {
        bvn
        isVerified
      }
    }
  `,
  userMetadata: gql`
    fragment UserMetadataDetails on UserMetadata {
      id
      name
      value
    }
  `,
  account: gql`
    fragment AccountDetails on Account {
      id
      name
      accountAttribute {
        attribute {
          name
        }
        value
        data
      }
      bankAccounts {
        id
        accountName
        accountNumber
        isDefault
        status
        okraRecord {
        id
        directDebitAuthorised
      }
        bank {
          id
          name
          code
        }
      }
    }
  `,
  portfolio: gql`
    fragment AccountPortfolio on Portfolio {
      id
      amount
      chargesAmount
      taxAmount
      status {
        name
      }
      createdAt
      fullAmount
      dateOfRepayment
      amountPaid
      amountDisbursed
      portfolioNumber
    }
  `,
  application: gql`
    fragment AccountApplication on Application {
      id
      applicationNumber
      amount
      taxAmount
      baseAmount
      chargesAmount
      rejectReason
      policy {
        id
        interestRate {
          value
        }
      }
      moratoriumData {
        numberOfPeriods
      }
      reviewFeedbacks {
        id
        status
        reviewType
        comment
        oldApplicationStatus {
          name
        }
        createdAt
        documentRequests {
          id
          title
          message
          status
          document {
            fileUrl
            documentName
            file {
              key
              bucket
            }
          }
        }
      }
      portfolio {
        id
        fullAmount
        amountPaid
        portfolioNumber
        createdAt
        amountDisbursed
        lateRepaymentFee
        status {
          name
        }
        repayments {
          id
          repaymentDate
          status {
            name
          }
          amountPaid
          totalPayment
        }
        policy {
          id
          interestRate {
            value
          }
        }
      }
      status {
        name
      }
      fullAmount
      loanDuration
      dateOfRepayment
      requiredSteps
      completedSteps
      createdAt
    }
  `,
};

export const VIEWER_QUERY = gql`
  query ViewerQuery {
    viewer {
      id
      me {
        ...UserDetails
        userMetadata {
          ...UserMetadataDetails
        }
        supportingDocument {
          documentName
          file {
            key
            bucket
            etag
            url
          }
        }
      }
      account {
        ...AccountDetails
        portfolios {
          nodes {
            ...AccountPortfolio
          }
        }
        applications(orderBy: createdAt_DESC) {
          nodes {
            ...AccountApplication
          }
        }
      }
    }
  }
  ${fragments.user}
  ${fragments.userMetadata}
  ${fragments.account}
  ${fragments.portfolio}
  ${fragments.application}
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      token
    }
  }
`;

export const UPDATE_USER_DATA_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUserData(input: $input) {
      success
      records {
        ...UserMetadataDetails
      }
    }
  }
  ${fragments.userMetadata}
`;

export const PORTFOLIO_BY_ID_SUBSCRIPTION = gql`
  subscription PortfolioById($portfolioId: ID!) {
    portfolioByIdUpdates(id: $portfolioId) {
      event
      message
      portfolio {
        ...AccountPortfolio
      }
    }
  }
  ${fragments.portfolio}
`;

export const SINGLE_APPLICATION_QUERY = gql`
  query application($applicationNumber: String!) {
    application(applicationNumber: $applicationNumber) {
      ...AccountApplication
    }
  }
  ${fragments.application}
`;

export const GET_REPAYMENT_BREAKDOWN_QUERY = gql`
  query getRepaymentBreakdown(
    $policyId: ID!
    $principalAmount: Float!
    $duration: Int!
    $moratoriumPeriod: Int
  ) {
    getRepaymentBreakdown(
      input: {
        policyId: $policyId
        principalAmount: $principalAmount
        duration: $duration
        moratoriumPeriod: $moratoriumPeriod
      }
    ) {
      principalAmount
      duration
      totalInterest
      rateInDecimal
      totalExpectedPayment
      totalInterestPortion
      repaymentFrequency
      repaymentBreakdown {
        principalBalance
        expectedPayment
        interestPortion
        principalPortion
        endingBalance
        dueDate
      }
    }
  }
`;

export const APPROVE_UPDATED_LOAN_AMOUNT_MUTATION = gql`
  mutation ApproveUpdatedLoanAmountMutation(
    $applicationId: ID!
    $requestId: ID!
    $approve: Boolean!
  ) {
    approveUpdatedLoanAmount(
      input: {
        applicationId: $applicationId
        requestId: $requestId
        approve: $approve
      }
    ) {
      mbsFeedback {
        status
        message
        feedback
        requestId
      }

      application {
        fullAmount
        dateOfRepayment
        reviewDetails {
          rejectReason
        }
      }

      success
    }
  }
`;

export const FETCH_FROM_S3_QUERY = gql`
  query fetchFromS3($bucket: String!, $key: String!) {
    fetchFromS3(bucket: $bucket, key: $key) {
      dataUrl
      status
    }
  }
`;

export const LASSRA_VERIFY_ID = gql`
  query lasrraVerifyIdentityInput(
    $lasrraId: String!
    $firstName: String!
    $middleName: String!
    $surname: String!
    $dob: String!
    $gender: String
  ) {
    lasrraVerifyIdentityInput(
      lasrraId: $lasrraId
      firstName: $firstName
      middleName: $middleName
      surname: $surname
      dob: $dob
      gender: $gender
    ) {
      success
      isMatch
      message
    }
  }
`;
