import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Query } from 'react-apollo';
import { CLIENT_INFO_QUERY } from './queries';
import { Loader } from '../../components';
import Context from '../../context';
import { clientInfoRequestDetails, setGlobalClientId } from '../../lib/utils';

const ClientInformationProvider = ({ children }) => {
  const { state, dispatch } = useContext(Context);
  const [ clientInfoVal, updateClientInfoVal ] = useState();

  let {slug, clientUrl} = clientInfoRequestDetails();

  useEffect(
    () => {
      setTimeout(function() {
        dispatch({ type: 'ADD_CLIENT_INFO', payload: clientInfoVal });
      }, 500);
    },
    [ clientInfoVal ]
  );

  return (
    <Query query={CLIENT_INFO_QUERY} variables={{ slug, clientUrl }}>
      {({ data, loading, error }) => {
        const props = {
          error,
          loading,
          slug: '',
          logoUrl: '',
          clientName: '',
          clientId: '',
          clientAppUrl: '',
          paystackPubKey: '',
          termsAndConditionsUrl: '',
          addCardCharge: 0,
          facebookAppId: '',
          clientContactInfo: {},
          bankDetails: {},
          clientInfo: {}
        };

        const contactDetails = {
          phone: '',
          email: ''
        };

        if (loading) return <Loader />;

        if (error) {
          return children(props);
        }

        if (data && !loading && !error && data.clientInfo) {
          const { clientInfo } = data;
          setGlobalClientId(clientInfo.clientId);
          props.slug = clientInfo.slug;
          props.clientId = clientInfo.clientId;
          props.clientTheme = clientInfo.clientTheme
            ? clientInfo.clientTheme
            : { primaryColor: '#3f3f51', secondaryColor: '#000000' };
          props.logoUrl = clientInfo.logoUrl;
          props.clientName = clientInfo.name;
          props.paystackPubKey = clientInfo.paystackPubKey;
          props.facebookAppId = clientInfo.facebookAppKeys ? clientInfo.facebookAppKeys.appId : '462336834580561';
          props.addCardCharge = clientInfo.addCardCharge ? clientInfo.addCardCharge : 0;
          props.bankDetails = clientInfo.bankDetails;
          props.clientContactInfo = clientInfo.contactDetails || contactDetails;
          props.clientInfo = clientInfo;
          props.termsAndConditionsUrl = clientInfo.termsAndConditionsUrl;
          updateClientInfoVal(data.clientInfo);
        }

        return children(props);
      }}
    </Query>
  );
};

export default ClientInformationProvider;
