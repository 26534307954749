import React, { Fragment, useState, useEffect } from 'react';
import cx from 'classnames';
import Notifications, { notify } from 'react-notify-toast';
import { DataCardV2, Button, Input, Alert, Loader } from '../../../../components';
import { UploadSupportingDocumentProvider } from '../../../../providers';
import { bytesToSize, authGetter, getErrorMessages } from '../../../../lib/utils';
import { AuthService } from '../../../../services';

import './_Styles.scss';

const Index = ({
  stepIndex,
  userData,
  setUserData,
  navigation,
  formInfo = {},
  updatePBStepCount,
  documentList,
  updateDocumentList,
  updateContextState
}) => {
  updatePBStepCount(stepIndex + 1);
  const { title, subTitle } = formInfo;
  const { next } = navigation;
  const [ uploadedDocs, updateDocs ] = useState([]);
  const [ isLoading, updateLoadingState ] = useState(false);
  const [ responseError, setResponseError ] = useState([]);
  const [ loanProduct, setLoanProduct ] = useState('') ;
  const [ inputErrors, setInputErrors ] = useState([]);
  const [ isSubmitted, updateSubmit ] = useState(false);
  const mySuccessColor = { background: '#095712', text: '#FFFFFF' };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  const { uploadSupportingDocumentFile } = UploadSupportingDocumentProvider({ uploadedDocs });

  const isReady = () => {
    return userData && Object.keys(userData).length !== 0;
  };

  const handleFileUpload = async (data) => {
    updateLoadingState(true);

    const response = await uploadSupportingDocumentFile(data);
    updateLoadingState(false);

    if (response.data && response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ documentUpload: error });
      return;
    }

    if (response.data && response.data.uploadSupportingDocument) {
      notify.show('File uploaded successfully', 'success', mySuccessColor);
      return;
    } else {
      setResponseError({ documentUpload: 'File could not be uploaded, kindly retry.' });
      return;
    }
  };

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    updateLoadingState(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    const response = viewer ? viewer.data.data.viewer : null;
    if (response) {
      const businessInformation = response.account.accountAttribute.find(accountAttr => accountAttr.attribute.name === 'businessInformation').data;
      setLoanProduct(businessInformation.loanProduct);

      updateDocumentList(documentList.sort((a,b) => {
        return b.requiredBy.includes(businessInformation.loanProduct) - a.requiredBy.includes(businessInformation.loanProduct);
      }));
      setUserData(response)
    }
  };

  const handleDocumentUpload = (e) => {
    let userId;
    const { name, validity, files } = e.target;
    const file = files[0];

    if (userData && Object.keys(userData).length !== 0) {
      userId = userData.me.id;
    }

    if (file && file.size < 10000000) {
      updateDocs((prevState) => ({
        ...prevState,
        [`${name}`]: {
          fileName: file.name,
          fileSize: bytesToSize(file.size),
          validity: validity
        }
      }));

      const data = {
        selectedFile: e.target,
        documentName: `${name}_${userId}_${file.name}`,
        userId: userId
      };

      handleFileUpload(data);
    } else {
      setResponseError({ documentUpload: 'Please, upload a file not higher than 10 MB' });
    }
  };

  const removeDocument = (event, itemName) => {
    event.preventDefault();

    updateDocumentList(documentList.filter(({ name }) => name !== itemName));
  };

  const nextStage = async () => {
    updateLoadingState(true);
    let businessInformation = { nextStage: 'loanInformation' };
    const response = await AuthService.updateUserDataV2({ businessInformation });

    updateLoadingState(false);
    if (response) {
      updateContextState({ page: 'loanInformation' });
      next();
    }
  };

  const handleSubmit = () => {
    updateSubmit(true);

    const defaultDocs = documentList.filter((item) => item.requiredBy.includes(loanProduct));

    let uploadedRequiredDocs = [];
    defaultDocs.map((item) => {
      if (uploadedDocs[item.name] && uploadedDocs[item.name].fileName) {
        uploadedRequiredDocs.push({ isUploaded: true });
      }
    });

    if (uploadedRequiredDocs.length < defaultDocs.length) {
      const error = 'Please ensure all required documents are attached.';
      let myColor = { background: '#0E1717', text: '#FFFFFF' };
      notify.show(error, 'error', 6000, myColor);
    } else {
      nextStage();
    }
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        <Notifications />
        {responseError &&
        responseError.length !== 0 && (
          <Fragment>
            {responseError.documentUpload ? <Alert classes="margin-top error">{responseError.documentUpload}</Alert> : ''}
          </Fragment>
        )}

        <div className="form_content document_upload_container">
          {documentList &&
            documentList.map((item, itemIndex) => (
              <label
                className={cx({
                  'document-upload': item,
                  custom: item.type === 'custom'
                })}
                key={item.title}
              >
                {item.requiredBy.includes(loanProduct) ? (
                  <div className="required">
                    <span>*</span>Required
                  </div>
                ) : (
                  <div className="remove" onClick={(event) => removeDocument(event, item.name)}>
                    x
                  </div>
                )}
                <Input
                  name={item.name}
                  type="file"
                  classes={`border-bottom`}
                  onChange={(e) => handleDocumentUpload(e)}
                  accept="application/pdf"
                />
                <section>
                  <div
                    className={cx({
                      'document-upload__label': item
                    })}
                  />
                  <div
                    className={cx({
                      title: item,
                      has_file: uploadedDocs[`${item.name}`]
                    })}
                  >
                    {uploadedDocs[`${item.name}`] ? (
                      `${uploadedDocs[`${item.name}`].fileName} of Size: ${uploadedDocs[`${item.name}`].fileSize}`
                    ) : (
                      item.title
                    )}
                  </div>
                </section>
              </label>
            ))}
        </div>

        <div className="space-apart single center">
          {isReady() ? <Button click_event={handleSubmit}>Continue</Button> : <Button>Continue</Button>}
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
