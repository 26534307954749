import React from 'react';
import Alert from "../Alert/Alert";
import Button from '../Button/Button';
import './_BvnVerificationMessage.scss';
import { useHistory } from 'react-router-dom';

const BvnVerificationMessage = () => {
  const history = useHistory();

  const goToSettings = ()=> {
    history.push("/settings#update-bvn");
    console.log("HELLO WORLD")
  }

  return (
    <Alert classes="warning bvn-verification-message">
      <span>
        We require your BVN to fetch and verify your information. Please proceed to the settings page to verify
      </span>

      <Button click_event={goToSettings}>Click to Proceed</Button>
    </Alert>
  )
}
export default BvnVerificationMessage