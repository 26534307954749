import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './_RadioGroup.scss';

const RadioGroup = (props) => {
  return (
    <div className={`radio-group ${props.classes == null ? '' : props.classes}`}>
      <Fragment>
        {props.children}

        {props.errorMessage ? <div className="input__error">{props.errorMessage}</div> : ''}
      </Fragment>
    </div>
  );
};

RadioGroup.propTypes = {
  classes: PropTypes.string
};

export default RadioGroup;
