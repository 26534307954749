import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import { useState } from 'react';

const CUSTOMER_BVN_STATUS = gql(/* GraphQL */ `
  query CustomerBvnStatus(
    $bvn: String!,
    $clientId: ID!,
    $bvnPhoneDigits: String,
    $bvnDOB: DateTime
  ) {
      customerBvnStatus (
        input: {
          bvn: $bvn, clientId: $clientId, bvnPhoneDigits: $bvnPhoneDigits, bvnDOB: $bvnDOB
        }
    ) {
      firstName
      lastName
      dateOfBirth
      hasCustomerAccount
      isVerified
    }
  }
`);

export const useCustomerBvnStatus = () => {
  const [bvnStatus, setBvnStatus] = useState();

  const [loadQuery, { loading, error }] = useLazyQuery(CUSTOMER_BVN_STATUS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.customerBvnStatus) {
        setBvnStatus(data.customerBvnStatus)
      }
    },
    onError: () => {
      setBvnStatus(null);
    }
  })

  const customerBvnStatus = ({ bvn, clientId, bvnPhoneDigits, bvnDOB }) => {
    const bvnDobZtime = moment(bvnDOB).add(2, 'hours').utc().format('YYYY-MM-DD');

    loadQuery({
      variables: {
        bvn, clientId, bvnPhoneDigits, bvnDOB: bvnDobZtime
      },
    });
  };

  return {
    bvnStatus,
    customerBvnStatus,
    bvnStatusError: error,
    bvnStatusLoading: loading,
  };
}