export default function reducer(state, action) {
  switch (action.type) {
    case 'ADD_CLIENT_INFO':
      return {
        ...state,
        clientInfo: action.payload
      };
    default:
      return state;
  }
}
