import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';

import './_CheckBox.scss';

const CheckBox = ({
  classes,
  value,
  disabled,
  checked,
  readOnly,
  name,
  placeholder,
  onChange,
  label,
  defaultChecked
}) => {
  return (
    <label className={'checkbox'}>
      <input
        className={`${classes == null ? '' : classes}`}
        type="checkbox"
        value={value}
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        readOnly={readOnly}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      <span className="checkbox__label">{label}</span>
    </label>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  checked: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  defaultChecked: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  readOnly: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ])
};

export default CheckBox;
