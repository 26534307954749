import React, { Fragment, useState, useEffect } from "react";
import Notifications, { notify } from 'react-notify-toast';

import { CLIENT_ID } from "../../../../lib/constants";
import {
  DataCardV2,
  Button,
  Select,
  BackArrow,
  FormGroup,
  Input,
  RadioGroup,
  Radio,
  Loader,
} from "../../../../components";
import { AuthService } from "../../../../services";
import {
  removeCommas,
  getErrorMessages,
  numberWithCommas,
  authGetter,
} from "../../../../lib/utils";

import "./_Styles.scss";

const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  updatePBStepCount,
  formParams,
  userData,
  updateFormParams,
  baseInputChangeHandler,
  appClientInfo,
  updateContextState,
}) => {
  updatePBStepCount(stepIndex + 1);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCustomerAmountEligibility();
  }, []);

  const clientId = localStorage.getItem(CLIENT_ID);
  const [ isLoading, updateLoadingState ] = useState(false);
  const [ inputErrors, setInputErrors ] = useState([]);
  const [ responseError, setResponseError ] = useState([]);
  const [ isSubmitted, updateSubmit ] = useState(false);
  const [ eligibleAmounts, updateEligibleAmounts ] = useState([]);
  const [ expectedMonthlyTurnover, updateExpectedMonthlyTurnover ] = useState(
    null
  );
  const [ expectedMonthlyExpenses, updateExpectedMonthlyExpenses ] = useState(
    null
  );
  const [ expectedMonthlySavings, updateExpectedMonthlySavings ] = useState(
    null
  );
  const [ loanDurations, updateLoanDurations ] = useState([]);
  const [ loanAmount, setLoanAmount ] = useState();
  const [ monthlyValues, setMonthlyValues ] = useState({
    monthlyTurnover: null,
    monthlyExpenses: null,
    monthlySavings: null,
  });

  const [ loanRules, setLoanRules ] = useState({
    maxAmount: 0,
    maxTenor: 15,
  });
  const [ loanProduct, setLoanProduct ] = useState("");
  const [ requiredFormFields, setRequiredFields ] = useState([
    "amount",
    "loanDuration",
    "expectedNewHires",
  ]);
  const [ loanCategory, setLoanCategory ] = useState();
  const {
    amount,
    loanDuration,
    expectedNewHires,
    cooperativeMember,
    willingToJoinCooperative,
    durationInCooperative,
    cooperativeName,
    cooperativeAddress,
  } = formParams;

  const { title, subTitle } = formInfo;
  const { next, previous } = navigation;

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case "amount":
        if (value.length === 0) {
          setInputErrors((prevState) => ({
            ...prevState,
            amount: "Kindly enter your desired loan amount.",
          }));
        } else if (removeCommas(value) > loanCategory.maxLoanAmount) {
          setInputErrors((prevState) => ({
            ...prevState,
            amount: `The maximum loan amount you can borrow is ${numberWithCommas(
              loanCategory.maxLoanAmount
            )}`,
          }));
        } else if (removeCommas(value) < loanCategory.minLoanAmount) {
          setInputErrors((prevState) => ({
            ...prevState,
            amount: `The requested loan amount has to be more than ${numberWithCommas(
              loanCategory.minLoanAmount
            )}`,
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, amount: "" }));
        }
        break;
      case "loanDuration":
        if (value.length === 0) {
          setInputErrors((prevState) => ({
            ...prevState,
            loanDuration: "Kindly enter loan amount and select an option from the list.",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, loanDuration: "" }));
        }
        break;
      case "expectedMonthlyTurnover":
        if (!value || value.length === 0 || value < 0) {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlyTurnover: "Kindly enter a value.",
          }));
        } else if (value <= monthlyValues.monthlyTurnover) {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlyTurnover:
              "Expected Monthly Turnover cannot be lower than or equal to Monthly Turnover",
          }));
        } else {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlyTurnover: "",
          }));
        }
        break;
      case "expectedMonthlyExpenses":
        if (!value || value.length === 0 || value < 0) {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlyExpenses: "Kindly enter a value.",
          }));
        } else if (value <= monthlyValues.monthlyExpenses) {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlyExpenses:
              "Expected Monthly Expense cannot be lower than or equal to  Monthly Expense",
          }));
        } else if (value > expectedMonthlyTurnover) {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlyExpenses:
              "Expected Monthly Expense can not be greater than Expected Monthly Turnover",
          }));
        } else {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlyExpenses: "",
          }));
        }
        break;
      case "expectedMonthlySavings":
        if (!value || value.length === 0) {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlySavings: "Kindly enter a value.",
          }));
        } else if (value <= monthlyValues.monthlySavings) {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlySavings:
              "Expected Monthly Savings cannot be lower than or equal to  Monthly Savings",
          }));
        } else {
          setInputErrors((prevState) => ({
            ...prevState,
            expectedMonthlySavings: "",
          }));
        }
        break;
      case "expectedNewHires":
        if (!value || value.length === 0) {
          setInputErrors((prevState) => ({
            ...prevState,
            [fieldName]: "Kindly enter a value.",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, [fieldName]: "" }));
        }
        break;
      case "cooperativeMember":
        if (!value) {
          setInputErrors((prevState) => ({
            ...prevState,
            [fieldName]:
              "Please state whether you belong to a cooperative/association",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, [fieldName]: "" }));
        }
        break;
      case "willingToJoinCooperative":
        if (!value) {
          setInputErrors((prevState) => ({
            ...prevState,
            [fieldName]:
              "Please state whether you are willing to join a cooperative/association",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, [fieldName]: "" }));
        }
        break;
      case "durationInCooperative":
      case "cooperativeName":
      case "cooperativeAddress":
        const fieldTitle =
          fieldName === "cooperativeName"
            ? "cooperative/association name"
            : fieldName === "cooperativeAddress"
              ? "cooperative/association address"
              : "duration in this cooperative/association";
        if (cooperativeMember === "true" && !value.length) {
          setInputErrors((prevState) => ({
            ...prevState,
            [fieldName]: `Kindly enter the ${fieldTitle}`,
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, [fieldName]: "" }));
        }
        break;
      default:
        break;
    }
    return true;
  };

  const addRequiredFields = () => {
    if (
      loanProduct === "ME" &&
      !requiredFormFields.includes("cooperativeMember")
    ) {
      setRequiredFields((prevState) => [ ...prevState, "cooperativeMember" ]);
    }

    if (
      cooperativeMember === "true" &&
      !requiredFormFields.includes("durationInCooperative")
    ) {
      setRequiredFields([
        "amount",
        "loanDuration",
        "expectedMonthlyTurnover",
        "expectedMonthlyExpenses",
        "expectedMonthlySavings",
        "expectedNewHires",
        "durationInCooperative",
        "cooperativeName",
        "cooperativeAddress",
      ]);
    } else if (
      cooperativeMember === "false" &&
      !requiredFormFields.includes("willingToJoinCooperative")
    ) {
      setRequiredFields([
        "amount",
        "loanDuration",
        "expectedMonthlyTurnover",
        "expectedMonthlyExpenses",
        "expectedMonthlySavings",
        "expectedNewHires",
        "willingToJoinCooperative",
      ]);
    }
  };

  const getCustomerAmountEligibility = async () => {
    updateLoadingState(true);

    const response = await AuthService.customerAmountEligibility();
    updateLoadingState(false);

    if (response && response.data && response.data.data) {
      updateEligibleAmounts(response.data.data.customerAmountEligibility);
    }
  };

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    updateLoadingState(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    const response = viewer ? viewer.data.data.viewer : {};

    if (response) {
      const businessInformation = response.account.accountAttribute.find(
        (accountAttr) => accountAttr.attribute.name === "businessInformation"
      ).data;
      const {
        monthlyTurnover,
        monthlyExpenses,
        monthlySavings,
        selectedCategory
      } = businessInformation;
      setMonthlyValues({
        monthlyTurnover: monthlyTurnover,
        monthlyExpenses: monthlyExpenses,
        monthlySavings: monthlySavings,
      });
      setLoanCategory(selectedCategory)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  const checkValidation = () => {
    requiredFormFields.map((item) => {
      validateForm(item, formParams[item]);
    });
    validateForm("expectedMonthlyTurnover", expectedMonthlyTurnover);
    validateForm("expectedMonthlySavings", expectedMonthlySavings);
    validateForm("expectedMonthlyExpenses", expectedMonthlyExpenses);
  };

  const checkLoanProduct = () => {
    if (userData) {
      const businessInformation = userData.account.accountAttribute.find(
        (attribute) => attribute.attribute.name === "businessInformation"
      ).data;

      setLoanProduct(businessInformation.loanProduct);

      if (businessInformation.loanProduct === "MES") {
        setLoanRules({
          maxAmount: 250000,
          minAmount: 1,
          maxTenor: 12,
        });
      } else if (businessInformation.loanProduct === "ME") {
        setLoanRules({
          maxAmount: 500000,
          minAmount: 1,
          maxTenor: 12,
        });
      } else {
        setLoanRules({
          maxAmount: 5000000,
          minAmount: 500000,
          maxTenor: 36,
        });
      }
    }
  };

  const handleAmountInput = (e) => {
    let inputAmount = removeCommas(e.target.value);

    let minAmount = loanCategory.minAmount
    let maxAmount = loanCategory.maxAmount

    if (
      inputAmount >= minAmount &&
      inputAmount <= maxAmount
    ) {
      setLoanAmount(removeCommas(parseFloat(inputAmount)));
    } else {
      setTimeout(() => 5000);
      const message =
        "Please enter an amount within the range of " +
        numberWithCommas(minAmount)+
        " and " +
        numberWithCommas(maxAmount);
      notify.show(message,'error', 3000);
      updateLoanDurations([])
    }

    updateFormParams((prevState) => ({
      ...prevState,
      'amount': inputAmount,
    }));
  };

  const handleExpectedMonthlyTurnoverInput = (e) => {
    const formatExpectedMonthlyTurnover = +removeCommas(e.target.value);
    updateExpectedMonthlyTurnover(formatExpectedMonthlyTurnover);
    updateExpectedMonthlySavings(
      formatExpectedMonthlyTurnover - expectedMonthlyExpenses
    );
  };

  const handleExpectedMonthlyExpensesInput = (e) => {
    const formatExpectedMonthlyExpenses = +removeCommas(e.target.value);
    updateExpectedMonthlyExpenses(formatExpectedMonthlyExpenses);
    updateExpectedMonthlySavings(
      expectedMonthlyTurnover - formatExpectedMonthlyExpenses
    );
  };

  useEffect(
    () => {
      checkValidation();
    },
    [
      requiredFormFields,
      formParams,
      isSubmitted,
      expectedMonthlyTurnover,
      expectedMonthlySavings,
      expectedMonthlySavings,
    ]
  );

  useEffect(() => checkLoanProduct(), [ userData ]);

  useEffect(
    () => {
      addRequiredFields();
    },
    [ loanProduct, cooperativeMember ]
  );

  const getApplicableTenors = async () => {
    updateLoadingState(true)
    const response = await AuthService.applicableTenors(
      clientId,
      loanCategory ? loanCategory.id : "",
      removeCommas(parseFloat(loanAmount))
    );
    updateLoadingState(false)
    updateLoanDurations(
      response && response.data.data && response.data.data.applicableTenors
    );
  };

  useEffect(
    () => {
      if (appClientInfo && userData && userData.account) {
    
        getApplicableTenors();
      }
    },
    [ loanAmount]
  );

  const handleRadioChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    updateFormParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveLoanRequest = async () => {
    updateLoadingState(true);

    const savedBusinessInfo = userData.account.accountAttribute.find(
      (attribute) => attribute.attribute.name === "businessInformation"
    ).data;

    const businessInformation = {
      ...savedBusinessInfo,
      amount,
      loanDuration,
      expectedMonthlyTurnover,
      expectedMonthlyExpenses,
      expectedMonthlySavings,
      expectedNewHires,
      cooperativeMember,
      willingToJoinCooperative,
      durationInCooperative,
      cooperativeName,
      cooperativeAddress,
    };

    const response = await AuthService.updateUserDataV2({
      businessInformation,
    });

    if (response == null || typeof response === "undefined") {
      const error =
        "An error occurred while updating your details. Please try again";
      setResponseError({ updateUserData: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ updateUserData: error });
      return;
    }

    if (response.data.data.updateUserData) {
      updateContextState({
        page: "addDebitCard",
        state: { amount, loanDuration },
      });
      next();  
    } else {
      setResponseError({
        updateUserData: "Could not update your information.",
      });
    }

    updateLoadingState(false);
  };

  const handleInputChange = (e) => {
    if (e.key === ".") {
      e.preventDefault();
      return;
    }

    e.target.value = e.target.value
      ? e.currentTarget.value.replace(".", "")
      : e.target.value;
  };

  const nextStep = () => {
    updateSubmit(true);
    updateFormParams((prevState) => ({
      ...prevState,
      expectedMonthlyTurnover: expectedMonthlyTurnover,
      expectedMonthlyExpenses: expectedMonthlyExpenses,
      expectedMonthlySavings: expectedMonthlySavings,
    }));
    const errors = Object.keys(inputErrors).filter(
      (item) => inputErrors[item] !== ""
    );

    if (errors.length < 1) {
      saveLoanRequest();
    } else {
      window.scroll(0, 80);
    }
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      <Notifications/>
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        <div className='form_content lets_get_started'>
          <section className='margin-top'>
            <FormGroup classes='one-grid'>
              <Input
                type='number'
                required
                amountField
                placeholder='How much do you want to borrow'
                label='How much do you want to borrow'
                name='amount'
                value={amount}
                onChange={handleAmountInput}
                classes={`border-bottom`}
                errorMessage={
                  isSubmitted && inputErrors.amount ? inputErrors.amount : ""
                }
              />
              <Select
                name='loanDuration'
                classes='border-bottom'
                label='How long will you take to pay back the loan'
                autoComplete='state'
                onChange={baseInputChangeHandler}
                required
                disabled={!loanAmount}
                defaultValue={loanDuration}
                errorMessage={
                  isSubmitted && inputErrors.loanDuration ? (
                    inputErrors.loanDuration
                  ) : (
                    ""
                  )
                }
              >
                <option disabled value=''>
                  How long will you take to pay back the loan
                </option>
                {loanDurations &&
                  loanDurations.map((duration, durationIndex) => (
                    <option
                      key={`duration_${durationIndex}`}
                      value={`${duration.duration} ${duration.durationType}`}
                    >
                      {`${duration.duration} ${duration.durationType}`}
                    </option>
                  ))}
              </Select>

              <Input
                type='number'
                required
                placeholder='If granted this loan, what is your expected Total Monthly Turnover'
                label='If granted this loan, what is your expected Total Monthly Turnover'
                amountField
                name='expectedMonthlyTurnover'
                value={expectedMonthlyTurnover}
                onChange={handleExpectedMonthlyTurnoverInput}
                classes={`border-bottom`}
                errorMessage={
                  isSubmitted && inputErrors.expectedMonthlyTurnover ? (
                    inputErrors.expectedMonthlyTurnover
                  ) : (
                    ""
                  )
                }
              />

              <Input
                type='number'
                required
                placeholder='If granted this loan, what is your expected Total Monthly Expenses'
                label='If granted this loan, what is your expected Total Monthly Expenses'
                amountField
                name='expectedMonthlyExpenses'
                value={expectedMonthlyExpenses}
                onChange={handleExpectedMonthlyExpensesInput}
                classes={`border-bottom`}
                errorMessage={
                  isSubmitted && inputErrors.expectedMonthlyExpenses ? (
                    inputErrors.expectedMonthlyExpenses
                  ) : (
                    ""
                  )
                }
              />

              <Input
                type='text'
                required
                placeholder='If granted this loan, what is your expected Total Monthly Savings'
                label='If granted this loan, what is your expected Total Monthly Savings'
                amountField
                name='expectedMonthlySavings'
                value={expectedMonthlySavings}
                onChange={baseInputChangeHandler}
                classes={`border-bottom`}
                readOnly={true}
                allowNegative={true}
                errorMessage={
                  isSubmitted && inputErrors.expectedMonthlySavings ? (
                    inputErrors.expectedMonthlySavings
                  ) : (
                    ""
                  )
                }
              />

              <Input
                type='text'
                numberField
                required
                placeholder='If granted this loan, how many people will you employ?'
                label='If granted this loan, how many people will you employ?'
                name='expectedNewHires'
                value={expectedNewHires}
                onKeyDown={handleInputChange}
                onChange={baseInputChangeHandler}
                readOnly={false}
                classes={`border-bottom`}
                errorMessage={
                  isSubmitted && inputErrors.expectedNewHires ? (
                    inputErrors.expectedNewHires
                  ) : (
                    ""
                  )
                }
              />
            </FormGroup>

            <p className='section-title'>Cooperative Membership</p>

            <div>
              <FormGroup classes='one-grid'>
                <label>
                  Do you belong to any recognized Cooperative or Association?
                  {loanProduct === "ME" && <span className='required'>*</span>}
                </label>
                <RadioGroup
                  errorMessage={
                    isSubmitted && inputErrors.cooperativeMember ? (
                      inputErrors.cooperativeMember
                    ) : (
                      ""
                    )
                  }
                >
                  <Radio
                    label='Yes'
                    name='cooperativeMember'
                    value='true'
                    onChange={(e) => handleRadioChange(e)}
                    checked={cooperativeMember === "true"}
                  />
                  <Radio
                    label='No'
                    name='cooperativeMember'
                    value='false'
                    onChange={(e) => handleRadioChange(e)}
                    checked={cooperativeMember === "false"}
                  />
                </RadioGroup>
              </FormGroup>
            </div>

            {cooperativeMember === "false" && (
              <FormGroup classes='margin-top one-grid'>
                <label>
                  Are you willing to join a Business Cluster or Cooperative?{" "}
                </label>
                <RadioGroup
                  errorMessage={
                    isSubmitted && inputErrors.willingToJoinCooperative ? (
                      inputErrors.willingToJoinCooperative
                    ) : (
                      ""
                    )
                  }
                >
                  <Radio
                    label='Yes'
                    name='willingToJoinCooperative'
                    value='true'
                    onChange={(e) => handleRadioChange(e)}
                    checked={willingToJoinCooperative === "true"}
                  />
                  <Radio
                    label='No'
                    name='willingToJoinCooperative'
                    value='false'
                    onChange={(e) => handleRadioChange(e)}
                    checked={willingToJoinCooperative === "false"}
                  />
                </RadioGroup>
              </FormGroup>
            )}

            {cooperativeMember === "true" && (
              <FormGroup classes='two-grid'>
                <Input
                  type='text'
                  required
                  placeholder='How long have you been a Member?'
                  label='How long have you been a Member?'
                  name='durationInCooperative'
                  value={durationInCooperative}
                  readOnly={false}
                  onChange={baseInputChangeHandler}
                  classes={`border-bottom`}
                  errorMessage={
                    isSubmitted && inputErrors.durationInCooperative ? (
                      inputErrors.durationInCooperative
                    ) : (
                      ""
                    )
                  }
                />

                <Input
                  type='text'
                  required
                  placeholder='Cooperative or Association Name'
                  label='Cooperative or Association Name'
                  name='cooperativeName'
                  value={cooperativeName}
                  readOnly={false}
                  onChange={baseInputChangeHandler}
                  classes={`border-bottom`}
                  errorMessage={
                    isSubmitted && inputErrors.cooperativeName ? (
                      inputErrors.cooperativeName
                    ) : (
                      ""
                    )
                  }
                />

                <Input
                  type='text'
                  required
                  placeholder='Cooperative or Association Address'
                  label='Cooperative or Association Address'
                  name='cooperativeAddress'
                  value={cooperativeAddress}
                  readOnly={false}
                  onChange={baseInputChangeHandler}
                  classes={`border-bottom`}
                  errorMessage={
                    isSubmitted && inputErrors.cooperativeAddress ? (
                      inputErrors.cooperativeAddress
                    ) : (
                      ""
                    )
                  }
                />
              </FormGroup>
            )}
          </section>
        </div>

        <div className='space-apart'>
          <BackArrow height='24' onClick={previous} text='Back' />
          <Button click_event={nextStep}>Continue</Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
