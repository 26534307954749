import React from 'react';
import ReactGA from 'react-ga';
import config from '../config/config';
import { getGlobalClientId } from "./utils";

const GA_TAG = process.env.REACT_APP_GA_TAG || '';
let initializedGa = false;

export const initializeGA = () => {
  if (GA_TAG != null && typeof GA_TAG !== 'undefined') {
    ReactGA.initialize(GA_TAG, {
      debug: config.web.node_env !== 'production',
      gaOptions: {
        clientId: getGlobalClientId(),
      }
    });

    initializedGa = true;
  }
};

export const logPageView = () => {
  if (initializedGa) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const logEvent = (stepCategory, action, nonInteraction = false) => {
  if (initializedGa) {
    ReactGA.event({
      category: stepCategory,
      action,
      nonInteraction
    });
  }
};
