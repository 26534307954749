import gql from 'graphql-tag';

export const CLIENT_INFO_QUERY = gql`
  query ClientInfo($clientId: ID, $loanCategoryId: ID, $clientUrl: String, $slug: String) {
    clientInfo(input: { clientId: $clientId, loanCategoryId: $loanCategoryId, clientAppUrl: $clientUrl, slug: $slug}) {
      name
      slug
      clientId
      logoUrl
      addCardCharge
      clientAppUrl
      requireWorkEmail
      useRemita
      faviconUrl
      termsAndConditionsUrl
      requiresSelfieUpload
      requiresWorkIDUpload
      requiresBankStatement
      requiresBankStatementUpload
      paystackPubKey
      minLoanAmount
      maxLoanAmount
      okraDirectDebitIsEnabled
      useLoanGroups
      requiresOkraTransactions
      requiredSupportingDocuments {
        documentName
        description
      }
      clientTheme {
        primaryColor
        secondaryColor
      }
      loanMonthlyDurations {
        duration
        durationType
        minLoanAmount
        maxLoanAmount
      }
      facebookAppKeys {
        appId
      }
      requiredSocialMediaAccounts{
        name
      }
      contactDetails {
        phone
        email
      }
      bankDetails {
        accountName
        accountNumber
        isDefault
        bank {
          name
        }
      }
      bvnServiceProviders {
        name
        enabled
        options
      }
    }
  }
`;
