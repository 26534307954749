import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './_ProgressBar.scss';

const ProgressBar = (props) => {
  const { fillColor = '#D14B45', current, limit } = props;

  const percentage = current / limit * 100;
  const width = percentage >= 100 ? 100 : percentage;
  const styles = {
    width: `${width}%`,
    background: `${fillColor}`
  };

  return (
    <div
      className={cx({
        rails: props
      })}
    >
      <div
        className={cx({
          bar: props
        })}
        style={styles}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  current: PropTypes.number,
  limit: PropTypes.number,
  fillColor: PropTypes.string
};

export default ProgressBar;
