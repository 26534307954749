import React, { useEffect } from 'react';
import { useDeclineOfferLetterProvider } from '../../../providers/index';
import { toTitleCase } from '../../../lib/utils';
import { Alert, Button, Popup } from '../../index';

const DeclineOfferLetter = ({
  application,
  popupToggle,
  setLoading,
  refetch,
}) => {
  const {
    loading,
    success,
    errorMessage,
    declineOffer,
  } = useDeclineOfferLetterProvider({ application });

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [loading]);

  useEffect(() => {
    if (success) {
      popupToggle('OfferLetterDeclined');
      setTimeout(() => {
        refetch();
      }, 4000);
    }
  }, [success]);

  return (
    <Popup title="Decline Offer Letter" classes="offer-letter">
      {errorMessage ? (
        <Alert classes="warning">{toTitleCase(errorMessage)}</Alert>
      ) : (
        ''
      )}

      <p className="center-text">
        Declining to sign this offer letter will automatically decline your
        loan. Are you sure you want to decline to sign the offer letter?
      </p>

      <div className="decline-offer">
        <Button click_event={declineOffer} classes="decline_button secondary">
          Decline
        </Button>

        <Button
          click_event={() => popupToggle('OfferLetterOptions')}
          classes="cancel_button secondary"
        >
          Cancel
        </Button>
      </div>
    </Popup>
  );
};

export default DeclineOfferLetter;
