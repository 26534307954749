import React from 'react';
import PropTypes from 'prop-types';
import './_CheckBoxGroup.scss';

const CheckBoxGroup = (props) => {
    return(
        <div className={`checkbox-group ${props.classes == null ? "" : props.classes}`}>
            {props.children}
        </div>
    )
};

CheckBoxGroup.propTypes = {
    classes: PropTypes.string,
};

export default CheckBoxGroup;