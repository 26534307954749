import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Okra from 'npm-okrajs';
import { Alert, Button, DataCardV2, Loader } from '../../../../components';
import { BankService } from '../../../../services';
import { notify } from 'react-notify-toast';
import { getErrorMessages } from '../../../../lib/utils';

import './_Styles.scss';

const Index = ({
  stepIndex,
  updatePBStepCount,
  formInfo,
  navigation,
  appClientInfo: clientInfo,
  updateContextState,
  getPreviouslySavedData,
  userData,
  updateFormParams
}) => {
  updatePBStepCount(stepIndex + 1);
  const { title, subTitle } = formInfo;
  const { next } = navigation;
  const okraDataRef = useRef({});
  const [isLoading, setLoading] = useState(false);
  const [okraWidgetUrl, setOkraWidgetUrl] = useState('');
  const [okraError, setOkraError] = useState();
  const [okraWidgetClosed, setOkraWidgetClosed] = useState(true);

  const resolveOkraBank = async (okraBankId) => {
    setLoading(true);

    const response = await BankService.resolveOkraBank(okraBankId);

    if (!response) {
      notify.show('There was an error retrieving your bank details from Okra. Kindly try manual entry', 'error', 4000);
    }

    if (response && response.data.errors) {
      const errorMsg = getErrorMessages(response.data.errors);
      notify.show(errorMsg, 'error', 4000);
    }

    if (response && response.data.data) {
      const { resolveOkraBank: { id, code } } = response.data.data;
      okraDataRef.current = { ...okraDataRef.current, bankId: id, bankCode: code }
    }
  }

  const processOkraResponse = okra_data => {
    const { bank_id, accounts, customer_id, record_id } = okra_data;
    const { name: accountName, nuban, id } = accounts.find(account => account.connected);
    okraDataRef.current = { okraCustomerId: customer_id, okraRecordId: record_id, accountName, accountNumber: nuban, okraAccountId: id,  };
    resolveOkraBank(bank_id);
  }

  const handleClose = () => {
    if (!(okraDataRef.current && okraDataRef.current.okraCustomerId)) {
      setOkraError('You need to connect your bank account to be able to proceed with the application');
      setTimeout(() => setOkraError(''), 4000);
    }
    setOkraWidgetClosed(true);
  }

  useEffect(() => {
    if (okraWidgetClosed && okraDataRef.current && okraDataRef.current.okraCustomerId) {
      const { okraCustomerId, okraRecordId, okraAccountId } = okraDataRef.current;
      updateFormParams(prevState => ({ ...prevState, okraCustomerId, okraRecordId, okraAccountId }));
      updateContextState({ page: 'bankStatement', state: { ...okraDataRef.current } });
      next();
    } else if (okraWidgetClosed && !okraDataRef.current) {
      notify.show('You need to connect your bank account to be able to proceed with the application', 'error', 4000);
    }
  }, [okraDataRef, okraWidgetClosed]);

  const initiateOkra = useCallback(() => {
    const short_url = okraWidgetUrl.replace(/\s/g, '').split('/');
    setOkraWidgetClosed(false);
    Okra.buildWithShortUrl({
      short_url: short_url[short_url.length - 1],
      onSuccess: data => {
        processOkraResponse(data);
      },
      onClose: () => {
        handleClose();
      },
    });
  }, [okraWidgetUrl]);

  useEffect(() => {
    if (clientInfo) {
      setOkraWidgetUrl(clientInfo.okraWidgetUrl);
    } 
  }, [clientInfo]);

  useEffect(() => {
    if (!userData) {
      getPreviouslySavedData();
    }
  }, []);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={clientInfo && clientInfo.okraDirectDebitIsEnabled ? ('Bank Statement and Direct Debit Authorization') : ('Bank Statement')} centeralize hasPadding>
        {okraError && <Alert classes="margin-top error">{okraError}</Alert>}
        <div className="okra-page">
          <p className="sub-title">
            As part of your loan application, we connect to Okra to fetch your
            bank statement {clientInfo && clientInfo.okraDirectDebitIsEnabled &&  `and authorize Okra for direct debits on your recurring payments`}.
            This improves your chances in getting a loan. We value
            security therefore transfer of your information is encrypted and your
            login credentials will never be made accessible to us by Okra.<br/>
            <span className='notes'>
              Please Note: Your monthly repayments will be deducted from the bank account you have authorized for recurring direct debit on Okra
            </span>
          </p>
          <div className="margin-top space-apart single center">
            <Button click_event={initiateOkra}>Proceed To Okra</Button>
          </div>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
