import React, { useContext, useEffect } from 'react';
import './_Menu.scss';
import { NavLink } from 'react-router-dom';
import Context from '../../context';
import { HomeIcon, MyLoansIcon, SettingsIcon, NewLoanIcon } from '../Icon/';
import { ViewerProvider } from '../../providers';
import { useApolloClient } from '@apollo/react-hooks';
import styled from 'styled-components';

const Menu = (props) => {
  const { state } = useContext(Context);
  const clientTheme = state && state.clientInfo ? state.clientInfo.clientTheme : {};

  const dynamicStyle = {
    background: clientTheme.primaryColor
  };

  const StyledNav = styled.nav`
      .main-menu {
      &__left {
        li{
          a{
            &.active{
                border-bottom-color: rgb(53, 75, 156);
              }
          }
        }
      }
      &__right  {
        .button {
          color: rgb(53, 75, 156);
              svg {
                fill: rgb(53, 75, 156);
              }
        }
      }
    }
    `;

  return (
    <ViewerProvider>
      {({ currentLoan, latestLoan }) => {
        const hideNewLoanButton = currentLoan.id ||
            (latestLoan && latestLoan.status.name !== 'DENIED' && latestLoan.status.name !== 'APPROVED');

        const newLoanButton = <NavLink to='/loans/new' className='button icon-left secondary'>
                  <span className='icon'>
                    <NewLoanIcon />
                  </span>
                  <span className={'nav-text'}>New Loan</span>
                </NavLink>;

        return (
        <>
        <StyledNav className={'main-menu'} style={dynamicStyle}>
          <div className='container'>
            <div className='main-menu__left'>
              <ul>
                <li>
                  <NavLink to='/dashboard'>
                    <HomeIcon />
                    <span className={'nav-text'}>Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/loans'>
                    <MyLoansIcon />
                    <span className={'nav-text'}>My Loans</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/settings'>
                    <SettingsIcon />
                    <span className={'nav-text'}>Settings</span>
                  </NavLink>
                </li>
              </ul>
            </div>
            {!hideNewLoanButton && (
              <div className='main-menu__right'>
                {newLoanButton}
              </div>
            )}
          </div>
        </StyledNav>

        { !hideNewLoanButton && <div className="mobileNewLoan"> {newLoanButton}</div>}
        </>
      )
      }}
    </ViewerProvider>
  );
};

export default Menu;
