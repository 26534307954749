import moment from "moment";
import React, { useContext } from "react";
import Context from "../../context";

import "./_Footer.scss";

const Footer = () => {
  const { state } = useContext(Context);
  let clientTheme;
  let clientName;

  clientTheme = state && state.clientInfo ? state.clientInfo.clientTheme : {};
  clientName =
    state && state.clientInfo && state.clientInfo.name
      ? state.clientInfo.name
      : "...";

  const dynamicStyle = {
    background: clientTheme.primaryColor,
  };

  return (
    <footer className="footer" style={dynamicStyle}>
      <section className="container">
        &copy; {clientName} {moment().format("Y")}
      </section>
    </footer>
  );
};

export default Footer;
