import React from 'react';
import './_TransactionTable.scss';
import PropTypes from 'prop-types';

const TransactionTable = (props) => {
    return(
        <ul className={'transaction-table'}>
            {props.txTableHeaders === [] || props.txTableHeaders == null ? "" : (
                <li className='transaction-table__header'>
                    {props.txTableHeaders.map((val, index) =>
                        <div key={index}>{val}</div>
                    )}
                </li>
            )}
            {props.children}
        </ul>
    )
};

TransactionTable.defaultProps = {
    txTableHeaders: [],
};

TransactionTable.propTypes = {
    txTableHeaders: PropTypes.array,
};

export default TransactionTable;
