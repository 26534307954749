import React, { Fragment, useContext } from 'react';
import 'react-web-tabs/dist/react-web-tabs.css';
import Context from '../../context';
import { useApolloClient } from '@apollo/react-hooks';
import styled from 'styled-components';

import DataCardV2 from '../../components/DataCardV2/DataCard';
import { Page } from '../../components';

import { Link, NavLink, Route, Switch } from 'react-router-dom';
import {
  FacilitySettings,
  ServiceSettings,
  ProfileSettings,
  SecuritySettings,
  SupportingDocumentsSettings,
  NextOfKinSettings,
  BusinessSettings
} from '../index';
import './_Settings.scss';
import { ClientInformationProvider } from '../../providers';


export const Settings = props => {

  const { state } = useContext(Context);
  const clientTheme = state && state.clientInfo ? state.clientInfo.clientTheme : {};

    const StyledLink = styled.nav`
      a{
        &.active {
        border-color: ${clientTheme.secondaryColor};
      }
      }
    `;


  return (
    <ClientInformationProvider>
      {({ clientInfo }) => {
        return (
          <Fragment>
            <section className={'container settings_wrapper'}>
              <DataCardV2 noPad settingsCard>
                <div className='settings-menu__holder'>
                  <StyledLink className='settings-menu'>
                    <NavLink to='/settings' exact>
                      PROFILE
                    </NavLink>
                    <NavLink to='/settings/business-information'>
                      BUSINESS INFORMATION
                    </NavLink>
                    <NavLink to='/settings/payment'>PAYMENT OPTIONS</NavLink>
                    {clientInfo &&
                    clientInfo.requiredSupportingDocuments.length > 1 && (
                      <NavLink to='/settings/supporting-documents'>SUPPORTING DOCUMENTS</NavLink>
                    )}
                    <NavLink to='/settings/security'>CHANGE PASSWORD</NavLink>
                  </StyledLink>
                </div>
                <section className='main'>
                  <Switch>
                    <Route
                      path='/settings'
                      exact
                      render={props => <Page {...props} component={ProfileSettings} title='Settings' />}
                    />

                    <Route
                      path='/settings/business-information'
                      exact
                      render={props => <Page {...props} component={BusinessSettings} title='Settings' />}
                    />

                    <Route
                      path='/settings/referee'
                      exact
                      render={props => <Page {...props} component={NextOfKinSettings} title='Settings' />}
                    />

                    <Route
                      path='/settings/employment'
                      exact
                      render={props => <Page {...props} component={FacilitySettings} title='Settings' />}
                    />

                    <Route
                      path='/settings/payment'
                      exact
                      render={props => <Page {...props} component={ServiceSettings} title='Settings' />}
                    />

                    <Route
                      path='/settings/supporting-documents'
                      exact
                      render={props => <Page {...props} component={SupportingDocumentsSettings} title='Settings' />}
                    />
                    <Route
                      path='/settings/security'
                      exact
                      render={props => <Page {...props} component={SecuritySettings} title='Settings' />}
                    />

                    <Route
                      path=''
                      render={props => (
                        <section className='container not-found'>
                          <DataCardV2>
                            <h1>404</h1>
                            <h3>Page not found</h3>
                            <Link to='/dashboard' className='button'>
                              Return to Dashboard
                            </Link>
                          </DataCardV2>
                        </section>
                      )}
                    />
                  </Switch>
                </section>
              </DataCardV2>
            </section>
          </Fragment>
        );
      }}
    </ClientInformationProvider>
  );
};

export default Settings;
