import React from 'react'

const MyLoansIcon = props => (
    <svg viewBox={"0 0 23 18"} fill="none" {...props}>
        <path
            d="M22.263 2.842H7.106a.474.474 0 0 1 0-.947h15.157a.474.474 0 0 1 0 .947zM22.263 9.474H7.106a.474.474 0 0 1 0-.948h15.157a.474.474 0 0 1 0 .948zM22.263 16.105H7.106a.474.474 0 0 1 0-.947h15.157a.474.474 0 0 1 0 .947zM2.368 18A2.371 2.371 0 0 1 0 15.632a2.371 2.371 0 0 1 2.368-2.369 2.371 2.371 0 0 1 2.369 2.369A2.371 2.371 0 0 1 2.368 18zm0-3.79c-.783 0-1.42.638-1.42 1.422 0 .783.637 1.42 1.42 1.42.784 0 1.421-.637 1.421-1.42 0-.784-.637-1.421-1.42-1.421zM2.368 11.368A2.371 2.371 0 0 1 0 9a2.371 2.371 0 0 1 2.368-2.368A2.371 2.371 0 0 1 4.737 9a2.371 2.371 0 0 1-2.369 2.368zm0-3.789c-.783 0-1.42.638-1.42 1.421s.637 1.421 1.42 1.421c.784 0 1.421-.638 1.421-1.421s-.637-1.421-1.42-1.421zM2.368 4.737A2.371 2.371 0 0 1 0 2.368 2.371 2.371 0 0 1 2.368 0a2.371 2.371 0 0 1 2.369 2.368 2.371 2.371 0 0 1-2.369 2.369zm0-3.79c-.783 0-1.42.638-1.42 1.421 0 .784.637 1.421 1.42 1.421.784 0 1.421-.637 1.421-1.42 0-.784-.637-1.422-1.42-1.422z"
            fill="#fff"
        />
    </svg>
);

export default MyLoansIcon;
