import React, { useContext } from "react";
import PropTypes from "prop-types";

import Context from "../../context";
import "./_Button.scss";

const Button = (props) => {
  const { state } = useContext(Context);

  const dynamicStyle = {
    borderColor: 'rgb(53, 75, 156)',
    background: 'rgb(53, 75, 156)',
    color: "#FFFFFF",
  };

  const dynamicStyleSecondary = {
    borderColor: 'rgb(53, 75, 156)',
    background: "#FFFFFF",
    color: 'rgb(53, 75, 156)',
  };

  return (
    <button
      name={props.name}
      id={props.buttonId}
      value={props.value}
      style={!props.secondary ? dynamicStyle : dynamicStyleSecondary}
      className={`button ${props.className ? props.className : ""} ${
        props.classes == null ? "" : props.classes
      } ${props.disabled === true ? "loading" : ""}`}
      disabled={
        props.disabled === true || props.inactive === true ? "disabled" : ""
      }
      onClick={props.click_event}
      type={props.type ? props.type : "button"}
    >
      <span className="button-text">{props.children || props.button_text}</span>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.string,
  buttonId: PropTypes.string,
  button_text: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  click_event: PropTypes.func,
};

export default Button;
