import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { VIEWER_QUERY } from '../../../providers/User/queries';

const UPDATE_USER_DATA = gql(/* GraphQL */ `
  mutation UpdateUserData($number: String!, $validateBvn: Boolean) {
    updateUserData(
      input: { bvn: { number: $number, validateBvn: $validateBvn } }
    ) {
      success
    }
  }
`);

export const useUpdateUserData = () => {

  const [mutate, { data, loading, error }] = useMutation(UPDATE_USER_DATA, {
    refetchQueries: () => [{ query: VIEWER_QUERY }],
  })

  const updateUserData = ({ bvn }) => {
    mutate({
      variables: {
        number: bvn,
        validateBvn: true
      },
    });
  };

  return {
    data,
    updateUserData,
    updateUserError: error,
    updateUserLoading: loading,
  };
}
