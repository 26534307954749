import React, { Fragment } from 'react';
import { IdentityPassBvnValidator, VfdBvnValidator } from './components';

const BvnValidator = ({ bvnServiceProviders, completeValidation, bvn }) => {
  const enabledBvnProvider = bvnServiceProviders?.find((provider) => provider.enabled) || {};

  return (
    <Fragment>
      {enabledBvnProvider.name === "IDENTITY_PASS" &&
        <IdentityPassBvnValidator
          bvn={bvn}
          completeValidation={completeValidation}
        />
      }

      {enabledBvnProvider.name === "VFD" &&
        <VfdBvnValidator
          bvn={bvn}
          completeValidation={completeValidation}
        />
      }
    </Fragment>
  )
}
export default BvnValidator;
