import React, { useEffect, useState } from 'react';
import { Button, DocumentBox, Popup, Alert } from '../..';
import { CloseIcon } from '../../Icon/index';
import { numberWithCommas, getErrorMessages } from '../../../lib/utils';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { CONSENT_PROVIDERS } from '../../../providers/OfferLetter/queries';

const OfferLetterOptions = ({
  application,
  document,
  setLoading,
  popupToggle,
}) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [consentProviders, setConsentProviders] = useState([]);

  const { loading: consentProvidersLoading } = useQuery(CONSENT_PROVIDERS, {
    onError(errors) {
      setErrorMessage(errors.message);
      return;
    },
    onCompleted(data) {
      setConsentProviders(data.consentProviders);
    },
  });

  const handleConsentProviders = (e, value) => {
    e.preventDefault();

    if (value === 'DocuSign') {
      signWithDocuSign();
      return;
    } else if (value === 'OTP') {
      signWithOtp();
    }
  };

  const signWithDocuSign = () => {
    popupToggle('SignWithDocusign');
  };

  const signWithOtp = () => {
    popupToggle('SignWithOtp');
  };
  useEffect(() => {
    if (consentProvidersLoading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [consentProvidersLoading]);

  return (
    <div>
      <Popup title={'Sign your Offer Letter'} classes="offer-letter">
        <Button classes="close-popup" click_event={() => popupToggle('')}>
          <CloseIcon />
        </Button>
        {errorMessage ? <Alert classes="error">{errorMessage}</Alert> : ''}
        <p className="center-text">
          We require that you sign your offer letter in order to accept your
          loan offer.{' '}
          <b>Please read the offer letter carefully before signing</b>.
        </p>
        <section className="offer-letter-summary">
          <div className="offer-letter-summary__text">
            <span>Requested Amount</span>
            <span>NGN {numberWithCommas(application.amount)}</span>
          </div>

          <div className="offer-letter-summary__text">
            <span>Repayment Amount</span>
            <span>NGN {numberWithCommas(application.fullAmount)}</span>
          </div>

          {application.policy ? (
            <div className="offer-letter-summary__text">
              <span>Interest Rate</span>
              <span>{application.policy.interestRate.value}%</span>
            </div>
          ) : (
            ''
          )}

          <div className="offer-letter-summary__text">
            <span>Repayment Date</span>
            <span>{moment(application.dateOfRepayment).format('ll')}</span>
          </div>
        </section>

        <DocumentBox document={document} />

        {consentProviders ? (
          <div className="button-container">
            {consentProviders.map((item, index) => (
              <Button
                key={index}
                value={item.name}
                click_event={(e) => handleConsentProviders(e, item.name)}
              >
                Sign with {item.name}
              </Button>
            ))}
            <Button
              classes="decline-button "
              click_event={() => popupToggle('DeclineOfferLetter')}
            >
              Decline Offer Letter
            </Button>
          </div>
        ) : (
          ''
        )}
      </Popup>
    </div>
  );
};

export default OfferLetterOptions;
