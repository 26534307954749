import React from 'react'

const HomeIcon = props => (
    <svg viewBox={"0 0 20 18"} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.638 13.604v4.009c0 .371.297.668.668.668h4.826c.296 0 .593-.297.593-.668V8.704l1.188 1.114c.594.594 1.485-.371.891-.965L10.524.167c-.222-.223-.668-.223-.89 0L.205 8.853c-.593.594.223 1.485.891.965l1.337-1.262v9.057c0 .371.222.668.593.668h4.826c.297 0 .594-.297.594-.668v-4.009h3.192zM3.695 7.368l6.384-5.865 6.384 6.014v9.502h-3.489V13.01a.665.665 0 0 0-.668-.668H7.852a.665.665 0 0 0-.668.668v4.009h-3.49v-9.65z"
            fill="#fff"
        />
    </svg>
);

export default HomeIcon;
