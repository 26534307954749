import React from 'react'

const FailureIcon = props => (
    <svg width={92} height={92} fill="none" {...props}>
        <g filter="url(#prefix__filter0_d)">
            <circle cx={46} cy={42} r={42} fill="#9A3334" />
            <circle cx={46} cy={42} r={41} stroke="#fff" strokeWidth={2} />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.413 31.68a2.186 2.186 0 0 0-3.092-3.092l-7.993 7.992a4 4 0 0 1-5.657 0l-7.992-7.992a2.186 2.186 0 0 0-3.092 3.091l7.993 7.993a4 4 0 0 1 0 5.657l-7.992 7.992a2.186 2.186 0 1 0 3.091 3.092l7.992-7.993a4 4 0 0 1 5.657 0l7.993 7.993a2.186 2.186 0 1 0 3.092-3.092L52.42 45.33a4 4 0 0 1 0-5.657l7.992-7.992z"
            fill="#fff"
        />
        <defs>
            <filter
                id="prefix__filter0_d"
                x={0}
                y={0}
                width={92}
                height={92}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={2} />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default FailureIcon;
