import React, {Fragment} from 'react';
import 'react-web-tabs/dist/react-web-tabs.css';
import './_Settings.scss';
import {
  Card,
  Input,
  Radio,
  RadioGroup,
  Button,
  Loader,
  Alert
} from "../../components"

import { ViewerProvider, CurrentTimeProvider, UpdateUserDetailsProvider } from '../../providers';

export const EmploymentSettings = () => <ViewerProvider>{({ user, remitaCustomer }) => <section className={'container'}>
    <CurrentTimeProvider>{(timeProps) =>
        <UpdateUserDetailsProvider user={user} timeProps={timeProps}>{({ form, onSubmit, loading, errorMessage, successMessage }) =>
            <div className="employment-settings">

                <Card
                    title={'Employment Status'}
                    subtitle={'Do you currently have a job'}
                >
                    <RadioGroup classes="two-grid-column">
                        <Radio label="Employed Full Time" name="employmentStatus" {...form.employmentStatus.input("Employed Full Time")}/>
                        <Radio label="Self Employed" name="employmentStatus" {...form.employmentStatus.input("Self Employed")}/>
                    </RadioGroup>
                </Card>

                <Card
                    title={'Company Name'}
                    subtitle={'Where do you work?'}
                >
                    <div>
                        <Input
                            label="Company Name"
                            classes="border-bottom"
                            placeholder=""
                            name="companyName"
                            readOnly={remitaCustomer}
                            {...form.employerName.input}
                        />
                    </div>
                </Card>
                
                <Card
                    title={'Work Email'}
                    subtitle={'Your official email address at work'}
                >
                    <div>
                        <Input
                            label="Work Email"
                            classes="border-bottom"
                            placeholder=""
                            name="workEmail"
                            {...form.workEmail.input}
                        />
                    </div>
                </Card>
                
               
                <Card
                    title={'Company Address'}
                    subtitle={'Where is your company located?'}
                >
                    <div>
                        <Input label="company address" classes="border-bottom" placeholder="" name="companyAddress" {...form.workAddress.input} />
                    </div>
                </Card>
                <Card
                    title={'Net Monthly Income'}
                    subtitle={'What is your take home?'}
                >
                    <div>
                        <Input
                            label="Net monthly income"
                            classes="border-bottom"
                            placeholder=""
                            amountField
                            name="netMonthlyIncome"
                            readOnly={remitaCustomer}
                            {...form.netIncome.input}
                        />
                    </div>
                </Card>
                <Card>
                    {errorMessage && <Alert classes='error'>{errorMessage}</Alert>}
                    {successMessage && <Alert classes='success'>{successMessage}</Alert>}
                    {loading ? <Loader /> : <Button click_event={onSubmit}>Update Profile</Button>}
                </Card>
            </div>
        }</UpdateUserDetailsProvider>
    }</CurrentTimeProvider>

</section>
}</ViewerProvider>;

export default EmploymentSettings;
