import React, { Fragment, useState } from 'react';
import './_LoanDetailsCard.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RepaymentBreakdown, Button, Tag, Popup, FormGroup } from '../index';
import {
  numberWithCommas,
  removeUnderscore,
  splitCommaToArray,
} from '../../lib/utils';
import { CdnFileProvider, ClientInformationProvider } from '../../providers';
import { MasterCard } from '../../components/Icon';

const LoanDetailsCard = ({
  amountPaid,
  fullAmount,
  status,
  loanDate,
  applicationDate,
  amountDisbursed,
  interestRate,
  numberOfInstallments,
  monthlyInstallment,
  portfolioNumber,
  applicationNumber,
  amountRequested,
  repaymentDate,
  lateRepaymentFee,
  rejectReason,
  paymentMethod,
  application,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const percentComplete = 100 - ((fullAmount - amountPaid) / fullAmount) * 100;
  let percentCompleted = '10';

  const handleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const offerLetter =
    application &&
    application.status &&
    application.reviewFeedbacks &&
    application.reviewFeedbacks.find(
      (feedback) => feedback.reviewType === 'OFFER_LETTER_REQUEST',
    );

  const offerLetterDocument = offerLetter
    ? offerLetter.documentRequests[0].document
    : {};

  return (
    <Fragment>
      <ClientInformationProvider>
        {({ slug, bankDetails }) => {
          const tandcUrl = `https://www.fcmb.com/legal/terms/#term_id_2`;
          return (
            <section className="loan-details-card">
              <section className="loan-details-card__left">
                <h6>Current Loan</h6>
                {portfolioNumber ? (
                  <h2 className="loan-details-card_amount">
                    N {numberWithCommas(amountPaid)}
                    <small>of</small>N {numberWithCommas(fullAmount)}
                  </h2>
                ) : (
                  <h3>N {numberWithCommas(amountRequested)}</h3>
                )}
                <div className="loan-details-card_two-grid">
                  {portfolioNumber ? (
                    <h5 className="loan-details-card_percent">
                      {percentCompleted ? percentCompleted : 0}% Complete
                    </h5>
                  ) : (
                    <h5 className="loan-details-card_percent">0% Complete</h5>
                  )}
                  <h5>
                    Status:{' '}
                    <Tag
                      status={
                        removeUnderscore(status).toLowerCase() === 'closed'
                          ? 'paid'
                          : status.toLowerCase() === 'disbursed'
                          ? 'active'
                          : status.toLowerCase() === 'pending_disbursement'
                          ? 'approved'
                          : removeUnderscore(status).toLowerCase()
                      }
                    />
                  </h5>
                </div>
                <div>
                  {removeUnderscore(status).toLowerCase() === 'denied' ? (
                    <Button classes="button-link" click_event={handleShowPopup}>
                      <small>View Reasons</small>
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
                <hr className="hr" />
                <div className="loan-details-card_repaymentDetails">
                  <li>
                    Next Payment:{' '}
                    <span>
                      {repaymentDate
                        ? moment(repaymentDate).format('ll')
                        : 'NA'}
                    </span>
                  </li>
                  <li>
                    Payment Method:{' '}
                    <span>{paymentMethod === '' ? 'NA' : paymentMethod}</span>
                  </li>
                  <li>
                    Amount: <span> N{amountPaid}</span>
                  </li>
                </div>
              </section>

              {showPopup && status.toLowerCase() === 'denied' && (
                <Popup
                  title="Why you didn’t get your loan"
                  classes="loan-requirements"
                >
                  <ul>
                    {rejectReason &&
                      splitCommaToArray(rejectReason).map((reason) => (
                        <li>{reason}</li>
                      ))}
                  </ul>

                  <div className="button-list center-text">
                    <Button
                      classes="close-button"
                      click_event={handleShowPopup}
                    >
                      Close
                    </Button>
                  </div>
                </Popup>
              )}

              <section className="loan-details-card__right">
                <RepaymentBreakdown>
                  {loanDate ? (
                    <li>
                      You got this loan on
                      <span className="value">
                        {moment(loanDate).format('ll')}
                      </span>
                    </li>
                  ) : (
                    <li>
                      Application Date
                      <span className="value">
                        {moment(applicationDate).format('ll')}
                      </span>
                    </li>
                  )}
                  {amountDisbursed ? (
                    <li>
                      Amount Disbursed
                      <span className="value">
                        N {numberWithCommas(amountDisbursed)}
                      </span>
                    </li>
                  ) : (
                    <li>
                      Loan Amount
                      <span className="value">
                        N {numberWithCommas(amountRequested)}
                      </span>
                    </li>
                  )}
                  {interestRate ? (
                    <li>
                      Interest Rate
                      <span className="value">{interestRate}p.a %</span>
                    </li>
                  ) : (
                    ''
                  )}
                  {numberOfInstallments ? (
                    <li>
                      Number of Installments
                      <span className="value">{numberOfInstallments}</span>
                    </li>
                  ) : (
                    ''
                  )}
                  {monthlyInstallment ? (
                    <li>
                      Monthly Installments
                      <span className="value">
                        N {numberWithCommas(monthlyInstallment)}
                      </span>
                    </li>
                  ) : (
                    ''
                  )}

                  <div className="quick-repay">
                    <a
                      className="button small"
                      href={tandcUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Contract & Terms
                    </a>
                    {offerLetter ? (
                      <CdnFileProvider file={offerLetterDocument.file}>
                        {({ openFile }) => (
                          <Button
                            secondary
                            className="secondary small"
                            click_event={openFile}
                          >
                            View Offer Letter
                          </Button>
                        )}
                      </CdnFileProvider>
                    ) : (
                      ''
                    )}
                  </div>
                </RepaymentBreakdown>
              </section>
            </section>
          );
        }}
      </ClientInformationProvider>
    </Fragment>
  );
};

LoanDetailsCard.defaultProps = {
  amountPaid: 0,
  amountRequested: 0,
  fullAmount: 0,
  status: '',
  loanDate: '',
  applicationDate: '',
  amountDisbursed: 0,
  interestRate: '',
  numberOfInstallments: 0,
  monthlyInstallment: 0,
  portfolioNumber: '',
  rejectReason: '',
};

LoanDetailsCard.propTypes = {
  amountPaid: PropTypes.number,
  amountRequested: PropTypes.number,
  fullAmount: PropTypes.number,
  status: PropTypes.string,
  loanDate: PropTypes.string,
  applicationDate: PropTypes.string,
  amountDisbursed: PropTypes.number,
  interestRate: PropTypes.string,
  numberOfInstallments: PropTypes.number,
  monthlyInstallment: PropTypes.number,
  portfolioNumber: PropTypes.string,
  rejectReason: PropTypes.string,

  repaymentAmount: PropTypes.number,
  repaymentDate: PropTypes.any,
  overlay: PropTypes.bool,
};

export default LoanDetailsCard;
