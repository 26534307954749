import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { Query } from 'react-apollo';
import { useApolloClient, useSubscription } from '@apollo/react-hooks';
import { Loader } from '../../components';

import { VIEWER_QUERY, PORTFOLIO_BY_ID_SUBSCRIPTION } from './queries';
import Constants from '../../lib/constants';
import {authGetter, getErrorMessages, getGlobalClientId} from '../../lib/utils';
import { LoanService, AuthService, UserService } from '../../services';

const { status, duration } = Constants;

const ViewerProvider = ({ children, history }) => {
  const [skipSubscribe, setSkipSubcribe] = useState(true);
  const [portfolioId, setPortfolioId] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [failureReason, setFailureReason] = useState('');
  const [showCombineModal, setCombineModal] = useState(false);
  const [showFundTransferModal, setFundTransferModal] = useState(false);
  const [showFundTransferSuccessModal, setFundTransferSuccessModal] = useState(
    false
  );
  const [remitaStatus, setRemitaStatus] = useState(false);

  const apolloClient = useApolloClient();
  useSubscription(PORTFOLIO_BY_ID_SUBSCRIPTION, {
    variables: { portfolioId },
    onSubscriptionData: ({
      subscriptionData: { data: { portfolioByIdUpdates } = {} },
    }) => {
      if (portfolioByIdUpdates) {
        switch (portfolioByIdUpdates.event) {
          case 'RAVE_BANK_TRANSFER_SUCCESS':
            // TODO:
            break;
          case 'RAVE_BANK_TRANSFER_FAILURE':
            // TODO:
            break;
          case 'RAVE_BANK_TRANSFER_ERROR':
            // TODO:
            break;
          default:
          // TODO:
        }
      }
    },
    skip: skipSubscribe,
  });

  const redirectToSignIn = async (message, type, duration) => {
    notify.show(message, type, duration);
    localStorage.clear();
    history.push('/sign-in');
    // Clear apollo cache on sign out
    await apolloClient.resetStore();
  };

  const checkUserLoggedIn = () => {
    const apiKey = authGetter();

    if (!apiKey.hasApiKey) {
      redirectToSignIn(
        'Your session has expired. Kindly re-login to proceed',
        status.ERROR,
        duration.LONG
      );
    }
  };

  const getCustomerRemitaStatus = async () => {
    setLoading(true);

    const {
      data: {
        data: {
          viewer: { me },
        },
      },
    } = await UserService.viewer();
    const phone = me.phone;

    const response = await AuthService.customerRemitaStatus(
      phone,
      getGlobalClientId()
    );

    const { customerRemitaStatus } = response.data.data;

    if (customerRemitaStatus) {
      return customerRemitaStatus;
    }

    return false;
  };

  const getCurrentLoan = portfolios => {
    const loan = portfolios.find(({ status }) => status.name !== 'CLOSED');

    if (loan) {
      loan.percent =
        ((loan.fullAmount - loan.amountPaid) / loan.fullAmount) * 100 - 100;
      return loan;
    }
    return {};
  };

  const triggerFundTransfer = e => {
    setFundTransferModal(!showFundTransferModal);
  };

  const getDefaultBankInfo = (bankDetails = []) => {
    const bank =
      bankDetails.length !== 0
        ? bankDetails.filter(item => item.isDefault)
        : [];

    return bank;
  };

  const checkStuckApplications = (applications = []) => {
    applications.forEach(({ id, status, requiredSteps, completedSteps }) => {
      if (
        status.name === 'PENDING' &&
        requiredSteps &&
        completedSteps &&
        requiredSteps.length > 0
      ) {
        if (requiredSteps.every(elem => completedSteps.indexOf(elem) > -1)) {
          // run compeleteApplication
          try {
            LoanService.completeApplication(id);
          } catch (error) {
            // do nothing
          }
        }
        // add more logic for other cases
      }
    });
  };

  const subscribeToPortfolio = portfolioId => {
    setPortfolioId(portfolioId);
    setSkipSubcribe(false);
  };

  const resetModalDisplay = () => {
    setFundTransferModal(false);
    setFundTransferSuccessModal(false);
  };

  const handleFundTransfer = async (e, params) => {
    setLoading(true);
    const {
      sourceAccountNumber,
      sourceAccountName,
      amount,
      description,
      portfolioId,
    } = params;

    const response = await LoanService.transferFundsToBankAccount(
      sourceAccountNumber,
      sourceAccountName,
      amount,
      description,
      portfolioId
    );

    setLoading(false);

    if (response == null || typeof response === 'undefined') {
      setFailureReason('Error! Transfer was unsuccessful. Kindly retry.');
      resetModalDisplay();
      setCombineModal(true);
      return;
    }

    if (response.data.errors) {
      resetModalDisplay();
      setCombineModal(true);
      setFailureReason('Oops! Transfer was unsuccessful, kindly retry.');
      return;
    }

    if (response.data.data.transferFundsToBankAccount) {
      resetModalDisplay();
      setFundTransferSuccessModal(true);
    }
  };

  return (
    <Query query={VIEWER_QUERY} fetchPolicy="cache-and-network">
      {({ data, loading = isLoading, error, refetch }) => {
        const props = {
          error,
          loading,
          checkUserLoggedIn,
          user: {},
          account: {},
          portfolios: [],
          currentLoan: {},
          applications: [],
          latestLoan: {},
          businessInfo: {},
          refetch,
          handleFundTransfer: (e, params) => handleFundTransfer(e, params),
          triggerFundTransfer: e => triggerFundTransfer(e),
        };

        if (loading) return <Loader />;

        if (error) {
          notify.show(
            'An error has occurred. Please try again later.',
            Constants.status.ERROR,
            4000
          );
          return children;
        }

        const renderMetadata = userMetadata => {
          let renderedMetadata = {};
          if (userMetadata && userMetadata.length) {
            userMetadata.forEach(metadata => {
              renderedMetadata = {
                ...renderedMetadata,
                ...{ [metadata.name]: metadata.value },
              };
            });
          } else {
            renderedMetadata = {
              refereeName: '',
              refereeEmail: '',
              refereePhone: '',
              refereeWorkPlace: '',
            };
          }
          return renderedMetadata;
        };

        if (data && !loading && !error) {
          const { viewer } = data;
          const portfolios = viewer.account.portfolios.nodes;

          const watchPortfolio = portfolios.find(
            ({ status }) =>
              status.name === 'DISBURSING' ||
              status.name === 'PENDING_DISBURSEMENT'
          );
          if (watchPortfolio) subscribeToPortfolio(watchPortfolio.id);
          checkStuckApplications(viewer.account.applications.nodes);
          const metadata = renderMetadata(viewer.me.userMetadata);
          const businessInfo = viewer.account.accountAttribute.find(
            attr => attr.attribute.name === 'businessInformation'
          );

          const applications = viewer.account.applications.nodes;
          const activeApplication = applications.find(a => a.status.name !== 'DENIED' && a.status.name !== 'APPROVED');

          props.viewer = viewer;
          props.user = viewer.me;
          props.user.metadata = metadata;
          props.account = viewer.account;
          props.defaultBankAccount = getDefaultBankInfo(
            viewer.account.bankAccounts
          );
          props.portfolios = portfolios;
          props.currentLoan = getCurrentLoan(viewer.account.portfolios.nodes);
          props.isLoading = isLoading;
          props.showCombineModal = showCombineModal;
          props.setCombineModal = setCombineModal;
          props.failureReason = failureReason;
          props.showFundTransferModal = showFundTransferModal;
          props.showFundTransferSuccessModal = showFundTransferSuccessModal;
          props.applications = applications;
          props.latestLoan = activeApplication || applications[0];
          props.remitaCustomer = remitaStatus;
          props.businessInfo = businessInfo ? businessInfo.data : {};
        }
        return children(props);
      }}
    </Query>
  );
};

export default withRouter(ViewerProvider);
