import React, {Fragment} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authGetter } from "../../lib/utils";

const authCreds = authGetter();

const PreLoginRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        !authCreds.hasApiKey || authCreds.isTempApiKey ? (

            <Fragment>
                <Component {...props} />
            </Fragment>

        ) : (
            <Redirect to={{
                pathname: 'dashboard',
            }}
            />
        )
    )} />
);


export default PreLoginRoute;
