import React, {Fragment} from 'react';
import './_Card.scss';
import PropTypes from 'prop-types';

const Card = (props) => {
    return(
        <div className={'card'}>
            <div className="card-desc">
                {props.title == null ? "" : (
                    <Fragment>
                        <h4 className={'card-title'}>{props.title}</h4>
                        <h5 className={'card-subtitle'}>{props.subtitle}</h5>
                    </Fragment>
                )}
            </div>
            <div className={'card-content'}>
                {props.children}
            </div>
        </div>
    )
};

Card.propTypes = {
    title: PropTypes.string,
    classes: PropTypes.string,
    subtitle: PropTypes.string,
};

export default Card;