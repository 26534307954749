import React from 'react'

const MasterCard = props => (
    <svg width={39} height={24} viewBox="0 0 39 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.73 12c0 6.627-5.362 12-11.977 12s-11.978-5.373-11.978-12S20.139 0 26.753 0C33.368 0 38.73 5.373 38.73 12z"
                fill="#F79E1B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.955 12c0 6.627-5.363 12-11.978 12C5.362 24 0 18.627 0 12S5.362 0 11.977 0s11.978 5.373 11.978 12z"
                fill="#EB001B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.365 2.554a11.984 11.984 0 00-4.588 9.445 11.99 11.99 0 004.588 9.448A11.989 11.989 0 0023.954 12c0-3.835-1.794-7.248-4.589-9.445z"
                fill="#FF5F00"
            />
    </svg>
);

export default MasterCard
