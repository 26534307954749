import React, { Fragment, useState } from 'react';
import { Alert, Button, Loader } from '..';
import PropTypes from 'prop-types';
import {
  OfferLetterOptions,
  SignWithDocusign,
  SignWithOtp,
  DeclineOfferLetter,
  OfferLetterDeclined,
  OfferLetterSigned,
  DocusignSent,
} from './SubComponents';
import './OfferLetter.scss';

const OfferLetter = ({ application, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [visiblePopup, setVisiblePopup] = useState('OfferLetterOptions');

  const pendingOffer =
    application &&
    application.status &&
    application.status.name !== 'APPROVED' &&
    application.status.name !== 'DENIED' &&
    application.reviewFeedbacks &&
    application.reviewFeedbacks.find(
      feedback => feedback.status === 'PENDING'
    ) &&
    application.reviewFeedbacks.find(
      feedback =>
        feedback.status === 'PENDING' &&
        feedback.reviewType === 'OFFER_LETTER_REQUEST'
    );

  const offerLetterDocument = pendingOffer
    ? pendingOffer.documentRequests[0].document
    : {};
  return (
    <Fragment>
      {pendingOffer ? (
        <div className="offer-letter">
          {loading ? <Loader /> : ''}
          {visiblePopup && visiblePopup === 'OfferLetterOptions' ? (
            <OfferLetterOptions
              application={application}
              document={offerLetterDocument}
              setLoading={setLoading}
              popupToggle={setVisiblePopup}
            />
          ) : (
            ''
          )}
          {visiblePopup && visiblePopup === 'SignWithDocusign' ? (
            <SignWithDocusign
              application={application}
              popupToggle={setVisiblePopup}
              setLoading={setLoading}
            />
          ) : (
            ''
          )}
          {visiblePopup && visiblePopup === 'SignWithOtp' ? (
            <SignWithOtp
              application={application}
              pendingOffer={pendingOffer}
              popupToggle={setVisiblePopup}
              setLoading={setLoading}
              refetch={refetch}
            />
          ) : (
            ''
          )}
          {visiblePopup && visiblePopup === 'DeclineOfferLetter' ? (
            <DeclineOfferLetter
              application={application}
              popupToggle={setVisiblePopup}
              setLoading={setLoading}
              refetch={refetch}
            />
          ) : (
            ''
          )}
          {visiblePopup && visiblePopup === 'OfferLetterDeclined' ? (
            <OfferLetterDeclined />
          ) : (
            ''
          )}
          {visiblePopup && visiblePopup === 'OfferLetterSigned' ? (
            <OfferLetterSigned />
          ) : (
            ''
          )}

          {visiblePopup && visiblePopup === 'DocusignSent' ? (
            <DocusignSent />
          ) : (
            ''
          )}
          {!visiblePopup ? (
            <Alert classes="error">
              Your need to sign the offer letter attached to your application in
              order to process your loan. Click{' '}
              <Button
                click_event={() => setVisiblePopup('OfferLetterOptions')}
                classes="button-link-offer"
              >
                here
              </Button>{' '}
              to sign the offer letter
            </Alert>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

OfferLetter.propTypes = {
  application: PropTypes.object,
};

export default OfferLetter;
