import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';

import './_DataCard.scss';

const DataCard = (props) => {
  return (
    <article
      className={cx({
        'data-card': props,
        'no-pad': props.noPad,
        loading: props.loading,
        centeralize: props.centeralize,
        repaymentCard: props.repaymentCard,
        loanDetailsCard: props.loanDetailsCard,
        settingsCard: props.settingsCard,
        [props.classes]: props.classes
      })}
    >
      {props.title == null ? '' : <h4 className={'data-card__title'}>{props.title}</h4>}
      {props.subTitle == null ? (
        ''
      ) : (
        <h4
          className={cx({
            'data-card__subTitle': props,
            hasPadding: props.hasPadding
          })}
        >
          {Parser(props.subTitle)}
        </h4>
      )}
      <div className={'data-card__content'}>{props.children}</div>
    </article>
  );
};

DataCard.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
  noPad: PropTypes.bool
};

export default DataCard;
