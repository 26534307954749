import React, { Fragment, useEffect, useState } from "react";
import Notifications, { notify } from "react-notify-toast";

import { DataCardV2, Button, Input, Loader, Alert, CheckBox } from "../../../../components";
import { BankService } from "../../../../services";
import { getErrorMessages, authGetter } from "../../../../lib/utils";
import { AuthService } from "../../../../services";
import Constants from '../../../../lib/constants';

const TicketNumber = ({
  formParams,
  userData,
  setUserData,
  navigation,
  formInfo = {},
  updateFormParams,
  baseInputChangeHandler,
  updateContextState,
}) => {
  const { mbsTicketNo, mbsOtp, skipStep } = formParams;
  const { title, subTitle } = formInfo;
  const [isLoading, updateLoadingState] = useState(false);
  const [responseError, setResponseError] = useState([]);
  const [inputErrors, setInputErrors] = useState([]);
  const [isSubmitted, updateSubmit] = useState(false);
  const { go } = navigation;
  const [isRetried, setIsRetried] = useState(true);
  let mySuccessColor = { background: '#095712', text: '#FFFFFF' };
  const { status, duration } = Constants;
  const [ otpTimer, setOtpTimer ] = useState(true);
  const [skipMbsTimer, setSkipMbsTimer] = useState(true)

  const handleRadioChange = (e) => {
    const name = e.target.name;

    updateFormParams((prevState) => ({
      ...prevState,
      [name]: !formParams.skipStep
    }));
  };

  const initiateBankStatement = async () => {
    updateLoadingState(true);

    let bankId, applicationId;

    if (userData && Object.keys(userData).length !== 0) {
      applicationId = userData.account.applications.nodes[0].id;
      bankId = userData.account.bankAccounts[0].bank.id;
    }

    const response = await BankService.initiateBankStatementReq( applicationId ,bankId, isRetried);
    updateLoadingState(false);

    if (!response) {
      setResponseError({ initiateBankStatement: "Error initiating bank statement request" });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ initiateBankStatement: error });
      return;
    }

    const { initiateBankStatementRequest } = response.data.data;

    if (initiateBankStatementRequest.success) {
      notify.show('OTP resent successfully', 'success', 4000, mySuccessColor);
      return;
    } else {
      setResponseError({ initiateBankStatement: "Error initiating bank statement request." });
      return;
    }
  };

  const resendMBSOTP = async () => {
    setIsRetried(true);

    await initiateBankStatement();
  };

  const completeExternalBankStatementReq = async (applicationId) => {
    const { mbsOtp, mbsTicketNo, skipStep } = formParams;
    updateLoadingState(true);

    const response = await BankService.completeExternalBankStatementRequest(applicationId, mbsTicketNo, mbsOtp, skipStep);
    updateLoadingState(false);

    if (!response) {
      setResponseError({ completeBankStatement: "Error getting bank statement" });
      // TO DO push user to error page
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ completeBankStatement: error });
      // TO DO push user to error page
      return;
    }

    const { completeExternalBankStatementRequest } = response.data.data;

    if (completeExternalBankStatementRequest.success) {
      let businessInformation = { nextStage: "supportingDocuments" };
      const response = await AuthService.updateUserDataV2({ businessInformation });

      if (response) {
        updateContextState({ page: 'confirmRequest' });
        go("confirmRequest");
      }

      return;
    } else {
      // TO DO push user to error page
      setResponseError({ completeBankStatement: "Error! We were unable to get the bank statement. Try again." });
      return;
    }
  };

  const completeBankStatement = async (applicationId) => {
    const { mbsOtp, mbsTicketNo, skipStep } = formParams;

    updateLoadingState(true);
    const response = await BankService.completeBankStatementReq(applicationId, mbsTicketNo, mbsOtp, skipStep);

    updateLoadingState(false);
    if (!response) {
      setResponseError({ completeBankStatement: "Error getting bank statement" });
      // TO DO push user to error page
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ completeBankStatement: error });
      // TO DO push user to error page
      return;
    }

    const { completeBankStatementRequest } = response.data.data;

    if (completeBankStatementRequest.success) {
      if (skipStep) {
        updateContextState({ page: 'mbsUpload' })
        go("mbsUpload")
      } else {
        updateContextState({ page: 'confirmRequest' });
        go("confirmRequest");
      }
    } else {
      // TO DO push user to error page
      setResponseError({ completeBankStatement: "Error getting your bank statement." });
      return;
    }
  };

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    updateLoadingState(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    const response = viewer ? viewer.data.data.viewer : {};
    if (response) setUserData(response);

    setTimeout(() => {
      setSkipMbsTimer(false)
    }, 100000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  const checkValidation = () => {
    validateForm("mbsTicketNo", formParams["mbsTicketNo"]);
  };

  useEffect(() => {
    checkValidation();
  }, [formParams, isSubmitted]);

  const validateMBSTicket = (mbsTicketNo) => {
    const response = /^(\d{6,8})-(\d{1,3})/;
    return response.test(mbsTicketNo);
  };

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case "mbsTicketNo":
        !validateMBSTicket(value) && !skipStep
          ? setInputErrors((prevState) => ({
              ...prevState,
              mbsTicketNo: "Please, enter valid Ticket Number sent by your bank.",
            }))
          : setInputErrors((prevState) => ({ ...prevState, mbsTicketNo: "" }));
        break;
      default:
        break;
    }
  };

  const submitData = () => {
    if (userData && Object.keys(userData).length !== 0) {
      const { applications, bankAccounts, accountAttribute } = userData.account;
      const { requiredSteps, completedSteps } = applications.nodes[0];
      const applicationId = applications.nodes[0].id;

      if (
        requiredSteps.includes("COMPLETE_BANK_STATEMENT_REQUEST") &&
        !completedSteps.includes("COMPLETE_BANK_STATEMENT_REQUEST")
      ) {
        completeBankStatement(applicationId);
        return;
      }

      if (
        requiredSteps.includes("COMPLETE_EXTERNAL_BANK_STATEMENT_REQUEST") &&
        !completedSteps.includes("COMPLETE_EXTERNAL_BANK_STATEMENT_REQUEST")
      ) {
        completeExternalBankStatementReq(applicationId);
        return;
      }

      if (requiredSteps.includes("GENERATE_CREDIT_SCORE") && !completedSteps.includes("GENERATE_CREDIT_SCORE")) {
        updateContextState({ page: 'confirmRequest' });
        go('confirmRequest');
        return;
      }

      return;
    }
  };

  const nextStep = () => {
    updateSubmit(true);
    const errors = Object.keys(inputErrors).filter((item) => inputErrors[item] !== "");

    if (!formParams.mbsOtp &&
        !formParams.mbsTicketNo &&
        skipStep === false) {
        notify.show('Please enter Ticket Number and Password or Skip to Upload Bank Statement', status.ERROR, duration.LONG);
    } else if ((formParams.mbsOtp &&
        !formParams.mbsTicketNo &&
        skipStep === false) ||
            (!formParams.mbsOtp &&
                formParams.mbsTicketNo &&
                skipStep === false)) {
      notify.show('Please enter Ticket Number and Password ', status.ERROR, duration.LONG);
    } else if (skipStep){
      go("mbsUpload")
    }
    else if (errors.length < 1) {
      submitData();
    }
  };

  const useCountDown = (start) => {
    const [ counter, setCounter ] = useState(start);
    useEffect(
      () => {
        if (counter === 0) {
          setOtpTimer(false)
          setSkipMbsTimer(true)
          return;
        }
        setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
      },
      [ counter ]
    );
    return counter;
  };

 const Countdown = ({ seconds }) => {
    const timeLeft = useCountDown(seconds);
    return <div> Resend OTP in <span className="timer">00:{timeLeft}s</span></div>;
  };


  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        <Notifications />

        {responseError && responseError.length !== 0 && (
          <Fragment>
            {responseError.initiateBankStatement ? (
              <Alert classes="error">{responseError.initiateBankStatement}</Alert>
            ) : (
              ""
            )}
            {responseError.completeBankStatement ? (
              <Alert classes="error">{responseError.completeBankStatement}</Alert>
            ) : (
              ""
            )}
          </Fragment>
        )}
        <div className="form_content">
          <Input
            type="text"
            placeholder="Ticket Number: 123456-7"
            label="Ticket Number"
            name="mbsTicketNo"
            minLength={4}
            readOnly={false}
            onChange={baseInputChangeHandler}
            classes={`border-bottom`}
            disabled={formParams.skipStep}
            errorMessage={isSubmitted && inputErrors.mbsTicketNo ? inputErrors.mbsTicketNo : ""}
          />

          <Input
            numberField
            type="text"
            placeholder="OTP / Password"
            label="OTP / Password"
            name="mbsOtp"
            readOnly={false}
            onChange={baseInputChangeHandler}
            classes={`border-bottom`}
            disabled={formParams.skipStep}
          />
        </div>
        {otpTimer ? (
          <Countdown seconds={59} />
        ) : (
          <div>
            <p>Didn't get Ticket Number/OTP sent by your Bank?</p>
            <Button classes='button-link-otp' click_event={resendMBSOTP}>
              Resend OTP
            </Button>{" "}
            <br />
          </div>
        )}
        {!skipMbsTimer && <CheckBox
          name='skipStep'
          label='Skip to Upload Bank Statement'
          disabled={formParams.mbsOtp || formParams.mbsTicketNo}
          onChange={(e) => handleRadioChange(e)}
        />}
        <div className="space-apart single right">
          {" "}
          <Button click_event={nextStep}>Continue</Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default TicketNumber;
