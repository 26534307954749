import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { SINGLE_APPLICATION_QUERY } from './queries';
import { Loader } from '../../components';

const ApplicationDetails = ({ children, match: { params } }) => {
  const { applicationNumber } = params;

  return (
    <Query
      query={SINGLE_APPLICATION_QUERY}
      variables={{ applicationNumber: applicationNumber }}
      fetchPolicy="cache-and-network"
    >
      {({ loading, error, data, refetch }) => {
        const props = {
          error,
          loading,
          refetch,
          amount: '',
          status: {},
          application: '',
          policy: '',
          createdAt: '',
          amount: 0,
          loanDuration: 0,
          fullAmount: '',
          applicationNumber: '',
          dateOfRepayment: '',
          rejectReason: '',
          moratoriumData: {},

          portfolio: {
            amountPaid: '',
            fullAmount: '',
            status: { name: '' },
            dateOfRepayment: '',
            createdAt: '',
            amountDisbursed: '',
            lateRepaymentFee: '',
          },
        };

        if (loading) {
          return <Loader />;
        }

        if (error) {
          return children(props);
        }

        if (!loading && !error && data) {
          const { application } = data;

          props.application = application;
          props.amount = application.amount;
          props.status = application.status;
          props.policy = application.policy;
          props.createdAt = application.createdAt;
          props.amount = application.amount;
          props.loanDuration = application.loanDuration;
          props.fullAmount = application.fullAmount;
          props.applicationNumber = application.applicationNumber;
          props.portfolio = application.portfolio;
          props.dateOfRepayment = application.dateOfRepayment;
          props.rejectReason = application.rejectReason;
          props.moratoriumData = application.moratoriumData;
        }

        return children(props);
      }}
    </Query>
  );
};

export default withRouter(ApplicationDetails);
