import React, { Fragment, useState, useEffect } from "react";
import { AuthService } from "../../../../services";
import {
  DataCardV2,
  Button,
  FormGroup,
  RadioGroup,
  Radio,
  Loader,
  Alert,
} from "../../../../components";
import {
  getErrorMessages, replaceTideSymbol, handleGraphQLErrors, clientInfoRequestDetails,
} from "../../../../lib/utils";

import "./_Styles.scss";
import Notifications, { notify } from "react-notify-toast";
import { useClientInfo } from "../../../../providers/User/useCategoryIdClientInfo";

const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  updatePBStepCount,
  formParams,
  updateFormParams,
  userData,
  getPreviouslySavedData,
  updateContextState,
  appClientInfo,
}) => {
  updatePBStepCount(stepIndex + 1);

  const [isLoading, setLoadingState] = useState(false);
  const [isSubmitted, setSubmissionState] = useState(false);
  const [inputErrors, setInputErrors] = useState([]);
  const [responseError, setResponseError] = useState([]);
  const { title, subTitle } = formInfo;
  const { next } = navigation;
  const { loanProduct, hasBusiness } = formParams;
  const [loanCategories, setLoanCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  let myColor = { background: '#0E1717', text: '#FFFFFF' };

  const validateForm = () => {
    if (!selectedCategory && (appClientInfo && appClientInfo.useLoanGroups)) {
      setInputErrors(prevState => ({ ...prevState, loanProduct: 'Please select a loan product.'}))
    } else {
      setInputErrors(prevState => ({ ...prevState, loanProduct: ''}))
    }
    if (!hasBusiness) {
      setInputErrors(prevState => ({ ...prevState, hasBusiness: 'Kindly state whether you have a business.'}))
    } else {
      if (loanProduct === 'SME' && hasBusiness === 'No') {
        setInputErrors(prevState => ({ ...prevState, hasBusiness: 'You are required to have a business for the selected loan product.'}))
      } else {
        setInputErrors(prevState => ({ ...prevState, hasBusiness: ''}));
      }
    }
  };

  useEffect(
    () => {
      validateForm();
    },
    [ formParams, isSubmitted ]
  );

  useEffect(() => {
    getPreviouslySavedData();
    getLoanCategoriesData();
  }, []);

  const handleRadioChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    updateFormParams(prevState => ({ ...prevState, [name]: value }));
  };

  const getLoanCategoriesData = async () => {
    setLoadingState(true);

    const response = await AuthService.getLoanCategories();
    let loanCategories = response.data
        ? response.data.data.getLoanCategories && response.data.data.getLoanCategories.categories
        : [];
    setLoanCategories(loanCategories);
    setLoadingState(false);
  };

  const handleLoanGroupSelect = (e, category) => {

    setSelectedCategory(category);

    if (category.name === "Micro Enterprise - Start up") {
      updateFormParams(prevState => ({ ...prevState, loanProduct: "MES" }));
    } else if ( category.name === "Micro Enterprise") {
      updateFormParams(prevState => ({ ...prevState, loanProduct: "ME" }));
    }  else if ( category.name === "Small Medium Enterprise" || category.name === "SME Category")  {
      updateFormParams(prevState => ({ ...prevState, loanProduct: "SME" }));
    } else {
      updateFormParams(prevState => ({ ...prevState, loanProduct: "" }));
    }
  };

  const setLoanProduct = async () => {
    setLoadingState(true);


    const nextStage = 'businessInformation';

    const businessInfo = userData.account.accountAttribute.find(attr => attr.attribute.name === 'businessInformation').data;
    const businessInformation = { ...businessInfo, loanProduct, hasBusiness, selectedCategory };
    const response = await AuthService.updateUserDataV2({ businessInformation });

    setLoadingState(false);

    let error = 'Error setting your loan product.';
    if (!response) {
      window.scroll(0, 0);
      setResponseError({ addLoanProduct: error });
      return;
    }

    if (response.data.errors) {
      window.scroll(0, 0);
      error = getErrorMessages(response.data.errors);
      notify.show(error, 'error', 5000, myColor);
      return;
    }

    if (!response.data || !response.data.data || !response.data.data.updateUserData) {
      window.scroll(0, 0);
      setResponseError({ addLoanProduct: error });
      return;
    }

    const { success } = response.data.data.updateUserData;

    if (success) {
      updateContextState({ page: nextStage });
      next();
    }
  };

  const {slug, clientUrl} = clientInfoRequestDetails();

  const { error: loanGroupError } = useClientInfo({ slug, clientUrl, loanCategoryId: selectedCategory.id });
  localStorage.setItem('selectedCategory', selectedCategory);

  const nextStep = () => {
    setSubmissionState(true);
    const errors = Object.keys(inputErrors).filter((item) => inputErrors[item] !== '');

    if (!errors.length && ((!selectedCategory && !loanGroupError) || (selectedCategory && !loanGroupError))) {
      setLoanProduct()
    }
  };

  return (
    <Fragment>
      {isLoading && <Loader />}

      <DataCardV2 title={title} subTitle={subTitle} centeralize hasPadding>
        <Notifications />
        {responseError &&
        responseError.length !== 0 && (
          <Fragment>
            {responseError.addLoanProduct ? <Alert classes="margin-top error">{responseError.addLoanProduct}</Alert> : ''}
          </Fragment>
        )}
        {loanGroupError && loanGroupError ? <Alert classes="margin-top error">{handleGraphQLErrors(loanGroupError)}</Alert> : ''}
        <div className="form_content">
                <section>
                  {
                    appClientInfo && appClientInfo.useLoanGroups && (loanCategories && loanCategories.length > 0) ?
                        <FormGroup classes="one-grid">
                          <RadioGroup
                              errorMessage={isSubmitted && inputErrors.loanProduct ? inputErrors.loanProduct : ''}>
                            {loanCategories && loanCategories.map((category, categoryIndex) => (
                                <div key={`product_${categoryIndex}`}>
                                  <div className="loan_product">
                                    <div className="loan_product_title">
                                      <span className="">{category.name}</span>
                                      <Radio
                                          label=""
                                          name="loanProduct"
                                          value={loanProduct}
                                          onChange={(e) => handleLoanGroupSelect(e, category)}
                                          // checked={loanProduct === category.id}
                                      />
                                    </div>
                                    <div className="loan_product_description">
                                      {category?.products[0].description && replaceTideSymbol(category?.products[0].description).map(item => (<li> {item.trim()}</li>) )}
                                    </div>
                                  </div>
                                </div>
                            ))}
                          </RadioGroup>
                        </FormGroup>
                        : ''
                  }
                  <FormGroup classes="one-grid">
                    <div>
                      <p>Do you have a registered business?<span className="required">*</span></p>
                    </div>
                    <RadioGroup  errorMessage={ isSubmitted && inputErrors.hasBusiness ? inputErrors.hasBusiness : '' }>
                      <Radio
                          label="Yes"
                          value="Yes"
                          checked={hasBusiness === 'Yes'}
                          onChange={e => handleRadioChange(e)}
                          name="hasBusiness"
                      />

                    <Radio
                      label="No"
                      value="No"
                      checked={hasBusiness === 'No'}
                      onChange={e => handleRadioChange(e)}
                      name="hasBusiness"
                    />
              </RadioGroup>
            </FormGroup>
          </section>
        </div>

        <div className="space-apart single right">
          <Button click_event={nextStep}>Continue</Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
