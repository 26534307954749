import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import { FormGroup, Button, Input } from '../../components';
import { Export } from '../../components/Icon';

import { ViewerProvider, UploadSupportingDocumentProvider, CdnFileProvider } from '../../providers';
import './_Settings.scss';

import {unCamelize} from '../../lib/utils'

const SupportingDocumentsSettings = ({
  stepIndex,
  setForm,
  formData,
  navigation,
  formInfo = {},
  updatePBStepCount,
  documentList = [
    { name: 'bankStatement', title: 'Upload CAC Certificate', type: 'default' },
    { name: 'workId', title: 'Upload Owner’s ID ( Passport / Voters card )', type: 'default' }
  ],
  updateDocumentList
}) => {
  const [ uploadedDocs, updateDocs ] = useState([]);
  const [ isLoading, updateLoadingState ] = useState(false);
  const [ responseError, setResponseError ] = useState([]);

  const { uploadSupportingDocumentFile } = UploadSupportingDocumentProvider({ uploadedDocs });

  const addNewDocument = () => {
    const currentLength = documentList.length + 1;

    updateDocumentList([
      ...documentList,
      {
        name: `document${currentLength}`,
        title: `Additional Document ${currentLength}`,
        type: 'custom'
      }
    ]);
  };

  const removeDocument = (event, itemName) => {
    event.preventDefault();

    updateDocumentList(documentList.filter(({ name }) => name !== itemName));
  };

  const getDocumentName = (data) => {
    const name = data.split('_');
    return name[0];
  };

  const handleDocumentUpload = (e) => {};

  return (
    <ViewerProvider>
      {({ user }) => (
        <Fragment>
          <FormGroup>
            <div className="form_content document_upload_container dashboard">
              {user.supportingDocument &&
                user.supportingDocument.length > 0 &&
                user.supportingDocument.map((item) => (
                  <CdnFileProvider file={item.file}>
                    {({ loading, openFile }) => (
                          <label
                          className={cx({
                          'document-upload': item,
                          custom: item.type === 'custom'
                        })}
                          key={item.documentName}
                        >
                        <div className="remove" onClick={(event) => removeDocument(event, item.documentName)}>
                          x
                        </div>
                        {/* <Input
                          name={item.name}
                          type="file"
                          classes={`border-bottom`}
                          onChange={(e) => handleDocumentUpload(e)}
                          accept="application/pdf"
                        /> */}
                         <section>
                            <div
                              className={cx({
                                'document-upload__label': item
                              })}
                            />
                            <div
                              className={cx({
                              title: item,
                              padded_text: item.documentName,
                              has_file: uploadedDocs[`${item.documentName}`]
                              })}
                            >
                            {uploadedDocs[`${item.name}`] ? (
                              `${uploadedDocs[`${item.name}`].fileName} of Size: ${uploadedDocs[`${item.name}`].fileSize}`
                            ) : item.documentName ? (
                              getDocumentName(unCamelize(item.documentName))
                            ) : (
                              ''
                            )}
                            {item.documentName ? (
                              <button className="view-button" onClick={openFile}>View Document </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </section>
                        </label>)}
                        {/* <label className="document-upload" onClick={() => {}}>
                          <section className="_new">
                            <div className="document-upload__label add" />
                            <div className="title">Add New Document</div>
                          </section>
                        </label> */}
                </CdnFileProvider>
                ))}
            </div>
          </FormGroup>
          <div className="center-space">
            <Button inactive classes="center">
              Update
            </Button>
          </div>
        </Fragment>
      )}
    </ViewerProvider>
  );
};

export default SupportingDocumentsSettings;
