import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { DataCardV2, Button, Loader, CheckBox } from '../../../../components';
import { AuthService } from '../../../../services';
import { authGetter } from '../../../../lib/utils';

const MbsInstructions = ({ stepIndex, userData, setUserData, navigation, formInfo = {}, updatePBStepCount, updateContextState }) => {
  updatePBStepCount(stepIndex + 1);
  const { title, subTitle } = formInfo;
  const { go} = navigation;
  const [ isLoading, updateLoadingState ] = useState(false);
  const [ skipCheckValue, updateskipCheckValue ] = useState(false);
  const customMbsDates = {
    startDate: moment().subtract(1, 'day').subtract(3, 'months').format('ll'),
    endDate: moment().subtract(1, 'day').format('ll')
  };

  const handleSkipCheck = e => {
    updateskipCheckValue(!skipCheckValue);
  };

  const getPreviouslySavedData = async step => {
    updateLoadingState(true);
    const authentication = authGetter();
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    if (viewer.data.errors) return;

    const response = viewer && viewer.data.data.viewer ? viewer.data.data.viewer : {};
    if (response) {
      setUserData(response);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  const nextStep = () => {
    const nextStep = skipCheckValue ? 'mbsUpload' : 'ticketNumber';
    updateContextState({page: nextStep})
    go(nextStep);
    return;
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize hasPadding>
        <div className='form_content mbs_instructions'>
          <div className='list-items'>
            <li>
              Login to {userData && userData.account && userData.account.bankAccounts.length ? userData.account.bankAccounts[0].bank.name : ''} internet banking
              on your computer or smartphone
            </li>
            <li>Check for "Send Statement to Third party" or "Statement Request" </li>
            <li>For Destination, please select LSETF</li>
            <li>For Role, please select Applicant</li>
            <li>For Start Date, please select {customMbsDates.startDate}</li>
            <li>For Start End, please select {customMbsDates.endDate}</li>
            <li>For Applicant(s), please enter your Full Name</li>
            <li>Complete the rest of the form and submit.</li>
          </div>

          <div className='continue_info'>
            Click Continue button bellow when you receive your ticket number and password via SMS
          </div>

          <div className='skip-check'>
            <div>
              <CheckBox checked={skipCheckValue} onChange={e => handleSkipCheck(e)} label='Skip, I do not have mobile/internet
              banking.'/>
            </div>
          </div>
        </div>

        <div className='space-apart single right'>
          <Button click_event={nextStep}>{skipCheckValue ? 'Next' : 'Continue'}</Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default MbsInstructions;
