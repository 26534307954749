import React, { Component } from 'react';
import './_Layouts.scss';
import {Link} from "react-router-dom";
import {
    DataCard, Footer, Header, Menu,
} from "../../components";
import {authGetter} from "../../lib/utils";

class NotFound extends Component {

    render() {
        const authentication = authGetter();

        return (
            <section className={`layout ${authentication.authenticationType}`}>
                <Header authentication={authentication} />
                {authentication.hasApiKey && (<Menu/>)}

                <main className="main">
                    <section className="container not-found">
                        <DataCard>
                            <h1>404</h1>
                            <h3>Page not found</h3>
                            {
                                !authentication.hasApiKey || authentication.isTempApiKey ? (
                                    <Link to="/" className="button">Return to Home Page</Link>
                                ) : (
                                    <Link to="/dashboard" className="button">Return to Dashboard</Link>
                                )
                            }
                        </DataCard>
                    </section>
                </main>
                <Footer/>
            </section>
        );
    }
}

export default NotFound;
