import React, { Fragment, useEffect, useState } from 'react'
import { getGlobalClientId, handleGraphQLErrors } from '../../../../lib/utils'
import Button from '../../../Button/Button'
import FormGroup from "../../../FormElements/FormGroup/FormGroup"
import Input from "../../../FormElements/Input/Input"
import Select from '../../../FormElements/Select/Select'
import FullPageLoader from '../../../FullPageLoader/FullPageLoader'
import {
  useConfirmBvnOtp,
  useGetBvnOtpChannels,
  useSendBvnOtpToChannel
} from './useIdentityPassBvnValidator'

export const IdentityPassBvnValidator = ({ bvn, completeValidation }) => {
  const clientId = getGlobalClientId();
  const [hasVerifiedBvn, setVerifiedBvn] = useState(false);
  const [showOtpError, setShowOtpError] = useState(false);

  const [formState, setFormState] = useState({
    bvn: bvn || '',
    otpChannel: '',
    bvnOtp: ''
  });

  const {
    bvnOtpChannels,
    getBvnOtpChannels,
    bvnOtpChannelsError,
    bvnOtpChannelsLoading,
  } = useGetBvnOtpChannels();

  const {
    bvnOtpToChannels,
    sendBvnOtpToChannel,
    bvnOtpToChannelsError,
    bvnOtpToChannelsLoading,
  } = useSendBvnOtpToChannel();

  const {
    bvnDetails,
    bvnOtpError,
    bvnOtpLoading,
    confirmBvnOtp,
  } = useConfirmBvnOtp();

  useEffect(() => {
    if (bvnOtpError) {
      setShowOtpError(true);
      const timeoutId = setTimeout(() => {
        setShowOtpError(false);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [bvnOtpError]);


  const handleBvnChange = (e) => {
    const { value } = e.target;

    setFormState({
      bvn: value,
      otpChannel: '',
      bvnOtp: ''
    });

    if (value?.length === 11 && !bvnOtpChannelsLoading) {
      getBvnOtpChannels({ bvn: value, clientId });
    }
  }

  useEffect(()=> {
    if (bvn) {
      getBvnOtpChannels({ bvn, clientId });
    }
  }, [bvn])

  const handleChannelSelection = (e) => {
    const { value } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      otpChannel: value,
      bvnOtp: ''
    }));

    if (value) {
      sendBvnOtpToChannel({
        channel: value,
        verificationId: bvnOtpChannels.verificationId,
        clientId
      })
    }
  }

  const handleOtpChange = (e) => {
    const { value } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      bvnOtp: value
    }));
  }

  const handleConfirmOtp = () => {
    confirmBvnOtp({
      otp: formState.bvnOtp,
      verificationId: bvnOtpChannels.verificationId,
      clientId
    })
  }

  useEffect(() => {
    if (bvnDetails) {
      completeValidation({ ...bvnDetails, bvn: formState.bvn });
      setVerifiedBvn(true);
    }
  }, [bvnDetails])

  return (
    <Fragment>
      {
        (bvnOtpChannelsLoading || bvnOtpToChannelsLoading || bvnOtpLoading)
        && <FullPageLoader />
      }

      <FormGroup>
        <Input
          required
          autoFocus
          type="text"
          label="BVN"
          numberField
          maxlength={11}
          minlength={11}
          defaultValue={bvn}
          disabled={hasVerifiedBvn || bvnOtpChannelsLoading}
          classes={`border-bottom`}
          onChange={handleBvnChange}
          placeholder="Enter your BVN"
          errorMessage={bvnOtpChannelsError ? handleGraphQLErrors(bvnOtpChannelsError) : ''}
        />
      </FormGroup>

      {!hasVerifiedBvn &&
        <Fragment>
          {formState.bvn.length === 11 &&
            !bvnOtpChannelsLoading &&
            bvnOtpChannels?.verificationId ? (
            <FormGroup>
              <Select
                autoFocus
                name="otpChannel"
                classes="border-bottom"
                label={bvnOtpChannels.detail}
                onChange={handleChannelSelection}
                errorMessage={bvnOtpToChannelsError ? handleGraphQLErrors(bvnOtpToChannelsError) : ''}
              >
                <option value="">
                  Select OTP Channel
                </option>

                {bvnOtpChannels.contacts?.map((contact) => (
                  <option value={contact} key={contact}>
                    {contact}
                  </option>
                ))}
              </Select>
            </FormGroup>
          ) : null}

          {formState.otpChannel &&
            !bvnOtpToChannelsLoading &&
            bvnOtpToChannels?.detail ? (
            <FormGroup>
              <Input
                required
                autoFocus
                type="text"
                numberField
                name="bvnOtp"
                classes={`border-bottom`}
                onChange={handleOtpChange}
                label={bvnOtpToChannels.detail}
                  errorMessage={showOtpError && bvnOtpError ? handleGraphQLErrors(bvnOtpError) : ''}
              />
            </FormGroup>
          ) : null}

          <div className="space-apart">
            <div></div>
            <Button
              disabled={bvnOtpLoading}
              click_event={handleConfirmOtp}
              inactive={!formState.bvnOtp}
            >
              Verify BVN
            </Button>
          </div>
        </Fragment>
      }
    </Fragment>
  )
}
