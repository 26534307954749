import React from 'react';
import { NewCheck } from '../../Icon';
import { Popup } from '../../index';

const OfferLetterSigned = () => {
  return (
    <Popup classes="offer-letter">
      <div className="response-icon-holder">
        <NewCheck />
      </div>

      <h4 className={'data-card__title'}>Offer Letter Signed Successfully</h4>

      <p className="popup-text center-text">
        We have received your Signed offer letter. <br />
        Thank you.
      </p>
    </Popup>
  );
};

export default OfferLetterSigned;
