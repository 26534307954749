import React, { useState, Fragment, useEffect } from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import { useHistory } from 'react-router';
import { AuthService, BankService } from '../../services';
import { formSteps as steps, inputOptionTypes } from './constants';
import {authGetter, clientInfoRequestDetails, CONTEXTS } from '../../lib/utils';
import { LoanRequest, DebitCardList, ConfirmRequest, LoanConfirmation, LoanSelection, BusinessInformation, BankSelection, MbsUpload, OkraStep, TicketNumber, SupportingDocuments } from './views';
import { APPLICATION_CLOSED } from '../../lib/constants';
import { ApplicationClosed } from '../../components/ApplicationClosed';

const defaultData = {
  // others
  documentList: [
    {
      name: "govtId",
      title: "Upload Govt-issued ID",
      type: "default",
      requiredBy: ["ME", "MES", "SME"],
    },
    {
      name: "lassraId",
      title: "Upload LASSRA ID Card",
      type: "default",
      requiredBy: ["ME", "MES", "SME"],
    },
    {
      name: "passportPhoto",
      title: "Upload your Passport Photograph",
      type: "default",
      requiredBy: ["ME", "MES", "SME"],
    },
    {
      name: "cacCertForm",
      title: "Upload CAC Certificate",
      type: "default",
      requiredBy: ["SME"],
    },
    {
      name: "taxIdCert",
      title: "Upload Personal Tax ID Card",
      type: "default",
      requiredBy: ["SME", "MES", "ME"],
    },
    {
      name: "tinCert",
      title: "Upload TIN Certificate",
      type: "default",
      requiredBy: ["SME"],
    },
    {
      name: "trainingCert",
      title: "Upload Training Certificate",
      type: "default",
      requiredBy: ["MES"],
    },
    {
      name: "memArt",
      title: "Memorandum and Articles of Association",
      type: "default",
      requiredBy: ["SME"],
    },
  ],
};

const Index = () => {
  const history = useHistory();
  const { index: stepIndex, step, navigation } = useStep({ initialStep: 0, steps });
  const [ userData, setUserData ] = useState();
  const [ formData, setForm ] = useForm({});
  const [ appClientInfo, setAppClientInfo ] = useState();
  const [ bankList, setBankList ] = useState();
  const [contextState, setContextState] = useState();
  const [documentList, updateDocumentList] = useState(defaultData.documentList);
  let activeView = {};

  const [ formParams, updateFormParams ] = useState({
    // before you begin
    tNc: false,

    // bvn details
    bvn: '',
    bvnPhoneDigits: '',
    dob: 'none',
    verificationCode: '',

    // personal details
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    state: 'Lagos',
    lga: '',
    email: '',
    password: '',
    confirmPassword: '',

    // Loan selection
    loanProduct: '',
    hasBusiness: '',

    // otp verifications
    otp: '',
    userId: '',

    // employment details
    employmentStatus: '',
    companyName: '',
    companyAddress: '',
    workEmail: '',
    employmentDuration: '',
    netMonthlyIncome: '',
    businessRegistration: '',
    typeOfbusiness: '',

    companyLga: '',
    cacRegNo: '',
    companyTaxId: '',
    businessStartDate: '',
    businessSector: '',
    monthlyTurnover: '',
    monthlyExpenses: '',
    monthlySavings: '',
    noOfEmployees: '',

    employees: [{
      employeeName: '',
      employeeTaxId: ''
    }],
    directors: [{
      directorName: '',
      directorTaxId: ''
    }],

    // loan request
    amount: '',
    loanDuration: '',
    expectedMonthlyTurnover: '',
    expectedMonthlyExpenses: '',
    expectedMonthlySavings: '',
    expectedNewHires: '',
    cooperativeMember: '',
    willingToJoinCooperative: '',
    durationInCooperative: '',
    cooperativeName: '',
    cooperativeAddress: '',

    // Bank statement
    bankId: '',
    accountName: '',
    accountNumber: '',
    servicesOffered: '',

    hasInternetBanking: '',

    applicationId: '',
    statementPassword: '',
    skipStep: false,

    tNcFinal: false
  });

  const { id, formInfo } = step;

  const getUserSavedData = () => {
    const authentication = authGetter();

    let viewer;
    setTimeout(async () => {
      viewer = await AuthService.viewerQueryClone(authentication.apiKey);

      if (viewer && viewer.data && viewer.data.data) {
        const response = viewer.data.data.viewer;
        setUserData(response);
        if (response?.account?.contextStates?.length) {
          const newLoanContext = response.account.contextStates.find(
            (context) => context.context === CONTEXTS.NEW_LOAN && context.page !== 'dashboard'
          );

          setContextState(newLoanContext);
        }
      }
    }, 200);
  };

  const getBanks = async () => {
    const response = await BankService.getBanks();

    const error = 'Error fetching bank list';
    if (response == null || typeof response === 'undefined') {
      return;
    }

    if (response.data.errors) {
      return;
    }

    if (response.data.data && response.data.data.getBanks) {
      const banks = response.data.data.getBanks;
      setBankList(banks);
    } else {
      setBankList([]);
    }
    return;
  };

  const getClientInfo = async () => {
    const {slug, clientUrl} = clientInfoRequestDetails();
    const response = await AuthService.clientInfo({slug, clientUrl});

    if (response && response.data && response.data.data) {
      const { clientInfo } = response.data.data;
      setAppClientInfo(clientInfo);

      getBanks()
    }
  };

  const baseInputChangeHandler = (e) => {
    let name, value;
    let type = e.target.type;

    if (inputOptionTypes.includes(type)) {
      name = e.target.name;
      value = e.target.value;
    }

    updateFormParams((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const updateContextState = async ({ page, state }) => {
    const payload = {
      context: CONTEXTS.NEW_LOAN,
      page,
      ...(state ? { state } : {}),
      ...(contextState ? { id: contextState.id } : {}) }

    history.replace('/loans/new', { jumpToScreen: page !== 'dashboard' ? page : 'loanConfirmation' });

    payload.state = payload.id ? { ...payload.state, ...(contextState.state || {}) } : payload.state;

    const response = payload.id
      ? await AuthService.updateContextState(payload)
      : await AuthService.createContextState(payload);

    if (response && response.data.data) {
      setContextState(response.data.data.createContextState || response.data.data.updateContextState);
    }
  }

  useEffect(() => {
    getUserSavedData();
    getClientInfo();
    if (history.location.state && history.location.state.jumpToScreen) {
      navigation.go(history.location.state.jumpToScreen)
    }
  },[])

  const documentProps = { documentList, updateDocumentList };
  const props = {
    setForm,
    formInfo,
    userData,
    setUserData,
    formData,
    stepIndex,
    updateFormParams,
    formParams,
    navigation,
    baseInputChangeHandler,
    getUserSavedData,
    appClientInfo,
    bankList,
    updateContextState,
    contextState,
    ...documentProps,
  };

  switch (id) {
    case 'loanSelection':
      activeView = <LoanSelection {...props} />
      break;
    case 'businessInformation':
      activeView = <BusinessInformation {...props} />
      break;
    case 'loanInformation':
      activeView = <LoanRequest {...props} />;
      break;
    case 'addDebitCard':
      activeView = <DebitCardList {...props} />;
      break;
    case 'supportingDocuments':
      activeView = <SupportingDocuments {...props} />;
      break;
    case 'okraPage':
      activeView = <OkraStep {...props} />;
      break;
    case 'bankStatement':
      activeView = <BankSelection {...props} />;
      break;
    case "ticketNumber":
      activeView = <TicketNumber {...props} />;
      break;
    case 'mbsUpload':
      activeView = <MbsUpload {...props} />;
      break;
    case 'confirmRequest':
      activeView = <ConfirmRequest {...props} />;
      break;
    case 'loanConfirmation':
      activeView = <LoanConfirmation {...props} />;
      break;
    default:
      activeView = null;
  }

  return (
    <Fragment>
      {APPLICATION_CLOSED ? (
        <ApplicationClosed isLoggedIn />
      ) : (
        <div className="container">
          <div className="viewWrapper">{activeView}</div>
        </div>
      )}
    </Fragment>
  );
};

export default Index;
