import React from 'react'

const CloseIcon = props => (
    <svg width={props.width || 10} height={props.height || 10} viewBox='0 0 10 10' fill="none" {...props}>
        <path
            d="M9.667 1.273l-.94-.94L5 4.06 1.273.333l-.94.94L4.06 5 .333 8.726l.94.94L5 5.94l3.727 3.726.94-.94L5.94 5l3.727-3.727z"
            fill="#B35151"
        />
    </svg>
);

export default CloseIcon