import React, { useState, Fragment, useEffect } from 'react';
import PaystackButton from 'react-paystack';
import Notification, { notify } from 'react-notify-toast';

import { DebitCard, DataCardV2, BankCard, BackArrow, Button, Loader, Alert } from '../../../../components';
import { BankService, LoanService, AuthService, CardService } from '../../../../services';
import Constants from '../../../../lib/constants';
import { ClientInformationProvider, PaymentInformationProvider } from '../../../../providers';
import config from '../../../../config/config';
import {
  authGetter,
  getErrorMessages,
  getGlobalClientId,
  getLoanDurationName,
  removeCommas
} from '../../../../lib/utils';

import './_Styles.scss';

const Index = ({
  navigation,
  formInfo = {},
  userData,
  setUserData,
  formParams,
  updateFormParams,
  updateContextState,
  appClientInfo: clientInfo
}) => {
  const [reference, setReference] = useState('');
  const [loading, setLoading] = useState(false);
  const { status, duration } = Constants;
  const { next, go, previous } = navigation;
  const { title, subTitle } = formInfo;
  const myColor = { background: '#0E1717', text: '#FFFFFF' };
  const [loanDur, setLoanDur] = useState('');
  const [isLoading, updateLoadingState] = useState(false);
  const [debitCardError, setDebitCardError] = useState('');
  const channel = useState(['card']);
  let payStackMetadata = {};

  const getReference = async () => {
    setLoading(true);

    const response = await CardService.getAddCardReference(getGlobalClientId());

    if (response && response.status === 200 && response.data.data != null) {
      const { getAddCardReference } = response.data.data;

      if (getAddCardReference && getAddCardReference.reference) {
        setReference(getAddCardReference.reference);

        setLoading(false);
        return getAddCardReference.reference;
      }
    }
  };

  const getReferenceStatus = async payStackReference => {
    setLoading(true);
    let ref = payStackReference;

    if (payStackReference.reference) {
      ref = payStackReference.reference;
    } else if (reference) {
      ref = reference;
    }

    const response = await CardService.getCardReferenceStatus({ reference: ref });

    if (response && response.status === 200 && response.data.data != null) {
      const { getCardReferenceStatus } = response.data.data;
      const payStackReferenceStatus = getCardReferenceStatus.status;

      switch (payStackReferenceStatus) {
        case 'SUCCESS':
          notify.show('Your card has been added successfully.', status.SUCCESS, duration.LONG);
          getReference();
          window.location.reload();
          break;
        case 'FAILED':
          notify.show(`${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add new card button`, 'error', 8000);
          getReference();
          break;
        case 'NOT_USED':
        case 'ABANDONED':
          notify.show(`To add a card, please proceed by clicking the add new card button`, 'info', 8000);
          break;
        case 'INVALID':
          notify.show(`${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add new card button`, 'info', 8000);
          break;
        default:
          await getReferenceStatus();
          break;
      }

      setLoading(false);
    }
  };

  const addCard = async () => {
    setLoading(true);
    const generatedReference = await getReference();

    if (generatedReference) {
      const payStack = document.querySelector('.psButton');
      payStack.click();
    } else {
      setLoading(false);
      notify.show(
        'There was an error generating your reference. Please, refresh the page and try again later.',
        status.ERROR,
        duration.LONG
      );
    }
  };

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    updateLoadingState(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    const response = viewer ? viewer.data.data.viewer : {};

    if (response) {
      const businessInformation = response.account.accountAttribute.find(accountAttr => accountAttr.attribute.name === 'businessInformation').data;
      const { loanDuration } = businessInformation;

      setLoanDur(loanDuration)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  const closePaystackPopup = () => {
    notify.show('You have cancelled add new card.', status.ERROR, duration.LONG);
  };

  const getUserData = async () => {
    setLoading(true);

    const authentication = authGetter();
    let viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    setLoading(false);

    if ((viewer && viewer.data && viewer.data.data) && debitCardError === '') {
      const response = viewer.data.data.viewer;
      setUserData(response);
      initiateApplication();
    } else {
      notify.show('An Error was encountered. Kindly retry again.', 'error', 6000, myColor);
      return;
    }
  };

  const handleNextStep = (applicationId) => {
    const nextPage = clientInfo.requiresOkraTransactions ? 'okraPage' : 'bankStatement';
    const bankLinkedToOkra = userData.account.bankAccounts.find(account => account.okraRecord);
    const okraRecord = bankLinkedToOkra && userData.account.bankAccounts.okraRecord;
    const okraDirectDebitAuthorised = clientInfo.okraDirectDebitIsEnabled && okraRecord && okraRecord.directDebitAuthorised;
    if (bankLinkedToOkra) {
      if (!okraDirectDebitAuthorised) {
        updateContextState({ page: 'okraPage', state: { applicationId } });
        go('okraPage');
      } else {
        updateContextState({ page: 'bankStatement', state: { applicationId } });
        go('bankStatement');
      }
    } else {
      updateContextState({ page: nextPage });
      go(nextPage);
    }
  };


  const initiateApplication = async () => {
    setLoading(true);
    let amount = formParams.amount;
    let loanDuration = formParams.loanDuration;
    let durType = loanDur && loanDur.split(" ");
    durType = durType[1];

    const businessInfo = userData.account.accountAttribute.find(attr => attr.attribute.name === 'businessInformation').data;
    if (!(amount && loanDuration)) {
      amount = businessInfo.amount;
      loanDuration = businessInfo.loanDuration;
    }

    const response = await LoanService.initiateApplication({
      clientId: getGlobalClientId(),
      amount: removeCommas(amount),
      loanDuration: parseInt(loanDuration),
      durationType: getLoanDurationName(durType),
      loanCategoryId: businessInfo.newLoanSelectedCategory.id
    });

    setLoading(false);

    if (response && response.data.data) {
      const { initiateApplication } = response.data.data;
      const { application } = initiateApplication;
      const { id } = application;

      updateFormParams((prevState) => ({
        ...prevState,
        applicationId: id
      }));
      handleNextStep(id);
    } else {
      if (response.data && response.data.errors) {
        const errorMsg = getErrorMessages(response.data.errors);
        if (errorMsg === 'Existing pending application\n') {
          handleNextStep();
          setLoanDur("");
        }
      } else {
        notify.show('Error! Kindly retry.', 'error', 6000, myColor);
        return;
      }

    }
  };


  if (userData) {
    payStackMetadata = {
      accountId: userData?.me?.id,
      transactionType: Constants.transactType.transactionType,
      loanDuration: loanDur,
    };
  }



  const nextStep = () => {
    getUserData();
  };
  return (
    <DataCardV2 title={title} subTitle={subTitle} centeralize>
      <div className="form_content debit_card_page">
        <Notification />
        <PaymentInformationProvider>
          {({ cards, bankAccounts, email, accountId, error }) => (
            <section>
              {loading && <Loader />}
              {error && (
                <Alert classes="error">There was an error loading cards and banks. Please reload the page</Alert>
              )}
              {debitCardError && (
                <Alert classes="error">Your selected card will expire before the loan tenure of your request is complete. Please select or add a different card.</Alert>
              )}

              <div>
                <section>
                  <div className="cardListing">
                    {cards.map((card, index) => <DebitCard cardDetails={card} accountId={accountId} key={index} loanDuration={loanDur} setDebitCardError={setDebitCardError} />)}
                  </div>
                  <div className="add_field_btn">
                    <button onClick={addCard}>
                      <span className="add-icon">+</span> Add Card
                    </button>
                  </div>
                  <PaystackButton
                    text="Add Card"
                    className="PayStackButton button psButton hidden"
                    embed={false}
                    reference={reference}
                    email={email}
                    close={closePaystackPopup}
                    metadata={payStackMetadata}
                    amount={
                      clientInfo
                        ? parseInt(clientInfo.addCardCharge) * 100
                        : parseInt('100') * 100
                    }
                    paystackkey={clientInfo ? clientInfo.paystackPubKey : ''}
                    callback={getReferenceStatus}
                    tag="button"
                    channels={channel}
                  />
                </section>

                <div className="space-apart">
                  <BackArrow height="24" onClick={previous} text="Back" />
                  <Button click_event={nextStep}>Continue</Button>
                </div>
              </div>
            </section>
          )}
        </PaymentInformationProvider>
      </div>
    </DataCardV2>
  );
};

export default Index;
