import React from 'react';
import PropTypes from 'prop-types';
import './_Popup.scss';
import {DataCard} from "../index";

const Popup = (props) => {
    return(
        <section className={`popup ${props.classes == null ? "" : props.classes}`}>
            <div className="popup__content">
                <DataCard title={props.title}>
                    {props.children}
                </DataCard>
            </div>
        </section>
    )
};

Popup.propTypes = {
    title: PropTypes.string,
    classes: PropTypes.string,
};

export default Popup;