import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Context from '../../context';

import './_ProgressBox.scss';

const ProgressBox = (props) => {
  const { state } = useContext(Context);
  const clientTheme = state && state.clientInfo ? state.clientInfo.clientTheme : {};
  const { current = '', title = '', width = '' } = props;

  const styles = {
    width: `${width}`,
    color: clientTheme ? clientTheme.primaryColor : ''
  };

  const dynamicBorder = {
    borderColor: clientTheme ? clientTheme.primaryColor : ''
  };

  return (
    <div
      className={cx({
        ProgressBoxWrapper: props
      })}
      style={styles}
    >
      <div className="circle" style={dynamicBorder}>
        {current}
      </div>
      <div className="title">{title}</div>
    </div>
  );
};

ProgressBox.propTypes = {
  current: PropTypes.number,
  title: PropTypes.string
};

export default ProgressBox;
