import React from 'react'

const VisaCard = props => (
    <svg width={79} height={24} viewBox="0 0 79 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.323 23.352l6.93-22.85h6.183l-6.93 22.85h-6.183zM20.56.508l-5.563 9.686c-1.416 2.537-2.244 3.818-2.642 5.421h-.085c.1-2.032-.184-4.527-.21-5.937l-.616-9.17H1.037l-.107.614c2.673 0 4.26 1.344 4.695 4.092l2.029 18.137h6.406L27.012.508H20.56zM68.643 23.351l-.17-3.398-7.72-.006-1.58 3.404H52.46L64.632.545h8.24l2.061 22.806h-6.29zm-.709-13.478c-.069-1.689-.126-3.98-.011-5.368h-.091c-.377 1.135-1.993 4.54-2.704 6.216l-2.3 5.008h5.42l-.314-5.856zM42.927 24c-4.365 0-7.262-1.385-9.33-2.619l2.945-4.498c1.857 1.039 3.315 2.237 6.67 2.237 1.078 0 2.117-.28 2.708-1.303.86-1.486-.199-2.285-2.614-3.653l-1.192-.776c-3.582-2.446-5.13-4.77-3.445-8.826C39.748 1.967 42.592 0 47.285 0c3.237 0 6.271 1.4 8.04 2.768l-3.387 3.97c-1.726-1.394-3.155-2.1-4.792-2.1-1.305 0-2.297.503-2.639 1.182-.644 1.276.209 2.146 2.09 3.313l1.419.903c4.347 2.742 5.383 5.617 4.293 8.304-1.874 4.627-5.543 5.66-9.382 5.66zM76.773 3.598h-.267v-1.63h.618c.394 0 .595.137.595.469 0 .295-.181.417-.426.445l.456.716h-.302l-.422-.697h-.252v.697zm.3-.926c.2 0 .377-.02.377-.255 0-.197-.193-.221-.353-.221h-.324v.476h.3zm-.008 1.557c-.846 0-1.45-.635-1.45-1.474 0-.885.67-1.472 1.45-1.472.771 0 1.44.587 1.44 1.472 0 .886-.669 1.474-1.44 1.474zm0-2.69c-.638 0-1.134.5-1.134 1.216 0 .67.433 1.217 1.133 1.217.63 0 1.127-.496 1.127-1.217 0-.716-.496-1.216-1.127-1.216z"
            fill="#fff"
        />
    </svg>
);

export default VisaCard
