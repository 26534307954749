import React, { useEffect } from "react";
import config from "../../config/config";
import { ViewerProvider } from "../../providers";

const ProdCampNPS = ({ email, firstName, lastName }) => {
  useEffect(() => {
    if (config.web.app_env === "production") {
      window.ProdCamp("initNPSWidget", {
        email,
        company: {
          name: "LSETF",
        },
        firstName,
        lastName,
      });
    }
  }, []);

  return <></>;
};

const ProdCampNPSWidget = () => (
  <ViewerProvider>
    {({ user }) => {
      const { email, firstName, lastName } = user;
      return (
        <ProdCampNPS email={email} firstName={firstName} lastName={lastName} />
      );
    }}
  </ViewerProvider>
);
export default ProdCampNPSWidget;
