import React, { Fragment, useContext, useEffect, useRef, useState } from "react"
import { Alert, Button, BvnValidator, Card, FormGroup, Input, PopupV2 } from "../../../components"
import Context from "../../../context";
import './_DisplayBvn.scss';
import { useUpdateUserData } from "./useDisplayBvn";
import FullPageLoader from "../../../components/FullPageLoader/FullPageLoader";
import { handleGraphQLErrors } from "../../../lib/utils";
import { useLocation } from 'react-router-dom';
import { CloseIcon } from "../../../components/Icon";

export const DisplayBvn = ({ bvnStatus }) => {
  const [bvn, setBvn] = useState(bvnStatus?.bvn);
  const [canVerifyBvn, setCanVerifyBvn] = useState(false);
  const [showBvnValidator, setShowBvnValidator] = useState(false);
  const { state: localContextState } = useContext(Context);
  const location = useLocation();

  const { updateUserData, updateUserError, updateUserLoading } = useUpdateUserData()

  const { bvnServiceProviders } = localContextState?.clientInfo || [];

  const handleBvnChange = (e) => {
    const { value } = e.target;

    setBvn(value);

    if (value?.length === 11) {
      setCanVerifyBvn(true);
    } else {
      setCanVerifyBvn(false);
    }
  }

  const toggleBvnValidator = () => {
    setShowBvnValidator((prevState) => !prevState)
  }

  const completeValidation = (data) => {
    toggleBvnValidator();
    updateUserData({ bvn: data.bvn })
  }

  return (
    <Fragment>

      {updateUserLoading && <FullPageLoader />}

      <Card
        title={'BVN'}
        subtitle={'We require your BVN to fetch nd verify your information.'}
      >
        <section id="update-bvn">
          <FormGroup classes="two-grid">
            <Input
              name="bvn"
              label="BVN"
              numberField
              maxlength={11}
              minlength={11}
              placeholder="BVN"
              classes="border-bottom"
              defaultValue={bvn}
              onChange={handleBvnChange}
              readOnly={bvnStatus?.isVerified}
              hasCheckmark={bvnStatus?.isVerified}
              autoFocus={location?.hash === '#update-bvn'}
            />

            {!bvnStatus?.isVerified &&
              <section>
                <Button classes="small" inactive={!canVerifyBvn} click_event={toggleBvnValidator}>Verify</Button>
              </section>
            }
          </FormGroup>

          {!!updateUserError &&
            <Alert classes="warning display-bvn-warning">
              {handleGraphQLErrors(updateUserError)}
            </Alert>
          }
        </section>
      </Card>

      {showBvnValidator &&
        <PopupV2 classes="modal-validate-bvn">
          <Button classes="close-popup" click_event={toggleBvnValidator}>
            <CloseIcon width={16} height={16} />
          </Button>
          <BvnValidator bvnServiceProviders={bvnServiceProviders} bvn={bvn} completeValidation={completeValidation} />
        </PopupV2>
      }
    </Fragment>
  )
};
