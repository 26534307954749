import React from 'react';
import './_BankCard.scss';
import Ellipsis from "../Icon/Ellipsis";
import Radio from "../FormElements/Radio/Radio";

const BankCard = ({isDefault, children, onClick}) => {
    return(
        <div className={'bank-card'} onClick={onClick}>
            <div className={'bank-card__top'}>
                <div className={'card-status'}>
                    <Radio defaultChecked={isDefault} name="defaultBank" label={isDefault && 'This Account is Active'} />
                </div>
                <div className={'icon'}>
                    <Ellipsis/>
                </div>
            </div>
            <div className={'bank-card__mid'}>
                {children}
            </div>
        </div>
    )
};

export default BankCard;
