import React, { Fragment } from 'react';
import ReactDOM from "react-dom";
import './_FullPageLoader.scss';

const Loader = () => {
  return (
    <aside className="full-page-loader">
      <div className="loader__content" />
    </aside>
  );
};


export const FullPageLoader = () => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Loader />,
        document.body
      )}
    </Fragment>
  )
}

export default FullPageLoader;
