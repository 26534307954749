import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Okra from 'npm-okrajs';
import { Alert, Button, DataCardV2, Loader } from '../../../../components';
import { BankService } from '../../../../services';
import Notification, { notify } from 'react-notify-toast';
import { getErrorMessages } from '../../../../lib/utils';
import config from '../../../../config/config';

const Index = ({
  formInfo,
  navigation,
  appClientInfo: clientInfo,
  updateContextState,
  userData,
}) => {
  const { title, subTitle } = formInfo;
  const { next } = navigation;
  const [isLoading, setLoading] = useState(false);
  const [okraWidgetUrl, setOkraWidgetUrl] = useState('');
  const [okraError, setOkraError] = useState();
  const [okraWidgetClosed, setOkraWidgetClosed] = useState(true);
  const okraDataRef = useRef({});

  const connectBankToOkra = async (data) => {
    setLoading(true);

    const response = await BankService.connectBankToOkra(data);

    if (!response) {
      notify.show('There was an error connecting you bank account to Okra. Please try again', 'error', 4000);
    } else {
      if (response.data && response.data.errors) {
        const errorMsg = getErrorMessages(response.data.errors);
        notify.show(errorMsg, 'error', 4000);
      }

      if (response.data && response.data.data) {
        notify.show('Your bank account has been connected to Okra successfully.', 'success', 3500);
        const { okraCustomerId, okraRecordId, okraAccountId } = okraDataRef.current;
        updateContextState({ page: 'bankStatement', state: { okraCustomerId, okraRecordId, okraAccountId } });
        next();
      }
    } 
  };

  const resolveOkraBank = async (okraBankId) => {
    setLoading(true);

    const response = await BankService.resolveOkraBank(okraBankId);

    if (!response) {
      notify.show('There was an error retrieving your bank details from Okra. Kindly try manual entry', 'error', 4000);
      setLoading(false);
    }

    if (response && response.data.errors) {
      const errorMsg = getErrorMessages(response.data.errors);
      notify.show(errorMsg, 'error', 4000);
      setLoading(false);
    }

    if (response && response.data.data) {
      const { resolveOkraBank: { id, code } } = response.data.data;
      okraDataRef.current = { ...okraDataRef.current, accountBankId: id, bankCode: code }    
    }
    setOkraWidgetClosed(true);
  }

  const processOkraResponse = okra_data => {
    const { accounts, bank_id: okraBankId, customer_id: customerId, record_id: recordId } = okra_data;
    const { nuban: accountNumber, name: accountName, id: okraAccountId } = accounts.find(account => account.connected);
    okraDataRef.current = { okraCustomerId: customerId, okraRecordId: recordId, accountNumber, okraBankId, accountName, okraAccountId };
  };

  const handleClose = () => {
    if (!(okraDataRef.current && okraDataRef.current.okraCustomerId)) {
      setOkraError('You need to connect your bank account to be able to proceed with the application');
      setTimeout(() => setOkraError(''), 4000);
      setOkraWidgetClosed(true);
    } else {
      resolveOkraBank(okraDataRef.current.okraBankId)
    }
  };

  const okraRecord = userData.account.bankAccounts.okraRecord;
  const okraDirectDebitNotAuthorised =  okraRecord && clientInfo.okraDirectDebitIsEnabled && !okraRecord.directDebitAuthorised;

  useEffect(() => {
    if (okraWidgetClosed && okraDataRef.current && okraDataRef.current.accountBankId) {
      const { accountNumber, okraCustomerId: customerId, okraRecordId: recordId, accountBankId, accountName, okraAccountId } = okraDataRef.current;
      const noneMatchingAccount = userData.account.bankAccounts.find(account => account.accountNumber !== accountNumber);
      const testAccountNumber = userData.account.bankAccounts.find(account => account.accountNumber);
      const matchingAccount = userData.account.bankAccounts.find(account => account.accountNumber === accountNumber && account.accountName === accountName);
      if (config.web.node_env === 'staging' || config.web.node_env === 'production') {
      if (matchingAccount) {
        connectBankToOkra({ accountBankId: matchingAccount.id, recordId, customerId, okraAccountId, directDebitAuthorised: clientInfo.okraDirectDebitIsEnabled });
      } else {
        setLoading(false);
        setOkraError(`
          The selected bank account from Okra doesn't match the bank account ${noneMatchingAccount.accountNumber} in your profile.
        `)
      }
    } else if (okraWidgetClosed && !okraDataRef.current) {
      notify.show('Please select a bank account that matches the one in your profile to proceed', 'error', 5000);
    }  else {
      connectBankToOkra({ accountBankId: testAccountNumber.id, recordId, customerId, okraAccountId, directDebitAuthorised: clientInfo.okraDirectDebitIsEnabled });

    }}
  }, [okraWidgetClosed]);

  useEffect(() => {
    if (okraError) {
      setTimeout(() => setOkraError(''), 6000);
    }
  }, [okraError])

  const initiateOkra = () => {
    const short_url = okraWidgetUrl.replace(/\s/g, '').split('/');
    setOkraWidgetClosed(false);
    Okra.buildWithShortUrl({
      short_url: short_url[short_url.length - 1],
      onSuccess: data => {
        processOkraResponse(data);
      },
      onClose: () => {
        handleClose();
      }
    });
  };

  useEffect(() => {
    if (clientInfo) {
      setOkraWidgetUrl(clientInfo.okraWidgetUrl);
    } 
  }, [clientInfo]);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={clientInfo && clientInfo.okraDirectDebitIsEnabled ? ('Bank Statement and Direct Debit Authorization') : ('Bank Statement')} centeralize hasPadding>
      <Notification />
        {okraError && <Alert classes="margin-top error">{okraError}</Alert>}
        {okraDirectDebitNotAuthorised && <Alert classes='success'> As part of your loan application, please authorize a direct debit connection to Okra</Alert>}
        <div className="okra-page">
          <p className="sub-title">
            As part of your loan application, we connect to Okra to fetch your
            bank statement {clientInfo && clientInfo.okraDirectDebitIsEnabled &&  `and authorize Okra for direct debits on your recurring payments`}.
            This improves your chances in getting a loan. We value
            security therefore transfer of your information is encrypted and your
            login credentials will never be made accessible to us by Okra.
          </p>
          <p className="subtitle__okra">
              Please note the following:
            <ul>
              <li>
                Ensure the bank account you select is the one previously submitted in your application.
              </li>
              <li>
                Your monthly repayments will be deducted from the bank account you have authorized for recurring direct debit on Okra
              </li>
            </ul>
          </p>
          <div className="margin-top space-apart single center">
            {
              okraDirectDebitNotAuthorised ?
                  <Button click_event={initiateOkra}>Proceed to Authorize Direct Debit</Button>
                  :
                  <Button click_event={initiateOkra}>Proceed To Okra</Button>
            }
          </div>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
