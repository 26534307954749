import gql from "graphql-tag";

export const fragments = {
    bank: gql`
        fragment BankDetails on Bank {
            id
            name
            code
        }
    `,
    card: gql`
        fragment CardDetails on Card {
            id
            maskedPan
            expiryDate
            type
            status
            bankName
            isDefault
        }
    `,
    accountBank: gql`
        fragment AccountBankDetails on AccountBank {
            id
            accountName
            accountNumber
            isDefault
            status
        }
    `,
};

export const PAYMENT_INFO_QUERY = gql`
query PaymentInformationQuery {
    viewer {
        id
        me {
            id
            email
        }
        account {
            id
            bankAccounts {
                ...AccountBankDetails
                bank {
                    ...BankDetails
                }
            }
            cards {
                ...CardDetails
            }
        }
    }
}
${fragments.bank}
${fragments.card}
${fragments.accountBank}
`;
