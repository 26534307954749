import cx from "classnames";
import moment from "moment";
import React, { Fragment, useState } from "react";

import {
  Alert,
  DataCardV2,
  LoanDetailsCard,
  OfferLetter,
  PopupV2,
  Table,
  Tag,
  TransactionTable,
} from "../../components";
import { Close } from "../../components/Icon";
import {
  ApplicationDetailsProvider,
  RepaymentBreakDownProvider,
} from "../../providers";
import { repaymentBreakdownHeader } from "../GetStarted/constants";

import {
  numberWithCommas,
  removeUnderscore,
  round,
  sumColumn,
} from "../../lib/utils";

import ProdCampNPSWidget from "../../components/ProdCampNPSWidget/ProdCampNPSWidget";
import "./_styles.scss";

const LoanDetails = () => {
  const [showRepaymentDetails, switchRepaymentDetails] = useState(false);

  const toggleRepaymentDetailsVisibility = (e) => {
    e.preventDefault();
    switchRepaymentDetails(!showRepaymentDetails);
  };

  return (
    <Fragment>
      <ApplicationDetailsProvider>
        {({
          amount,
          fullAmount,
          status,
          createdAt,
          application,
          refetch,
          policy,
          loanDuration,
          portfolio,
          applicationNumber,
          dateOfRepayment,
          rejectReason,
          moratoriumData,
        }) => (
          <section className={"container loan-details"}>
            <OfferLetter application={application} refetch={refetch} />
            {applicationNumber && application.policy != null ? (
              <RepaymentBreakDownProvider
                policyId={policy ? policy.id : 0}
                principalAmount={amount}
                duration={loanDuration}
                moratoriumPeriod={
                  moratoriumData && moratoriumData.numberOfPeriods
                    ? moratoriumData.numberOfPeriods
                    : null
                }
              >
                {({ repaymentBreakdown }) => (
                  <Fragment>
                    {portfolio ? (
                      <Fragment>
                        <DataCardV2>
                          <LoanDetailsCard
                            amountRequested={amount}
                            amountPaid={portfolio.amountPaid || 0}
                            fullAmount={portfolio.fullAmount || fullAmount}
                            portfolioNumber={portfolio.portfolioNumber}
                            applicationNumber={applicationNumber}
                            loanDate={portfolio.createdAt || createdAt}
                            amountDisbursed={portfolio.amountDisbursed || 0}
                            interestRate={
                              portfolio.policy && portfolio.policy.interestRate
                                ? portfolio.policy.interestRate.value
                                : policy.interestRate
                                ? policy.interestRate.value
                                : 0
                            }
                            numberOfInstallments={portfolio.repayments.length}
                            status={portfolio.status.name}
                            monthlyInstallment={
                              portfolio.repayments[0]
                                ? portfolio.repayments[0].totalPayment
                                : 0
                            }
                            repaymentDate={
                              portfolio.dateOfRepayment || dateOfRepayment
                            }
                            lateRepaymentFee={portfolio.lateRepaymentFee}
                          />
                          {repaymentBreakdown &&
                          repaymentBreakdown.length !== 0 ? (
                            <div
                              className="view-repayment"
                              onClick={(e) =>
                                toggleRepaymentDetailsVisibility(e)
                              }
                            >
                              View Repayment Details
                            </div>
                          ) : (
                            ""
                          )}
                        </DataCardV2>

                        {portfolio.repayments.length !== 0 && (
                          <DataCardV2>
                            <TransactionTable
                              txTableHeaders={[
                                "Date",
                                "Status",
                                "Total Amount",
                                "Total Amount Paid",
                              ]}
                            >
                              {portfolio.repayments.map((data, index) => (
                                <Fragment key={index}>
                                  {data.amountPaid ? (
                                    <li key={index}>
                                      <div
                                        className="tx-column transaction-date"
                                        data-header="Date"
                                      >
                                        {moment(data.repaymentDate).format(
                                          "ll"
                                        )}
                                      </div>
                                      <div
                                        className="tx-column"
                                        data-header="Status"
                                      >
                                        <Tag
                                          status={removeUnderscore(
                                            data.status.name
                                          ).toLowerCase()}
                                        />
                                      </div>
                                      <div
                                        className="tx-column"
                                        data-header="Total Amount"
                                      >
                                        <span>
                                          NGN{" "}
                                          {numberWithCommas(data.totalPayment)}
                                        </span>
                                      </div>
                                      <div
                                        className="tx-column"
                                        data-header="Total Amount Paid"
                                      >
                                        <span>
                                          NGN{" "}
                                          {numberWithCommas(
                                            data.amountPaid || 0
                                          )}
                                        </span>
                                      </div>
                                    </li>
                                  ) : (
                                    <Alert classes="warning">
                                      Repayment History not available
                                    </Alert>
                                  )}
                                </Fragment>
                              ))}
                            </TransactionTable>
                          </DataCardV2>
                        )}
                      </Fragment>
                    ) : (
                      <DataCardV2>
                        <LoanDetailsCard
                          amountRequested={amount}
                          amountPaid={0}
                          fullAmount={fullAmount}
                          applicationNumber={applicationNumber}
                          applicationDate={createdAt}
                          amountDisbursed={0}
                          status={status.name}
                          rejectReason={rejectReason}
                          paymentMethod=""
                          application={application}
                        />

                        {repaymentBreakdown &&
                        repaymentBreakdown.length !== 0 ? (
                          <div
                            className="view-repayment"
                            onClick={(e) => toggleRepaymentDetailsVisibility(e)}
                          >
                            View Repayment Details
                          </div>
                        ) : (
                          ""
                        )}
                      </DataCardV2>
                    )}

                    {showRepaymentDetails ? (
                      <PopupV2 title="Repayment Breakdown">
                        <Close
                          className="close-icon"
                          onClick={(e) => toggleRepaymentDetailsVisibility(e)}
                        />
                        <div className="modal-rb-content">
                          <Table>
                            <div className="table__head">
                              {repaymentBreakdownHeader.map((header, key) => (
                                <span
                                  key={`${header}_${key}`}
                                  className={cx("Status", {
                                    "checked-width": header === "Status",
                                    "width-sm": header === "",
                                  })}
                                >
                                  {header}
                                </span>
                              ))}
                            </div>
                            <div className="table__body">
                              {repaymentBreakdown &&
                                repaymentBreakdown.length != 0 &&
                                repaymentBreakdown.map((item, key) => (
                                  <div
                                    key={`{item}`}
                                    className={cx("table__body-row", {
                                      "last-row": item.date === "Total",
                                    })}
                                  >
                                    <span>
                                      {item.dueDate
                                        ? moment(item.dueDate).format("ll")
                                        : "NA"}
                                    </span>
                                    <span>{`₦${numberWithCommas(
                                      item.principalPortion
                                    )}`}</span>
                                    <span>{`₦${numberWithCommas(
                                      item.interestPortion
                                    )}`}</span>
                                    <span>{`₦${numberWithCommas(
                                      item.expectedPayment
                                    )}`}</span>
                                  </div>
                                ))}
                              <div
                                className={cx("table__body-row", {
                                  "last-row": repaymentBreakdown
                                    ? repaymentBreakdown
                                    : "",
                                })}
                              >
                                <span>Total</span>
                                <span>
                                  ₦
                                  {numberWithCommas(
                                    round(
                                      sumColumn(
                                        repaymentBreakdown,
                                        "principalPortion"
                                      ),
                                      2
                                    )
                                  )}
                                </span>
                                <span>
                                  ₦
                                  {numberWithCommas(
                                    round(
                                      sumColumn(
                                        repaymentBreakdown,
                                        "interestPortion"
                                      ),
                                      2
                                    )
                                  )}
                                </span>
                                <span>
                                  ₦
                                  {numberWithCommas(
                                    round(
                                      sumColumn(
                                        repaymentBreakdown,
                                        "expectedPayment"
                                      ),
                                      2
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                          </Table>
                        </div>
                      </PopupV2>
                    ) : (
                      ""
                    )}
                  </Fragment>
                )}
              </RepaymentBreakDownProvider>
            ) : (
              <section className="container loan-details no-loan">
                <DataCardV2>
                  <LoanDetailsCard
                    amountRequested={amount}
                    amountPaid={(portfolio && portfolio.amountPaid) || 0}
                    fullAmount={fullAmount}
                    portfolioNumber={0}
                    applicationNumber={applicationNumber}
                    loanDate={createdAt}
                    amountDisbursed={0}
                    interestRate={0}
                    numberOfInstallments={
                      portfolio && portfolio.repayments.length
                    }
                    status={(portfolio && portfolio.status.name) || status.name}
                    monthlyInstallment="NA"
                    repaymentDate="NA"
                    lateRepaymentFee="NA"
                    paymentMethod="NA"
                    rejectReason={rejectReason}
                  />
                </DataCardV2>
              </section>
            )}
          </section>
        )}
      </ApplicationDetailsProvider>

      <ProdCampNPSWidget />
    </Fragment>
  );
};

export default LoanDetails;
