import React, { Fragment, useState, useEffect } from 'react';
import Notifications from 'react-notify-toast';
import { DataCardV2, Button, Input, FormGroup, BackArrow, Alert, Loader } from '../../../../components';
import { AuthService } from '../../../../services';
import { getErrorMessages, authGetter } from '../../../../lib/utils';

const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  updatePBStepCount,
  formParams,
  userData,
  baseInputChangeHandler,
  getPreviouslySavedData,
  updateContextState,
  requiredSocialMedia,
}) => {
  updatePBStepCount(stepIndex + 1);

  const [ responseError, setResponseError ] = useState([]);
  const [ inputErrors, setInputErrors ] = useState([]);
  const [ isSubmitted, updateSubmit ] = useState(false);
  const [ isLoading, updateLoadingState ] = useState(false);
  const requiredFormFields = [ 'bvnPhoneDigits' ];
  const { bvnPhoneDigits } = formParams;
  const { title, subTitle } = formInfo;
  const { go } = navigation;

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case 'bvnPhoneDigits':
        if (value.length === 0 || value.length < 4) {
          setInputErrors((prevState) => ({ ...prevState, bvnPhoneDigits: 'Please enter the last 4 digits of your bvn phone number' }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, bvnPhoneDigits: '' }));
        }
        break;
    }
    return true;
  };

  const checkValidation = () => {
    requiredFormFields.map((item) => {
      validateForm(item, formParams[item]);
    });
  };

  useEffect(
    () => {
      checkValidation();
    },
    [ formParams, isSubmitted ]
  );

  const confirmPhone = async () => {
    updateLoadingState(true);

    const userId = userData ? userData.me.id : null;
    const response = await AuthService.triggerBvnPhoneConfirmation(bvnPhoneDigits, userId);
    
    updateLoadingState(false);

      if (response == null || typeof response === 'undefined') {
        const error = 'Unable to verify bvn phone number.';
        setResponseError({ resendOtp: error });
        return;
      }
  
      if (response.data.errors) {
        const error = getErrorMessages(response.data.errors);
        setResponseError({ bvnPhoneError: error });
        return;
      }
  
      const { ok } = response.data.data.triggerBvnPhoneConfirmation;
  
      if (!ok) {
        const error = 'BVN phone verification failed.';
        setResponseError({ bvnPhoneError: error });
        return;
      }
      const nextStep = requiredSocialMedia.length ? 'socialMediaDetails' : 'loanSelection';
      updateContextState({page: nextStep});
      go(nextStep);
  };

  const nextStep = () => {
    updateSubmit(true);

    const errors = Object.keys(inputErrors).filter((item) => inputErrors[item] !== '');

    if (errors.length < 1 && userData) {
      confirmPhone();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        {responseError &&
        responseError.length !== 0 && (
          <Fragment>{responseError.verifyOtp ? <Alert classes="error">{responseError.verifyOtp}</Alert> : ''}</Fragment>
        )}

        <div className="form_content">
          <Notifications />
          <section>
            <FormGroup>
              <Input
                required
                numberField
                placeholder="Last 4 Digits"
                name="bvnPhoneDigits"
                value={bvnPhoneDigits}
                maxlength={4}
                onChange={baseInputChangeHandler}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.bvnPhoneDigits ? inputErrors.bvnPhoneDigits : ''}
              />
            </FormGroup>
          </section>
        </div>

        <div className="space-apart single right">
            <Button inactive={!bvnPhoneDigits} click_event={nextStep}>
              Continue
            </Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
