import moment from "moment";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import {
  DataCardV2,
  OfferLetter,
  RepaymentCard,
  Tag,
  TransactionTable,
} from "../../components";
import { NewLoanIcon, SadSmiley } from "../../components/Icon";
import { numberWithCommas, removeUnderscore } from "../../lib/utils";
import { ViewerProvider } from "../../providers";

import ProdCampNPSWidget from "../../components/ProdCampNPSWidget/ProdCampNPSWidget";
import "./_styles.scss";


const MyLoans = () => {
  return (
    <Fragment>
      <ViewerProvider>
        {({ currentLoan, applications, latestLoan, refetch }) =>
          applications.length !== 0 ? (
            <section className={"container my-loans"}>
              {currentLoan.id ||
                (latestLoan && latestLoan.status.name !== 'DENIED' && latestLoan.status.name !== 'APPROVED') ? (
                ""
              ) : (
                <Link
                  to="/loans/new"
                  className="button icon-left visible-mobile"
                >
                  <span className="icon">
                    <NewLoanIcon />
                  </span>
                  <span className={"nav-text"}>New Loan</span>
                </Link>
              )}
              {(latestLoan && latestLoan.status.name !== 'DENIED' && latestLoan.status.name !== 'APPROVED') && (
                  <DataCardV2>
                    <RepaymentCard
                      amountPaid="0.00"
                      fullAmount={numberWithCommas(latestLoan.fullAmount)}
                      percent="0"
                      repaymentAmount="n/a"
                      repaymentDate={latestLoan.dateOfRepayment}
                      overlay={true}
                      status={removeUnderscore(latestLoan.status.name)}
                    />
                  </DataCardV2>
                )}
              {currentLoan.id && (
                <DataCardV2>
                  <RepaymentCard
                    amountPaid={currentLoan.amountPaid}
                    fullAmount={currentLoan.fullAmount}
                    percent={currentLoan.percent}
                    repaymentAmount={currentLoan.fullAmount}
                    repaymentDate={currentLoan.dateOfRepayment}
                    portfolioNumber={currentLoan.portfolioNumber}
                  />
                </DataCardV2>
              )}
              <OfferLetter application={latestLoan} refetch={refetch} />
              <DataCardV2>
                <TransactionTable
                  txTableHeaders={[
                    "Date",
                    "Status",
                    "Loan Amount",
                    "Repayment Amount",
                    "Repayment Date",
                    ""
                  ]}
                >
                  {applications.map((data, index) => (

                    <li key={index} className="tranx-row">
                      <div
                        className="tx-column transaction-date"
                        data-header="Date"
                      >
                        {moment(data.createdAt).format("ll")}
                      </div>
                      <div className="tx-column" data-header="Status">
                        <Tag
                          status={
                            data.portfolio
                              ? data.portfolio.status.name.toLowerCase() ===
                                "closed"
                                ? "paid"
                                : data.portfolio.status.name.toLowerCase() ===
                                  "disbursed"
                                  ? "active"
                                  : data.portfolio.status.name.toLowerCase() ===
                                    "pending_disbursement"
                                    ? "approved"
                                    : removeUnderscore(
                                      data.portfolio.status.name
                                    ).toLowerCase()
                              : removeUnderscore(data.status.name).toLowerCase()
                          }
                        />
                      </div>
                      <div className="tx-column full" data-header="Loan Amount">
                        <span>NGN {numberWithCommas(data.amount)}</span>
                      </div>
                      <div className="tx-column" data-header="Repayment Amount">
                        <span>NGN {numberWithCommas(data.fullAmount)}</span>
                      </div>
                      <div className="tx-column" data-header="Repayment Date">
                        {moment(data.dateOfRepayment).format("ll")}
                      </div>

                      <div className="tx-column full">
                        {data.status.name === 'PENDING' &&
                          !data?.completedSteps?.includes(
                            'COMPLETE_APPLICATION',
                          ) ? (
                          <Link
                            to={`loans/new`}
                            data-testid="view-details"
                            className="button block active small"
                          >
                            Complete Application
                          </Link>
                        ) : (
                          <Link
                            to={`/loans/loan-${data.applicationNumber.toLowerCase()}`}
                            data-testid="view-details"
                            className="button block secondary small"
                          >
                            View Details
                          </Link>
                        )}
                      </div>
                    </li>

                  ))}
                </TransactionTable>
              </DataCardV2>
            </section>
          ) : (
            <section className="container  my-loans no-loan">
              <DataCardV2>
                <div className="smiley-holder">
                  <SadSmiley />
                </div>
                <h3 className="center-text">You have no active loan</h3>
                <Link to="/loans/new" className="button">
                  Apply for a new loan
                </Link>
              </DataCardV2>
            </section>
          )
        }
      </ViewerProvider>

      <ProdCampNPSWidget />
    </Fragment>
  );
};
export default MyLoans;
