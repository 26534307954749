import React, { Fragment, useState, useEffect } from 'react';
import PaystackButton from 'react-paystack';
import config from '../../../../config/config';
import Notifications, { notify } from 'react-notify-toast';

import { DataCardV2, Button, Alert, Loader } from '../../../../components';
import { CardService, AuthService, LoanService } from '../../../../services';
import {
  getErrorMessages,
  generateCardReference,
  removeCommas,
  authGetter, getGlobalClientId, getLoanDurationName,
} from '../../../../lib/utils';
import Constants from '../../../../lib/constants';

import './_Styles.scss';

const Details = ({
  stepIndex,
  appClientInfo,
  navigation,
  formInfo = {},
  formParams,
  updateFormParams,
  userData,
  setUserData,
  updatePBStepCount,
  loanDurationType,
  updateContextState,
}) => {
  updatePBStepCount(stepIndex + 1);

  const [isLoading, updateLoadingState] = useState(false);
  const [cardReference, updateCardReference] = useState();
  const [paystackIntel, updatePaystackIntel] = useState({
    payStackMeta: null,
    closePayStack: false,
    payStackSuccess: null,
    willInitiatePaystack: false,
    payStackReference: '',
    isReady: false,
  });
  const [responseError, setResponseError] = useState([]);
  const [inputErrors, setInputErrors] = useState([]);
  const channel = useState(['card']);
  const { title, subTitle } = formInfo;
  const { next, go } = navigation;
  let myColor = { background: '#0E1717', text: '#FFFFFF' };
  let payStackMetadata = {};
  let paystackReference;
  const loanCategoryId = localStorage.getItem('selectedCategory');
  const [loanDur, setLoanDur] = useState('');

  const checkAddCardReference = (response) => {
    if (response) {
      const { contextStates } = response && response.account;
      const {
        state,
      } = contextStates.find(item => item.context === 'SIGN_UP');
      paystackReference = state.paystackReference;
      if (paystackReference) {
        updateCardReference(paystackReference);
        updatePaystackIntel(prevState => ({
          ...prevState,
          payStackReference: paystackReference,
        }));
        getCardReferenceStatus();
        return;
      }
    }
    getAddCardReference();
  };

  const getAddCardReference = async () => {
    const error = 'Error generating Transaction Reference';
    updateLoadingState(true);

    const response = await CardService.getAddCardReference();
    updateLoadingState(false);

    if (!response) {
      updatePaystackIntel(prevState => ({ ...prevState, isReady: true }));
      setInputErrors(prevState => ({
        ...prevState,
        errorGettingCardReference: error,
      }));
      return;
    }

    if (response.data.errors) {
      updatePaystackIntel(prevState => ({ ...prevState, isReady: true }));

      const error = getErrorMessages(response.data.errors);
      setInputErrors(prevState => ({
        ...prevState,
        errorGettingCardReference: error,
      }));
      return;
    }

    const { getAddCardReference } = response.data.data;

    if (!getAddCardReference) {
      updatePaystackIntel(prevState => ({ ...prevState, isReady: false }));

      setInputErrors(prevState => ({
        ...prevState,
        errorGettingCardReference: error,
      }));
      return;
    } else {
      updatePaystackIntel(prevState => ({ ...prevState, isReady: false }));

      const { reference } = getAddCardReference;
      updatePaystackIntel(prevState => ({
        ...prevState,
        payStackReference: reference,
      }));
      updateContextState({ state: { paystackReference: reference } })
      updateCardReference(reference);
      return;
    }

    return;
  };

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    updateLoadingState(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    const response = viewer.data.data.viewer;
    if (response) {
      setUserData(response);
      checkAddCardReference(response);

      const businessInformation = response.account.accountAttribute.find(accountAttr => accountAttr.attribute.name === 'businessInformation').data;
      const { loanDuration } = businessInformation;
      setLoanDur(loanDuration)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  const payStackCallback = async response => {
    if (!response) {
      updatePaystackIntel(prevState => ({
        ...prevState,
        payStackSuccess: false,
      }));
    }

    getCardReferenceStatus();
  };

  const initiateCardVerification = async () => {
    if (!cardReference || !cardReference.reference) {
      getCardReferenceStatus();
    }

    const payStackBtn = document.getElementsByClassName('PayStackButton');

    if (payStackBtn[0]) {
      payStackBtn[0].click();
    }

    const success = () => {
      const { payStackSuccess } = paystackIntel;

      if (payStackSuccess == null || typeof payStackSuccess === 'undefined') {
        setTimeout(success, 100);
      }

      if (payStackSuccess) next();
      if (payStackSuccess === false) {
        setInputErrors(prevState => ({
          ...prevState,
          errorGettingCardReference: 'Error validating your card.',
        }));
        return;
      }
    };

    success();
  };

  const getCardReferenceStatus = async () => {
    const error = 'Error verifying your payment status. Please, try again.';
    updateLoadingState(true);

    const reference = paystackReference ?
      paystackReference :
      cardReference.reference ?
        cardReference.reference :
        cardReference;

    let { loanDuration } = formParams;
    const response = await CardService.getCardReferenceStatus({
      reference: reference,
      loanDuration: loanDuration,
    });

    updateLoadingState(false);

    if (!response) {
      setInputErrors(prevState => ({
        ...prevState,
        errorGettingCardReference: 'Error validating your card..',
      }));
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setInputErrors(prevState => ({
        ...prevState,
        errorGettingCardReference: error,
      }));
      return;
    }

    updatePaystackIntel(prevState => ({ ...prevState, isReady: true }));

    const { getCardReferenceStatus } = response.data.data;

    if (!getCardReferenceStatus.status) {
      setInputErrors(prevState => ({
        ...prevState,
        errorGettingCardReference: 'Error validating your card status',
      }));
      return;
    }

    const payStackReferenceStatus = getCardReferenceStatus.status;
    const cardBank = getCardReferenceStatus.bank;
    const failureReason = getCardReferenceStatus.reason
      ? getCardReferenceStatus.reason
      : 'The card you entered is already linked to another user';


    switch (payStackReferenceStatus) {
      case 'SUCCESS':
        updatePaystackIntel(prevState => ({
          ...prevState,
          closePayStack: true,
          payStackSuccess: true,
          cardBank,
          willInitiatePaystack: false,
        }));
        handleNextStep(cardBank);
        break;
      case 'FAILED':
        const cardRef = await getAddCardReference();
        updatePaystackIntel(prevState => ({
          ...prevState,
          payStackSuccess: false,
          payStackReference: cardRef,
        }));
        notify.show(failureReason ? failureReason : 'An error occurred, please add your card.', 'error', 60000);
        break;
      case 'NOT_USED':
        notify.show(`${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the continue button`, 'info', 8000);
        break;
      case 'ABANDONED':
        notify.show(`${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the continue button`, 'info', 8000);
        break;
      default:
        getCardReferenceStatus();
        break;
    }
    return;
  };

  const handleNextStep = data => {
    initiateApplication();
  };

  const initiateApplication = async () => {
    const authentication = authGetter();

    updateLoadingState(true);
    let { amount, loanDuration } = formParams;
    let durType = loanDur && loanDur.split(" ");
    durType = durType[1];

    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    const userData = viewer.data.data.viewer;

    let nextPage = 'bankStatement';
    if (appClientInfo?.requiresOkraTransactions) {
      nextPage = 'okra';
    }

    const businessInfo = userData.account.accountAttribute.find(
      attr => attr.attribute.name === 'businessInformation'
    ).data;
    if (!amount) {
      ({ amount, loanDuration } = businessInfo);
    }
    const selectedCategory = businessInfo.selectedCategory

    try {
      const response = await LoanService.initiateApplication(
        {
          clientId: getGlobalClientId(),
          amount: removeCommas(amount),
          loanDuration: parseInt(loanDuration),
          durationType: getLoanDurationName(durType),
          loanCategoryId: selectedCategory && selectedCategory.id ? selectedCategory.id : null,
        }
      );

      if (response?.data?.data) {
        const { initiateApplication } = response.data.data;
        const { application } = initiateApplication;

        const { id } = application;

        updateFormParams(prevState => ({
          ...prevState,
          applicationId: id,
        }));
        updateContextState({ page: nextPage, state: { applicationId: id } });
        updateLoadingState(false);
        go(nextPage);

        return;
      }

      if (response?.data?.errors && getErrorMessages(response.data.errors)?.includes('existing active loan application')) {
        updateContextState({ page: nextPage });
        updateLoadingState(false);
        go(nextPage);
        return;
      }

      notify.show(getErrorMessages(response?.data?.errors) || 'There was an error processing your loan. Please try again later.', 'error', 60000);

      updateLoadingState(false);

    } catch (e) {
      notify.show('There was an error processing your loan. Please try again later.', 'error', 60000);
      updateLoadingState(false);
    }

    return;
  };

  const nextStep = () => {
    initiateCardVerification();
  };

  if (userData) {
    let { loanDuration } = formParams;
    payStackMetadata = {
      accountId: userData && userData.user ? userData.user.id : userData.userId,
      cardReference: generateCardReference,
      transactionType: Constants.transactType.transactionType,
      loanDuration
    };
  }

  return (
    <Fragment>
      {isLoading && <Loader />}
      <Notifications />
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        {responseError && responseError.length !== 0 && (
          <Fragment>
            {responseError.errorGettingCardReference ? (
              <Alert classes="error">{responseError.validateInputs}</Alert>
            ) : (
              ''
            )}
          </Fragment>
        )}
        <div className="form_content add_debit_card">
          <section className="margin-top">
            <p className='info-box_warning center'>Please ensure that your card is not expiring within the loan duration you are applying for.</p>
            <div className="info-box">
              Please note that N
              {appClientInfo ? appClientInfo.addCardCharge : '0'} is a
              <span className="info-box_warning"> NON-REFUNDABLE FEE</span> for
              bank statement check, credit bureau check and card tokenization.
              <br /> This fee is not a guarantee of loan disbursement.
            </div>
          </section>

          {!paystackIntel.closePayStack && userData && (
            <PaystackButton
              text="Add Card"
              className="PayStackButton button hidden"
              embed={false}
              channels={channel}
              reference={cardReference ? cardReference : paystackIntel.payStackReference}
              email={userData ? userData.me.email : ''}
              close={() => { }}
              amount={
                appClientInfo
                  ? parseInt(appClientInfo.addCardCharge) * 100
                  : parseInt('100') * 100
              }
              paystackkey={appClientInfo ? appClientInfo.paystackPubKey : ''}
              metadata={payStackMetadata}
              callback={payStackCallback}
              tag="button"
            />
          )}
        </div>

        <div className="space-apart single right">
          <Button click_event={nextStep}>Continue</Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Details;
