import * as React from 'react';

const DocumentIcon = props => (
  <svg width={38} height={43} viewBox="0 0 38 43" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 0H12a2.99 2.99 0 00-2.54 1.443l-8 8.128A3.059 3.059 0 000 12.193v27.434c0 1.683 1.343 3.048 3 3.048h32c1.657 0 3-1.365 3-3.048V3.048C38 1.365 36.657 0 35 0zM9 6.218v2.927H6.12L9 6.218zm26 33.409H3V12.294h.06l.06-.06H12V3.21l.06-.061v-.061h22.9L35 39.627z"
      fill="#4C5C6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 23.37H9c-.828 0-1.5.682-1.5 1.524 0 .841.672 1.524 1.5 1.524h20c.828 0 1.5-.683 1.5-1.524 0-.842-.672-1.524-1.5-1.524zM26 30.482H9c-.828 0-1.5.683-1.5 1.524 0 .842.672 1.524 1.5 1.524h17c.828 0 1.5-.682 1.5-1.524 0-.841-.672-1.524-1.5-1.524zM22 19.305h6c1.657 0 3-1.364 3-3.048v-6.096c0-1.684-1.343-3.048-3-3.048h-6c-1.657 0-3 1.364-3 3.048v6.096c0 1.684 1.343 3.048 3 3.048zm0-9.144h6v6.096h-6v-6.096z"
      fill="#4C5C6D"
    />
  </svg>
);

export default DocumentIcon;
