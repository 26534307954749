import gql from 'graphql-tag'

export const UPLOAD_IMAGE = gql`
  mutation($image: Upload! $fileName: String!) {
    uploadImage(
    input: {image: $image, fileName: $fileName}
    ) {
      imageUrl
    }
  }
`;

export const UPLOAD_BANK_STATEMENT = gql`
  mutation UploadBankStatement ($file: Upload!, $password: String, $bankId: String!, $applicationId: ID!) {
    uploadBankStatement(
        input: {file: $file, password: $password, bankId: $bankId, applicationId: $applicationId}
    ) {
      filename
      mimetype
      encoding
    }
  }
`;

export const UPLOAD_SUPPORTING_DOCUMENT = gql`
  mutation UploadSupportingDocument($file: Upload!, $documentName: String!, $userId: ID!) {
    uploadSupportingDocument(
        input: {documentName: $documentName, file: $file, userId: $userId}
    ) {
      id
      documentName
      fileUrl
    }
  }
`;

export const UPLOAD_REQUESTED_DOCUMENT = gql`
  mutation UploadRequestedSupportingDocument(
    $requestId: ID!
    $requestedDocuments: [RequestedDocumentsInput!]!
  ) {
    uploadRequestedSupportingDocument(
      input: {
        requestId: $requestId
        requestedDocuments: $requestedDocuments
      }
    ) {
      success
    }
  }
`;
