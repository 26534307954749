import React, { Fragment, useState, useEffect } from 'react';
import Notifications, { notify } from 'react-notify-toast';

import { AuthService } from '../../../../services';
import {
  DataCardV2,
  Button,
  CheckBox,
  CheckBoxGroup,
  Loader,
  Alert,
  Input,
  BackArrow,
} from '../../../../components';
import {
  validateSocialMediaUrl,
  getErrorMessages,
} from '../../../../lib/utils';
import 'react-datepicker/dist/react-datepicker.css';
import './_Styles.scss';

let requiredFormFields = [
  'employmentStatus',
  'companyName',
  'companyAddress',
  'employmentDuration',
  'netMonthlyIncome',
  'workEmail',
];

const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  updatePBStepCount,
  formParams,
  updateFormParams,
  baseInputChangeHandler,
  updateContextState,
  requiredSocialMedia,
}) => {
  updatePBStepCount(stepIndex + 1);

  const [inputErrors, setInputErrors] = useState([]);
  const [isLoading, updateLoadingState] = useState(false);
  const [responseError, setResponseError] = useState([]);
  const [valuesUpdated, setValuesUpdated] = useState([]);
  const [isSubmitted, updateSubmit] = useState(false);
  const [socialMedia, setSocialMedia] = useState([]);
  const [checkboxError, setCheckboxError] = useState(false);
  const { title, subTitle } = formInfo;
  const { next, previous } = navigation;
  let myColor = { background: '#0E1717', text: '#FFFFFF' };

  const { facebook } = formParams;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateMediaUrl = (e) => {
    const { name, value } = e.target;
    if (validateSocialMediaUrl(name, value)) {
      checkSocialMediaUrl(name, value);
    } else {
      notify.show(`Please enter a valid ${name} URL`, 'error', 4000, myColor);
    }
  };

  const handleOnChange = (event) => {
    const { value } = event.target;
    if (
      socialMedia.length &&
      socialMedia.some((item) => item.provider === value)
    ) {
      let filteredArray = socialMedia.filter((item) => item.provider !== value);
      setSocialMedia(filteredArray);
    } else {
      setSocialMedia((prev) => [
        ...prev,
        { provider: value, profileUrl: '', profileId: value },
      ]);
    }
    return socialMedia;
  };

  const checkSocialMediaUrl = (provider, url) => {
    if (socialMedia.length && socialMedia.length > 0) {
      let filteredArray = socialMedia.find(
        (item) => item.profileUrl === url && item.provider === provider,
      );

      setValuesUpdated((prev) => {
        let prevState = prev.filter((item) => item.provider !== provider);
        return [...prevState, filteredArray];
      });
    }
  };

  const updateUser = async () => {
    updateLoadingState(true);

    const response = await AuthService.updateUserDataV2({
      socialMediaInformation: [...valuesUpdated],
    });
    updateLoadingState(false);

    if (response == null || typeof response === 'undefined') {
      const error =
        'An error occurred while updating your details. Please try again';
      setResponseError({ updateUserData: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ updateUserData: error });
      return;
    }

    if (response.data.data.updateUserData) {
      updateContextState({ page: 'loanSelection' });
      next();
    } else {
      window.setTimeout(() => {
        setResponseError({
          updateUserData: 'Could not update your information.',
        });
        return;
      }, 10);
    }
  };

  const nextStep = () => {
    updateSubmit(true);
    const errors = Object.keys(inputErrors).filter(
      (item) => inputErrors[item] !== '',
    );
    if (valuesUpdated.length === requiredSocialMedia.length) {
      updateUser();
    } else {
      if (!socialMedia.length) {
        notify.show(
          'Please select a value from the available checkboxes',
          'error',
          4000,
          myColor,
        );
      } else {
        notify.show(
          'Please select all the available checkboxes and provide a valid profile URL for the selected social media account',
          'error',
          4000,
          myColor,
        );
      }
    }
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      <Notifications />
      <DataCardV2 title={title} subTitle={subTitle} centeralize hasPadding>
        <Notifications />
        {responseError && responseError.length !== 0 && (
          <Fragment>
            {responseError.updateUserData ? (
              <Alert classes="margin-top error">
                {responseError.updateUserData}
              </Alert>
            ) : (
              ''
            )}
          </Fragment>
        )}
        {checkboxError ? (
          <Alert classes="error">
            {' '}
            Please select a value from the checkbox and provide the required
            input
          </Alert>
        ) : (
          ''
        )}
        <div className="form_content social_media_details">
          <CheckBoxGroup>
            {requiredSocialMedia &&
              requiredSocialMedia.map((requiredUrl) => (
                <CheckBox
                  noBorder
                  label={requiredUrl.name}
                  name={requiredUrl.name}
                  value={requiredUrl.name}
                  onChange={(e) => handleOnChange(e)}
                />
              ))}
          </CheckBoxGroup>
          {socialMedia.length > 0 && (
            <h5>
              Login to your social media accounts on your internet browser then
              copy and paste your profile link below
            </h5>
          )}
          {socialMedia.length !== 0 &&
            socialMedia.map((item) => (
              <Input
                name={`${item.provider}`}
                placeholder={`${item.provider.toUpperCase()} URL`}
                classes="border-bottom"
                onChange={(e) => {
                  item.profileUrl = e.target.value;
                  setSocialMedia([...socialMedia]);
                  validateMediaUrl(e);
                }}
              />
            ))}
        </div>

        <div className="space-apart space-apart">
          <BackArrow height="24" onClick={previous} text="Back" />
          <Button click_event={nextStep}>Continue</Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
