import React, { Fragment, useState, useEffect } from "react";
import { Loader, Alert, Card, Button, PasswordInput } from "../../components";
import { UpdatePasswordProvider, ViewerProvider } from "../../providers";
import { inputOptionTypes } from "../GetStarted/constants";
import { validatePasswordStrength } from "../../lib/utils";

import "react-web-tabs/dist/react-web-tabs.css";
import "./_Settings.scss";

export const SecuritySettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formParams, updateFormParams] = useState({
    newPassword: "",
    retypeNewPassword: "",
    screen: "securitySetting",
  });
  const [inputErrors, setInputErrors] = useState([]);

  const baseInputChangeHandler = (e) => {
    let name, value;
    let type = e.target.type;

    if (inputOptionTypes.includes(type)) {
      name = e.target.name;
      value = e.target.value;
    }

    updateFormParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getPasswordConfirmed = (value) => {
    return value === formParams.newPassword;
  };

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case "newPassword":
        if (value.length === 0 || !validatePasswordStrength(value)) {
          setInputErrors((prevState) => ({
            ...prevState,
            newPassword:
              "Password must be at least 6 characters and must contain a digit, a lowercase character and an uppercase character",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, newPassword: "" }));
        }
        break;
      case "retypeNewPassword":
        if (value.length === 0 || !getPasswordConfirmed(value)) {
          setInputErrors((prevState) => ({
            ...prevState,
            retypeNewPassword: "Passwords do not match. Please check your password",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, retypeNewPassword: "" }));
        }
        break;
    }
    return true;
  };

  const requiredFormFields = ["newPassword", "retypeNewPassword"];

  const checkValidation = () => {
    requiredFormFields.map((item) => {
      validateForm(item, formParams[item]);
    });
  };

  useEffect(() => {
    checkValidation();
  }, [formParams]);

  return (
    <ViewerProvider>
      {({ user }) => (
        <UpdatePasswordProvider user={user} formParams={formParams}>
          {({ form, onSubmit, loading, errorMessage, successMessage, isSubmitted }) => {
            return (
              <Fragment>
                <Card title={"Current Password"} subtitle={"Enter your current password"}>
                  <div className="security">
                    <PasswordInput
                      label="CURRENT PASSWORD"
                      classes="border-bottom"
                      placeholder=""
                      name="currentPassword"
                      value={formParams.currentPassword}
                      onChange={(e) => baseInputChangeHandler(e)}
                    />
                  </div>
                </Card>
                <Card title={"New Password"} subtitle={"Enter your new password"}>
                  <div className="security">
                    <PasswordInput
                      label="NEW PASSWORD"
                      classes="border-bottom"
                      placeholder=""
                      name="newPassword"
                      value={formParams.newPassword}
                      onChange={(e) => baseInputChangeHandler(e)}
                      errorMessage={isSubmitted && inputErrors.newPassword ? inputErrors.newPassword : ""}
                    />
                  </div>
                </Card>
                <Card title={"Confirm Password"} subtitle={"Confirm your new password"}>
                  <div className="security">
                    <PasswordInput
                      label="CONFIRM PASSWORD"
                      classes="border-bottom"
                      placeholder=""
                      name="retypeNewPassword"
                      value={formParams.retypeNewPassword}
                      onChange={(e) => baseInputChangeHandler(e)}
                      errorMessage={isSubmitted && inputErrors.retypeNewPassword ? inputErrors.retypeNewPassword : ""}
                    />
                  </div>
                </Card>
                <Card>
                  {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
                  {successMessage && <Alert classes="success">{successMessage}</Alert>}
                  {loading ? <Loader /> : <Button click_event={onSubmit}>Update Password</Button>}
                </Card>
              </Fragment>
            );
          }}
        </UpdatePasswordProvider>
      )}
    </ViewerProvider>
  );
};

export default SecuritySettings;
