import React from "react";
import { Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_REPAYMENT_BREAKDOWN_QUERY } from "./queries";
import { Loader } from "../../components";

const RepaymentBreakDown = ({ children, policyId, principalAmount, duration, moratoriumPeriod }) => {
  return (
    <Query query={GET_REPAYMENT_BREAKDOWN_QUERY} variables={{ policyId, principalAmount, duration, moratoriumPeriod }}>
      {({ loading, error, data }) => {
        const props = {
          error,
          loading,
          repaymentBreakdown: [],
        };

        if (loading) {
          return <Loader />;
        }

        if (error) {
          return children(props);
        }

        if (!loading && !error && data) {
          const { getRepaymentBreakdown } = data;

          props.repaymentBreakdown = getRepaymentBreakdown.repaymentBreakdown;
        }

        return children(props);
      }}
    </Query>
  );
};

export default withRouter(RepaymentBreakDown);
