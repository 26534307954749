import React from 'react';
import PropTypes from 'prop-types';
import './_Radio.scss';

const Radio = (props) => {
  return (
    <label className={`radio ${props.disabled ? 'radio--disabled' : ''} ${props.readOnly ? 'radio--read-only' : ''}`}>
      <input
        className={`${props.classes == null ? '' : props.classes}`}
        type="radio"
        value={props.value}
        disabled={props.disabled}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        readOnly={props.readOnly}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <span className="radio__label">{props.label}</span>
    </label>
  );
};

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  checked: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  readOnly: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ])
};

export default Radio;
