const graphQlRequest = require('../lib/requestClient');

const APPLICATION_FRAGMENT = /* GraphQL */ `
    fragment ApplicationDetails on Application {
        id
        amount
        taxAmount
        baseAmount
        chargesAmount
        status {
            name
        }
        policy {
            id
            name
        }
        applicationNumber
        fullAmount
        loanDuration
        dateOfRepayment
        requiredSteps
        completedSteps
        createdAt
    }
`;

// eslint-disable-next-line
const SUGGESTED_APPLICATION_FRAGMENT = `
    on ApplicationSuggestion {
        amount
        loanDuration
        dateOfRepayment
    }
`;

const PORTFOLIO_FRAGMENT = `
    on Portfolio {
        id
        amount
        chargesAmount
        baseAmount
        fullAmount
        taxAmount
        loanDuration
        amountPaid
        amountDisbursed
        dateOfRepayment
        createdAt
    }
`;

export default class LoanService {
  static initiateApplication({clientId, amount, loanDuration, durationType, dateOfRepayment, dayOfRepayment, loanCategoryId}) {
    amount = parseFloat(parseFloat(amount).toFixed(2));
    const query = `
            mutation($clientId: ID!, $amount: Float!, $loanDuration: Int!, $durationType: DurationType, $dateOfRepayment: DateTime, $dayOfRepayment: Int, $loanCategoryId: ID) {
                initiateApplication(input: {clientId: $clientId, amount: $amount, loanDuration: $loanDuration, durationType: $durationType, dateOfRepayment: $dateOfRepayment, dayOfRepayment: $dayOfRepayment, loanCategoryId: $loanCategoryId}) {
                    success
                    denied
                    application { ...ApplicationDetails }
                    errors{
                      message
                    }
                    warnings{
                      message
                    }
                }
            }
            ${APPLICATION_FRAGMENT}
        `;

    const data = {
      query,
      variables: {
        clientId,
        amount,
        loanDuration,
        durationType,
        dateOfRepayment,
        dayOfRepayment,
        loanCategoryId,
      }
    };

    return graphQlRequest().post('graphql', data).catch((err) => {});
  }

  static completeApplication(applicationId) {
    const query = `
            mutation($applicationId: ID!) {
                completeApplication(input: {applicationId: $applicationId}) {
                    approved
                    errors {
                        message
                    }
                    warnings {
                        message
                    }
                    portfolio {
                        id
                    }
                    application { ...ApplicationDetails }
                }
            }
            ${APPLICATION_FRAGMENT}
        `;

    return graphQlRequest().post('graphql', { query, variables: { applicationId } }).catch((err) => {});
  }

  static getApplicantCreditScore(applicationId) {
    const query = `
            mutation($applicationId: ID!) {
                getApplicantCreditScore(input: {applicationId: $applicationId}) {
                    success
                    application {
                        credit{
                            score
                        }
                        status {
                            name
                        }
                    }
                }
            }
        `;

    return graphQlRequest().post('graphql', { query, variables: { applicationId } }).catch((err) => {});
  }

  static generateApplicationOffers(applicationId) {
    const query = `
      mutation($applicationId: ID!) {
        generateApplicationOffers(input: {applicationId: $applicationId}) {
            application { ...ApplicationDetails }
            suggestedOffer {
                id
                data {
                    amount
                    baseAmount
                    fullAmount
                    chargesAmount
                    taxAmount
                    discountAmount
                    noOfRepayments
                    repaymentBreakdown {
                        id
                        principalBalance
                        expectedPayment
                        interestPortion
                        principalPortion
                        endingBalance
                        dueDate
                    }
                }
            }
        }
      }
      ${APPLICATION_FRAGMENT}
      `;

    return graphQlRequest().post('graphql', { query, variables: { applicationId } }).catch((err) => {});
  }

  /**
     * @param applicationId
     * @return {*}
     */
  static getLoanApplicationById(applicationId) {
    const query = `
            query($applicationId: ID!) {
                applicationById(id: $applicationId) {
                    ... ${APPLICATION_FRAGMENT}
                }
            }
        `;

    return graphQlRequest().post('graphql', { query, variables: { applicationId } }).catch((err) => {});
  }

  /**
     * @param portfolioId
     * @return {*}
     */
  static getPortfolioById(portfolioId) {
    const query = `
            query($portfolioId: ID!) {
                portfolioById(id: $portfolioId) {
                    id
                    amount
                    chargesAmount
                    baseAmount
                    fullAmount
                    taxAmount
                    loanDuration
                    amountPaid
                    amountDisbursed
                    lateRepaymentFee
                    dateOfRepayment
                    createdAt
                    status {
                      name
                    }
                }
            }
        `;

    return graphQlRequest().post('graphql', { query, variables: { portfolioId } }).catch((err) => {});
  }

  static disburseLoanToBank(portfolioId, accountBankId) {
    const query = `
            mutation ($portfolioId: ID!, $accountBankId: ID!) {
                disburseLoanToBank(input: { portfolioId: $portfolioId, accountBankId: $accountBankId}) {
                    success
                    portfolio {
                        ...${PORTFOLIO_FRAGMENT}
                    }
                }
            }
        `;

    return graphQlRequest().post('graphql', { query, variables: { portfolioId, accountBankId } }).catch((err) => {});
  }

  static transferFundsToBankAccount(sourceAccountNumber, sourceAccountName, amount, description, portfolioId) {
    const query = `
            mutation($sourceAccountNumber: String!, $sourceAccountName: String!, $amount: Float!, $description: String, $portfolioId: ID!) {
                transferFundsToBankAccount(input: {sourceAccountNumber: $sourceAccountNumber, sourceAccountName: $sourceAccountName, amount: $amount, description: $description, portfolioId: $portfolioId}) {
                    success
                }
            }
        `;

    return graphQlRequest()
      .post('graphql', {
        query,
        variables: { sourceAccountNumber, sourceAccountName, amount, description, portfolioId }
      })
      .catch((err) => {});
  }

  static approveLoanModification(payload) {
    const query = `
        mutation ApproveUpdatedLoanAmountMutation($applicationId: ID!, $requestId: ID!, $approve: Boolean!) {
            approveUpdatedLoanAmount(input: { applicationId: $applicationId, requestId: $requestId, approve: $approve }) {
                mbsFeedback {
                status
                message
                feedback  
                requestId
                }  
                application {  
                fullAmount  
                dateOfRepayment  
                reviewDetails {  
                    rejectReason  
                }  
                }  
                success  
            }  
        }  
    `;


    return graphQlRequest()
      .post('graphql', {
        query,
        variables: { ...payload }
      })
      .catch((err) => {});
  }

  static addApplicationMetadata(payload) {
    const query = `
        mutation($applicationId: ID!, $data: [ApplicationMetadataInput!]!) {
            addApplicationMeta(input: { applicationId: $applicationId, data: $data }) {
                status
            }
        }
    `;

    return graphQlRequest()
        .post('graphql', {
            query,
            variables: { ...payload }
        })
        .catch((err) => {});
  }
}
