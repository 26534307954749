const graphQlRequest = require('../lib/requestClient');

const USER_FRAGMENT = `
     me {
        id
        firstName
        lastName
        email
        phone
        status
        isEmailConfirmed
        isPhoneConfirmed
    }
`;

// eslint-disable-next-line
const TRANSACTION_FRAGMENT = `
        transactions {
            nodes {
              id
              amount
              amountBefore
              amountAfter
              paymentMethod {
                name
                icon
              }
              status
              type {
                name
              }
              createdAt
            }
          }`;

const PORTFOLIO_FRAGMENT = `
    portfolios {
        nodes {
          id
          amount
          chargesAmount
          taxAmount
          status {
            name
          }
          createdAt
          fullAmount
          dateOfRepayment
          amountPaid
          amountDisbursed
        }
      }`;

const APPLICATION_FRAGMENT = `
    applications {
        nodes {
          amount
          chargesAmount
          taxAmount
          status {
            name
          }
          createdAt
          fullAmount
          dateOfRepayment
        }
      }
`;
const ACCOUNT_FRAGMENT = `
    account {
      id
      name
      ${APPLICATION_FRAGMENT}
      ${PORTFOLIO_FRAGMENT}
    }
`;

export default class UserService {

    /**
     *
     * @return {*}
     */
    static viewer() {
        const query = `{                
            viewer {                    
                    id                         
                    ${USER_FRAGMENT}
                    ${ACCOUNT_FRAGMENT}
                 }            
            }`;



        return graphQlRequest().post('graphql', {query}).catch(err => {});
    }
}
