export const formSteps = [
  // Please and pls and pls, don't change id for any reason.
  {
    id: 'loanSelection',
    formInfo: {
      title: 'Select a Loan Product',
      subTitle: 'Please select a loan product below to continue your application'
    },
    title: 'Select a Loan Product'
  },
  {
    id: 'businessInformation',
    formInfo: {
      title: 'Business Information',
      subTitle: 'Please enter your business details below.'
    },
    title: 'Business Information',
  },
  {
    id: 'loanInformation',
    formInfo: {
      title: 'Loan Information',
      subTitle: 'To get started, tell us how much you need and how long you fully intend to pay back'
    },
    title: 'Loan Information'
  },
  {
    id: 'addDebitCard',
    formInfo: {
      title: 'Select Debit Card',
      subTitle:
        'Select the debit card you want to use to handle repayment'
    },
    title: 'Select Debit Card'
  },
  {
    id: 'supportingDocuments',
    formInfo: {
      title: 'Supporting Documents',
      subTitle:
        'Please upload all required documents to continue your application. Only PNG, JPG, JPEG, PDF files are allowed (maximum file size is 10MB)'
    },
    title: 'Supporting Documents'
  },
  {
    id: 'okraPage',
    formInfo: {
      title: 'Bank Statement',
      subTitle:
        `As part of your loan application, we connect to Okra to fetch your
        bank statement. This improves your chances in getting a loan. We value
        security therefore transfer of your information is encrypted and your
        login credentials will never be made accessible to us by Okra.`
    },
    title: 'Bank Statement'
  },
  {
    id: 'bankStatement',
    formInfo: {
      title: 'Select Bank',
      subTitle: 'Enter your bank account number so we can request for your  bank statement'
    },
    title: 'Select Bank'
  },
  {
    id: 'ticketNumber',
    formInfo: {
      title: 'Ticket Number',
      subTitle: 'Enter your ticket number and OTP sent by your bank to your email'
    },
    title: 'Ticket Number'
  },
  {
    id: 'mbsUpload',
    formInfo: {
      title: 'Bank Statement',
      subTitle: ''
    },
    title: 'Bank Statement'
  },
  {
    id: 'confirmRequest',
    formInfo: {
      title: 'Confirm your loan request',
      subTitle: ''
    },
    title: 'Confirm your request'
  },
  {
    id: 'loanConfirmation',
    formInfo: {
      title: '',
      subTitle: ''
    },
    title: 'Loan Confirmation'
  }
];

export const excludedStepIds = [
  'bvnDetails',
  'loanRequest',
  'addDebitCard',
  'loanConfirmation'
];

export const removeProgressBarOn = [ 'loanConfirmation' ];
export const removeProgressBoxOn = [ 'loanConfirmation' ];

export const noop = () => {
  return () => {};
};

export const loanRequest = [
  {
    id: '7syd7sdt7d',
    text: 'Request Amount',
    value: '15000000.23',
    repayment: '450000.23',
    interest: '20%',
    loan_tenor: '13 Months'
  }
];

export const maxLoanableAmount = 500000000;

export const defaultRepaymentInfo = {
  title: 'Monthly Repayment',
  amount: 'NGN 200,000',
  info:
    'The offer above is an initial estimate based on the information you have provided. The final offer will be determined by the financial profile of your facility.'
};

export const repaymentBreakdown = [
  { date: '12 May, 2020', principal: '10500.22', interest: '500.12', totalPayment: '11000.34' },
  { date: '15 May, 2020', principal: '22500.22', interest: '1200.12', totalPayment: '23700.34' },
  { date: 'Total', principal: '435400.44', interest: '5440.62', totalPayment: '93884.43' }
];

export const repaymentBreakdownHeader = [ 'Repayment Date', 'Principal', 'Interest', 'Total Payment' ];

export const loanDurationType = 'MONTHLY';
export const loanDurationList = [ { name: '1 Month', value: 1 }, { name: '2 Months', value: 2 } ];

export const inputOptionTypes = [ 'text', 'tel', 'number', 'email', 'select-one', 'password' ];

export const loanRequestReasons = [ { name: 'Personal' }, { name: 'Bill' } ];
