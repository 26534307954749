import React from 'react';
import { Query } from 'react-apollo';

import { PAYMENT_INFO_QUERY } from './queries';
import { notify } from 'react-notify-toast';
import Constants from '../../lib/constants';
import { Loader } from '../../components';

const PaymentInformationProvider = ({ children }) => {
  const getDefault = array => array.find(({ isDefault }) => isDefault === true);

  return (
    <Query query={PAYMENT_INFO_QUERY} fetchPolicy="cache-and-network">
      {({ data, loading, error, refetch }) => {
        const props = {
          error,
          loading,
          refetchCards: refetch,
          email: '',
          bankAccounts: [],
          cards: [],
          defaultBankAccount: {},
          defaultCard: {},
        };

        if (loading) return <Loader />;

        if (error) {
          notify.show(
            'An error has occurred. Please try again later.',
            Constants.status.ERROR,
            4000
          );
          return children(props);
        }

        if (data?.viewer && !loading && !error) {
          const { viewer } = data;

          props.email = viewer?.me?.email;
          props.bankAccounts = viewer?.account?.bankAccounts;
          props.defaultBankAccount = getDefault(viewer?.account?.bankAccounts);
          props.cards = viewer?.account?.cards;
          props.defaultCard = getDefault(viewer?.account?.cards);
          props.accountId = viewer?.account?.id;
        }
        return children(props);
      }}
    </Query>
  );
};

export default PaymentInformationProvider;
