import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Context from '../../context';
import {  DataCardV2 } from '../../components';

import './_Styles.scss';

const Index = () => {
  const { state } = useContext(Context);
  const clientName = state.clientInfo ? state.clientInfo.name : 'LSETF';
  
  return (
    <section className="container welcome-screen">
      <DataCardV2>
        <section>
            <div> <img src="https://ignite-api.s3.us-east-2.amazonaws.com/merchant-logos/staging/lsetf-logo.png" alt="image"/></div>
            <div> 
                <h3>
                    APPLICATION PORTAL IS NOW CLOSED <b>FOR NEW APPLICANTS</b> UNTIL END OF JANUARY 2022!!!
                </h3>
            </div>
            <div>
            <aside className="sign-up-step__footer">
                Already have an account? {' '}
                <Link to="/sign-in">Sign In</Link>
            </aside>
            </div>
        </section>
      </DataCardV2>
    </section>
  );
};

export default Index;
