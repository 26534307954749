import React, { useEffect, useState, useContext, Fragment } from "react";
import Notifications from "react-notify-toast";
import { Helmet } from "react-helmet";
import "./_Layouts.scss";
import { Header, Footer, Page, Menu, NotFound, BreadCrumbs } from "../index";
import { Route, Switch, withRouter } from "react-router-dom";
import {
  Settings,
  Dashboard,
  MyLoans,
  NewLoan,
  LoanDetails,
} from "../../containers";
import { authGetter } from "../../lib/utils";
import Context from "../../context";

const AuthenticatedLayout = () => {
  const authentication = authGetter();

  const { state } = useContext(Context);

  return (
    <Fragment>
      <Helmet>
        <title>
          LSETF Loan Application Portal
        </title>
        <link
          id="favicon"
          rel="icon"
          href={state && state.clientInfo ? state.clientInfo.faviconUrl : ""}
          type="image/x-icon"
        />
      </Helmet>
      <section className={`layout ${authentication.authenticationType}`}>
        <Header authentication={authentication} />
        <Menu />
        <BreadCrumbs authentication={authentication} />
        <Notifications />

        <main className="main">
          <Switch>
            <Route
              path="/dashboard"
              exact
              render={(props) => (
                <Page {...props} component={Dashboard} title="Dashboard" />
              )}
            />
            <Route
              path="/loans"
              exact
              render={(props) => (
                <Page {...props} component={MyLoans} title="Loans" />
              )}
            />

            <Route
              path="/loans/loan-:applicationNumber"
              exact
              render={(props) => (
                <Page {...props} component={LoanDetails} title="Loan Details" />
              )}
            />

            <Route
              path="/loans/new"
              exact
              render={(props) => (
                <Page {...props} component={NewLoan} title="New Loan" />
              )}
            />

            <Route
              path="/settings"
              exact
              render={(props) => (
                <Page {...props} component={Settings} title="Settings" />
              )}
            />

            <Route
              path="/settings/:subPage"
              exact
              render={(props) => (
                <Page {...props} component={Settings} title="Settings" />
              )}
            />
            <Route
              path=""
              exact
              render={(props) => (
                <Page {...props} component={NotFound} title="Page Not Found" />
              )}
            />
          </Switch>
        </main>
        <Footer />
      </section>
    </Fragment>
  );
};

export default withRouter(AuthenticatedLayout);
