import React,{useState} from 'react';
import './_RepaymentCard.scss';
import PropTypes from 'prop-types';
import moment from "moment";
import {Button,Popup} from "../index";
import {numberWithCommas} from "../../lib/utils";
import {ClientInformationProvider} from "../../providers";

const RepaymentCard = (props) => {

    const [showPopup, setShowPopup] = useState(false)

    const handleShowPopup = () => {
        setShowPopup(!showPopup);
    };

    const progressWidth = {
        width: `${Math.abs(props.percent).toFixed(2)}%`
    };

    return(
        <section className={'repayment-card'}>
            <div className="left">
                <h6>Your Current Repayment</h6>
                <h1>
                    N{numberWithCommas(props.amountPaid)} <span>of</span> N{numberWithCommas(props.fullAmount)}
                </h1>
                <div className="repayment-progress">
                    <span className="progress" style={progressWidth}/>
                </div>
                <span className="text">
                    {Math.abs(props.percent).toFixed(2)}% Complete
                </span>
            </div>
            <div className="right">
                <h6>Your Next Payment</h6>
                <h1>N{numberWithCommas(props.repaymentAmount)}</h1>
                <span className="text">{props.repaymentDate === null ? 'N/A' : moment(props.repaymentDate).format('LL')}</span>
                <Button classes="secondary block" click_event={handleShowPopup}>Make a payment</Button>
                {
                    showPopup &&
                    <ClientInformationProvider>{
                        ({bankDetails}) =>
                            <Popup title="Bank Details"  classes="loan-requirements">
                                <p>You can make payment to the account below:</p>
                                {bankDetails ? (
                                    <ul>
                                        <li>BANK NAME : {bankDetails.bank.name}</li>
                                        <li>ACCOUNT NUMBER :{bankDetails.accountNumber}</li>
                                        <li>ACCOUNT NAME : {bankDetails.accountName}</li>
                                    </ul>
                                ) : ""}

                                {props.portfolioNumber ? (
                                    <p>Use <span className="bold-text">{props.portfolioNumber}</span> as the narrative.</p>
                                ) : ""}
                                <div className="button-list center-text">
                                    <Button classes="close-button" click_event={handleShowPopup}>Close</Button>
                                </div>
                            </Popup>
                    }
                    </ClientInformationProvider>
                }
            </div>
            {props.overlay && (
                <aside className="overlay">
                    <div className="overlay-status">{props.status}</div>
                </aside>
            )}
        </section>
    )
};

RepaymentCard.propTypes = {
    amountPaid: PropTypes.number,
    fullAmount: PropTypes.number,
    percent: PropTypes.number,
    repaymentAmount: PropTypes.number,
    repaymentDate: PropTypes.any,
    status: PropTypes.any,
    overlay: PropTypes.bool,
    portfolioNumber: PropTypes.string,
};

export default RepaymentCard;
