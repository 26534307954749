import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { datadogRum } from '@datadog/browser-rum';
import { App } from "./containers/";
import config from "./config/config";
// import * as serviceWorker from "./serviceWorker";
import ApolloWrapper from "./lib/ApolloWrapper";
import withClearCache from "./ClearCache";

if (config.web.app_env === "production") {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: `${config.client.client_slug}_${config.web.node_env}`,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

if (config.web.app_env === "production") {
  datadogRum.init({
    applicationId: '82621eef-4097-44fc-9a64-8f0862af5d92',
    clientToken: 'pub115d98adc920fe1fab71d18d20a653d9',
    site: 'datadoghq.eu',
    service: 'lsetf-cp',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: config.web.version,
    env: `${config.client.client_slug}_${config.web.app_env}`,
    sampleRate: 20,
    replaySampleRate: 20,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
}

const ClearCacheComponent = withClearCache(App);

ReactDOM.render(
  <ApolloWrapper>
    <ClearCacheComponent />
  </ApolloWrapper>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// const configuration = {
//   onUpdate: (registration) => {
//     if (registration && registration.waiting) {
//       if (window.confirm('New version available! Refresh to update your app?')) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         window.location.reload();
//       }
//     }
//   }
// };
//
// serviceWorker.register(configuration);
