import React, { useState } from "react";
import { Mutation } from "react-apollo";

import { UPDATE_PASSWORD_MUTATION } from "./queries";
import { useFlashMessage } from "../../containers/Form";
import { authTypes } from "../../lib/constants";
import { validatePasswordStrength } from "../../lib/utils";

export default ({ children, formParams }) => {
  const [errorMessage, flashError, setErrorMessage] = useFlashMessage();
  const [successMessage, flashSuccess] = useFlashMessage();
  const [isSubmitted, setSubmission] = useState(false);
  let inputs = {};

  const changePassword = (e, runMutation) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage("");
    setSubmission(true);

    const { currentPassword, newPassword, retypeNewPassword } = formParams;
    const isValidated = validatePasswordStrength(newPassword);

    if (newPassword.length > 5 && retypeNewPassword.length > 5) {
      if (newPassword === retypeNewPassword) {
        if (isValidated) {
          runMutation({
            variables: {
              input: {
                oldPassword: currentPassword,
                newPassword: newPassword,
              },
            },
          });
        } else {
          flashError(
            "New password must be at least 6 characters and must contain a digit, a lowercase character and an uppercase character"
          );
        }
      } else {
        flashError("Passwords do not match");
      }
    } else {
      flashError("You have not made any changes");
    }
  };

  return (
    <Mutation
      mutation={UPDATE_PASSWORD_MUTATION}
      onCompleted={({ changePassword: { token } }) => {
        if (token) {
          // resetInputs(Object.values(inputs));
          flashSuccess("Your password has been updated");
          // update authtoken

          let authCreds = {
            apiKey: token,
            keyType: authTypes.FIXED,
          };

          localStorage.setItem("Auth", JSON.stringify(authCreds));
        }
      }}
      onError={(error) => {
        if (error.graphQLErrors.length === 0) flashError("Something Went Wrong: Try again later");

        error.graphQLErrors.forEach((error) => {
          switch (error.message) {
            case "cannot set new password to old one":
              flashError("Cannot reuse old password");
              break;
            case "Invalid password":
              flashError("The password you supplied is incorrect");
              break;
            default:
              flashError("Something Went Wrong: Try Again Later");
          }
        });
      }}>
      {(runMutation, { error, loading }) =>
        children({
          onSubmit: (e) => changePassword(e, runMutation),
          loading,
          error,
          errorMessage,
          successMessage,
          form: inputs,
          isSubmitted,
        })
      }
    </Mutation>
  );
};
