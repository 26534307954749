import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Constants from '../../../../lib/constants';
import { DataCard, Button, SuccessIcon } from '../../../../components';
import { FailureIcon } from '../../../../components/Icon';
import './_Styles.scss';

const Index = ({ formParams, updateContextState }) => {
  let history = useHistory();
  const [historyState, setHistoryState] = useState();
  const { loanRequestStatus, loanAgreement } = formParams;

  useEffect(() => {
    window.scrollTo(0, 0);

    const { state } = history.location;
    if (state) {
      setHistoryState(state);
    }
  }, []);

  const updateAuthType = () => {
    const authCreds = JSON.parse(localStorage.getItem('Auth'));
    authCreds.keyType = Constants.authTypes.FIXED;
    localStorage.setItem('Auth', JSON.stringify(authCreds));
  };


  const handleNext = () => {
    updateAuthType();
    updateContextState({ page: 'dashboard' });
    history.push('/dashboard');
  };

  return (
    <div className="loan-confirmation-wrapper">
      <DataCard centeralize>
        <div className="loan-confirmation">
          {historyState && historyState.error ? <FailureIcon /> : <SuccessIcon />}

          <h2> All Done </h2>

          <h6>
            {historyState && historyState.error ? (
              historyState.error.message
            ) : loanRequestStatus === 'under review' ? (
              'Your application is currently being reviewed. You will be notified of any updates to your application.'
            ) : (
              'Your loan is under review and you’ll get a feedback from us soon. To track your loan, click on go to your dashboard'
            )}
          </h6>
          <h6> Please click on the link in your email to verify your account </h6>

          <Button classes="center" click_event={handleNext}>
            Go to your Dashboard
          </Button>
        </div>
      </DataCard>
    </div>
  );
};

export default Index;
