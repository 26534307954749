import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import { handleGraphQLErrors } from '../../lib/utils';
import { DECLINE_OFFER_LETTER } from './queries';

const useDeclineOfferLetterProvider = ({ application }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState('');

  const [decline, { loading }] = useMutation(DECLINE_OFFER_LETTER, {
    onError(errors) {
      setErrorMessage(handleGraphQLErrors(errors));
      return;
    },
    onCompleted() {
      setSuccess(true);
      return;
    },
  });

  const declineOffer = () => {
    decline({
      variables: { applicationNumber: application.applicationNumber },
    });
  };

  const props = {
    loading,
    success,
    errorMessage,
    declineOffer,
  };
  return props;
};

export default useDeclineOfferLetterProvider;
