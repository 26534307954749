import React, { Fragment, useState, useEffect } from 'react';

import { Alert, DataCardV2, Button, Loader, Radio } from '../../../../components';
import {
  resolveLoanTenor,
  numberWithCommas,
  getErrorMessages,
  authGetter, durationTypeMapping, getLoanDurationName,
} from '../../../../lib/utils';
import { LoanService, AuthService } from '../../../../services';

import './_Styles.scss';

const Index = ({
  formInfo = {},
  userData,
  setUserData,
  navigation,
  formParams,
  updateFormParams,
  updateContextState,
}) => {
  const { title, subTitle } = formInfo;
  const [responseError, setResponseError] = useState([]);
  const [isLoading, updateLoadingState] = useState(false);
  const [generatedOffer, setGeneratedOffer] = useState({});
  const { next } = navigation;
  const { loanDuration, loanAgreement } = formParams;
  const [loanDur, setLoanDur] = useState('');

  const duration = loanDur.split(" ");
  const durationType = getLoanDurationName(duration[1]);

  const generateApplicationOffers = async () => {
    updateLoadingState(true);

    if (
      userData &&
      Object.keys(userData).length !== 0 &&
      userData.account.applications.nodes.length > 0 &&
      userData.account.applications.nodes[0].id
    ) {
      const { applications } = userData.account;
      const applicationId =
        applications.nodes.find((app) => (app.status.name === "PENDING")).id;
      const response = await LoanService.generateApplicationOffers(
        applicationId
      );

      updateLoadingState(false);

      if (response == null) {
        updateFormParams(prevState => ({
          ...prevState,
          ['loanRequestStatus']: 'under review',
        }));
        updateContextState({ page: 'dashboard' });
        next();
      }

      if (response.data.errors) {
        const error = getErrorMessages(response.data.errors);
        setResponseError({ generateApplicationOffers: error });
        updateFormParams(prevState => ({
          ...prevState,
          ['loanRequestStatus']: 'under review',
        }));
        updateContextState({ page: 'dashboard' });
        next();
      }

      if (response?.data?.data?.generateApplicationOffers) {
        const { generateApplicationOffers } = response.data.data;

        if (
          generateApplicationOffers == null ||
          typeof generateApplicationOffers === 'undefined'
        ) {
          setResponseError({
            generateApplicationOffers:
              'There was an error processing your loan. Please try again later.',
          });
          updateFormParams(prevState => ({
            ...prevState,
            ['loanRequestStatus']: 'under review',
          }));
          updateContextState({ page: 'dashboard' });
          next();
        }

        const { application, suggestedOffer } = generateApplicationOffers;
        const {
          requiredSteps,
          dateOfRepayment,
          id,
          amount,
          chargesAmount,
        } = application;

        if (suggestedOffer) {
          const {
            fullAmount,
            amount,
            baseAmount,
            chargesAmount,
            discountAmount,
            noOfRepayments,
            repaymentBreakdown,
          } = suggestedOffer.data;
          setGeneratedOffer(prevState => ({
            ...prevState,
            fullAmount,
            amount,
            baseAmount,
            chargesAmount,
            discountAmount,
            noOfRepayments,
            repaymentBreakdown,
            dateOfRepayment,
            loanTenor: resolveLoanTenor({ repaymentBreakdown, noOfRepayments }),
          }));
        } else {
          setGeneratedOffer(prevState => ({
            ...prevState,
            amount: amount,
            noOfRepayments: loanDuration ? loanDuration : 0,
            requiredSteps: requiredSteps,
            chargesAmount: chargesAmount ? chargesAmount : 0,
            id: id,
          }));
          updateFormParams(prevState => ({
            ...prevState,
            ['loanRequestStatus']: 'under review',
          }));
        }
      } else {
        updateContextState({ page: 'dashboard' });
        next();
      }

      return;
    }
  };

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    updateLoadingState(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    const response = viewer ? viewer.data.data.viewer : {};
    if (response) {
      setUserData(response);

      const businessInformation = response.account.accountAttribute.find(accountAttr => accountAttr.attribute.name === 'businessInformation').data;
      const { loanDuration } = businessInformation;

      setLoanDur(loanDuration)
    }
    return;
  };

  const completeApplication = async () => {
    updateLoadingState(true);

    if (userData && Object.keys(userData).length !== 0) {
      const application = userData.account.applications.nodes[0];

      const response = await LoanService.completeApplication(application.id);

      updateLoadingState(false);

      updateContextState({ page: 'dashboard' });

      if (response == null) {
        setResponseError({
          completeApplication: "Error, couldn't complete application",
        });
        next();
        return;
      }

      if (response?.data?.errors) {
        const error = getErrorMessages(response.data.errors);
        setResponseError({ completeApplication: error });
        next();
        return;
      }

      const { completeApplication } = response.data.data;

      if (completeApplication?.application?.errors) {
        const error = getErrorMessages(response.data.errors);
        setResponseError({ completeApplication: error });
        next();
        return;
      }

      if (completeApplication?.application?.policy === null) {
        setResponseError({
          completeApplication: 'Application Completed and under Review',
        });
        next();
      }

      next();
    }
  };

  const handleRadioChange = e => {
    const value = e.target.value === 'true' ? true : false;
    updateFormParams(prevState => ({ ...prevState, loanAgreement: value }))
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  useEffect(() => {
    if (userData) {
      generateApplicationOffers();
    }
  }, [userData]);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        {responseError && responseError.length !== 0 && (
          <Fragment>
            {responseError.generateApplicationOffers ? (
              <Alert classes="error">
                {responseError.generateApplicationOffers}
              </Alert>
            ) : (
              ''
            )}

            {responseError.completeApplication ? (
              <Alert classes="error">{responseError.completeApplication}</Alert>
            ) : (
              ''
            )}
          </Fragment>
        )}

        {generatedOffer && Object.keys(generatedOffer).length !== 0 ? (
          <Fragment>
            <div className="form_content">
              <div className="loan-repayment">
                <div className="row">
                  <div>Requested Amount</div>
                  <div>
                    {generatedOffer.amount
                      ? `₦${numberWithCommas(generatedOffer.amount)}`
                      : '--'}
                  </div>
                </div>
                <div className="row">
                  <div>Requested Tenor</div>
                  <div>
                    {generatedOffer.loanTenor || '--'}
                  </div>

                  {/*<div>*/}
                  {/*  { generatedOffer.noOfRepayments ?*/}
                  {/*     generatedOffer.noOfRepayments + ' ' + durationTypeMapping(durationType, generatedOffer.noOfRepayments)*/}
                  {/*      : '--'*/}
                  {/*  }*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>


            <div className="agreement-text">
              <Radio
                label="I have read all the information provided in my application and will like to be considered as a beneficiary under the LSETF Scheme. The information provided in my application is accurate and I understand there are penalties for providing any false information to the LSETF including imprisonment."
                value="true"
                name="loanAgreement"
                onChange={e => handleRadioChange(e)}
                checked={loanAgreement}
              />
            </div>

            <div className="space-apart single right">
              <Button inactive={!loanAgreement} click_event={() => completeApplication()}>
                Continue
              </Button>
            </div>
          </Fragment>
        ) : (
          ''
        )}
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
