import React, { useState, useEffect } from 'react';
import { ViewerProvider, UpdateUserDetailsProvider } from '../../providers';
import {
  FormGroup,
  Loader,
  Alert,
  Button,
  Input,
  Card,
  Select,
} from '../../components';
import NaijaStates from 'naija-xbystate';
import './_Settings.scss';
import { numberWithCommas } from '../../lib/utils';
import moment from 'moment';

const BusinessSettings = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const states = NaijaStates.states();
    updateNaijaStates(states);
  }, []);

  const loanStatuses = ['PENDING_DISBURSEMENT', 'DISBURSING', 'ACTIVE'];
  const applicationStatus = ['PENDING', 'UNDER_REVIEW'];

  const readOnly = (latestLoan, currentLoan) => {
    return (
      applicationStatus.includes(latestLoan?.status?.name) ||
      (currentLoan.status
        ? loanStatuses.includes(currentLoan?.status?.name)
        : false)
    );
  };

  const [formParams, updateFormParams] = useState({
    screen: 'businessSettings',
  });
  const [naijaStates, updateNaijaStates] = useState([]);

  return (
    <ViewerProvider>
      {({ user, latestLoan, currentLoan, businessInfo }) => (
        <UpdateUserDetailsProvider user={user} formParams={formParams}>
          {({ form, onSubmit, loading, errorMessage, successMessage }) => (
            <section className="container">
              <Card title={'Company Name'} subtitle={'Where do you work?'}>
                <FormGroup classes="one-grid">
                  <Input
                    label="Company Name"
                    classes="border-bottom"
                    placeholder="Company Name"
                    name="refereeName"
                    defaultValue={businessInfo.companyName}
                    readOnly
                  />
                </FormGroup>
              </Card>

              <Card
                title={'Company Address'}
                subtitle={'Where is your work place?'}
              >
                <Input
                  label="Company Address"
                  classes="border-bottom"
                  name="address"
                  readOnly
                  defaultValue={businessInfo.companyAddress}
                />

                <FormGroup classes="two-grid">
                  <Input
                    label="LGA"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.companyLga}
                  />
                  <Select
                    label="State"
                    name="residentialState"
                    classes="border-bottom try"
                    autoComplete="state"
                    readOnly
                    disabled
                  >
                    <option disabled value=""></option>
                    {naijaStates &&
                      naijaStates.map((state, index) => (
                        <option value={state} selected={'Lagos' === state}>
                          {state}
                        </option>
                      ))}
                  </Select>
                </FormGroup>
              </Card>

              <Card
                title={'Business Type'}
                subtitle={'What type of business is it?'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="Business Type"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.typeOfBusiness}
                  />
                </FormGroup>
              </Card>

              <Card
                title={'Business Sector'}
                subtitle={'What sector would you classify your business in?'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="Business Sector"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.businessSector}
                  />
                </FormGroup>
              </Card>

              <Card
                title={'Business Start Date'}
                subtitle={'When did you start the business?'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="Business Start Date"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={moment(
                      businessInfo.businessStartDate,
                      'DD/MM/YYYY',
                    ).format('ll')}
                  />
                </FormGroup>
              </Card>

              <Card
                title={'Company CAC Registration No'}
                subtitle={'When is the company CAC Registration No?'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="CAC Registration No"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.cacRegNo}
                  />
                </FormGroup>
              </Card>

              <Card
                title={'Company Tax Identification Number'}
                subtitle={'When is the company Tax Identification Number?'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="Company Tax Identification Number"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.companyTaxId}
                  />
                </FormGroup>
              </Card>

              <Card
                title={'Total Monthly Turnover'}
                subtitle={'What is your Total Monthly Turnover'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="Total Monthly Turnover"
                    classes="border-bottom"
                    placeholder=""
                    amountField
                    name=""
                    readOnly
                    defaultValue={businessInfo.monthlyTurnover}
                  />
                </FormGroup>
              </Card>

              <Card
                title={'Total Monthly Expenses'}
                subtitle={'What is your Total Monthly Expenses'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="Total Monthly Expenses"
                    classes="border-bottom"
                    placeholder=""
                    amountField
                    name=""
                    readOnly
                    defaultValue={businessInfo.monthlyExpenses}
                  />
                </FormGroup>
              </Card>
              <Card
                title={'Total Monthly Savings'}
                subtitle={'What is your Total Monthly Savings'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="Total Monthly Savings"
                    classes="border-bottom"
                    placeholder=""
                    amountField
                    name=""
                    readOnly
                    defaultValue={businessInfo.monthlySavings}
                  />
                </FormGroup>
              </Card>
              <Card
                title={'Current employee?'}
                subtitle={'How many people do you currently employ?'}
              >
                <FormGroup classes="one-grid">
                  <Input
                    label="Current employee"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.noOfEmployees}
                  />
                </FormGroup>
              </Card>
              <Card
                title={'Director Information'}
                subtitle={
                  'State all Company Directors, and provide their Personal Tax Identification No'
                }
              >
                {businessInfo.directors &&
                  businessInfo.directors.map((director, directorIndex) => (
                    <FormGroup
                      classes="two-grid"
                      key={`director_${directorIndex}`}
                    >
                      <Input
                        label="Director Name"
                        classes="border-bottom"
                        placeholder=""
                        name=""
                        readOnly
                        defaultValue={director.directorName}
                      />
                      <Input
                        label="Director's Personal Tax ID No"
                        classes="border-bottom"
                        placeholder=""
                        name=""
                        readOnly
                        defaultValue={director.directorTaxId}
                      />
                    </FormGroup>
                  ))}
              </Card>

              <Card
                title={'Employee Information'}
                subtitle={
                  'State all Company Employees, and provide their Personal Tax Identification No'
                }
              >
                {businessInfo.employees &&
                  businessInfo.employees.map((employee, employeeIndex) => (
                    <FormGroup
                      classes="two-grid"
                      key={`employee_${employeeIndex}`}
                    >
                      <Input
                        label="Employee Name"
                        classes="border-bottom"
                        placeholder=""
                        name=""
                        readOnly
                        defaultValue={employee.employeeName}
                      />
                      <Input
                        label="Employee's Personal Tax ID No"
                        classes="border-bottom"
                        placeholder=""
                        name=""
                        readOnly
                        defaultValue={employee.employeeTaxId}
                      />
                    </FormGroup>
                  ))}
              </Card>

              <Card
                title={'Expectations'}
                subtitle={'If granted this loan, what are your expectations?'}
              >
                <FormGroup classes="two-grid">
                  <Input
                    label="Expected Total Monthly Turnover"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.expectedMonthlyTurnover}
                  />
                  <Input
                    label="Expected Total Monthly Expenses"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.expectedMonthlyExpenses}
                  />
                </FormGroup>
                <FormGroup classes="two-grid">
                  <Input
                    label="Expected Total Monthly Savings"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.expectedMonthlySavings}
                  />
                  <Input
                    label="Expected No. of employees"
                    classes="border-bottom"
                    placeholder=""
                    name=""
                    readOnly
                    defaultValue={businessInfo.expectedNewHires}
                  />
                </FormGroup>
              </Card>

              <Card title={''} subtitle={''}>
                {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
                {successMessage && (
                  <Alert classes="success">{successMessage}</Alert>
                )}
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    inactive={readOnly(latestLoan, currentLoan)}
                    click_event={onSubmit}
                  >
                    Update Profile
                  </Button>
                )}
              </Card>
            </section>
          )}
        </UpdateUserDetailsProvider>
      )}
    </ViewerProvider>
  );
};

export default BusinessSettings;
