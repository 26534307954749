import React from 'react';

const BackArrow = (props) => (
  <svg viewBox="0 0 27 24" fill="none" {...props}>
    <path
      d="M20.6287 12H5.42871"
      stroke={props.stroke ? props.stroke : '#212D40'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0287 19L5.42871 12L13.0287 5"
      stroke={props.stroke ? props.stroke : '#212D40'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackArrow;
