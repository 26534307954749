import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input/Input';

import './_PasswordInput.scss';

const PasswordInput = (props) => {
    const { value, allowToggle: toggleSetting } = props;

    const allowToggle = typeof toggleSetting === 'undefined' ? true : toggleSetting;

    const [inputType, setInputType] = useState('password');

    const toggleInputType = () => {
        setInputType(inputType === 'password' ? 'text' : 'password');
    }

    return (
      <div className="password-field">
        <Input required type={inputType} {...props} />
        {value && allowToggle && (
          <button type="button" className="password-field_toggle" onClick={toggleInputType}>
            {inputType === "password" ? "show" : "hide"}
          </button>
        )}
      </div>
    );
}

PasswordInput.propTypes = {
    allowToggle: PropTypes.bool,
    ...Input.propTypes
}

export default PasswordInput;