import React from "react";
import LSETFLogo from "./LSETFLogo";
import "./_Layouts.scss";

const MaintenancePage = () => (
  <section className="error-layouts">
    <LSETFLogo />

    <section className="error-wrapper">
      <MaintenanceIcon />

      <section className="error-message">
        <h3>Under Maintenance</h3>
        <p>
          We're currently carrying out maintenance on our portal. Please, check
          back after some time.
        </p>
      </section>
    </section>
  </section>
);

export default MaintenancePage;

const MaintenanceIcon = (props) => (
  <svg viewBox="0 0 140 120" width={140} height={120} {...props}>
    <path
      d="M68.8 105.7c28 0 50.7-22.7 50.7-50.8 0-28.1-22.8-50.8-50.7-50.8-28 0-50.7 22.7-50.7 50.8 0 28.1 22.7 50.8 50.7 50.8Zm47.4-84.1a4.1 4.1 0 1 0 0-8.2 4.1 4.1 0 0 0 0 8.2Zm6.418-15a2.8 2.8 0 1 0 0-5.6 2.8 2.8 0 0 0 0 5.6ZM20.8 21.5a2.8 2.8 0 1 0 0-5.6 2.8 2.8 0 0 0 0 5.6Z"
      fill="#E6F2F6"
    />
    <path
      d="M24.018 99.4a5.2 5.2 0 1 0 0-10.4 5.2 5.2 0 0 0 0 10.4Z"
      fill="#E6F2F6"
    />
    <path
      d="M139.34 92.317c-.073 2.469-2.251 4.357-4.72 4.357h-31.946c-1.162 0-2.106.87-2.179 1.96-.072 1.234.872 2.178 2.106 2.178h8.132c.508 0 .944.436.944.944a.962.962 0 0 1-.944.944h-47.92c-2.469 0-4.574-1.888-4.72-4.356-.072-2.542 1.96-4.647 4.502-4.647h7.043c1.016-.145 1.887-1.017 1.887-2.106a2.107 2.107 0 0 0-2.105-2.105H47.783c-3.63 0-6.752-2.687-6.97-6.317-.29-3.92 2.832-7.188 6.752-7.188h63.749c3.703 0 6.752 3.05 6.752 6.752 0 1.598-.581 3.05-1.452 4.212a6.26 6.26 0 0 1 1.162.726h17.28c2.323.072 4.356 2.105 4.284 4.646Z"
      fill="url(#a)"
    />
    <g filter="url(#b)">
      <path
        d="m91.818 29.312-19.095 3.703-32.02 6.244-14.085 2.76c-2.541.507-4.284 2.83-4.066 5.445l2.76 30.857c.217 2.541 2.395 4.574 4.936 4.574l66.435.799-4.865-54.382Z"
        fill="url(#c)"
      />
    </g>
    <g filter="url(#d)">
      <path
        d="m99.732 31.49 4.429 48.72c0 .362-.218.725-.581.798l-5.3 1.161-4.72-51.55 5.446.145c.363 0 .653.363.726.726Z"
        fill="url(#e)"
      />
    </g>
    <g filter="url(#f)">
      <path
        d="M87.607 27.424 92.98 86.38c.072.436.508.727.871.509l5.228-2.033a1.76 1.76 0 0 0 1.016-1.743l-4.864-53.946c-.073-.726-.581-1.307-1.307-1.525l-5.518-.944c-.436-.072-.8.29-.8.726Z"
        fill="url(#g)"
      />
    </g>
    <path
      d="m73.449 32.942-32.02 6.245c-.363 1.742-.508 3.485-.29 5.3.871 9.802 9.511 16.99 19.24 16.118 9.73-.871 16.918-9.511 16.047-19.24-.29-3.122-1.307-5.954-2.977-8.423Z"
      fill="#98A5A9"
    />
    <defs>
      <linearGradient
        id="a"
        x1={40.749}
        y1={89.343}
        x2={139.359}
        y2={89.343}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0C6CC" />
        <stop offset={1} stopColor="#96A9AE" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={59.584}
        y1={28.054}
        x2={59.584}
        y2={84.28}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDFFFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={98.857}
        y1={29.427}
        x2={98.857}
        y2={82.725}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDFFFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={93.851}
        y1={25.295}
        x2={93.851}
        y2={87.612}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDFFFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <filter
        id="b"
        x={0.534}
        y={18.312}
        width={118.149}
        height={98.382}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1061_50" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1061_50"
          result="shape"
        />
      </filter>
      <filter
        id="d"
        x={71.561}
        y={19.619}
        width={54.601}
        height={95.55}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1061_50" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1061_50"
          result="shape"
        />
      </filter>
      <filter
        id="f"
        x={65.607}
        y={15.689}
        width={56.496}
        height={104.274}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1061_50" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_1061_50"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);