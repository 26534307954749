const config = {
  web: {
    port: process.env.WEB_PORT || 80,
    app_env: process.env.REACT_APP_ENV,
    node_env: process.env.NODE_ENV,
    mode: process.env.REACT_APP_MODE_ENV,
    version: process.env.REACT_APP_IMAGE_TAG || 'latest',
  },
  ignite: {
    base_url: process.env.REACT_APP_BASE_URL,
    ws_url: process.env.REACT_APP_IGNITE_WEBSOCKET_URL,
    api_url: `${process.env.REACT_APP_BASE_URL}graphql`
  },
  client: {
    client_id: process.env.REACT_APP_CLIENT_ID || '',
    client_slug: process.env.REACT_APP_CLIENT_SLUG || 'lsetf',
    interest_rate: process.env.REACT_APP_CLIENT_INTEREST_RATE,
    facebook_app_id: process.env.REACT_APP_CLIENT_FACEBOOK_APP_ID,
    minimum_loan_duration: process.env.REACT_APP_CLIENT_MINIMUM_LOAN_DURATION,
    requirements_popup: process.env.REACT_APP_CLIENT_REQUIREMENT_POPUP
  },
  payStack: {
    public_key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  },
  okra: {
    public_key: process.env.REACT_APP_OKRA_PUBLIC_KEY || '',
    token: process.env.REACT_APP_OKRA_TOKEN || ''
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN
  },
};

module.exports = config;
