import React from 'react';
import { DataCard, Button, SuccessIcon } from '../../components';
import { CloseIcon } from '../../components/Icon';
import { noop, loanRequest } from '../GetStarted/constants';
import './_styles.scss';

const index = () => (
  <div className="loan-confirmation-wrapper">
    <DataCard centeralize>
      <div className="loan-confirmation">
        <CloseIcon />
        <h2> All Done </h2>
        <h6>There was an error processing your loan. Please try again later.</h6>
        <Button
          classes="center"
          click_event={() => {
            window.location.href = '/sign-in';
          }}
        >
          Click Here to Sign In
        </Button>
      </div>
    </DataCard>
  </div>
);

export default index;
