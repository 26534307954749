import { useMutation } from "@apollo/react-hooks";

import { APPROVE_UPDATED_LOAN_AMOUNT_MUTATION } from "./queries";

const LoanAdjustmentProvider = ({ children }) => {
  const [mutate, { loading }] = useMutation(APPROVE_UPDATED_LOAN_AMOUNT_MUTATION, {
    onError(...error) {
      return error;
    },

    async onCompleted() {
      window.location.reload();
    },
  });

  const approveUpdatedLoanAmount = (data) => {
    const { applicationId = 0, requestId = 0 } = data || {};

    const payload = {
      applicationId,
      requestId,
      approve: true,
    };

    return mutate({
      variables: payload,
    });
  };

  const declineUpdatedLoanAmount = (data) => {
    const { applicationId, requestId } = data;

    const payload = {
      applicationId,
      requestId,
      approve: false,
    };

    return mutate({
      variables: payload,
    });
  };

  const props = {
    approveUpdatedLoanAmount,
    declineUpdatedLoanAmount,
    isLoading: loading,
  };

  return children(props);
};

export default LoanAdjustmentProvider;
