import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router';
import './_Settings.scss';
import { DebitCard, BankCard, Button, Loader, Alert } from '../../components';
import { notify } from 'react-notify-toast';
import { CardService, BankService, AuthService } from '../../services';
import Constants from '../../lib/constants';
import {authGetter, CONTEXTS, getGlobalClientId} from '../../lib/utils';
import { ClientInformationProvider, PaymentInformationProvider } from '../../providers';
import PaystackButton from 'react-paystack';

const { status, duration } = Constants;

const PaymentSettings = () => {
  const history = useHistory();
  const [ reference, setReference ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [contextState, setContextState] = useState();
  const [ changeDefaultBankComplete, setChangeDefaultBankComplete ] = useState(false);
  const [ refetchBankCards, setRefetchBankCards ] = useState(false);
  const channel = useState(['card']);

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);

    if (viewer && viewer.data && viewer.data.data) {
      const response = viewer.data.data.viewer;

      if (response.account.contextStates.length) {
        const { contextStates } = response && response.account;
        let filteredArray = contextStates.filter(item => item.context === 'REGISTERED_USER');
        if (filteredArray.length) {
          let {state:{payStackReference}}= filteredArray[filteredArray.length -1];
          setReference(payStackReference)
          if (payStackReference) {
          getReferenceStatus(payStackReference); 
          return; 
        }
        }
      }
    }
  };

  useEffect(() => {
   getPreviouslySavedData();
  }, []);

  const updateContextState = async ({page, state}) => {

    const payload = { 
      context: CONTEXTS.DASHBOARD, 
      page, 
      ...(state ? { state } : {}), 
      ...(contextState ? { id: contextState.id } : {}) }
    
    history.replace('payment', { jumpToScreen: page !== 'dashboard' ? page : 'loanConfirmation' });

    payload.state = payload.id ? { ...payload.state, ...(contextState.state || {}) } : payload.state;

    const response = payload.id 
      ? await AuthService.updateContextState(payload)
      : await AuthService.createContextState(payload);

    if (response && response.data.data) {
      setContextState(response.data.data.createContextState || response.data.data.updateContextState);
    }

  }

  const getReference = async () => {
    setLoading(true);

    const response = await CardService.getAddCardReference(getGlobalClientId());

    if (response && response.status === 200 && response.data.data != null) {
      const { getAddCardReference } = response.data.data;

      if (getAddCardReference && getAddCardReference.reference) {
        setReference(getAddCardReference.reference);
        updateContextState({page: 'dashboard', state:{payStackReference: getAddCardReference.reference}})
        setLoading(false);
        return getAddCardReference.reference;
      }
    }
  };

  const getReferenceStatus = async (paystackReference) => {
    setLoading(true);

    const ref = paystackReference.reference || paystackReference

    const response = await CardService.getCardReferenceStatus({reference: ref});

    if (response && response.status === 200 && response.data.data != null) {
      const { getCardReferenceStatus } = response.data.data;
      const payStackReferenceStatus = getCardReferenceStatus.status;

      switch (payStackReferenceStatus) {
        case 'SUCCESS':
          notify.show('Your card has been added successfully.', status.SUCCESS, duration.LONG);
          await getReference();
          setRefetchBankCards(true)
          break;
        case 'FAILED':
          notify.show(`${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add new card button`, 'error', 8000);
          await getReference();
          break;
          case 'NOT_USED':
          case 'ABANDONED':
            notify.show(`To add a card, please proceed by clicking the add new card button`, 'info', 8000);
            break;
          case 'INVALID':
              notify.show(`${getCardReferenceStatus.reason}, Please proceed to add your card by clicking the add new card button`, 'info', 8000);
              break;
          default:
            getCardReferenceStatus();
            break;
      }

      setLoading(false);
      setRefetchBankCards(false);
    }
  };

  const addCard = async () => {
    setLoading(true);
    const generatedReference = reference ? reference : await getReference();

    if (generatedReference) {
      const payStack = document.querySelector('.psButton');
      payStack.click();
    } else {
      setLoading(false);
      notify.show(
        'There was an error generating your reference. Please, refresh the page and try again later.',
        status.ERROR,
        duration.LONG
      );
    }
  };

  const closePaystackPopup = () => {
    notify.show('You have cancelled add new card.', status.ERROR, duration.LONG);
  };

  const setDefaultBank = async (accountId, bankAccount) => {
    setLoading(true);

    const accountBankId = bankAccount.id;

    const response = await BankService.setDefaultAccountBank(accountId, accountBankId);
    if (!response) {
      setLoading(false);
      notify.show('There was an error changing your default bank. Please try again later', status.ERROR, duration.LONG);
    }

    if (response && response.status === 200 && response.data.data != null) {
      setLoading(false);
      const { setDefaultAccountBank } = response.data.data;
      if (setDefaultAccountBank) {
        notify.show('Your default account has been successfully changed', status.SUCCESS, duration.LONG);
        setChangeDefaultBankComplete(setDefaultAccountBank);
      } else {
        notify.show(
          'There was an error changing your default bank. Please try again later',
          status.ERROR,
          duration.LONG
        );
      }
    }
  };

  return (
    <PaymentInformationProvider>
      {({ cards, bankAccounts, email, accountId, error, refetchCards }) => {
        if (changeDefaultBankComplete || refetchBankCards
        ) {
          setTimeout(() => {
            refetchCards();
          }, 1000);
        }
        return (
          <section className={'container two-grid'}>
            {loading && <Loader />}
            {error && <Alert classes='error'>There was an error loading cards and banks. Please reload the page</Alert>}

            <div className={'payment-options'}>
              <section>
                <h3>Cards</h3>
                <p className={'payment-options__subtitle'}>Your repayments can be charged to your active card.</p>
                {cards?.map((card, index) => <DebitCard cardDetails={card} accountId={accountId} key={index} />)}

                <Fragment>
                  <Button button_text='Add New Card' classes='add-card-btn' click_event={addCard} />
                  <ClientInformationProvider>
                    {({ paystackPubKey, addCardCharge }) => (
                      <PaystackButton
                        text='Add Card'
                        className='PayStackButton button psButton hidden'
                        channels={channel}
                        embed={false}
                        reference={reference}
                        email={email}
                        close={closePaystackPopup}
                        amount={addCardCharge ? parseInt(addCardCharge) * 100 : parseInt('100') * 100}
                        paystackkey={paystackPubKey}
                        callback={getReferenceStatus}
                        tag='button'
                      />
                    )}
                  </ClientInformationProvider>
                </Fragment>
              </section>
              <section>
                <h3>Bank Accounts</h3>
                <p className={'payment-options__subtitle'}>This is used for loan disbursements.</p>
                {bankAccounts?.map((bankAccount, index) => (
                  <BankCard
                    isDefault={bankAccount?.isDefault}
                    key={index}
                    onClick={() => setDefaultBank(accountId, bankAccount)}
                  >
                    <div>
                      <div className={'account-number'}>{bankAccount?.accountNumber}</div>
                      <div className={'bank'}>
                        <span className={'account-name'}>{bankAccount?.accountName}</span>
                        <span className={'bank-name'}>{bankAccount?.bank?.name}</span>
                      </div>
                    </div>
                  </BankCard>
                ))}
              </section>
            </div>
          </section>
        );
      }}
    </PaymentInformationProvider>
  );
};

export default PaymentSettings;
