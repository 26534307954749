import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CLIENT_INFO_QUERY } from "../Client/queries";
import { Loader } from "../../components";

const useClientInfo = ({ slug, clientUrl, loanCategoryId}) => {

    const { loading, error } = useQuery(
         CLIENT_INFO_QUERY, {
             variables: {slug, clientUrl, loanCategoryId: loanCategoryId },
        });
    if (loading) {
        return <Loader />;
    }

    return {
        loading,
        error
    };
};

export { useClientInfo };
