import React, { Fragment, useEffect } from 'react';
import { DataCardV2, Button, CheckBox } from '../../../../components';

import './_Styles.scss';

const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  updatePBStepCount,
  formParams,
  updateFormParams,
  jumpScreenTo,
  appClientInfo
}) => {
  updatePBStepCount(stepIndex + 1);

  const { tNc, NDPR } = formParams;
  const { title, subTitle } = formInfo;
  const { next, go } = navigation;

  const nextStep = () => {
    next();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => {
      if (jumpScreenTo && jumpScreenTo !== '') {
        go(jumpScreenTo);
      }
    },
    [ jumpScreenTo ]
  );

  const handleRadioChange = (e) => {
    const name = e.target.name;

    updateFormParams((prevState) => ({
      ...prevState,
      [name]: !formParams.tNc || !formParams.NDPR
    }));
  };

  return (
    <Fragment>
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        <div className="form_content before_you_begin">
          <section>
            <div className="list-items">
              <li>I am at least 18 years old</li>
              <li>I have a valid BVN</li>
              <li>I am a Lagos State Resident</li>
              <li>I have a personal account and debit card with at least 3 months validity</li>
              <li>Ensure your card is valid until the end of your expected loan tenor e.g. if you plan to take a loan for 6 months, your card must be valid for at least 6 months</li>
              <li>I have a LASSRA ID Card</li>
              <li>
                <span>
                  Please visit our <a href="https://www.lsetf.ng/" target="_blank">website</a> and read the Law creating the LSETF 
                  and understand that there are penalties for providing false information  
                </span>
              </li>

              <div className="tNcWrapper">
                <CheckBox checked={tNc} name="tNc" onChange={(e) => handleRadioChange(e)} />
                I have read the laws on the LSETF and understand there are penalties for providing false information.
              </div>
              <div className="tNcWrapper">
                <CheckBox checked={NDPR} name="NDPR" onChange={(e) => handleRadioChange(e)} />
                <span>
                We are NDPR Compliant. By using this form, you agree to the storage and usage of your data by LSETF in accordance with our
                <a href="https://lsetf.ng/content/privacy-policy" target="_blank"><b>Privacy policy</b></a></span>
              </div>

              {appClientInfo && appClientInfo.useRemita ? (
                <div className="disclaimer">
                  By clicking on Continue, I consent to {appClientInfo.name ? appClientInfo.name : 'the Owner'}{' '}
                  obtaining information from relevant third parties as may be necessary to make a decision on my loan
                  application.
                </div>
              ) : (
                <div className="disclaimer">
                  By clicking on Continue, I consent to{' '}
                  {appClientInfo && appClientInfo.name ? appClientInfo.name : 'the Owner'} obtaining information from
                  relevant third parties as may be necessary to make a decision on my loan application
                </div>
              )}

              
            </div>
          </section>
        </div>

        <div className="space-apart single center">
          <Button inactive={!formParams.tNc || !formParams.NDPR} click_event={nextStep}>
            Apply for a Loan
          </Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
