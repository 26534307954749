import React from 'react';
import PropTypes from 'prop-types';
import './_TextArea.scss';

const TextArea = (props) => {
    return(
        <label className={'textarea__group'}>
            {props.label == null ? '' : <span className={'textarea__label'}>{props.label}</span>}

            <textarea
                autoComplete={props.autoComplete}
                autoFocus={props.autoFocus}
                className={`textarea ${props.classes == null ? "" : props.classes}`}
                cols={props.cols}
                rows={props.rows}
                value={props.value}
                disabled={props.disabled}
                readOnly={props.readOnly}
                name={props.name}
                placeholder={props.placeholder}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
            {props.errorMessage && (<span className={'textarea__error'}>{props.errorMessage}</span>)}
        </label>
    )
};

TextArea.propTypes = {
    cols: PropTypes.number,
    rows: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    classes: PropTypes.string,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
    readOnly: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
    ]),
};

export default TextArea;
