import React, { useEffect, useState, useContext, Fragment } from "react";
import cx from "classnames";
import { useApolloClient } from "@apollo/react-hooks";
import JwtDecode from "jwt-decode";
import { Route, Switch, useHistory, useLocation, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./_Layouts.scss";
import { Header, Footer, Page } from "../index";
import Context from "../../context";

import {
  SignIn,
  ResetPassword,
  WelcomeScreen,
  GetStarted,
  TriggerResetPassword,
  LoanConfirmation,
  LoanConfirmationError,
  ResetPasswordConfirmation,
  PasswordResetConfirmation
} from "../../containers";
import config from "../../config/config";
import { authGetter } from "../../lib/utils";

const Layout = () => {
  const location = useLocation();
  const [pathname, updatePathname] = useState([]);
  const [authentication, setAuth] = useState(authGetter());
  const { state } = useContext(Context);

  const client = useApolloClient();

  const checkExpiry = async () => {
    if (config.web.node_env === "staging" || config.web.node_env === "production") {
      if (window.location.protocol !== "https:") {
        window.location.href = `https:${window.location.href.substring(window.location.protocol.length)}`;
      }
    }

    if (authentication.hasApiKey) {
      const expiryDate = JwtDecode(authentication.apiKey).exp;
      const currentTime = new Date().getTime() / 1000;

      if (currentTime > expiryDate) {
        window.location.href = "/";
        localStorage.clear();
        await client.resetStore();
      }
    }
  };

  useEffect(() => {
    checkExpiry();

    setTimeout(() => {
      updatePathname(window.location.pathname || "");
    }, 100);
  });

  useEffect(() => {
    if (!authentication.hasApiKey) {
      setAuth(authGetter());
    }
  }, [location]);

  return (
    <Fragment>
      <Helmet>
        <title>LSETF Loan Application Portal</title>
        <link
          id="favicon"
          rel="icon"
          href={state && state.clientInfo ? state.clientInfo.faviconUrl : ""}
          type="image/x-icon"
        />
      </Helmet>
      <section className={`layout ${authentication.authenticationType}`}>
        <div className="background-glow">
          <span className="glow-1" />
          <span className="glow-2" />
          <span className="glow-3" />
          <span className="glow-4" />
          <span className="glow-5" />
          <span className="glow-6" />
          <span className="glow-7" />
          <span className="glow-8" />
          <span className="glow-9" />
        </div>

        <Header authentication={authentication} />
        <main
          className={cx({
            main: pathname,
            _unauthenticated: pathname.includes("/sign-up"),
          })}
        >
          <Switch>
            <Route
              path="/sign-up"
              exact
              render={(props) => (
                <Page
                  {...props}
                  component={GetStarted}
                  title="Let&#39;s Get Started"
                />
              )}
            />
            <Route
              path="/forgot-password"
              exact
              render={(props) => (
                <Page
                  {...props}
                  component={TriggerResetPassword}
                  title="Forgot Password"
                />
              )}
            />
            <Route
              path="/reset-password-confirmation"
              exact
              render={(props) => (
                <Page
                  {...props}
                  component={ResetPasswordConfirmation}
                  title="Password Reset Link Sent"
                />
              )}
            />
            <Route
              path="/reset-password/:resetToken"
              exact
              render={(props) => (
                <Page
                  {...props}
                  component={ResetPassword}
                  title="Reset Password Confirmation"
                />
              )}
            />
            <Route
              path="/password-reset-complete"
              exact
              render={(props) => (
                <Page
                  {...props}
                  component={PasswordResetConfirmation}
                  title="Reset Password Confirmation"
                />
              )}
            />
            <Route
              path="/loan-confirmation"
              exact
              render={(props) => (
                <Page
                  {...props}
                  component={LoanConfirmation}
                  title="Loan Confirmation"
                />
              )}
            />
            <Route
              path="/confirmation-error"
              exact
              render={(props) => (
                <Page
                  {...props}
                  component={LoanConfirmationError}
                  title="Loan Confirmation"
                />
              )}
            />
            <Route
              path="/sign-in"
              exact
              render={(props) => (
                <Page {...props} component={SignIn} title="Sign In" />
              )}
            />
            <Route
              path="/"
              exact
              render={(props) => (
                <Page
                  {...props}
                  component={WelcomeScreen}
                  title="Welcome"
                />
              )}
            />
          </Switch>
        </main>
        <Footer />
      </section>
    </Fragment>
  );
};

export default withRouter(Layout);
