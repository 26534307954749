import React, { Fragment, useEffect, useState } from "react";

import {
  DataCardV2,
  Button,
  Select,
  Input,
  Loader,
  Alert,
} from "../../../../components";
import { BankService, AuthService } from "../../../../services";
import { getErrorMessages } from "../../../../lib/utils";
const BankStatement = ({
  stepIndex,
  bankList,
  userData,
  formParams,
  updateFormParams,
  navigation,
  formInfo = {},
  updatePBStepCount,
  baseInputChangeHandler,
  appClientInfo,
  updateContextState,
  contextState,
  getPreviouslySavedData,
}) => {
  updatePBStepCount(stepIndex + 1);
  const { title, subTitle } = formInfo;
  const { go } = navigation;
  const [isLoading, updateLoadingState] = useState(false);
  const [responseError, setResponseError] = useState([]);
  const [inputErrors, setInputErrors] = useState([]);
  const [isSubmitted, updateSubmit] = useState(false);
  const [bankAccountName, setAccountName] = useState("");
  const requiredFormFields = ["bankId", "accountNumber"];
  let initiatedApplicationId = 0;
  let accountBankId;

  const { bankId, accountNumber } = formParams;

  const addBankAccount = async (accountName = "") => {
    updateLoadingState(true);

    let { bankId, accountNumber, okraRecordId, okraCustomerId, okraAccountId } =
      formParams;

    if (!okraCustomerId) {
      ({ okraCustomerId, okraRecordId, okraAccountId } = contextState.state);
    }

    const response = await BankService.addAccountBank({
      bankId,
      accountName,
      accountNumber,
      okraRecordId,
      okraCustomerId,
      okraAccountId,
      directDebitAuthorised: appClientInfo.okraDirectDebitIsEnabled,
    });

    updateLoadingState(false);

    if (!response) {
      const error = "Error adding your bank account";
      setResponseError({ addingBankAccount: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ addingBankAccount: error });
      return;
    }

    if (response.data.data && response.data.data.addAccountBank) {
      const { addAccountBank } = response.data.data;
      accountBankId = addAccountBank.id;

      if (!addAccountBank.id) {
        const error = "Error adding your bank account";
        setResponseError({ addingBankAccount: error });
        return;
      }

      if (addAccountBank.id && addAccountBank.status === "enabled") {
        setApplicationBankAccount(accountBankId);
        return;
      } else {
        const error = "Error! Can not add your bank account";
        setResponseError({ addingBankAccount: error });
        return;
      }
    } else {
      const error = "Error adding your bank account";
      setResponseError({ addingBankAccount: error });
      return;
    }
  };

  const resolveAccountNo = async (accountNumber, bankId) => {
    updateLoadingState(true);

    const response = await AuthService.resolveAccountNumber(
      bankId,
      accountNumber
    );

    updateLoadingState(false);
    const error = "Unable to get account name.";
    if (response == null || typeof response === "undefined") {
      setResponseError({ resolveAccountNumber: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ resolveAccountNumber: error });
      return;
    }

    if (response.data.data && response.data.data.resolveAccountNumber) {
      const { accountName } = response.data.data.resolveAccountNumber;
      setAccountName(accountName);
    }

    return;
  };

  const initiateBankStatement = async (applicationId, bankId) => {
    updateLoadingState(true);

    const response = await BankService.initiateBankStatementReq(
      applicationId,
      bankId
    );
    updateLoadingState(false);

    if (!response) {
      setResponseError({
        initiateBankStatement: "Error initiating bank statement request",
      });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ initiateBankStatement: error });
      return;
    }

    const { initiateBankStatementRequest } = response.data.data;

    if (!initiateBankStatementRequest.success) {
      setResponseError({
        initiateBankStatement: "Error initiating bank statement request.",
      });
      return;
    } else {
      return;
    }
  };

  const setApplicationBankAccount = async (accountBankId, id) => {
    updateLoadingState(true);

    let applicationId;

    if (userData.account && userData.account.applications.nodes.length !== 0) {
      applicationId = userData.account.applications.nodes[0].id;
    } else {
      applicationId =
        initiatedApplicationId !== 0
          ? initiatedApplicationId
          : formParams.applicationId;
    }

    const response = await BankService.setApplicationBankAccount(
      applicationId,
      accountBankId
    );

    updateLoadingState(false);
    const error = "Unable to link your bank account.";
    if (response == null || typeof response === "undefined") {
      setResponseError({ setAppBankAccount: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ setAppBankAccount: error });
      return;
    }

    if (response.data.data) {
      const { setApplicationBankAccount } = response.data.data;

      if (!setApplicationBankAccount.success) {
        const sABError =
          "Your loan request was rejected. Please try again later.";
        setResponseError({ setAppBankAccount: sABError });
        return;
      }

      if (setApplicationBankAccount && setApplicationBankAccount.success) {
        const { completedSteps, requiredSteps } =
          setApplicationBankAccount.application;
        const { okraCustomerId } = contextState ? contextState.state || {} : {};

        if (!(appClientInfo.requiresOkraTransactions && okraCustomerId)) {
          if (
            requiredSteps.includes(
              "COMPLETE_EXTERNAL_BANK_STATEMENT_REQUEST"
            ) &&
            !completedSteps.includes("COMPLETE_EXTERNAL_BANK_STATEMENT_REQUEST")
          ) {
            updateContextState({ page: "mbsInstructions" });
            go("mbsInstructions");
            return;
          }

          if (
            requiredSteps.includes("INITIATE_BANK_STATEMENT_REQUEST") &&
            !completedSteps.includes("INITIATE_BANK_STATEMENT_REQUEST")
          ) {
            initiateBankStatement(applicationId, accountBankId);
            updateContextState({ page: "ticketNumber" });
            go("ticketNumber");
            return;
          }

          if (
            requiredSteps.includes("UPLOAD_BANK_STATEMENT") &&
            !completedSteps.includes("UPLOAD_BANK_STATEMENT")
          ) {
            updateContextState({ page: "mbsUpload" });
            go("mbsUpload");
          } else {
            updateContextState({ page: "confirmRequest" });
            go("confirmRequest");
          }
          return;
        } else {
          if (
            requiredSteps.includes("UPLOAD_BANK_STATEMENT") &&
            !completedSteps.includes("UPLOAD_BANK_STATEMENT")
          ) {
            updateContextState({ page: "mbsUpload" });
            go("mbsUpload");
          } else {
            updateContextState({ page: "confirmRequest" });
            go("confirmRequest");
          }
          return;
        }
      }
    } else {
      setResponseError({
        setAppBankAccount:
          "Your loan request was rejected. Please try again later.",
      });
      return;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!userData) {
      getPreviouslySavedData();
    }
  }, []);

  useEffect(() => {
    if (userData) {
      getAddedCardBankName();
    }
  }, [userData]);

  useEffect(() => {
    if (contextState) {
      const { state } = contextState;
      updateFormParams((prevState) => ({
        ...prevState,
        bankId: state.bankId,
        accountNumber: state.accountNumber,
      }));
      setAccountName(state.accountName);
    }
  }, [contextState]);

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case "bankId":
        value && value.length < 1
          ? setInputErrors((prevState) => ({
              ...prevState,
              bankId: "Select your Bank from the list.",
            }))
          : setInputErrors((prevState) => ({ ...prevState, bankId: "" }));
        break;
      case "accountNumber":
        value && value.length !== 10
          ? setInputErrors((prevState) => ({
              ...prevState,
              accountNumber: "Kindly check the Account Number.",
            }))
          : setInputErrors((prevState) => ({
              ...prevState,
              accountNumber: "",
            }));
        break;
      default:
        break;
    }
  };

  const checkValidation = () => {
    requiredFormFields.map((item) => {
      validateForm(item, formParams[item]);
    });
  };

  useEffect(() => {
    checkValidation();
  }, [formParams, isSubmitted]);

  useEffect(() => {
    if (accountNumber && accountNumber.length === 10 && bankId) {
      resolveAccountNo(accountNumber, bankId);
    } else {
      setAccountName("");
    }
  }, [accountNumber, bankId]);

  const nextStep = () => {
    updateSubmit(true);

    const errors = Object.keys(inputErrors).filter(
      (item) => inputErrors[item] !== ""
    );

    if (errors.length < 1 && bankAccountName) {
      let applicationId, accountName;
      if (
        userData &&
        userData.account.applications &&
        userData.account.applications.nodes[0]
      ) {
        applicationId = userData.account.applications.nodes[0].id;
        accountName = userData.account.applications.nodes[0].name;
      } else {
        applicationId = formParams.applicationId;
      }

      addBankAccount(bankAccountName);
    }
  };

  const getAddedCardBankName = () => {
    let response = "empty";
    if (userData && userData.account) {
      const { cards } = userData.account;
      response = cards.length >= 1 ? cards[0].bankName : "empty";
    }

    return response;
  };

  const getInlineBankList = () => {
    let response = [];
    let bankName = "";

    if (userData) {
      bankName = getAddedCardBankName().toLowerCase().trim();
    }

    if (bankList) {
      response = bankList.filter(
        (bank) => bankName === bank.name.toLowerCase()
      );

      if (response.length !== 0) {
        const value = response[0].id;

        setTimeout(() => {
          updateFormParams((prevState) => ({
            ...prevState,
            bankId: value,
          }));
        }, 100);
      }
    }

    return response;
  };

  const changeCard = () => {
    updateContextState({
      page: "addDebitCard",
      state: { ...contextState.state, paystackReference: "", payStackSuccess: null },
    });
    go("addDebitCard");
  };

  useEffect(()=> {
    console.log(contextState);
  },[contextState])

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize hasPadding>
        {responseError && responseError.length !== 0 && (
          <Fragment>
            {responseError.resolveAccountNumber ? (
              <Alert classes="error">
                {responseError.resolveAccountNumber}
              </Alert>
            ) : (
              ""
            )}
            {responseError.setAppBankAccount ? (
              <Alert classes="error">{responseError.setAppBankAccount}</Alert>
            ) : (
              ""
            )}
            {responseError.addingBankAccount ? (
              <Alert classes="error">{responseError.addingBankAccount}</Alert>
            ) : (
              ""
            )}
          </Fragment>
        )}

        <div className="">
          <p className="text-success">
            Your bank may charge you separately for this statement
          </p>
        </div>

        <div className="form_content bank_statement">
          {getInlineBankList().length !== 0 ? (
            <Input
              type="numberField"
              placeholder="Your Bank"
              label="Your Bank"
              name="accountNumber"
              value={getInlineBankList()[0].name}
              readOnly={true}
              classes={`border-bottom`}
            />
          ) : (
            <Select
              name="bankId"
              classes="border-bottom"
              label="Select your Bank"
              autoComplete="state"
              onChange={baseInputChangeHandler}
              required
              defaultValue={bankId || ''}
              disabled={contextState && contextState.state.bankId}
              value={bankId}
              errorMessage={
                isSubmitted && inputErrors.bankId ? inputErrors.bankId : ""
              }
            >
              <option disabled value="">
                Select your bank
              </option>
              {bankList &&
                bankList.map((bank) => (
                  <option key={bankList.indexOf(bank)} value={bank.id}>
                    {bank.name}
                  </option>
                ))}
            </Select>
          )}

          <Input
            numberField
            placeholder="Enter your Account Number"
            label="Enter your Account Number"
            maxlength={10}
            name="accountNumber"
            readOnly={contextState && contextState.state.bankId}
            value={accountNumber}
            required
            onChange={baseInputChangeHandler}
            classes={`border-bottom`}
            errorMessage={
              isSubmitted && inputErrors.accountNumber
                ? inputErrors.accountNumber
                : ""
            }
            autoComplete="off"
          />

          {bankAccountName && (
            <div className="">
              <span>
                Account Name: <b>{bankAccountName}</b>
              </span>
            </div>
          )}

          {appClientInfo && appClientInfo.requiresBankStatement ? (
            <div className="help_text">
              We will request your bank statement from your bank and you may be
              charged. By clicking Next you agree to be charged for the bank
              statement.
            </div>
          ) : (
            ""
          )}

          <div className="help_text">
            Having trouble with your bank details?{" "}
            <button
              className="change-card"
              type="button"
              onClick={changeCard}
            >
              Click here to change your added card
            </button>
          </div>
        </div>

        <div className="space-apart single right">
          {" "}
          <Button click_event={nextStep}>Continue</Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default BankStatement;
