import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './_Styles.scss';

const DataCard = (props) => {
  return (
    <div className="_container">
      {props.title || props.amount ? (
        <div>
          {props.title ? (
            <span
              className={cx({
                'data-info': props
              })}
            >
              {props.title}
            </span>
          ) : (
            ''
          )}
          {props.amount ? <span className="amount">{props.amount}</span> : ''}
        </div>
      ) : (
        ''
      )}

      {props.info ? <div className="content">{props.info}</div> : ''}
    </div>
  );
};

DataCard.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string,
  classes: PropTypes.string,
  noPad: PropTypes.bool,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string
};

export default DataCard;
