import React, { Fragment, useEffect, useState } from 'react';
import Notification from 'react-notify-toast';
import {
  Alert,
  BackArrow,
  Button,
  DataCardV2,
  Loader,
  Radio,
} from '../../../../components';
import Ellipsis from '../../../../components/Icon/Ellipsis';
import { authGetter, getErrorMessages } from '../../../../lib/utils';
import { PaymentInformationProvider } from '../../../../providers';
import { AuthService, BankService } from '../../../../services';
import './_Styles.scss';

const Index = ({
  navigation,
  formInfo = {},
  userData,
  setUserData,
  updateContextState,
  appClientInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const { next, previous, go } = navigation;
  const { title, subTitle } = formInfo;
  const [defaultAccount, setDefaultAccount] = useState({});
  const [responseError, setResponseError] = useState({});

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    setLoading(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    setLoading(false);

    const response = viewer ? viewer.data.data.viewer : {};
    if (response) {
      setUserData(response);
    }
  };

  const setApplicationBankAccount = async (accountBankId) => {
    setLoading(true);

    const applications = userData.account.applications.nodes;
    const applicationId =
      applications.find((app )=> app.status.name === "PENDING").id;

    const response = await BankService.setApplicationBankAccount(
      applicationId,
      accountBankId,
    );

    setLoading(false);
    const error = 'Unable to link your bank account.';
    if (response == null || typeof response === 'undefined') {
      setResponseError({ setAppBankAccount: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ setAppBankAccount: error });

      //temporary fix to bypass MBS
      return;
    }

    //temporary fix to bypass Okra if we want to
    if (response.data.data) {
      const { setApplicationBankAccount } = response.data.data;

      if (!setApplicationBankAccount.success) {
        const sABError =
          'Your loan request was rejected. Please try again later.';
        setResponseError({ setAppBankAccount: sABError });
        return;
      }

      if (setApplicationBankAccount && setApplicationBankAccount.success) {
        const {
          completedSteps,
          requiredSteps,
        } = setApplicationBankAccount.application;

        if (
            requiredSteps?.includes("INITIATE_BANK_STATEMENT_REQUEST") &&
            !completedSteps?.includes("INITIATE_BANK_STATEMENT_REQUEST")
        ) {
          updateContextState({ page: "ticketNumber" });
          go("ticketNumber");
          return;
        }

        if (
          requiredSteps?.includes("UPLOAD_BANK_STATEMENT") &&
          !completedSteps?.includes("UPLOAD_BANK_STATEMENT")
        ) {
          updateContextState({ page: 'mbsUpload' });
          go('mbsUpload');
        } else {
          updateContextState({ page: 'confirmRequest' });
          go('confirmRequest');
        }
      }
    } else {
      setResponseError({
        setAppBankAccount:
          'Your loan request was rejected. Please try again later.',
      });
      return;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  const nextStep = () => {
    setApplicationBankAccount(defaultAccount.id);
  };

  return (
    <DataCardV2 title={title} subTitle={subTitle} centeralize>
      <div className="form_content debit_card_page">
        <Notification />
        {responseError
          ? Object.keys(responseError).map((errorKey, errorIndex) => (
              <Fragment key={`alert_${errorIndex}`}>
                {responseError[errorKey] ? (
                  <Alert classes="margin-top error">
                    {responseError[errorKey]}
                  </Alert>
                ) : (
                  ''
                )}
              </Fragment>
            ))
          : ''}
        <PaymentInformationProvider>
          {({ bankAccounts, error, defaultBankAccount }) => {
            if (!defaultAccount?.accountNumber)
              setDefaultAccount(defaultBankAccount);

            return (
              <section>
                {loading && <Loader />}
                {error && (
                  <Alert classes="error">
                    There was an error loading cards and banks. Please reload
                    the page
                  </Alert>
                )}

                <div>
                  <section>
                    <div className="accountListing">
                      {bankAccounts.map((bankAccount, bankIndex) => (
                        <div
                          key={`account_${bankIndex}`}
                          className={'bank-card'}
                        >
                          <div className={'bank-card__top'}>
                            <div className={'card-status'}>
                              <Radio
                                onChange={() => setDefaultAccount(bankAccount)}
                                checked={
                                  defaultAccount.accountNumber ===
                                  bankAccount.accountNumber
                                }
                                name="defaultBank"
                              />
                            </div>
                            <div className={'icon'}>
                              <Ellipsis />
                            </div>
                          </div>
                          <div className={'bank-card__mid'}>
                            <div>
                              <div className={'account-number'}>
                                {bankAccount.accountNumber}
                              </div>
                              <div className={'bank'}>
                                <span className={'account-name'}>
                                  {bankAccount.accountName}
                                </span>
                                <span className={'bank-name'}>
                                  {bankAccount.bank.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>

                  <div className="space-apart">
                    <BackArrow height="24" onClick={previous} text="Back" />
                    <Button click_event={nextStep}>Continue</Button>
                  </div>
                </div>
              </section>
            );
          }}
        </PaymentInformationProvider>
      </div>
    </DataCardV2>
  );
};

export default Index;
