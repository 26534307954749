import React, { Fragment, useState } from 'react';
import {Alert, Button, Input, Loader, Popup, PopupV2} from '../index';
import {bytesToSize, camelize, numberWithCommas, round, toTitleCase} from '../../lib/utils';
import moment from 'moment';
import {Close, DocumentIcon} from '../Icon';
import './_ReviewFeedback.scss';
import {
  UploadRequestedDocumentProvider,
  LoanAdjustmentProvider,
  RepaymentBreakDownProvider,
  UploadAdditionalSupportingDocument
} from '../../providers';
import { logEvent } from '../../lib/GAHelper';

const ReviewFeedback = ({ application, user, refetch }) => (
  <LoanAdjustmentProvider>
    {({ approveUpdatedLoanAmount, declineUpdatedLoanAmount }) => {
      return (
        <UploadRequestedDocumentProvider>
          {({ uploadRequestedSupportingDocument }) => {
            return (
              <UploadAdditionalSupportingDocument>
                {({ uploadSupportingDocumentFile }) => {
                  return (
                    <ReviewFeedbackWrapper
                      refetch={refetch}
                      user={user}
                      application={application}
                      uploadSupportingFile={uploadSupportingDocumentFile}
                      uploadRequestedSupportingDocument={
                        uploadRequestedSupportingDocument
                      }
                      approveUpdatedAmount={approveUpdatedLoanAmount}
                      declineUpdatedAmount={declineUpdatedLoanAmount}
                    />
                  );
                }}
              </UploadAdditionalSupportingDocument>
            );
          }}
        </UploadRequestedDocumentProvider>
      );
    }}
  </LoanAdjustmentProvider>
);

const ReviewFeedbackWrapper = ({
  refetch,
  user,
  application,
  uploadSupportingFile,
  uploadRequestedSupportingDocument,
  approveUpdatedAmount,
  declineUpdatedAmount,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [documentDetails, setDocumentDetails] = useState('');
  const [selectedDocuments, setSelectedDocument] = useState([]);
  const [breakdownPopup, setBreakdownPopup] = useState(false);
  const [declineAmountPopup, setDeclineAmountPopup] = useState(false);

  const pendingFeedback =
    application &&
    application.status &&
    application.status.name === 'AWAITING_FEEDBACK' &&
    application.reviewFeedbacks &&
    application.reviewFeedbacks.find(feedback => feedback.status === 'PENDING');

  const pendingDocumentFeedback =
    pendingFeedback &&
    pendingFeedback.documentRequests.length !== 0 &&
    pendingFeedback.documentRequests.find(
      document => document.status === 'PENDING',
    );

  const documentRequests = pendingDocumentFeedback ? pendingFeedback.documentRequests.map(document => ({ ...document, name: camelize(document.title) })) : [];

  const toggleRepaymentBreakdown = e => {
    e.preventDefault();
    e.stopPropagation();

    setBreakdownPopup(!breakdownPopup);
  };

  const handleDocumentSelection = e => {
    setError(false);
    setLoading(true);
    const { files, validity, name:id } = e.target;
    const file = files[0]

    if (file) {
      setDocumentDetails(prevState => ({
        ...prevState,
        [id]: {
          fileName: file.name,
          fileSize: bytesToSize(file.size)
        },
      }));
    } else {
      setDocumentDetails('');
    }

    setLoading(false);
    if (file) {
      const data = { files, validity: validity };
      const documentRequest = documentRequests.find(document => document.id === id);
      uploadSelectedDocument(data, documentRequest.title,id);
    }
  };

  const uploadSelectedDocument = async (data, documentName,docId) => {
    logEvent('Review Feedback - Upload Supporting Document');

    setError(false);
    setLoading(true);

    const response = await uploadSupportingFile({
      selectedFile: data,
      documentName,
      userId: user.id
    });

    setLoading(false);

    const error =
      'There was an error uploading your document, please try again';

    if (response == null || typeof response === 'undefined') {
      setError({ supportingDocuments: error });
      return;
    }

    if (response && response.data.errors) {
      setError({ supportingDocuments: error });
      return;
    }

    const { uploadSupportingDocument } = response.data;

    if (!uploadSupportingDocument) {
      setError({ supportingDocuments: error });
      return;
    }
    setSelectedDocument(prevState => ({ ...prevState, [docId]: uploadSupportingDocument.id}));

  };

  const handleRequestedSupportingDocument = async () => {
    logEvent('Review Feedback - Upload Requested Supporting Document');

    setError(false);
    setLoading(true);

    const requestId = pendingFeedback.id;
    const documentRequestId = pendingDocumentFeedback.id;
    let requestedDocuments = [];
    pendingFeedback.documentRequests.forEach(document => {
      const documentKey = document.id;
      requestedDocuments.push({
        documentRequestId: document.id,
        uploadedDocumentId: selectedDocuments[documentKey]
      });
    })
    const error = 'There was an error uploading your document, please try again';

    const response = await uploadRequestedSupportingDocument({
      requestId,
      requestedDocuments
    });

    if (response == null || typeof response === 'undefined') {
      setError({ supportingDocuments: error });
      return;
    }

    if (response && response.data.errors) {
      setError({ supportingDocuments: error });
      return;
    }

    const { success } = response.data.uploadRequestedSupportingDocument;

    if (!success) {
      setError({ supportingDocuments: error });
      return;
    }

    setSuccess({ supportingDocuments: 'Document Successfully Uploaded.' });
    setLoading(false);

    setTimeout(() => {
      refetch()
    }, 3000);
  };

  const showDeclineUpdateAmountPopup = () => {
    setDeclineAmountPopup(!declineAmountPopup);
  }

  const handleUpdatedAmountApproval = async () => {
    logEvent('Review Feedback - Approve Updated Loan Amount');

    setError(false);
    setLoading(true);

    const requestId = pendingFeedback.id;
    const applicationId = application.id;

    const response = await approveUpdatedAmount({
      requestId,
      applicationId,
    });

    setLoading(false);

    const error =
      'There was an error approving the updated loan amount, please try again';

    if (response == null || typeof response === 'undefined') {
      setError({ loanAdjustment: error });
      return;
    }

    if (response && response.data.errors) {
      setError({ loanAdjustment: error });
      return;
    }

    const { approveUpdatedLoanAmount } = response.data;

    if (!approveUpdatedLoanAmount) {
      setError({ loanAdjustment: error });
      return;
    }

    setSuccess({
      loanAdjustment:
        'Updated loan amount approved. We will review and disburse your loan shortly.',
    });

    setTimeout(() => {
      refetch()
    }, 3000);
  };

  const handleUpdatedAmountDecline = async () => {
    logEvent('Review Feedback - Decline Updated Loan Amount');

    setError(false);
    setLoading(true);

    const requestId = pendingFeedback.id;
    const applicationId = application.id;

    const response = await declineUpdatedAmount({
      requestId,
      applicationId,
    });

    setLoading(false);

    const error =
      'There was an error declining the updated loan amount, please try again';

    if (response == null || typeof response === 'undefined') {
      setError({ loanAdjustment: error });
      return;
    }

    if (response && response.data.errors) {
      setError({ loanAdjustment: error });
      return;
    }

    const { approveUpdatedLoanAmount } = response.data;

    if (!approveUpdatedLoanAmount) {
      setError({ loanAdjustment: error });
      return;
    }

    setSuccess({
      loanAdjustment:
        'Updated loan amount declined. Please, apply for a new loan.',
    });

    setTimeout(() => {
      refetch()
    }, 3000);
  };


  return (
    <Fragment>
      {loading && <Loader />}

      {pendingFeedback &&
        (pendingDocumentFeedback ? (
          <Popup
            title={`Document Upload Request`}
            classes="loan-requirements"
          >
            {error.supportingDocuments && (
              <Alert classes="warning">{error.supportingDocuments}!</Alert>
            )}
            {success.supportingDocuments && (
              <Alert classes="success">{success.supportingDocuments}!</Alert>
            )}
            <div className="upload_documents">
              <h5 className="popup_text">
                {pendingDocumentFeedback.message
                  ? pendingDocumentFeedback.message
                  : `Your application requires the folllowing documents for it to be complete.`}
              </h5>
              <div className="document-upload_tiles">
                { documentRequests.map((requestedDoc, docIndex) => (
                  <label key={`doc_${docIndex}`} className="document-upload">
                    <Input
                      name={requestedDoc.id}
                      type="file"
                      onChange={handleDocumentSelection}
                      classes={`border-bottom`}
                      accept="image/png, image/jpeg, application/pdf"
                    />
                    <div className="document">
                      <DocumentIcon />
                      <span className="center-text document-detail">
                        {documentDetails[requestedDoc.id]
                          ? `${documentDetails[requestedDoc.id].fileName} (${documentDetails[requestedDoc.id].fileSize})`
                          : `Upload ${requestedDoc.title}`}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
              <div className="decision_buttons">
                <Button
                  children="Continue"
                  inactive={Object.keys(selectedDocuments).length !== documentRequests.length}
                  click_event={handleRequestedSupportingDocument}
                />
              </div>
            </div>
          </Popup>
        ) : !breakdownPopup ? (
          <Popup title="Loan Adjustment" classes="loan-requirements">
            {error.loanAdjustment && (
              <Alert classes="warning">{error.loanAdjustment}</Alert>
            )}
            {success.loanAdjustment && (
              <Alert classes="success">{success.loanAdjustment}</Alert>
            )}
            <div className="loan_adjustment">
              <h5 className="popup_text">
                Your loan has been updated. Please, review the loan details
                below.
              </h5>
              <h5 className="loan_text">New Loan Amount</h5>
              <span className="loan_amount">
                NGN {numberWithCommas(application.amount)}
              </span>
              <h5 className="loan_text">Next repayment date</h5>
              <span className="loan_amount">
                {moment(application.dateOfRepayment).format('ll')}
              </span>

              <h5 className="popup_text">
                By choosing I ACCEPT below your loan amount will be change to
                the current amount.
              </h5>

              <div className="amount_update_buttons">
                <Button
                    classes="accept_btn"
                    click_event={handleUpdatedAmountApproval}
                >
                  I ACCEPT
                </Button>
                <Button
                  classes="decline_btn"
                  click_event={showDeclineUpdateAmountPopup}
                >
                  I DO NOT ACCEPT
                </Button>

              </div>

              <div className="adjustment_link">
                <Button
                    classes="button-link"
                    click_event={toggleRepaymentBreakdown}
                >
                  <span className="link">View repayment Breakdown</span>
                </Button>
              </div>
            </div>
          </Popup>
        ) : (
          <div className="breakdown-popup">
            <Popup title="Repayment Breakdown">
              <RepaymentBreakDownProvider
                policyId={application.policy.id}
                principalAmount={application.amount}
                duration={application.loanDuration}
              >
                {({ repaymentBreakdown }) =>
                  repaymentBreakdown && (
                    <ul className="repaymentBreakdown">
                      <li className="repaymentBreakdown__header">
                        <div className="leftAlign">Repayment Date</div>
                        <div className="middleAlign">Principal </div>
                        <div className="middleAlign">Interest</div>
                        <div className="rightAlign">Total Payment</div>
                      </li>
                      {repaymentBreakdown.map((breakDownData, index) => (
                        <li key={index} className="repaymentBreakdown__body">
                          <div className="leftAlign">
                            {moment(breakDownData.dueDate).format('ll')}
                          </div>
                          <div className="middleAlign">
                            NGN{' '}
                            {numberWithCommas(
                              round(breakDownData.principalPortion),
                            )}
                          </div>
                          <div className="middleAlign">
                            NGN{' '}
                            {numberWithCommas(
                              round(breakDownData.interestPortion),
                            )}
                          </div>
                          <div className="rightAlign">
                            NGN{' '}
                            {numberWithCommas(
                              round(breakDownData.expectedPayment, 1),
                            )}
                          </div>
                        </li>
                      ))}

                      <li className="repaymentBreakdown__header">
                        <div className="leftAlign">Total</div>
                        <div className="middleAlign">
                          NGN{' '}
                          {numberWithCommas(
                            round(
                              repaymentBreakdown
                                .filter(item => item.principalPortion)
                                .map(item => item.principalPortion)
                                .reduce((sum, current) => sum + current),
                            ),
                          )}
                        </div>
                        <div className="middleAlign">
                          NGN{' '}
                          {numberWithCommas(
                            round(
                              repaymentBreakdown
                                .filter(item => item.interestPortion)
                                .map(item => item.interestPortion)
                                .reduce((sum, current) => sum + current),
                            ),
                          )}
                        </div>
                        <div className="rightAlign">
                          NGN{' '}
                          {numberWithCommas(
                            round(
                              repaymentBreakdown
                                .filter(item => item.expectedPayment)
                                .map(item => item.expectedPayment)
                                .reduce((sum, current) => sum + current),
                            ),
                          )}
                        </div>
                      </li>
                    </ul>
                  )
                }
              </RepaymentBreakDownProvider>
              <div className="decision_buttons">
                <Button
                  classes="close_button"
                  click_event={toggleRepaymentBreakdown}
                >
                  CLOSE
                </Button>
              </div>
            </Popup>
          </div>
        ))}

      {declineAmountPopup && (
          <Popup title="Confirm Decline" classes="loan-requirements">

            <p className="consent-message">
                You are about to decline this loan offer. Declining this offer cannot be undone, and the loan offer will be lost.
            </p>

            <div className="decline-offers">
              <Button click_event={handleUpdatedAmountDecline} className="decline_button secondary">
                Yes, Decline
              </Button>

              <Button
                  click_event={showDeclineUpdateAmountPopup}
                  classes="cancel_button secondary"
              >
                Go Back
              </Button>
            </div>
          </Popup>
      )}
    </Fragment>
  );
};

export default ReviewFeedback;
