const graphQlRequest = require("../lib/requestClient");

export default class AuthService {
  /**
   *
   * @param email - Email Address of the user
   * @param password - Password of the user
   * @param clientId = ID of the loan provider
   * @returns {*}
   */

  static login(email, password, clientId) {
    const loginMutation = `
            mutation($email: String!, $password: String!, $clientId: ID!) {
                login(input: {email: $email, password: $password, clientId: $clientId}) {
                    token
                    user {
                      id
                      status
                    }
                }
            }
        `;

    const data = {
      query: loginMutation,
      variables: {
        email,
        password,
        clientId,
      },
    };

    return graphQlRequest()
      .post("graphql", data)
      .catch((err) => {});
  }

  /**
   *
   * @param bvn
   * @param clientId = ID of the loan provider
   * @returns {*}
   */

  static customerBvnStatus(bvn, clientId, bvnPhoneDigits, bvnDOB) {
    const query = `
            query(
            $bvn: String!,
             $clientId: ID!,
             $bvnPhoneDigits: String,
             $bvnDOB: DateTime
            ) {
                customerBvnStatus(input: {
                bvn: $bvn, 
                clientId: $clientId,
                bvnPhoneDigits: $bvnPhoneDigits
                bvnDOB: $bvnDOB
                }) {
                    hasCustomerAccount
                    firstName
                    lastName
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { bvn, clientId, bvnPhoneDigits, bvnDOB } })
      .catch((err) => {});
  }

  /**
   *
   * @param firstName
   * @param lastName
   * @param email
   * @param phone
   * @param bvn
   * @param password
   * @param clientId
   * @param state
   * @param localGovernment
   * @param address
   * @returns {*}
   */

  static createCustomer({
    bvn,
    firstName,
    lastName,
    gender,
    email,
    phone,
    password,
    clientId,
    state,
    localGovernment,
    address,
    dateMovedIn,
    employmentStatus,
    businessInformation,
    maritalStatus,
    dateOfBirth,
  }) {
    const createCustomerMutation = `
            mutation($bvn: String!, $firstName: String!, $gender: Gender, $lastName: String!, $email: String!, $phone: String!, $password: String!, $clientId: ID!, $state: String, $localGovernment: String, $address: String, $dateMovedIn: DateTime, $employmentStatus: String, $businessInformation: JSON,  $maritalStatus: MaritalStatus,  $dateOfBirth: DateTime) {
                createCustomer( input: {bvn: $bvn, firstName: $firstName, lastName: $lastName, gender: $gender, email: $email, phone: $phone, password: $password, clientId: $clientId, state: $state, localGovernment: $localGovernment, address: $address, dateMovedIn: $dateMovedIn, employmentStatus: $employmentStatus, businessInformation: $businessInformation, maritalStatus: $maritalStatus, dateOfBirth: $dateOfBirth}) {
                    token
                    account {
                        id
                    }
                    user {
                        id
                    }
                }
            }
        `;

    const data = {
      query: createCustomerMutation,
      variables: {
        bvn,
        firstName,
        lastName,
        email,
        gender,
        phone,
        password,
        clientId,
        state,
        localGovernment,
        address,
        dateMovedIn,
        employmentStatus,
        businessInformation,
        maritalStatus,
        dateOfBirth,
      },
    };

    return graphQlRequest()
      .post("graphql", data)
      .catch((err) => {});
  }

  /**
   *
   * @param image
   * @returns {*}
   */

  static uploadImage(image) {
    const query = `
            mutation($image: Upload!) {
                uploadImage(image: $image) {
                    imageUrl
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { image } })
      .catch(() => {});
  }

  /**
   *
   * @returns {*}
   */

  static triggerEmailConfirmation() {
    const query = `
            mutation {
                triggerEmailConfirmation{
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query })
      .catch((err) => {});
  }

  static triggerPersonalEmailConfirmation() {
    const query = `
            mutation {
                triggerPersonalEmailConfirmation{
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query })
      .catch((err) => {});
  }

  /**
   *
   * @param code
   * @param userId
   * @returns {*}
   */

  static confirmPhone(code, userId, mutation = "confirmPhone") {
    const query = `
            mutation($code: String!, $userId: ID!) {
                ${mutation}(input: {code: $code, userId: $userId}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { code, userId } })
      .catch((err) => {});
  }

  static confirmPersonalEmail(code) {
    const query = `
            mutation($code: String!) {
                confirmPersonalEmail(code: $code) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { code } })
      .catch((err) => {});
  }

  static triggerBvnPhoneConfirmation(bvnPhoneDigits, userId) {
    const query = `
            mutation($userId: ID!, $bvnPhoneDigits: String!) {
                triggerBvnPhoneConfirmation(input: {userId: $userId, bvnPhoneDigits: $bvnPhoneDigits}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { userId, bvnPhoneDigits } })
      .catch((err) => {});
  }

  /**
   *
   * @param userId
   * @returns {*}
   */

  static triggerPhoneConfirmation(userId, useBvnPhone) {
    const query = `
            mutation($userId: ID!, $useBvnPhone: Boolean) {
                triggerPhoneConfirmation(input: { userId: $userId, useBvnPhone: $useBvnPhone }) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { userId, useBvnPhone } })
      .catch((err) => {});
  }

  /**
   *
   * @returns {*}
   */

  static triggerWorkEmailConfirmation(clientId, workEmail) {
    const query = `
            mutation($clientId:ID!,$workEmail: String!) {
                triggerWorkEmailConfirmation(input: {clientId:$clientId,workEmail: $workEmail}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { clientId, workEmail } })
      .catch(() => {});
  }

  /**
   *
   * @param emailConfirmToken
   * @returns {*}
   */

  static confirmEmail(emailConfirmToken) {
    const query = `
            mutation($emailConfirmToken: String!) {
                confirmEmail(input: {emailConfirmToken: $emailConfirmToken}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { emailConfirmToken } })
      .catch((err) => {});
  }

  /**
   *
   * @param code
   * @param workEmail
   * @returns {*}
   */

  static confirmWorkEmail(code, workEmail) {
    const query = `
            mutation($code: String!, $workEmail: String!) {
                confirmWorkEmail(input: {code: $code, workEmail: $workEmail}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { code, workEmail } })
      .catch(() => {});
  }

  /**
   *
   * @param bvnPhoneDigits
   * @param userId
   * @returns {*}
   */

  static triggerBvnPhoneConfirmation(bvnPhoneDigits, userId) {
    const query = `
            mutation($userId: ID!, $bvnPhoneDigits: String!) {
                triggerBvnPhoneConfirmation(input: {userId: $userId, bvnPhoneDigits: $bvnPhoneDigits}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { userId, bvnPhoneDigits } })
      .catch((err) => {});
  }

  /**
   *
   * @param address
   * @param state
   * @param dateMovedIn
   * @param employmentStatus
   * @param employerName
   * @param workAddress
   * @param jobRole
   * @param workEmail
   * @param workStartDate
   * @param netIncome
   * @param refereeName
   * @param refereePhone
   * @param selfie
   * @param workId
   * @returns {*}
   */

  static updateUserData(
    address,
    state,
    dateMovedIn,
    employmentStatus,
    employerName,
    workAddress,
    jobRole,
    workEmail,
    workStartDate,
    netIncome,
    workId,
    selfie,
    refereeName,
    refereePhone
  ) {
    const query = `
            mutation($address: String, $state: String, $dateMovedIn: DateTime, $employmentStatus: String, $employerName: String, $workAddress: String, $jobRole: String, $workEmail: String, $workStartDate: DateTime, $netIncome: String, $workId: String, $selfie: String, $refereeName: String, $refereePhone: String) {
                updateUserData( input: {address: $address, state: $state, dateMovedIn: $dateMovedIn, employmentStatus: $employmentStatus, employerName: $employerName, workAddress: $workAddress, jobRole: $jobRole, workEmail: $workEmail, workStartDate: $workStartDate, netIncome: $netIncome, workId: $workId, selfie: $selfie, refereeName: $refereeName, refereePhone: $refereePhone}) {
                    success
                }
            }
        `;

    const data = {
      query,
      variables: {
        address,
        state,
        dateMovedIn,
        employmentStatus,
        employerName,
        workAddress,
        jobRole,
        workEmail,
        workStartDate,
        netIncome,
        selfie,
        workId,
        refereeName,
        refereePhone,
      },
    };

    return graphQlRequest()
      .post("graphql", data)
      .catch((err) => {});
  }

  /**
   *
   * @param address
   * @param state
   * @param dateMovedIn
   * @param employmentStatus
   * @param employerName
   * @param workAddress
   * @param jobRole
   * @param workEmail
   * @param workStartDate
   * @param netIncome
   * @param refereeName
   * @param refereePhone
   * @param selfie
   * @param workId
   * @param facilityInformation
   * @returns {*}
   */

  static updateUserDataV2(updateUserData) {
    const query = `
                mutation($address: String, $state: String, $dateMovedIn: DateTime, $employmentStatus: String, $employerName: String, $workAddress: String, $jobRole: String, $workEmail: String, $workStartDate: DateTime, $netIncome: String, $workId: String, $selfie: String, $refereeName: String, $refereePhone: String, $refereeEmail: String, $refereeWorkPlace: String ,$businessInformation: JSON) {
                    updateUserData( input: {address: $address, state: $state, dateMovedIn: $dateMovedIn, employmentStatus: $employmentStatus, employerName: $employerName, workAddress: $workAddress, jobRole: $jobRole, workEmail: $workEmail, workStartDate: $workStartDate, netIncome: $netIncome, workId: $workId, selfie: $selfie, refereeName: $refereeName, refereePhone: $refereePhone, refereeEmail: $refereeEmail, refereeWorkPlace: $refereeWorkPlace, businessInformation: $businessInformation}) {
                        success
                    }
                }
            `;

    const data = {
      query,
      variables: {
        ...updateUserData,
      },
    };

    return graphQlRequest()
      .post("graphql", data)
      .catch((err) => {});
  }

  /**
   *
   * @param accessToken
   * @returns {*}
   */
  static authenticateCustomerFacebook(accessToken) {
    const query = `
            mutation($accessToken: String!) {
                authenticateCustomerFacebook(input: {accessToken: $accessToken}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { accessToken } })
      .catch((err) => {});
  }

  /**
   *
   * @param bankId
   * @param accountNumber
   * @returns {*}
   */
  static resolveAccountNumber(bankId, accountNumber) {
    const query = `
            query($bankId: ID!, $accountNumber: String!) {
                resolveAccountNumber(bankId: $bankId, accountNumber: $accountNumber) {
                    accountName
                    accountNumber
                }
            }
        `;

    const data = {
      query,
      variables: {
        bankId,
        accountNumber,
      },
    };

    return graphQlRequest()
      .post("graphql", data)
      .catch((err) => {});
  }

  static viewerQueryClone(token) {
    const query = `
            query ViewerQuery {
                viewer {
                    me {
                        id
                        firstName
                        lastName
                        email
                        phone
                        isPhoneConfirmed
                        isEmailConfirmed
                        supportingDocument {
                            id
                        }
                        bvnStatus {
                            phone
                        }
                        userMetadata {
                            id
                            name
                            value
                        }
                    }
                    account {
                        name
                        applications {
                            nodes {
                                id
                                amount
                                taxAmount
                                chargesAmount
                                fullAmount
                                loanDuration
                                bankAccount {
                                    id
                                }
                                requiredSteps
                                completedSteps
                                applicationNumber
                                status {
                                    name
                                }
                            }
                            totalCount
                        }
                        bankAccounts {
                            id
                            accountName
                            accountNumber
                            okraRecord {
                                id
                                directDebitAuthorised
                            }
                            bank {
                                id
                                name
                            }
                        }
                        cards {
                            id
                            bankName
                            isDefault
                        }
                        contextStates {
                            id
                            context
                            page
                            state
                        }
                        accountAttribute {
                            attribute {
                              name
                            }
                            value
                            data
                          }
                    }
                }
            }
        `;

    return graphQlRequest({ Authorization: `Bearer ${token}` })
      .post("graphql", { query })
      .catch((err) => {});
  }

  /**
   *
   * @param image
   * @returns {*}
   */

  static uploadImage(image) {
    const query = `
            mutation($image: Upload!) {
                uploadImage(image: $image) {
                    imageUrl
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { image } })
      .catch(() => {});
  }

  /**
   *
   * @returns {*}
   */

  static triggerWorkEmailConfirmation(clientId, workEmail) {
    const query = `
            mutation($clientId:ID!,$workEmail: String!) {
                triggerWorkEmailConfirmation(input: {clientId:$clientId,workEmail: $workEmail}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { clientId, workEmail } })
      .catch(() => {});
  }

  /**
   *
   * @param code
   * @param workEmail
   * @returns {*}
   */

  static confirmWorkEmail(code, workEmail) {
    const query = `
            mutation($code: String!, $workEmail: String!) {
                confirmWorkEmail(input: {code: $code, workEmail: $workEmail}) {
                    ok
                }
            }
        `;

    return graphQlRequest()
      .post("graphql", { query, variables: { code, workEmail } })
      .catch(() => {});
  }

  static customerRemitaStatus(customerPhoneNumber, clientId) {
    const query = `
            query customerRemitaStatus ($clientId: ID, $customerPhoneNumber: String) {
                customerRemitaStatus(input: { clientId: $clientId, customerPhoneNumber: $customerPhoneNumber }) {
                    bankId
                    customerId
                    companyName
                    hasRemitaData
                    accountNumber
                    countSalaryCredits
                    salaryPaymentDetails {
                        amount
                        bankCode
                        paymentDate
                        accountNumber
                    }
                    loanHistoryDetails{
                        loanAmount
                        loanProvider
                        repaymentFreq
                        outstandingAmount
                        loanDisbursementDate
                    }
                }
            }
        `;
    return graphQlRequest()
      .post("graphql", { query, variables: { customerPhoneNumber, clientId } })
      .catch(() => {});
  }

  static clientInfo({slug, clientUrl}) {
    const query = `
            query clientInfo ($clientUrl: String, $slug: String) {
                clientInfo(input: { clientAppUrl: $clientUrl, slug: $slug}) {
                    clientId
                    name
                    slug
                    logoUrl
                    clientAppUrl
                    addCardCharge
                    useRemita
                    minLoanAmount
                    maxLoanAmount
                    requireWorkEmail
                    requiresSelfieUpload
                    requiresWorkIDUpload
                    requiresBankStatement
                    requiresBankStatementUpload
                    requiresOkraTransactions
                    okraWidgetUrl
                    loanMonthlyDurations {
                        duration
                        durationType
                        minLoanAmount
                        maxLoanAmount
                    }
                    useLoanGroups
                    paystackPubKey
                    facebookAppKeys {
                        appId
                    }
                    contactDetails {
                        phone
                        email
                    }
                    requiredSocialMediaAccounts{
                        name
                    }
                    okraDirectDebitIsEnabled
                    bankDetails {
                        accountName
                        accountNumber
                        isDefault
                        bank {
                            name
                        }
                    }
                    bvnServiceProviders {
                        name
                        enabled
                        options
                    }
                }
            }
        `;
    return graphQlRequest()
      .post("graphql", { query, variables: { slug, clientUrl } })
      .catch(() => {});
  }

  /**
   *
   * @returns {*}
   */
  static customerAmountEligibility() {
    const query = `
                query customerAmountEligibility {
                    customerAmountEligibility {
                        amount
                        paid
                    }
                }
            `;
    return graphQlRequest()
      .post("graphql", { query })
      .catch(() => {});
  }

  /**
   * clientId
   * customerPhoneNumber
   * loanAmount
   * @returns {*}
   */
  static applicableTenors(clientId, categoryId , loanAmount, customerPhoneNumber) {
    const query = `
                query applicableTenors ($clientId: ID!, $categoryId: ID , $loanAmount: Float! ,$customerPhoneNumber: String) {
                    applicableTenors(input: { clientId: $clientId, categoryId: $categoryId , loanAmount: $loanAmount ,customerPhoneNumber: $customerPhoneNumber}) {
                        duration
                        durationType
                    }
                }
            `;
    return graphQlRequest()
      .post("graphql", { query, variables: { clientId, categoryId, customerPhoneNumber, loanAmount } })
      .catch(() => {});
  }

  /**
   *
   * @param phone
   * @returns {*}
   */

  static updatePhoneNumber(userId, phone) {
    const query = `
                    mutation($userId: ID!, $phone: String!) {
                        updatePhoneNumber(input: {userId: $userId, phone: $phone}) {
                            ok
                        }
                    }
                `;

    return graphQlRequest()
      .post("graphql", { query, variables: { userId, phone } })
      .catch(() => {});
  }

  static updateUserEmail(userId, email) {
    const query = `
        mutation($userId: ID!, $email: String!) {
            updateUserEmail(input: {userId: $userId, email: $email}) {
                ok
            }
        }
    `;

    return graphQlRequest()
      .post("graphql", { query, variables: { userId, email } })
      .catch(() => {});
  }

  static getEnumValues(enumName) {
    const query = `
        query {
            enumValues(type: ${enumName}) {
                name
            }
        }
    `;
    return graphQlRequest()
      .post("graphql", { query })
      .catch(() => {});
  }

  /**
   *
   * @param lasrraId
   * @param firstName
   * @param middleName
   * @param surname
   * @param dob
   * @param gender
   * @returns {*}
   */
  static lasrraVerifyIdentity(lasrraId, firstName, middleName, surname, dob, gender) {
    const query = `
            query lasrraVerifyIdentity ($lasrraId: String!, $firstName: String!, $middleName: String!, $surname: String!, $dob: String!, $gender: String) {
                lasrraVerifyIdentity(input: { lasrraId: $lasrraId, firstName: $firstName, middleName: $middleName, surname: $surname, dob: $dob, gender: $gender }) {
                    success
                    message
                    isMatch
                }
            }
        `;

    const data = {
      query,
      variables: {
        lasrraId,
        firstName,
        middleName,
        surname,
        dob,
        gender,
      },
    };

    return graphQlRequest()
      .post("graphql", data)
      .catch((err) => {});
  }

  static updateContextState(payload) {
    const query = `
            mutation updateContextState($id: ID, $state: JSON, $context: ContextType, $page: String) {
                updateContextState(input: { id: $id, state: $state, context: $context, page: $page }) {
                    id
                    state
                    context
                    page
                }
            }`;

    return graphQlRequest()
      .post("graphql", { query, variables: payload })
      .catch(() => {});
  }

  static createContextState(payload) {
    const query = `
            mutation createContextState($state: JSON, $context: ContextType!, $page: String) {
                createContextState(input: { state: $state, context: $context, page: $page }) {
                    id
                    state
                    context
                    page
                }
            }`;

    return graphQlRequest()
      .post("graphql", { query, variables: payload })
      .catch(() => {});
  }

  static getLoanCategories(payload) {
    const query = `
            query GetLoanCategories {
      getLoanCategories{
        categories{
          id
          name
          description
          minAmount
          maxAmount
          status
          products{
            id
            name
            description
          }
        }
      }
    }`;

    return graphQlRequest()
        .post("graphql", { query, variables: payload })
        .catch(() => {});
  }
}
