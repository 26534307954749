import React from 'react';
import { DataCard } from "../../components";
import {Link} from "react-router-dom";
import { CheckIcon } from "../../components/Icon";

const ResetPasswordConfirmation = () => {
    const resetPasswordEmail = localStorage.getItem('resetPasswordEmail');

    return(
        <section className="container sign-in">
            <DataCard title="Reset Password">
                <h5 className={'center-text'}>
                    If <strong>{resetPasswordEmail}</strong> is registered to an account in our system,
                    an email with a password reset link has been sent to the email address.
                </h5>
                <div className="center-text"><CheckIcon/></div>
                <div className="create-account"><Link to="/sign-in">Return To Sign In</Link></div>
            </DataCard>
        </section>
    )
};

export default ResetPasswordConfirmation;