import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Wizard, Steps } from 'react-albus';
import moment from 'moment';
import Collapse, { Panel } from 'rc-collapse';

import { FailureIcon, SadSmiley } from '../../../components/Icon';
import {
  Button,
  DataCard,
  DataCardV2,
  FormGroup,
  Input,
  Alert,
  Navigation,
  Radio,
  RadioGroup,
  RepaymentBreakdown,
  Select,
  SignUpStep,
  Loader
} from '../../../components';

import {
  CurrentTimeProvider,
  PaymentInformationProvider,
  UpdateUserDetailsProvider,
  ViewerProvider,
  ClientInformationProvider
} from '../../../providers';
import { numberWithCommas, loanTenorOptions } from '../../../lib/utils';
import '../_NewLoan.scss';
import 'react-datepicker/dist/react-datepicker.css';

const NewLoanView = ({ context, inputErrors, stepErrors, submitted }) => {
  // const { repaymentDetails, activeKey } = context.state;

  return (
    <ViewerProvider>
      {({ currentLoan, latestLoan, user }) =>
        currentLoan.id ||
        (latestLoan && latestLoan.status.name !== 'DENIED' && latestLoan.status.name !== 'APPROVED') ? (
          <section className="container no-loan">
            <DataCardV2>
              <div className="smiley-holder">
                <SadSmiley />
              </div>
              <h3 className="center-text">You can not apply for a loan now because you have a pending loan!</h3>
              <Link to="/loans/" className="button">
                Back to Loans
              </Link>
            </DataCardV2>
          </section>
        ) : (
          <DataCardV2
            loading={false}
            classes="new-loan"
            title="New Loan"
            subTitle="Please, enter the amount you want to borrow."
            centeralize
            hasPadding
          >
            <section>
              <div className="form_content">
                {/* <section className="margin-top">
                  <div className="section-title">Enter your Loan Request</div>
                  <FormGroup>
                    <Input
                      type="text"
                      required
                      placeholder="How much of a Loan do you need?"
                      label="How much of a Loan do you need?"
                      amountField
                      name="amount"
                      value={amount}
                      readOnly={false}
                      onChange={baseInputChangeHandler}
                      classes={`border-bottom`}
                      required
                      errorMessage={isSubmitted && inputErrors.amount ? inputErrors.amount : ''}
                    />
                  </FormGroup>
                  <Select
                    name="loanRequestReason"
                    required
                    placeholder="What is it for"
                    label="What is it for"
                    classes="border-bottom reduced"
                    onChange={baseInputChangeHandler}
                    defaultValue={loanRequestReason}
                    errorMessage={isSubmitted && inputErrors.loanRequestReason ? inputErrors.loanRequestReason : ''}
                  >
                    <option disabled value="">
                      -- Select --
                    </option>
                    {loanRequestReasons &&
                      loanRequestReasons.map((item, idx) => (
                        <option key={`${item.name}_${idx}`} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </Select>
                  <Select
                    name="loanDuration"
                    required
                    placeholder="How long do you need to pay back"
                    label="How long do you need to pay back"
                    classes="border-bottom reduced"
                    onChange={baseInputChangeHandler}
                    defaultValue={loanDuration}
                    errorMessage={isSubmitted && inputErrors.loanDuration ? inputErrors.loanDuration : ''}
                  >
                    <option disabled value="">
                      -- Select --
                    </option>
                    {loanDurationList &&
                      loanDurationList.map((item, idx) => (
                        <option key={`${item.value}_${idx}`} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                  </Select>
                  <Select
                    name="referralSource"
                    required
                    placeholder="Referral Source"
                    label="Referral Source"
                    classes="border-bottom reduced"
                    onChange={baseInputChangeHandler}
                    defaultValue={referralSource}
                    errorMessage={isSubmitted && inputErrors.referralSource ? inputErrors.referralSource : ''}
                  >
                    <option disabled value="">
                      -- Select --
                    </option>
                    {referralSourceList &&
                      referralSourceList.map((item, idx) => (
                        <option key={`${item.name}_${idx}`} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </Select>
                  {amount &&
                  loanDuration &&
                  amount.length > 0 &&
                  loanDuration !== 0 && (
                    <DefaultRepaymentInfo
                      info={defaultRepaymentInfo.info}
                      title={defaultRepaymentInfo.title}
                      amount={`NGN${numberWithCommas((removeCommas(amount) / loanDuration).toFixed(2))}`}
                    />
                  )}

                  <div className="tnc-check">
                    By clicking on “Continue”, you agree that all the information provided is accurate and up to date
                    <div>
                      <CheckBox checked={tNc} name="tNc" onChange={(e) => handleTnCChange(e)} />
                      I agree to the{' '}
                      <a href="https://www.heliumhealth.com/legal/terms/#term_id_2" target="_blank">
                        Terms and Conditions
                      </a>{' '}
                      and{' '}
                      <a href="https://www.heliumhealth.com/legal/privacy-policy/" target="_blank">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </section> */}
              </div>
            </section>
            {/* <Wizard>
                <Steps>
                  <SignUpStep id="detailsConfirmationStep">
                    <h2 className="center-text">New Loan Application</h2>
                    <h5 className="center-text">Please confirm if your details are up to date</h5>

                    <Collapse
                      accordion={true}
                      activeKey={activeKey}
                      className="new-loan-accordion"
                      onChange={context.onAccordionChange}
                    >
                      <Panel header="Employment" key={1} showArrow={false}>
                        <CurrentTimeProvider>
                          {(timeProps) => (
                            <UpdateUserDetailsProvider user={user} timeProps={timeProps}>
                              {({ form, onSubmit, loading, errorMessage, successMessage }) => (
                                <section>
                                  <h6>Employment Status</h6>

                                  <RadioGroup classes="two-grid-column">
                                    <Radio
                                      label="Employed Full Time"
                                      name="employmentStatus"
                                      {...form.employmentStatus.input('Employed Full Time')}
                                    />
                                    <Radio
                                      label="Employed Part Time"
                                      name="employmentStatus"
                                      {...form.employmentStatus.input('Employed Part Time')}
                                    />
                                    <Radio
                                      label="Self Employed"
                                      name="employmentStatus"
                                      {...form.employmentStatus.input('Self Employed')}
                                    />
                                    <Radio
                                      label="Unemployed"
                                      name="employmentStatus"
                                      {...form.employmentStatus.input('Unemployed')}
                                    />
                                  </RadioGroup>

                                  <FormGroup classes="two-grid">
                                    <Input
                                      label="Company Name"
                                      classes="border-bottom margin-bottom"
                                      placeholder=""
                                      name="companyName"
                                      readOnly={context.state.remitaCustomer}
                                      {...form.employerName.input}
                                    />

                                    <Input
                                      label="Current Position"
                                      classes="border-bottom margin-bottom"
                                      placeholder=""
                                      name="companyName"
                                      {...form.jobRole.input}
                                    />

                                    <Input
                                      label="Work Email"
                                      classes="border-bottom margin-bottom"
                                      placeholder=""
                                      name="workEmail"
                                      {...form.workEmail.input}
                                    />
                                  </FormGroup>

                                  <h6>Employment duration</h6>
                                  <RadioGroup classes="three-grid-column">
                                    <Radio
                                      label="< 1 Year"
                                      name="employmentDuration"
                                      {...form.workStartDate.input(timeProps.oneMonthAgo)}
                                    />
                                    <Radio
                                      label="1 - 3 Years"
                                      name="employmentDuration"
                                      {...form.workStartDate.input(timeProps.oneYearAgo)}
                                    />
                                    <Radio
                                      label="4 - 6 Years"
                                      name="employmentDuration"
                                      {...form.workStartDate.input(timeProps.fourYearsAgo)}
                                    />
                                    <Radio
                                      label="7 - 9 Years"
                                      name="employmentDuration"
                                      {...form.workStartDate.input(timeProps.sevenYearsAgo)}
                                    />
                                    <Radio
                                      label="> 10 Years"
                                      name="employmentDuration"
                                      {...form.workStartDate.input(timeProps.tenYearsAgo)}
                                    />
                                  </RadioGroup>

                                  <Input
                                    label="company address"
                                    classes="border-bottom margin-bottom"
                                    placeholder=""
                                    name="companyAddress"
                                    {...form.workAddress.input}
                                  />

                                  <Input
                                    label="Net monthly income"
                                    classes="border-bottom margin-bottom"
                                    placeholder=""
                                    amountField
                                    readOnly={context.state.remitaCustomer}
                                    name="netMonthlyIncome"
                                    {...form.netIncome.input}
                                  />

                                  {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
                                  {successMessage && <Alert classes="success">{successMessage}</Alert>}
                                  {loading ? (
                                    <Loader />
                                  ) : (
                                    <Button click_event={onSubmit} classes="secondary center">
                                      Update Employment
                                    </Button>
                                  )}
                                </section>
                              )}
                            </UpdateUserDetailsProvider>
                          )}
                        </CurrentTimeProvider>
                      </Panel>
                      <Panel header="Disbursement" key={2} showArrow={false}>
                        <PaymentInformationProvider>
                          {({ bankAccounts }) => (
                            <Fragment>
                              {bankAccounts.length > 0 ? (
                                <Fragment>
                                  <h6>Select bank account to pay loan into</h6>
                                  <h4 className="center-text">
                                    {' '}
                                    We will also request your bank statement.<br />Your bank may charge you separately
                                    for this statement.
                                  </h4>
                                  <RadioGroup classes="disbursement-bank">
                                    {bankAccounts.map((bankAccount, index) => (
                                      <Radio
                                        name="ApplicationBankAccount"
                                        label={`${bankAccount.bank.name} - ${bankAccount.accountNumber}`}
                                        defaultChecked={bankAccount.isDefault}
                                        key={index}
                                        value={bankAccount.id}
                                        onChange={(e) => context.setBankAccount(e.target.value)}
                                      />
                                    ))}
                                  </RadioGroup>
                                </Fragment>
                              ) : (
                                <Alert classes="error">
                                  You have not added a bank account yet. Please, add one to continue
                                </Alert>
                              )}
                              <aside className="note">
                                By clicking continue, you accept that your bank may charge you for the statement.
                              </aside>
                            </Fragment>
                          )}
                        </PaymentInformationProvider>
                      </Panel>
                      <Panel header="Repayment" key={3} showArrow={false}>
                        <PaymentInformationProvider>
                          {({ cards }) => (
                            <Fragment>
                              {cards.length > 0 ? (
                                <Fragment>
                                  <h6>Select card to pay Repayments from</h6>
                                  <RadioGroup classes="card-repayment">
                                    {cards.map((card, index) => (
                                      <Radio
                                        classes={card.type}
                                        label={`${card.type} - ${card.maskedPan.slice(-4)}`}
                                        defaultChecked={card.isDefault}
                                        key={index}
                                      />
                                    ))}
                                  </RadioGroup>
                                </Fragment>
                              ) : (
                                <Alert classes="error">
                                  You have not added any card yet. Please, add one to continue
                                </Alert>
                              )}
                            </Fragment>
                          )}
                        </PaymentInformationProvider>
                      </Panel>
                    </Collapse>
                  </SignUpStep>

                  <SignUpStep id="loanStatusStep" title="Do you currently have a Loan running?">
                    <RadioGroup classes="two-grid-column" onChange={context.handleRadioChange}>
                      <Radio label="Yes" name="currentLoan" value="yes" onChange={context.handleRadioChange} />
                      <Radio label="No" name="currentLoan" value="no" onChange={context.handleRadioChange} />
                    </RadioGroup>
                  </SignUpStep>

                  <SignUpStep
                    id="currentRepaymentStep"
                    title="What is the monthly repayment amount for  your current loan"
                  >
                    <Input
                      classes="border-bottom"
                      name="currentRepayment"
                      autoFocus
                      amountField
                      value={context.state.currentRepayment}
                      onChange={context.handleInputChange}
                      errorMessage={submitted && inputErrors.currentRepayment ? inputErrors.currentRepayment : ''}
                    />
                  </SignUpStep>

                  <SignUpStep id="amountStep" title="How much do you want to borrow?">
                    <Input
                      classes="border-bottom"
                      name="loanAmount"
                      autoFocus
                      amountField
                      value={context.state.loanAmount}
                      onChange={context.handleInputChange}
                      errorMessage={submitted && inputErrors.loanAmount ? inputErrors.loanAmount : ''}
                    />
                  </SignUpStep>

                  <SignUpStep id="tenorStep" title="What tenor would you prefer?">
                    {stepErrors.tenorStep && <Alert classes="error">{stepErrors.tenorStep}</Alert>}
                    <Select
                      classes="border-bottom margin-top"
                      name="loanDuration"
                      defaultValue={context.state.loanDuration}
                      onChange={context.handleInputChange}
                    >
                      {loanTenorOptions.map((loanTenor, index) => (
                        <option value={loanTenor.value} key={index}>
                          {loanTenor.name}
                        </option>
                      ))}
                    </Select>
                  </SignUpStep>

                  <SignUpStep
                    id="mbsOTPStep"
                    title={`Enter the Ticket Number and OTP/Password sent by your bank. (Check Email/SMS)`}
                  >
                    {stepErrors.mbsOTPStep && <Alert classes="error">{stepErrors.mbsOTPStep}</Alert>}
                    <Input
                      placeholder="Enter Ticket No."
                      classes="border-bottom"
                      name="mbsTicketNo"
                      autoFocus
                      onChange={context.handleInputChange}
                      errorMessage={submitted && inputErrors.mbsTicketNo ? inputErrors.mbsTicketNo : ''}
                    />
                    <Input
                      placeholder="OTP/Password"
                      classes="border-bottom margin-top"
                      name="mbsOtp"
                      type="password"
                      onChange={context.handleInputChange}
                      errorMessage={submitted && inputErrors.mbsOtp ? inputErrors.mbsOtp : ''}
                    />
                  </SignUpStep>

                  <SignUpStep id="repaymentBreakdownStep" title="Confirm your request">
                    {inputErrors.termsAgreed && <Alert classes="error">{inputErrors.termsAgreed}</Alert>}
                    <ClientInformationProvider>
                      {({ slug }) => {
                        const tandcUrl = `https://clients-terms-and-condition.s3.amazonaws.com/${slug}/Terms+and+Conditions.pdf`;

                        return (
                          <Fragment>
                            <RepaymentBreakdown>
                              <li>
                                Loan Amount
                                <span className="value">NGN {numberWithCommas(repaymentDetails.approvedAmount)}</span>
                              </li>
                              <li>
                                Repayment Date
                                <span className="value">{moment(repaymentDetails.dateOfRepayment).format('ll')}</span>
                              </li>
                              <li>
                                Repayment Amount
                                <span className="value">NGN {numberWithCommas(repaymentDetails.repaymentAmount)}</span>
                              </li>
                              <aside className="note">*NOTE: A late fee of 1.5% daily applies</aside>
                              <aside className="center-text">
                                <a href={tandcUrl} target="_blank" rel="noopener noreferrer">
                                  Read Terms & Conditions
                                </a>
                              </aside>
                            </RepaymentBreakdown>
                            <section className="tandc">
                              <label className={'checkbox'}>
                                <input
                                  type="checkbox"
                                  defaultChecked={context.state.termsAgreed}
                                  onChange={context.handleCheckboxChange}
                                  name="termsAgreed"
                                />
                                <div className="checkbox__label">
                                  I agree to the{' '}
                                  <a href={tandcUrl} target="_blank" rel="noopener noreferrer">
                                    terms and conditions
                                  </a>{' '}
                                  of this loan
                                </div>
                              </label>
                            </section>
                          </Fragment>
                        );
                      }}
                    </ClientInformationProvider>
                  </SignUpStep>

                  <SignUpStep
                    id="loanConfirmationStep"
                    title="All Done! Loans would usually take 5 minutes to get to your account"
                  >
                    <div className="loan-confirmation">
                      <h5>Want to track your loan?</h5>
                      <Button
                        classes="block"
                        click_event={() => {
                          window.location.href = '/dashboard';
                        }}
                      >
                        Go to your Dashboard
                      </Button>
                    </div>
                  </SignUpStep>

                  <SignUpStep
                    id="loanPendingStep"
                    title="All Done! Your loan is under review and you'll get a feedback from us soon."
                  >
                    <div className="loan-confirmation">
                      <h5>Want to track your loan?</h5>
                      <Button
                        classes="block"
                        click_event={() => {
                          window.location.href = '/dashboard';
                        }}
                      >
                        Go to your Dashboard
                      </Button>
                    </div>
                  </SignUpStep>

                  <SignUpStep
                    id="failureStep"
                    title="We are sorry, we can not offer you a loan at this time. Please, try again later."
                  >
                    <div className="center-text feedback-icon">
                      <FailureIcon />
                    </div>

                    <div className="button-prev-next">
                      <Link to="/dashboard" className="button">
                        Go to dashboard
                      </Link>
                    </div>
                  </SignUpStep>
                </Steps>
                <Navigation handleStepEvent={context.handleStepEvent} handlePreviousStep={context.handlePreviousStep} />
              </Wizard> */}
          </DataCardV2>
        )}
    </ViewerProvider>
  );
};

NewLoanView.defaultProps = {
  context: {},
  submitted: false,
  inputErrors: {}
};

NewLoanView.propTypes = {
  context: PropTypes.object,
  submitted: PropTypes.bool,
  inputErrors: PropTypes.object
};

export default NewLoanView;
