import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import { WithWizard } from 'react-albus';
import {Button} from "../index";
import { Link } from 'react-router-dom';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.handleStepEvent = props.handleStepEvent.bind(this);
        this.handlePreviousStep = props.handlePreviousStep.bind(this);
        this.backButtonEvent = this.backButtonEvent.bind(this);
        this.nextButtonEvent = this.nextButtonEvent.bind(this);
        this.state = {
            loading: false,
        }
    }

    backButtonEvent(push) {
        this.setState({ loading: true });
        this.handlePreviousStep(push);
        this.setState({ loading: false });
    }

    nextButtonEvent(step, push) {
        this.setState({loading: true });
        this.handleStepEvent(step, push);
        this.setState({ loading: false });
    }

    render(){
        const noNextButton = [
            'failureStep',
            'failureByAgeStep',
            'loanPendingStep',
            'loanConfirmationStep'
        ];
        const noPreviousButton = [
            'bankDetailStep',
            'mbsOTPStep',
            'loanStatusStep',
            'repaymentBreakdownStep',
            'failureStep',
            'failureByAgeStep',
            'successStep',
            'addressStep',
            'loanPendingStep',
            'loanConfirmationStep',
            'phoneVerificationStep',
            'bvnPhoneDigitsStep',
            'workEmailVerificationStep',
            'uploadBankStatementStep',
        ];

        const {loading} = this.state;

        return (<WithWizard
            render={({ step, steps, push }) => (
                <Fragment>
                    <div className="button-prev-next">
                        {!noNextButton.includes(step.id) && (
                            <Button
                                classes="block next"
                                click_event= {() => {
                                    this.nextButtonEvent(step, push);
                                }}
                                disabled={loading}
                            >
                                {step.id === "consentStep" ? "Continue"
                                    : this.props.payStackStep ? "Add Card"
                                        : "Next"
                                }
                            </Button>
                        )}

                        {steps.indexOf(step) > 0 && !noPreviousButton.includes(step.id) && (
                            <Button
                                classes="block previous"
                                click_event={() => {
                                    this.backButtonEvent(push);
                                }}
                                disabled={loading}
                            >
                                Previous
                            </Button>
                        )}
                        {step.id === "personalDetailsStep" ?
                            (
                                <aside className="sign-up-step__footer">
                                    Already have an account? {' '}
                                    <Link to="/sign-in">Sign In</Link>
                                </aside>
                            ) : ""
                        }
                    </div>
                </Fragment>
            )}
        />)

    }
}

Navigation.defaultProps = {
    handleStepEvent: () => {},
    handlePreviousStep: () => {},
    payStackStep: false,
};

Navigation.propTypes = {
    handleStepEvent: PropTypes.func,
    handlePreviousStep: PropTypes.func,
    payStackStep: PropTypes.bool,
};

export default Navigation;
