import React from 'react';
import { Link } from 'react-router-dom';

import './_SignIn.scss';

import { Button, Input, Alert, DataCardV2, PasswordInput } from '../../components';

import { LoginProvider } from '../../providers';

const SignIn = () => (
  <LoginProvider>
    {({ onSubmit, loading, errorMessage, successMessage, form }) => (
      <section className="container sign-in">
        <DataCardV2>
          <h2>Sign In</h2>
          <form onSubmit={onSubmit}>
            {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
            {successMessage && (
              <Alert classes="success">{successMessage}</Alert>
            )}
            <Input
              type="email"
              placeholder="Email Address"
              label="Email Address"
              name="email"
              autoFocus
              required
              readOnly={false}
              classes={`border-bottom ${
                form.email.input.errorMessage ? "error" : ""
              }`}
              {...form.email.input}
            />
            <PasswordInput
              placeholder="Password"
              label="Password"
              name="password"
              required
              readOnly={false}
              classes={`border-bottom ${
                form.password.input.errorMessage ? "error" : ""
              }`}
              {...form.password.input}
            />
            <Button
              type="submit"
              classes={`center ${loading ? "loading" : ""}`}
            >
              Sign In
            </Button>
            <div className="create-account">
              <div className="">
                Don't have an account? <Link to="/sign-up">Create account</Link>
              </div>
              <div className="">
                Forgot your password?{" "}
                <Link to="/forgot-password">Reset password</Link>
              </div>
            </div>
          </form>
        </DataCardV2>
      </section>
    )}
  </LoginProvider>
);

export default SignIn;
