import React, { Fragment, useContext } from 'react';
import './_BreadCrumbs.scss';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { HomeIcon } from '../Icon';
import styled from "styled-components";
import { useApolloClient } from '@apollo/react-hooks';
import Context from '../../context';
  

const BreadCrumbs = (props) => {


  const client = useApolloClient();

  const { state } = useContext(Context);
  const clientTheme = state && state.clientInfo ? state.clientInfo.clientTheme : {};


  const dynamicStyle = {
    background: clientTheme.primaryColor,
  };

  const StyledBreadCrumb = styled.nav`
        &.bread-crumbs {
          color: ${clientTheme.secondaryColor};
      }
    `

  const { breadcrumbs, authentication } = props;
  return (
    <section className="container">
      <StyledBreadCrumb className="bread-crumbs">
        {breadcrumbs.map(({ breadcrumb, match }, index) => (
          <div className="bread-crumb" key={match.url}>
            {index === 0 ? (
              <NavLink exact to={authentication.hasApiKey || authentication.isTempApiKey ? '/dashboard' : 'sign-in'}>
                <HomeIcon />
              </NavLink>
            ) : index < breadcrumbs.length - 1 ? (
              <NavLink exact to={match.url || ''}>
                {breadcrumb}
              </NavLink>
            ) : (
              <Fragment>{match.url.includes('loan-') ? <span>Loan Details</span> : <span>{breadcrumb}</span>}</Fragment>
            )}
          </div>
        ))}
      </StyledBreadCrumb>
    </section>
  );
};

export default withBreadcrumbs()(BreadCrumbs);
