import React from 'react';
import './_DataCard.scss';
import PropTypes from 'prop-types';

const DataCard = (props) => {
    return(
        <article className={`data-card ${props.noPad === true ? "no-pad" : ""} ${props.loading === true ? "loading" : ""}`}>
            {props.title == null ? "" : (
                <h4 className={'data-card__title'}>{props.title}</h4>
            )}
            <div className={'data-card__content'}>{props.children}</div>
        </article>
    )
};

DataCard.propTypes = {
    title: PropTypes.string,
    classes: PropTypes.string,
    noPad: PropTypes.bool
};

export default DataCard;
