import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_BANK_STATEMENT } from './queries';

const UploadBankStatementProvider = ({ children }) => {
  const [ mutate ] = useMutation(UPLOAD_BANK_STATEMENT, {
    onError(...error) {
      return error;
    }
  });

  const uploadBankStatementFile = (data) => {
    const { selectedFile, bankId, documentPassword, applicationId } = data;
    const { name, validity, files } = selectedFile;
    const file = files[0];

    return (
      validity.valid &&
      mutate({
        variables: {
          file,
          password: documentPassword,
          bankId,
          applicationId
        }
      })
    );
  };

  return {
    uploadBankStatementFile: (data) => uploadBankStatementFile(data)
  };
};

export default UploadBankStatementProvider;
