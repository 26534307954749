import React, { useEffect, useContext, useReducer, Fragment } from "react";
import JwtDecode from "jwt-decode";
import { useApolloClient, useQuery } from "@apollo/react-hooks";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  Layout,
  AuthenticatedLayout,
  PreLoginRoute,
  Page,
  ProtectedRoute,
  NotFound,
  Loader,
} from "../../components";
import { initializeGA, logPageView } from "../../lib/GAHelper";
import config from "../../config/config";
import ConfirmEmail from "../ConfirmEmail";
import { authGetter } from "../../lib/utils";
import Context from "../../context";
import reducer from "../../reducer";

import "./_App.scss";
import { CLIENT_INFO_QUERY } from "../../providers/Client/queries";
import MaintenancePage from "../../components/Layouts/MaintenancePage";

const App = () => {
  const authentication = authGetter();
  const client = useApolloClient();

  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (config.web.node_env === "development") {
      console.log(
        " _               _   _          _              \n(_)             | | (_)        (_)\n _   _ __     __| |  _    ___   _   _ __     __ -\n| | | '_ \\   / _  | | |  / __| | | | '_ \\   / _' |\n| | | | | | ( (_| | | | ( (__  | | | | | | ( (_| |\n|_| |_| |_|  \\__,_| |_|  \\___| |_| |_| |_|  \\__,_|\n"
      );
    }

    initializeGA();
    logPageView();

    if (
      config.web.node_env === "staging" ||
      config.web.node_env === "production"
    ) {
      if (window.location.protocol !== "https:") {
        window.location.href = `https:${window.location.href.substring(
          window.location.protocol.length
        )}`;
      }
    }
  }, []);

  useEffect(() => {
    if (authentication.hasApiKey) {
      clearUsertokenAndLogout();
    }
  });

  const clearUsertokenAndLogout = async () => {
    const expiryDate = JwtDecode(authentication.apiKey).exp;
    const currentTime = new Date().getTime() / 1000;

    if (currentTime > expiryDate) {
      window.location.href = "/sign-in";
      localStorage.clear();
      await client.resetStore();
    }
  };

  const { loading, error, data } = useQuery(CLIENT_INFO_QUERY, {
    variables: {
      slug: "lsetf",
    },
  });

  return (
    <Fragment>
      {loading && <Loader />}

      {error && <MaintenancePage />}

      {data && (
        <BrowserRouter>
          <Context.Provider value={{ state, dispatch }}>
            <Switch>
              <ProtectedRoute
                path="/dashboard"
                exact
                component={AuthenticatedLayout}
              />
              <ProtectedRoute
                path="/loans"
                exact
                component={AuthenticatedLayout}
              />
              <ProtectedRoute
                path="/loans/loan-:applicationNumber"
                exact
                component={AuthenticatedLayout}
              />
              <ProtectedRoute
                path="/settings"
                component={AuthenticatedLayout}
              />
              <ProtectedRoute
                path="/loans/new"
                component={AuthenticatedLayout}
              />

              <PreLoginRoute path="/" exact component={Layout} />
              <PreLoginRoute path="/sign-in" component={Layout} />
              <PreLoginRoute path="/sign-up" component={Layout} />
              <PreLoginRoute path="/forgot-password" component={Layout} />
              <PreLoginRoute
                path="/reset-password-confirmation"
                component={Layout}
              />
              <PreLoginRoute
                path="/password-reset-complete"
                component={Layout}
              />
              <PreLoginRoute
                path="/reset-password/:resetToken"
                component={Layout}
              />

              <Route
                path="/confirm-email/:emailToken"
                render={(props) => (
                  <Page
                    {...props}
                    component={ConfirmEmail}
                    title="Confirm Email"
                  />
                )}
              />

              <Route
                path=""
                exact
                render={(props) => (
                  <Page
                    {...props}
                    component={NotFound}
                    title="Page not found"
                  />
                )}
              />
            </Switch>
          </Context.Provider>
        </BrowserRouter>
      )}
    </Fragment>
  );
};

export default App;
