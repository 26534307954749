import React from 'react';

const NewCheck = ({ props }) => {
  return (
    <svg
      width={100}
      height={100}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={50} cy={50} r={50} fill="#3A84FF" fillOpacity={0.1} />
      <path
        d="M28 50.691L43.207 66 74 35"
        stroke="#07F"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewCheck;
