import React, { useState, useEffect } from 'react';
import { ViewerProvider, UpdateUserDetailsProvider } from '../../providers';
import { FormGroup, Loader, Alert, Button, Input, Card } from '../../components';

import './_Settings.scss';

const NextOfKinSettings = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loanStatuses = ['PENDING_DISBURSEMENT', 'DISBURSING', 'ACTIVE'];
  const applicationStatus = ['PENDING', 'UNDER_REVIEW'];

  const readOnly = (latestLoan, currentLoan) => {
    return applicationStatus.includes(latestLoan.status.name) ||
      (currentLoan.status ? loanStatuses.includes(currentLoan.status.name) : false)
  }

  const [ formParams, updateFormParams ] = useState({
    refereeName: '',
    refereeEmail: '',
    refereePhone: '',
    refereeWorkPlace: '',
    screen: 'refereeSettings'
  });

  return (
    <ViewerProvider>
      {({ user, latestLoan, currentLoan }) => (
        <UpdateUserDetailsProvider user={user} formParams={formParams}>{({ form, onSubmit, loading, errorMessage, successMessage }) =>
          <section className="container">
            <Card title={'Edit Full Name'} subtitle={'Change Referee/Next of Kin\'s Name'}>
              <FormGroup classes="one-grid">
                <Input
                  label="Full Name"
                  classes="border-bottom"
                  placeholder="Full Name"
                  name="refereeName"
                  readOnly={readOnly(latestLoan, currentLoan)}
                  { ...form.refereeName.input }
                />
              </FormGroup>
            </Card>
            <Card
              title={'Contact Information'}
              subtitle={'Your referee or next of kin\'s email and phone information.'}
            >
              <FormGroup classes="two-grid">
                  <Input
                      label="Phone Number"
                      classes="border-bottom"
                      placeholder="Phone Number"
                      name="refereePhone"
                      phoneField
                      readOnly={readOnly(latestLoan, currentLoan)}
                      { ...form.refereePhone.input }
                  />
                  <Input
                      label="email address"
                      classes="border-bottom"
                      placeholder="Email Address"
                      name="refereeEmail"
                      readOnly={readOnly(latestLoan, currentLoan)}
                      { ...form.refereeEmail.input }
                  />
              </FormGroup>
            </Card>
            <Card title={'Address'} subtitle={'Physical or postal address.'}>
              <FormGroup classes="two-grid">
                <Input
                  label="Address"
                  classes="border-bottom"
                  placeholder="Address"
                  name="refereeWorkPlace"
                  readOnly={readOnly(latestLoan, currentLoan)}
                  { ...form.refereeWorkPlace.input }
                />
              </FormGroup>
            </Card>
            <Card title={''} subtitle={''}>
              {errorMessage && <Alert classes='error'>{errorMessage}</Alert>}
              {successMessage && <Alert classes='success'>{successMessage}</Alert>}
              {loading ? <Loader /> : <Button inactive={readOnly(latestLoan, currentLoan)} click_event={onSubmit}>Update Profile</Button>}
            </Card>
          </section>
        }</UpdateUserDetailsProvider>
      )}
    </ViewerProvider>
  );
};

export default NextOfKinSettings;
