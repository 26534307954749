import React, { Fragment, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { dobMaxSelectableDate, getGlobalClientId, handleGraphQLErrors } from '../../../../lib/utils';
import Alert from '../../../Alert/Alert';
import Button from '../../../Button/Button';
import FormGroup from '../../../FormElements/FormGroup/FormGroup';
import Input from '../../../FormElements/Input/Input';
import FullPageLoader from '../../../FullPageLoader/FullPageLoader';
import { useCustomerBvnStatus } from './useVfdBvnValidator';

export const VfdBvnValidator = ({ bvn, completeValidation }) => {
  const clientId = getGlobalClientId();
  const [hasVerifiedBvn, setVerifiedBvn] = useState(false);
  const [error, setError] = useState('');

  const [formState, setFormState] = useState({
    bvn: bvn || '',
    dateOfBirth: '',
    bvnPhoneDigits: ''
  });

  const hasMissingField = !Object.values(formState).every(v => v);

  const {
    bvnStatus,
    bvnStatusError,
    bvnStatusLoading,
    customerBvnStatus
  } = useCustomerBvnStatus();

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const updateDateOfBirth = (dateOfBirth) => {
    setFormState((prevState) => ({
      ...prevState,
      dateOfBirth
    }));
  };

  const handleVerifyBvn = () => {
    setError('');
    const { bvn, dateOfBirth, bvnPhoneDigits } = formState;

    customerBvnStatus({
      clientId,
      bvn: bvn,
      bvnDOB: dateOfBirth,
      bvnPhoneDigits: bvnPhoneDigits,
    })
  }

  useEffect(() => {
    if (bvnStatusError) {
      setError(handleGraphQLErrors(bvnStatusError));

      const timeoutId = setTimeout(() => {
        setError('');
      }, 8000);

      return () => clearTimeout(timeoutId);
    }
  }, [bvnStatusError]);

  useEffect(() => {
    if (bvnStatus) {
      if (bvnStatus.hasCustomerAccount) {
        return
      }

      completeValidation({ ...bvnStatus, bvn: formState.bvn });
      setVerifiedBvn(true);
    }
  }, [bvnStatus])

  return (
    <Fragment>

      {bvnStatusLoading ? <FullPageLoader /> : null}

      {bvnStatus?.hasCustomerAccount &&
        <Alert classes="error">
          BVN is linked to another account. Please,{' '}
          <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
            <Link to='/'>Login with your existing account</Link>
          </span>
        </Alert>
      }

      {error ? (
        <Alert classes="error">
          {error}
        </Alert>
      ) : null}

      <FormGroup>
        <Input
          required
          name="bvn"
          type="text"
          label="BVN"
          numberField
          maxlength={11}
          minlength={11}
          autoFocus={!bvn}
          defaultValue={bvn}
          disabled={hasVerifiedBvn}
          classes={`border-bottom`}
          onChange={handleChange}
          placeholder="Enter your BVN"
        />
      </FormGroup>

      {!hasVerifiedBvn &&
        <Fragment>

          <FormGroup classes='two-grid'>
            <Input
              required
              type="text"
              numberField
              maxlength={4}
              minlength={4}
              autoFocus={!!bvn}
              name="bvnPhoneDigits"
              label="BVN phone number"
              disabled={hasVerifiedBvn}
              classes={`border-bottom`}
              onChange={handleChange}
              placeholder="Last 4 digits of BVN phone number"
            />

            <section>
              <div className="dob-input">
                <label htmlFor="">
                  Date of birth <span className="required">*</span>
                </label>

                <ReactDatePicker
                  name="dateOfBirth"
                  selected={formState.dateOfBirth}
                  onChange={(date) => updateDateOfBirth(date)}
                  dateFormat="dd/MMMM/yyyy"
                  peekNextMonth
                  required
                  showMonthDropdown
                  disabled={hasVerifiedBvn}
                  maxDate={dobMaxSelectableDate}
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  popperContainer={PopperContainer}
                />

              </div>
            </section>
          </FormGroup>

          <div className="space-apart">
            <div></div>
            <Button
              disabled={bvnStatusLoading}
              click_event={handleVerifyBvn}
              inactive={hasMissingField}
            >
              Verify BVN
            </Button>
          </div>
        </Fragment>
      }
    </Fragment>
  )
}

const $el = document.querySelector('#root');

const PopperContainer = ({ children }) => (
  ReactDOM.createPortal(
    children,
    $el,
  )
);