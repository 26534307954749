import React from 'react';
import './_Table.scss';
import PropTypes from 'prop-types';

const Table = ({ tableHeaders, children }) => {
  return (
    <table className={'table'}>
      {tableHeaders === '' || tableHeaders == null ? (
        ''
      ) : (
        <thead>
          <tr>{tableHeaders.map((val) => <th key={val}>{val}</th>)}</tr>
        </thead>
      )}
      {children}
    </table>
  );
};

Table.propTypes = {
  tableHeaders: PropTypes.array
};

export default Table;
