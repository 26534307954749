import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useState } from 'react';

const GET_BVN_OTP_CHANNELS = gql(/* GraphQL */ `
  query GetBvnOtpChannels($bvn: String!, $clientId: ID!) {
    getBvnOtpChannels(
      input: { bvn: $bvn, clientId: $clientId }
    ) {
      detail
      contacts
      verificationId
    }
  }
`);

const SEND_BVN_OTP_TO_CHANNEL = gql(/* GraphQL */ `
  mutation SendBvnOtpToChannel(
    $verificationId: ID!, $channel: String!, $clientId: ID!
  ) {
    sendBvnOtpToChannel(
      input: { verificationId: $verificationId, channel: $channel, clientId: $clientId }
    ) {
      detail
      verificationId
    }
  }
`);

const CONFIRM_BVN_OTP = gql(/* GraphQL */ `
  mutation ConfirmBvnOtp(
    $otp: String!, $verificationId: ID!, $clientId: ID!
) {
    confirmBvnOtp(
      input: { otp: $otp, verificationId: $verificationId, clientId: $clientId }
    ) {
      firstName
      lastName
      dateOfBirth
      phone
      gender
      title
    }
  }
`);

export const useGetBvnOtpChannels = () => {
  const [bvnOtpChannels, setBvnOtpChannels] = useState();

  const [loadQuery, { loading, error }] = useLazyQuery(GET_BVN_OTP_CHANNELS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.getBvnOtpChannels) {
        setBvnOtpChannels(data.getBvnOtpChannels)
      }
    },
    onError:()=> {
      setBvnOtpChannels(null)
    }
  })

  const getBvnOtpChannels = ({ bvn, clientId }) => {
    loadQuery({
      variables: {
        bvn, clientId
      },
    });
  };

  return {
    bvnOtpChannels,
    getBvnOtpChannels,
    bvnOtpChannelsError: error,
    bvnOtpChannelsLoading: loading,
  };
}

export const useSendBvnOtpToChannel = () => {
  const [bvnOtpToChannels, setBvnOtpToChannels] = useState()

  const [mutate, { loading, error, }] = useMutation(SEND_BVN_OTP_TO_CHANNEL, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.sendBvnOtpToChannel) {
        setBvnOtpToChannels(data.sendBvnOtpToChannel)
      }
    },
    onError:()=> {
      setBvnOtpToChannels(null)
    }
  })

  const sendBvnOtpToChannel = ({ verificationId, channel, clientId }) => {
    mutate({
      variables: {
        verificationId, channel, clientId
      },
    });
  };

  return {
    bvnOtpToChannels,
    sendBvnOtpToChannel,
    bvnOtpToChannelsError: error,
    bvnOtpToChannelsLoading: loading,
  };
}

export const useConfirmBvnOtp = () => {
  const [bvnDetails, setBvnDetails] = useState()

  const [mutate, { loading, error }] = useMutation(CONFIRM_BVN_OTP, {
    onCompleted: (data) => {
      if (data?.confirmBvnOtp) {
        setBvnDetails(data.confirmBvnOtp)
      }
    },
    onError:()=> {
      setBvnDetails(null)
    }
  })

  const confirmBvnOtp = ({ otp, verificationId, clientId }) => {
    mutate({
      variables: {
        otp, verificationId, clientId
      },
    });
  };

  return {
    bvnDetails,
    confirmBvnOtp,
    bvnOtpError: error,
    bvnOtpLoading: loading,
  };
}
