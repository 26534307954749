import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import { handleGraphQLErrors } from '../../lib/utils';
import { REQUEST_CONSENT } from './queries';

const useRequestConsentProvider = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState('');

  const [requestMutation, { loading }] = useMutation(REQUEST_CONSENT, {
    onError(errors) {
      setErrorMessage(handleGraphQLErrors(errors));
      return;
    },

    onCompleted() {
      setSuccess(true);
      return;
    },
  });

  const requestConsent = ({ applicationNumber, providerName }) => {
    requestMutation({
      variables: {
        applicationNumber,
        providerName,
      },
    });
  };

  const props = {
    loading,
    success,
    errorMessage,
    requestConsent,
  };

  return props;
};

export default useRequestConsentProvider;
