import React from 'react';
import PropTypes from 'prop-types';
import './_Popup.scss';

const Popup = (props) => {
  return (
    <section className={`popup ${props.classes == null ? '' : props.classes}`}>
      <div className="popup__content">
        <div className={`loan-requirements ${props.classes == null ? '' : props.classes}`}>
          {props.title ? <div className="title">{props.title}</div> : ''}
          {props.subTitle ? <div className="sub-title">{props.subTitle}</div> : ''}
          <div>{props.children}</div>
        </div>
      </div>
    </section>
  );
};

Popup.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string
};

export default Popup;
