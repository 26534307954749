import React, { Fragment, useState, useEffect } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import { DataCardV2, Button, Input, FormGroup, PopupV2, Alert, Loader } from '../../../../components';
import { Close } from '../../../../components/Icon';
import { AuthService } from '../../../../services';
import { getErrorMessages, maskPhoneNumber } from '../../../../lib/utils';

import './_Styles.scss';

const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  updatePBStepCount,
  formParams,
  userData,
  getPreviouslySavedData,
  baseInputChangeHandler,
  updateContextState
}) => {
  updatePBStepCount(stepIndex + 1);

  const [ showPhoneVerification, switchPhoneVerification ] = useState(false);
  const [ responseError, setResponseError ] = useState([]);
  const [ inputErrors, setInputErrors ] = useState([]);
  const [ isSubmitted, updateSubmit ] = useState(false);
  const [ isHeaderTextUpdated, updateHeaderText ] = useState(false);
  const [ isLoading, updateLoadingState ] = useState(false);
  const requiredFormFields = [ 'otp' ];
  const { otp, phoneNumber, userId } = formParams;
  const { title } = formInfo;
  const { next } = navigation;
  let myColor = { background: '#0E1717', text: '#FFFFFF' };
  let mySuccessColor = { background: '#095712', text: '#FFFFFF' };
  let subTitle = '';

  if (userData || phoneNumber) {
    const bvnPhone = userData.me?.bvnStatus?.phone;
    const numberToDisplay = isHeaderTextUpdated
      ? phoneNumber
      : userData && userData.me ? `0${userData.me.phone}` : phoneNumber;
    subTitle = `We have sent a verification code to the phone number (<b>${maskPhoneNumber(bvnPhone || numberToDisplay)}</b>) linked to your bvn via <b>SMS</b>. Please enter the 6-digit OTP below.`;
  }

  const triggerPhoneNumberUpdate = async () => {
    updateLoadingState(true);

    const userId = userData && userData.me ? userData.me.id : null;
    const response = await AuthService.updatePhoneNumber(userId, phoneNumber);
    updateLoadingState(false);

    if (response.status === 200 && response.data.data.updatePhoneNumber && response.data.data.updatePhoneNumber.ok) {
      resendOtp();
      updateHeaderText(true);
      handlePopupSwitch();
    } else {
      notify.show('Error! Unable to update your Phone Number.', 'error', 6000, myColor);
      handlePopupSwitch();
    }
  };

  const handlePopupSwitch = () => {
    switchPhoneVerification(!showPhoneVerification);
  };

  const updatePhoneNumberRecord = () => {
    const { phoneNumber } = formParams;

    if (phoneNumber.length !== 11 || phoneNumber.charAt(0) !== '0' || isNaN(Number(phoneNumber))) {
      const errorMsg =
        phoneNumber.length < 1
          ? 'Kindly enter a Phone Number'
          : 'The phone number you have entered is incorrect. Phone number format 08012345678';
      notify.show(errorMsg, 'error', 4000, myColor);
    } else {
      triggerPhoneNumberUpdate();
    }
  };

  const resendOtp = async () => {
    updateLoadingState(true);

    const response = await AuthService.triggerPhoneConfirmation(userData.me.id, true);

    updateLoadingState(false);

    if (response == null || typeof response === 'undefined') {
      const error = 'Unable to Resend OTP.';
      setResponseError({ resendOtp: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ resendOtp: error });
      return;
    }

    const { ok } = response.data.data.triggerPhoneConfirmation;
    notify.show('OTP resent successfully', 'success', 4000, mySuccessColor);

    if (!ok) {
      const error = 'Unable to Resend OTP.';
      setResponseError({ resendOtp: error });
      return;
    }
  };

  const triggerEmailConfirmation = async () => {
    updateLoadingState(true);
    const response = await AuthService.triggerPersonalEmailConfirmation();

    updateLoadingState(false);
    updateContextState({ page: 'emailVerification' });
    if (response == null || typeof response === 'undefined') {
      next();
    }

    if (response.data.errors) {
      next();
    }
    next();
  };

  const confirmPhone = async () => {
    // get value
    if (!userData || Object.keys(userData).length === 0) {
      window.location.href = 'sign-up';
    }

    let customerId;
    updateLoadingState(true);

    if (userData && Object.keys(userData).length !== 0) {
      customerId = userData.me.id;
    } else {
      customerId = userId;
    }

    const response = await AuthService.confirmPhone(otp, customerId);

    updateLoadingState(false);

    if (response == null || typeof response === 'undefined') {
      const error = 'Unable to verify phone number.';
      setResponseError({ verifyOtp: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);

      if (error.trim() === 'user phone already confirmed') {
        updateContextState({ page: 'emailVerification' });
        next();
      } else {
        setResponseError({ verifyOtp: error });
        return;
      }
    }

    const { ok } = response.data.data.confirmPhone;

    if (!ok) {
      const error = 'Unable to verify phone number.';
      setResponseError({ verifyOtp: error });
      return;
    } else {
      triggerEmailConfirmation();
    }

    return;
  };

  const toggleSwitchPhoneVerification = () => {
    if (!showPhoneVerification) {
      resendOtp();
    }

    switchPhoneVerification(!showPhoneVerification);
  };

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case 'otp':
        if (value.length === 0 || value.length < 4) {
          setInputErrors((prevState) => ({ ...prevState, otp: 'Expected character is 4.' }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, otp: '' }));
        }
        break;
        default:
        break;
    }
    return true;
  };

  const checkValidation = () => {
    requiredFormFields.map((item) => {
      validateForm(item, formParams[item]);
    });
  };

  useEffect(
    () => {
      checkValidation();
    },
    [ formParams, isSubmitted ]
  );

  const nextStep = () => {
    updateSubmit(true);

    const errors = Object.keys(inputErrors).filter((item) => inputErrors[item] !== '');

    if (errors.length < 1 && userData) {
      confirmPhone();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        {responseError &&
        responseError.length !== 0 && (
          <Fragment>{responseError.verifyOtp ? <Alert classes="error">{responseError.verifyOtp}</Alert> : ''}</Fragment>
        )}

        <div className="form_content">
          <Notifications />
          <section>
            <FormGroup>
              <Input
                required
                numberField
                placeholder="Enter OTP"
                label="Enter OTP"
                name="otp"
                value={otp}
                minlength={4}
                maxlength={6}
                autoFocus
                readOnly={false}
                disabled={false}
                onChange={baseInputChangeHandler}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.otp ? inputErrors.otp : ''}
              />
            </FormGroup>
            <p className="center-text">
              Didn't get OTP? <br />
              <span className="resend-otp" onClick={resendOtp}>
                Resend Code
              </span>
              {/* <span className="resend-otp spacer">/</span>
              <span className="resend-otp" onClick={handlePopupSwitch}>
                Edit Phone Number
              </span> */}
            </p>
            <p className='center-text OTP-note'> NOTE: The OTP may not deliver if DND is active on your number. Send ALLOW to 2442 to remove DND, then click <span className="resend-otp" onClick={resendOtp}>Resend Code</span></p>
          </section>
        </div>

        <div className="space-apart single right">
          <Button inactive={otp === ''} click_event={nextStep}>
            Continue
          </Button>
        </div>
      </DataCardV2>

      {showPhoneVerification && (
        <PopupV2 classes="modal-confirm-phone-wrapper" title="Edit Phone Number">
          <Close className="close-icon" onClick={() => handlePopupSwitch()} />
          <div className="modal-confirm-phone">
            {responseError.phoneNumber && <Alert classes="error">{responseError.phoneNumber}</Alert>}

            <Input
              type="tel"
              placeholder="Phone Number - 0xxxxxxx"
              label="Phone Number"
              phoneField
              minlength={10}
              maxlength={11}
              name="phoneNumber"
              value={phoneNumber}
              readOnly={false}
              onChange={baseInputChangeHandler}
              classes={`border-bottom`}
              required
              errorMessage={isSubmitted && inputErrors.phoneNumber ? inputErrors.phoneNumber : ''}
            />

            <div className="space-apart single center">
              <Button click_event={updatePhoneNumberRecord}>Save</Button>
            </div>
          </div>
        </PopupV2>
      )}
    </Fragment>
  );
};

export default Index;
