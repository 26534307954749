import React, { Fragment, useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import NaijaStates from "naija-xbystate";
import Notifications, { notify } from "react-notify-toast";
import moment from 'moment';

import { AuthService } from "../../../../services";
import {
  DataCardV2,
  BackArrow,
  Button,
  FormGroup,
  Input,
  Loader,
  Alert,
  Select,
  PasswordInput,
  BvnValidator,
} from "../../../../components";

import {
  validateEmail,
  validatePasswordStrength,
  convertDate,
  maxSelectableDate,
  getErrorMessages,
  removeUnderscore,
  toTitleCase,
  validateLassraId,
  getGlobalClientId,
  dobMaxSelectableDate,
} from "../../../../lib/utils";
import { validation } from "../../../../lib/constants";
import Constants from "../../../../lib/constants";
import "react-datepicker/dist/react-datepicker.css";
import "./_Styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import Context from "../../../../context";

const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  userData,
  setUserData,
  updatePBStepCount,
  formParams,
  updateFormParams,
  updateLocationState,
  baseInputChangeHandler,
  updateContextState,
}) => {
  updatePBStepCount(stepIndex + 1);

  const { state: localContextState } = useContext(Context);

  let myColor = { background: "#0E1717", text: "#FFFFFF" };
  const maritalStatuses = ["Single", "Married"];
  const residentialTypes = ["Rented", "Owned", "Living with Parents/Relatives"];
  const [moveInDate, setMoveInDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [inputErrors, setInputErrors] = useState([]);
  const [senatorialDistricts, setSenatorialDistricts] = useState([]);
  const [isLoading, updateLoadingState] = useState(false);
  const [responseError, setResponseError] = useState([]);
  const [educationalLevels, setEducationalLevels] = useState([
    "graduate",
    "masters",
    "undergraduate",
    "professional training",
    "post graduate",
    "others",
  ]);

  const [isSubmitted, updateSubmit] = useState(false);
  const [genders, setGenders] = useState([]);
  const { title, subTitle } = formInfo;
  const { next, previous } = navigation;
  const requiredFormFields = [
    "firstName",
    "lastName",
    "gender",
    "phoneNumber",
    "email",
    "password",
    "confirmPassword",
    "address",
    "lga",
    "dateMovedIn",
    "maritalStatus",
    "taxIdNo",
    "highestEducationalLevel",
    "residenceType",
    "senatorialDistrict",
    "dateOfBirth",
    "lassraId",
  ];

  const {
    bvn,
    firstName,
    lastName,
    gender,
    phoneNumber,
    email,
    password,
    confirmPassword,
    address,
    state,
    lga,
    maritalStatus,
    taxIdNo,
    highestEducationalLevel,
    senatorialDistrict,
    residenceType,
    dateMovedIn,
    dateOfBirth,
    lassraId,
  } = formParams;

  const getPasswordConfirmed = (value) => {
    return value === password;
  };

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case "firstName":
        if (value.length === 0) {
          setInputErrors((prevState) => ({ ...prevState, firstName: "First Name must be at least 3 characters." }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, firstName: "" }));
        }
        break;
      case "lastName":
        if (value.length === 0) {
          setInputErrors((prevState) => ({ ...prevState, lastName: "Last Name must be at least 3 characters." }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, lastName: "" }));
        }
        break;
      case "phoneNumber":
        if (value.charAt(0) !== "0") {
          setInputErrors((prevState) => ({
            ...prevState,
            phoneNumber: "The phone number you have entered is incorrect. Phone number format 08012345678",
          }));
        } else if (value.length > validation.PHONE_LENGTH) {
          setInputErrors((prevState) => ({
            ...prevState,
            phoneNumber: "The phone number you have entered is incorrect. Phone number format 08012345678.",
          }));
        } else if (value.length !== validation.PHONE_LENGTH || isNaN(Number(value))) {
          setInputErrors((prevState) => ({
            ...prevState,
            phoneNumber: `Phone number must be ${validation.PHONE_LENGTH} digits`,
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, phoneNumber: "" }));
        }
        break;
      case "email":
        if (!validateEmail(value)) {
          setInputErrors((prevState) => ({ ...prevState, email: "Please enter a valid email address" }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, email: "" }));
        }
        break;
      case "password":
        if (value.length === 0 || !validatePasswordStrength(value)) {
          setInputErrors((prevState) => ({
            ...prevState,
            password:
              "Password must be at least 6 characters and must contain a digit, a lowercase character and an uppercase character",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, password: "" }));
        }
        break;
      case "confirmPassword":
        if (value.length === 0 || !getPasswordConfirmed(value)) {
          setInputErrors((prevState) => ({
            ...prevState,
            confirmPassword: "Passwords do not match. Please check your password",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, confirmPassword: "" }));
        }
        break;
      case "address":
        if (value.length === 0 || value < 5) {
          setInputErrors((prevState) => ({
            ...prevState,
            address: "Enter your Address",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, address: "" }));
        }
        break;
      case "state":
        if (value.length === 0) {
          setInputErrors((prevState) => ({
            ...prevState,
            state: "Kindly select an option from list.",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, state: "" }));
        }
        break;
      case "lga":
        if (value.length === 0) {
          setInputErrors((prevState) => ({
            ...prevState,
            lga: "Kindly select an option from list.",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, lga: "" }));
        }
        break;
      case "lassraId":
        if (!value || !validateLassraId(value.toLowerCase())) {
          setInputErrors((prevState) => ({ ...prevState, lassraId: "Please enter a valid LASSRA ID" }))
        }
        else {
          setInputErrors((prevState) => ({ ...prevState, lassraId: "" }));
        }
        break;
      case "dateOfBirth":
        if (!value) {
          setInputErrors(prevState => ({ ...prevState, dateOfBirth: 'Date of birth is required.' }));
        } else {
          setInputErrors(prevState => ({ ...prevState, dateOfBirth: '' }));
        }
        break;
      case "taxIdNo":
      case "residenceType":
      case "senatorialDistrict":
      case "dateMovedIn":
      case "gender":
      case "highestEducationalLevel":
      case "maritalStatus":
        if (!value?.length) {
          setInputErrors((prevState) => ({
            ...prevState,
            [fieldName]: "This field is required.",
          }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, [fieldName]: "" }));
        }
        break;
      default:
        break;
    }
    return true;
  };

  const getGenders = async () => {
    updateLoadingState(true);

    const response = await AuthService.getEnumValues("Gender");

    updateLoadingState(false);

    if (!response || response.status !== 200) {
      notify("Error fetching gender data", "error", 4000);
      return;
    }

    if (response.data && response.data.data) {
      const data = response.data.data.enumValues.map((value) => ({
        name: removeUnderscore(value.name),
        value: value.name,
      }));
      setGenders(data);
    }
  };

  const getLcdasBySenetorialDistrict = (state, senatorialDistrict) => {
    return NaijaStates.senatorial_lcdas(state, senatorialDistrict).map((lcda, index) => (
      <option key={index}>{lcda}</option>
    ));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const senatorialDistricts = NaijaStates.senatorialDistricts(state);
    setSenatorialDistricts(senatorialDistricts);
    getGenders();
  }, []);

  const checkValidation = () => {
    requiredFormFields.map((item) => {
      validateForm(item, formParams[item]);
    });
  };

  useEffect(() => {
    checkValidation();
  }, [formParams, isSubmitted]);

  const setDate = (date) => {
    setMoveInDate(date);
    updateFormParams((prevState) => ({
      ...prevState,
      dateMovedIn: date ? convertDate(date) : null,
    }));
  };

  const updateDateOfBirth = (date) => {

    setBirthDate(date);

    updateFormParams((prevState) => ({
      ...prevState,
      dateOfBirth: date ? convertDate(date) : null,
    }));
  };

  const createCustomer = async () => {
    updateLoadingState(true);

    const businessInformation = {
      lassraId,
      taxIdNo,
      highestEducationalLevel,
      residenceType,
      senatorialDistrict,
      lga,
      nextStage: "otpVerification",
    };
    const phone = phoneNumber.length === 11 && phoneNumber.charAt(0) === "0" ? phoneNumber.substr(1) : phoneNumber;
    const localGovernment = lga;
    const clientId = getGlobalClientId();
    const employmentStatus = "self-employed";

    const response = await AuthService.createCustomer({
      bvn,
      firstName,
      lastName,
      email,
      gender,
      phone,
      password,
      clientId,
      state,
      localGovernment,
      address,
      dateMovedIn,
      employmentStatus,
      businessInformation,
      maritalStatus,
      dateOfBirth,
    });

    updateLoadingState(false);

    const error = "Error creating your account. Please, try again later";
    if (response == null || typeof response === "undefined") {
      setResponseError({ createCustomer: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);

      if (error.includes("phone")) {
        notify.show("This phone number already exists, try another or contact admin", "error", 5000, myColor);
      } else if (error.includes("email")) {
        notify.show("This email already exists, try another email or contact admin", "error", 5000, myColor);
      } else {
        notify.show(error, "error", 5000, myColor);
      }

      return;
    }

    if (!response.data || !response.data.data || !response.data.data.createCustomer) {
      setResponseError({ createCustomer: error });
      return;
    }

    const { createCustomer } = response.data.data;

    // setting temporary api key
    const authCreds = {
      apiKey: createCustomer.token,
      keyType: Constants.authTypes.TEMPORARY,
    };
    localStorage.setItem("Auth", JSON.stringify(authCreds));

    if (createCustomer && createCustomer.user) {
      const { id } = createCustomer.user;

      setUserData(createCustomer);
      updateFormParams((prevState) => ({
        ...prevState,
        userId: id,
      }));
      updateContextState({ page: "otpVerification" });

      next();
    } else {
      setResponseError({ createCustomer: "Account could not be created, please try again." });
      return;
    }
  };

  const nextStep = () => {
    updateSubmit(true);
    const errors = Object.keys(inputErrors).filter((item) => inputErrors[item] !== "");

    if (errors.length < 1) {
      createCustomer();
    } else {
      window.scrollTo(0, 300);
    }
  };

  const [hasVerifiedBvn, setHasVerifiedBvn] = useState(false);

  const completeBvnValidation = (data) => {

    if (data) {
      updateFormParams((prevState) => ({
        ...prevState,
        "firstName": data?.firstName,
        "lastName": data?.lastName,
        "bvn": data?.bvn,
        "gender": data?.gender?.toUpperCase(),
      }));

      updateDateOfBirth(new Date(data?.dateOfBirth))

      setHasVerifiedBvn(true);
    }
  }

  const { bvnServiceProviders } = localContextState?.clientInfo || [];

  return (
    <Fragment>
      {isLoading && <Loader />}

      <DataCardV2 title={title} subTitle={subTitle} centeralize hasPadding>
        <Notifications />
        {responseError && responseError.length !== 0 && (
          <Fragment>
            {responseError.createCustomer ? (
              <Alert classes="margin-top error">{responseError.createCustomer}</Alert>
            ) : (
              ""
            )}
          </Fragment>
        )}

        <div className="form_content personal_details">
            {!hasVerifiedBvn && bvnServiceProviders?.length > 0 ? (
              <BvnValidator
                bvnServiceProviders={bvnServiceProviders}
                completeValidation={completeBvnValidation}
                goBack={previous}
              />
            ) : null}

          <section className="margin-top">

            {!bvnServiceProviders?.length || (bvnServiceProviders?.length > 0 && hasVerifiedBvn) ? (
              <Fragment>
                <FormGroup classes="two-grid">
                  <Input
                    required
                    type="text"
                    placeholder="First Name"
                    label="First Name"
                    name="firstName"
                    value={firstName}
                    disabled={hasVerifiedBvn}
                    onChange={baseInputChangeHandler}
                    classes={`border-bottom`}
                    errorMessage={isSubmitted && inputErrors.firstName ? inputErrors.firstName : ""}
                  />

                  <Input
                    required
                    type="text"
                    placeholder="Last Name"
                    label="Last Name"
                    name="lastName"
                    value={lastName}
                    disabled={hasVerifiedBvn}
                    onChange={baseInputChangeHandler}
                    classes={`border-bottom`}
                    errorMessage={isSubmitted && inputErrors.lastName ? inputErrors.lastName : ""}
                  />
                </FormGroup>

                <FormGroup classes="two-grid">
                  <section>
                    <div className="dob-input">
                      <label htmlFor="">
                        Date of birth <span className="required">*</span>
                      </label>

                      <DatePicker
                        name="dateOfBirth"
                        selected={birthDate}
                        onChange={(date) => updateDateOfBirth(date)}
                        dateFormat="dd/MMMM/yyyy"
                        peekNextMonth
                        required
                        showMonthDropdown
                        disabled={hasVerifiedBvn}
                        maxDate={dobMaxSelectableDate}
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                      />

                    </div>
                    {isSubmitted && inputErrors.dateOfBirth ? (
                      <span className="input__error">{inputErrors.dateOfBirth}</span>
                    ) : null}
                  </section>

                  <Select
                    name="gender"
                    label="Gender"
                    placeholder="Gender"
                    classes="border-bottom"
                    autoComplete="gender"
                    defaultValue={gender}
                    onChange={baseInputChangeHandler}
                    errorMessage={isSubmitted && inputErrors.gender ? inputErrors.gender : ""}
                    required>
                    <option value="">
                      Gender
                    </option>
                    {genders.map((value, index) => (
                      <option key={`gender_${index}`} value={value.value}>
                        {toTitleCase(value.name)}
                      </option>
                    ))}
                  </Select>
                </FormGroup>

                <FormGroup classes="two-grid">
                  <Input
                    type="tel"
                    placeholder="Phone Number"
                    label="Phone Number"
                    phoneField
                    minlength={10}
                    maxlength={11}
                    name="phoneNumber"
                    value={phoneNumber}
                    readOnly={false}
                    onChange={baseInputChangeHandler}
                    classes={`border-bottom`}
                    required
                    errorMessage={isSubmitted && inputErrors.phoneNumber ? inputErrors.phoneNumber : ""}
                  />

                  <Select
                    name="maritalStatus"
                    label="Marital Status"
                    placeholder="Marital Status"
                    classes="border-bottom"
                    onChange={baseInputChangeHandler}
                    autoComplete="maritalStatus"
                    defaultValue={maritalStatus}
                    errorMessage={isSubmitted && inputErrors.maritalStatus ? inputErrors.maritalStatus : ""}
                    required>
                    <option disabled value="">
                      Marital Status
                    </option>
                    {maritalStatuses &&
                      maritalStatuses.map((value, id) => (
                        <option value={value} key={`${value}_${id}`}>
                          {value}
                        </option>
                      ))}
                  </Select>
                </FormGroup>

                <FormGroup classes="two-grid">
                  <Select
                    name="highestEducationalLevel"
                    label="Highest educational level"
                    placeholder="Highest educational level"
                    classes="border-bottom"
                    autoComplete="highestEducationalLevel"
                    onChange={baseInputChangeHandler}
                    defaultValue={highestEducationalLevel}
                    errorMessage={
                      isSubmitted && inputErrors.highestEducationalLevel ? inputErrors.highestEducationalLevel : ""
                    }
                    required>
                    <option disabled value="">
                      Highest educational level
                    </option>
                    {educationalLevels &&
                      educationalLevels.map((value, id) => (
                        <option value={value} key={`${value}_${id}`}>
                          {toTitleCase(value)}
                        </option>
                      ))}
                  </Select>

                  <Input
                    type="email"
                    required
                    placeholder="Contact Email"
                    label="Contact Email"
                    name="email"
                    value={email}
                    readOnly={false}
                    onChange={baseInputChangeHandler}
                    classes={`border-bottom`}
                    errorMessage={isSubmitted && inputErrors.email ? inputErrors.email : ""}
                  />
                </FormGroup>

                <FormGroup classes="two-grid">
                  <Input
                    required
                    type="text"
                    placeholder="e.g. N-123456"
                    label="Personal Tax ID No"
                    name="taxIdNo"
                    value={taxIdNo}
                    onChange={baseInputChangeHandler}
                    disabled={false}
                    classes={`border-bottom`}
                    errorMessage={isSubmitted && inputErrors.taxIdNo ? inputErrors.taxIdNo : ""}
                  />

                  <Input
                    type="text"
                    required
                    placeholder="Lassra ID No"
                    label="Lassra ID No"
                    name="lassraId"
                    value={lassraId}
                    readOnly={false}
                    onChange={baseInputChangeHandler}
                    classes={`border-bottom`}
                    errorMessage={isSubmitted && inputErrors.lassraId ? inputErrors.lassraId : ""}
                  />
                </FormGroup>

                <div>
                  <p className="section-subtitle">Please enter your Residential Address</p>
                </div>

                <FormGroup classes="two-grid">
                  <Input
                    required
                    type="text"
                    placeholder="Residential Address"
                    label="Residential Address"
                    name="address"
                    value={address}
                    disabled={false}
                    onChange={baseInputChangeHandler}
                    classes={`border-bottom`}
                    errorMessage={isSubmitted && inputErrors.address ? inputErrors.address : ""}
                  />

                  <Select
                    name="senatorialDistrict"
                    label="Senatorial District"
                    placeholder="Senatorial District"
                    classes="border-bottom"
                    autoComplete="senatorialDistrict"
                    onChange={baseInputChangeHandler}
                    defaultValue={senatorialDistrict}
                    errorMessage={isSubmitted && inputErrors.senatorialDistrict ? inputErrors.senatorialDistrict : ""}
                    required>
                    <option disabled value="">
                      Senatorial District
                    </option>
                    {senatorialDistricts &&
                      senatorialDistricts.map((value, idx) => (
                        <option value={value} key={`${value}_${idx}`}>
                          {value}
                        </option>
                      ))}
                  </Select>
                </FormGroup>

                <FormGroup classes="one-grid">
                  <Select
                    name="lga"
                    label="Local Govt Area"
                    placeholder="Local Govt Area"
                    classes="border-bottom"
                    autoComplete="lga"
                    onChange={baseInputChangeHandler}
                    defaultValue={lga}
                    errorMessage={isSubmitted && inputErrors.lga ? inputErrors.lga : ""}
                    required>
                    <option disabled value="">
                      Local Govt Area
                    </option>
                    {state && senatorialDistrict && getLcdasBySenetorialDistrict(state, senatorialDistrict)}
                  </Select>
                </FormGroup>

                <FormGroup classes="two-grid">
                  <Select
                    name="residenceType"
                    label="Type of Residence"
                    placeholder="Type of Residence"
                    classes="border-bottom"
                    autoComplete="residenceType"
                    onChange={baseInputChangeHandler}
                    defaultValue={residenceType}
                    errorMessage={isSubmitted && inputErrors.residenceType ? inputErrors.residenceType : ""}
                    required>
                    <option disabled value="">
                      Type of Residence
                    </option>
                    {residentialTypes &&
                      residentialTypes.map((value, idx) => <option key={`${value}_${idx}`}>{value}</option>)}
                  </Select>

                  <section>
                    <div className="dob-input">
                      <label htmlFor="">
                        When did you start living there <span className="required">*</span>
                      </label>
                      <DatePicker
                        placeholderText="(Jan/2000)"
                        name="dateMovedIn"
                        selected={moveInDate}
                        onChange={(date) => setDate(date)}
                        dateFormat="MMMM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        maxDate={maxSelectableDate}
                      />
                    </div>

                    {isSubmitted && inputErrors.dateMovedIn ? (
                      <span className="input__error">{inputErrors.dateMovedIn}</span>
                    ) : null}
                  </section>
                </FormGroup>

                <div>
                  <p className="section-subtitle">Please enter your Password</p>
                </div>

                <FormGroup classes="two-grid">
                  <PasswordInput
                    placeholder="Create Password"
                    label="Create Password"
                    name="password"
                    readOnly={false}
                    value={password}
                    onChange={baseInputChangeHandler}
                    classes={`border-bottom`}
                    errorMessage={isSubmitted && inputErrors.password ? inputErrors.password : ""}
                  />

                  <PasswordInput
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    name="confirmPassword"
                    readOnly={false}
                    value={confirmPassword}
                    onChange={baseInputChangeHandler}
                    classes={`border-bottom`}
                    errorMessage={isSubmitted && inputErrors.confirmPassword ? inputErrors.confirmPassword : ""}
                  />
                </FormGroup>
              </Fragment>
            ) : null}
          </section>
        </div>

        {!bvnServiceProviders?.length || (bvnServiceProviders?.length > 0 && hasVerifiedBvn) ? (
          <div className="space-apart">
            <BackArrow height="24" onClick={previous} text="Back" />
            <Button click_event={nextStep}>Continue</Button>
          </div>
        ) : null}
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
