import React from 'react';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { LOGIN_MUTATION } from './queries';
import { useFormInput, useFlashMessage } from '../../containers/Form';
import { authTypes } from '../../lib/constants';
import {CONTEXTS, getGlobalClientId} from '../../lib/utils';
import { AuthService } from '../../services';
import { formSteps } from '../../containers/GetStarted/constants';

const LoginProvider = ({ children, history }) => {
  const inputs = {
    email: useFormInput(),
    password: useFormInput()
  };

  const [ errorMessage, flashError, setErrorMessage ] = useFlashMessage();
  const [ successMessage, flashSuccess ] = useFlashMessage();

  const handleLogin = (e, runMutation) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');
    inputs.email.setErrorMessage('');
    inputs.password.setErrorMessage('');

    if (inputs.password.value && inputs.email.value) {
      runMutation({
        variables: {
          input: {
            email: inputs.email.value,
            password: inputs.password.value,
            clientId: getGlobalClientId()
          }
        }
      });
    } else {
      if (!inputs.email.value) inputs.email.setErrorMessage('Email Address is required');
      if (!inputs.password.value) inputs.password.setErrorMessage('Password is required');
    }
  };
  /**
   * Moves data from the facilityInformation attribute to the businessInformation attribute.
   */
  const updateFacilityInfo = async (facilityInfo) => {
    const businessInformation = { ...facilityInfo };
    return AuthService.updateUserDataV2({ businessInformation })
  }

  return (
    <Mutation
      mutation={LOGIN_MUTATION}
      onCompleted={async ({ login: { token } }) => {
        if (token) {
          // save authtoken
          let authCreds = {
            apiKey: token
          };

          const viewer = await AuthService.viewerQueryClone(token);

          const response = viewer.data.data.viewer;
          const { account, me } = response;
          const { applications } = account;
          const { isPhoneConfirmed, userMetadata, isEmailConfirmed } = me;
          const hasBusinessInfo = account.accountAttribute.find(attr => attr.attribute.name === 'businessInformation');
          const facilityInfo = account.accountAttribute.find(attr => attr.attribute.name === 'facilityInformation');
          
          const hasAddedBank = account?.bankAccounts?.length;
          const hasAddedCard = account?.cards?.length;
          const signUpContext = account.contextStates.find(context => context.context === 'SIGN_UP');
    
          let businessInfo;
          if (!hasBusinessInfo) {
            businessInfo = facilityInfo.data;
          } else {
            businessInfo = hasBusinessInfo.data;
          }
          let jumpToScreen = 'letsGetStarted';

          if (!account.contextStates.length) {
            if (applications.nodes.length < 1 && businessInfo.nextStage !== 'completed') {
              authCreds.keyType = authTypes.TEMPORARY;
              localStorage.setItem('Auth', JSON.stringify(authCreds));
              flashSuccess('Logged In Successfully but user data incomplete.');

              if (!isPhoneConfirmed) {
                jumpToScreen = 'otpVerification';
              } else if (!isEmailConfirmed) {
                jumpToScreen = 'emailVerification';
              } else {
                jumpToScreen = businessInfo.nextStage;
              }

              history.push('/sign-up', {
                isRegisteredUser: true,
                email: inputs.email.value,
                jumpToScreen
              });
            } else {
              flashSuccess('Logged In Successfully');

              if (applications && applications.nodes.length > 0) {
                const application = applications.nodes[0];
                const { requiredSteps, completedSteps } = application;
  
                if (requiredSteps.length === completedSteps.length && businessInfo.nextStage === 'completed') {
                  authCreds.keyType = authTypes.FIXED;
                  localStorage.setItem('Auth', JSON.stringify(authCreds));
                  history.push('/dashboard');
                } else {
                  authCreds.keyType = authTypes.TEMPORARY;
                  localStorage.setItem('Auth', JSON.stringify(authCreds));

                  jumpToScreen = businessInfo.nextStage;

                  history.push('/sign-up', {
                    isRegisteredUser: true,
                    email: inputs.email.value,
                    jumpToScreen
                  });
                }
              } else {
                authCreds.keyType = authTypes.FIXED;
                localStorage.setItem('Auth', JSON.stringify(authCreds));
                history.push('/dashboard');
              }  
            }
          }
          if (!hasBusinessInfo) {
            await updateFacilityInfo(facilityInfo.data);
          }

          if (signUpContext && !applications?.nodes?.length && !hasAddedCard) {
            const { page } = signUpContext;
            authCreds.keyType = authTypes.TEMPORARY;
            localStorage.setItem('Auth', JSON.stringify(authCreds));
            flashSuccess('Login successful. Redirecting to your last application step.');

            let jumpToScreen = page;
            const pageStep = formSteps.find(s => s.id === page);

            if (!isPhoneConfirmed) {
              jumpToScreen = 'otpVerification';
            } else if (!isEmailConfirmed) {
              jumpToScreen = 'emailVerification';
            } else {
              jumpToScreen = 'loanSelection';
            }
    
            history.push('/sign-up', {
              isRegisteredUser: true,
              email: inputs.email.value,
              jumpToScreen,
            });
          } else if (signUpContext && applications?.nodes?.length && !hasAddedBank) { 
            authCreds.keyType = authTypes.TEMPORARY;
            localStorage.setItem('Auth', JSON.stringify(authCreds));
            flashSuccess('Login successful. Redirecting to your last application step.');
            history.push('/sign-up', {
              isRegisteredUser: true,
              email: inputs.email.value,
              jumpToScreen: 'bankStatement'
            });
          } else {
            authCreds.keyType =  authTypes.FIXED;
            localStorage.setItem('Auth', JSON.stringify(authCreds));
            flashSuccess('Login successful. Redirecting to your dashboard');
            const route = '/dashboard'
            history.push(route, {
              isRegisteredUser: true,
              email: inputs.email.value,
              jumpToScreen: 'dashboard'
            });
          }
        }
      }}
      onError={error => {
        if (error.graphQLErrors.length === 0) flashError("We're unable to log you in now. Please try again later.");

        error.graphQLErrors.forEach(error => {
          switch (error.message) {
            case 'Cannot find a user with this email under the provided client':
              flashError('Invalid Email/Password');
              break;
            case 'Invalid password':
              flashError('Invalid Email/Password');
              break;
            default:
              flashError("We're unable to log you in now. Please try again later.");
          }
        });
      }}
    >
      {(runMutation, { error, loading }) =>
        children({
          onSubmit: e => handleLogin(e, runMutation),
          loading,
          error,
          errorMessage,
          successMessage,
          form: inputs
        })}
    </Mutation>
  );
};

export default withRouter(LoginProvider);
