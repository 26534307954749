import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {
    Alert,
    DataCard, Footer, Header, Menu,
} from "../../components";
import {getErrorMessages} from "../../lib/utils";
import {AuthService} from "../../services";
import {notify} from "react-notify-toast";
import Constants from "../../lib/constants";
import {authGetter} from '../../lib/utils';

const { status, duration } = Constants;

class ConfirmEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailToken: '',
            emailConfirmError: '',
            emailConfirmSuccess: ''
        };

        this.confirmEmail = this.confirmEmail.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    componentDidMount() {
        this.confirmEmail();
    }

    handleError(error, showAlert = false) {
        if (showAlert) {
            notify.show(error, status.ERROR, duration.SHORT);
        }
        this.setState({ error, loading: false });
    }

    async confirmEmail() {
        const
            { match: { params: {emailToken} } } = this.props,
            error = 'Unable to confirm email address'
        ;

        if (!emailToken) {
            this.setState({emailConfirmError: error})
        }

        const response = await AuthService.confirmEmail(emailToken);

        if (response == null || typeof response === 'undefined') {
            this.setState({emailConfirmError: error});
            return;
        }

        if (response.data.errors) {
            const error = getErrorMessages(response.data.errors);
            this.setState({emailConfirmError: error});
            return;
        }

        const {ok} = response.data.data.confirmEmail;

        if (!ok) {
            const error = getErrorMessages(response.data.errors);
            this.setState({emailConfirmError: error});
            return;
        }
        this.setState({emailConfirmSuccess: 'Congratulations, your email has been confirmed.'});

    }

    render() {
        const authentication = authGetter();

        return (
            <section className={`layout ${authentication.authenticationType}`}>
                <Header authentication={authentication} />
                {authentication.hasApiKey && !authentication.isTempApiKey && (<Menu/>)}

                <main className="main">
                    <section className="container not-found">
                        <DataCard>
                            {
                                this.state.emailConfirmError && (
                                    <Alert classes="error">{this.state.emailConfirmError}</Alert>
                                )
                            }
                            {
                                this.state.emailConfirmSuccess &&(
                                    <Alert classes="success">{this.state.emailConfirmSuccess}</Alert>
                                )
                            }

                        </DataCard>
                    </section>
                </main>
                <Footer/>
            </section>
        );
    }
}

export default withRouter(ConfirmEmail);
