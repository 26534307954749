const graphQlRequest = require('../lib/requestClient');

export default class BankService {
    static getBanks() {
        const query = `
            query {
                getBanks {
                    id
                    name
                    code
                }
            }
        `;

        return graphQlRequest().post('graphql', { query }).catch(() => {});
    }

    static addAccountBank(payload) {
        const query = `
           mutation ($bankId: ID!, $accountName: String!, $accountNumber: String!, $okraRecordId: String, $okraCustomerId: String, $okraAccountId: String, $directDebitAuthorised: Boolean) {
                addAccountBank(input: {bankId: $bankId, accountName: $accountName, accountNumber: $accountNumber, okraRecordId: $okraRecordId, okraCustomerId: $okraCustomerId, okraAccountId: $okraAccountId, directDebitAuthorised: $directDebitAuthorised}) {
                    id
                    status
                    isDefault
                }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: payload }).catch(() => {});
    }

    static initiateBankStatementReq(applicationId, accountBankId, isRetried) {
        const query = `
            mutation($applicationId: ID!, $accountBankId: ID!, $isRetried: Boolean) {
              initiateBankStatementRequest(input: { applicationId: $applicationId, accountBankId: $accountBankId, isRetried: $isRetried}) {
                success
              }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: { applicationId, accountBankId, isRetried }}).catch(() => {});
    }

    static completeBankStatementReq(applicationId, ticketNum, password, skipStep) {
        const query = `
            mutation($applicationId: ID!, $ticketNum: String, $password: String, $skipStep: Boolean) {
                completeBankStatementRequest(input: {applicationId: $applicationId, ticketNum: $ticketNum, password: $password, skipStep: $skipStep}){
                    success
                }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: { applicationId, ticketNum, password, skipStep }}).catch(() => {});
    }

    static completeExternalBankStatementRequest(applicationId, ticketNum, password, skipStep) {
        const query = `
            mutation($applicationId: ID!, $ticketNum: String!, $password: String!, $skipStep: Boolean!) {
                completeExternalBankStatementRequest(input: {applicationId: $applicationId, ticketNum: $ticketNum, password: $password, skipStep: $skipStep}){
                    success
                    application {
                        requiredSteps
                        completedSteps
                    }
                }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: { applicationId, ticketNum, password, skipStep }}).catch(() => {});
    }

    static setApplicationBankAccount(applicationId, accountBankId) {
        const query = `
            mutation($applicationId: ID!, $accountBankId: ID!) {
              setApplicationBankAccount(input: { applicationId: $applicationId, accountBankId: $accountBankId}) {
                success
                application{
                    id
                    amount
                    taxAmount
                    baseAmount
                    chargesAmount
                    fullAmount
                    loanDuration
                    dateOfRepayment
                    requiredSteps
                    completedSteps
                }
              }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: { applicationId, accountBankId }}).catch(() => {});
    }

    static resolveOkraBank(okraBankId) {
        const query = `
            query($okraBankId: String!) {
                resolveOkraBank(okraBankId: $okraBankId) {
                    id
                    name
                    code
                    okraSlug
                }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: { okraBankId }}).catch(() => {});
    }


    static connectBankToOkra(payload) {
        const query = `
            mutation ($accountBankId: ID!, $recordId: String!, $customerId: String!, $directDebitAuthorised: Boolean, $okraAccountId: String) {
            connectBankToOkra(input: {accountBankId: $accountBankId, recordId: $recordId, customerId: $customerId, directDebitAuthorised: $directDebitAuthorised, okraAccountId: $okraAccountId}){
                    id
                    accountName
                    accountNumber
                    status
                }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: payload }).catch(() => {});
    }

    static setDefaultAccountBank(accountId, accountBankId) {
        const query = `
          mutation setDefaultAccountBank($accountId: ID!, $accountBankId: ID!){
            setDefaultAccountBank(input: {accountId: $accountId, accountBankId: $accountBankId}) {
              isDefault
            }
          }
        `;
        return graphQlRequest()
          .post('graphql', { query, variables: { accountId, accountBankId } })
          .catch(() => {});
    }

}
