import React from 'react';
import { Query } from "react-apollo";
import subMonths from 'date-fns/sub_months';
import subYears from 'date-fns/sub_years';

import { CURRENT_TIME_QUERY } from "./queries";

export default ({ children }) => (
    <Query query={CURRENT_TIME_QUERY}>
        {({ data: { currentTime }, loading, error }) => {
            let date;
            if (loading || error) {
                date = new Date();
            } else {
                date = new Date(currentTime);
            }
            return children({
                currentTime,
                error,
                loading,
                oneMonthAgo: subMonths(date, 1).toISOString(),
                sixMonthsAgo: subMonths(date, 6).toISOString(),
                oneYearAgo: subYears(date, 1).toISOString(),
                fourYearsAgo: subYears(date, 4).toISOString(),
                sevenYearsAgo: subYears(date, 7).toISOString(),
                tenYearsAgo: subYears(date, 10).toISOString(),
            });
        }}
    </Query>
);
