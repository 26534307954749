import React from 'react';
import PropTypes from 'prop-types';
import './_FormGroup.scss';

const FormGroup = (props) => {
    return(
        <div className={`form-group ${props.classes == null ? "" : props.classes}`}>
            {props.children}
        </div>
    )
};

FormGroup.propTypes = {
    classes: PropTypes.string,
};

export default FormGroup;
