import React, { Fragment, useEffect, useState } from "react";
import cx from "classnames";
import Notifications, { notify } from "react-notify-toast";

import { DataCardV2, Button, Input, Loader, Alert, FormGroup, PasswordInput } from "../../../../components";
import { UploadBankStatementProvider } from "../../../../providers";
import { AuthService, LoanService } from "../../../../services";
import { bytesToSize, authGetter, getErrorMessages } from "../../../../lib/utils";

import "./_Styles.scss";

const TicketNumber = ({
  stepIndex,
  navigation,
  formInfo = {},
  formParams,
  updatePBStepCount,
  userData,
  setUserData,
  baseInputChangeHandler,
  updateContextState,
}) => {
  updatePBStepCount(stepIndex + 1);
  const { title, subTitle } = formInfo;
  const [isLoading, updateLoadingState] = useState(false);
  const [uploadedDocs, updateDocs] = useState({});
  const [inputErrors, setInputErrors] = useState([]);
  const [isSubmitted, updateSubmit] = useState(false);
  const [responseError, setResponseError] = useState([]);
  let item = { name: "mbsDocUpload", title: "Click here to upload your Bank Statement" };
  const { statementPassword } = formParams;
  const errorColor = { background: "#0E1717", text: "#FFFFFF" };

  const { next } = navigation;
  let mbsParams;

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    updateLoadingState(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    updateLoadingState(false);

    const response = viewer ? viewer.data.data.viewer : {};
    if (response) setUserData(response);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  const isReady = () => {
    return userData && Object.keys(userData).length !== 0;
  };

  const { uploadBankStatementFile } = UploadBankStatementProvider({ uploadedDocs });

  const handleFileUpload = async (mbsParams) => {
    updateLoadingState(true);

    const response = await uploadBankStatementFile(mbsParams);

    updateLoadingState(false);
    if (response.data && response.data.uploadBankStatement) {
      setResponseError({
        mbsUploadSuccess: "File is now ready, click the continue button below to upload.",
      });
      return;
    } else {
      setResponseError({
        mbsUploadError: "File could not be uploaded, kindly retry.",
      });
      return;
    }
  };

  const handleDocumentUpload = (e) => {
    const { name, validity, files } = e.target;
    const file = files[0];
    let bankId, applicationId;

    if (file.type !== "application/pdf") {
      setResponseError({
        mbsUploadError: "Please, upload a file of type PDF lower than 1 MB.",
      });
      return;
    }

    if (userData && Object.keys(userData).length !== 0) {
      applicationId = userData.account.applications.nodes[0].id;
      bankId = userData.account.bankAccounts[0].bank.id;
    }

    if (file && file.size < 10000000) {
      updateDocs({
        fileName: file.name,
        fileSize: bytesToSize(file.size),
        validity: validity,
      });

      mbsParams = {
        selectedFile: e.target,
        bankId: bankId,
        applicationId: applicationId,
        documentPassword: `${statementPassword}`,
      };

      if (Object.keys(mbsParams).length > 0) {
        handleFileUpload(mbsParams);
      }
    } else {
      setResponseError({
        mbsUploadError: "Please, upload a file not higher than 10 MB",
      });
      return;
    }
  };

  const getApplicantCreditScore = async () => {
    updateLoadingState(true);

    const applicationId = userData.account.applications.nodes[0].id;
    const response = await LoanService.getApplicantCreditScore(applicationId);
    updateLoadingState(true);

    if (!response) {
      setResponseError({ completeBankStatement: "Error generating your credit score" });
      next();
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ completeBankStatement: "Error generating your credit score." });
      next();
    }

    if (response.data.data) {
      const { getApplicantCreditScore } = response.data.data;

      if (getApplicantCreditScore == null || typeof getApplicantCreditScore === "undefined") {
        notify.show("There was an error processing your loan. Please try again later.", "error", 6000, errorColor);
        // To do: push user to failure step/screen
        next();
      }

      if (getApplicantCreditScore.success == null || typeof getApplicantCreditScore.success === "undefined") {
        notify.show("Unable to get your credit score. Please try again later.", "error", 6000, errorColor);
        // To do: push user to failure step/screen
        next();
      }

      if (getApplicantCreditScore.success) {
        next();
      }
    }
  };

  const handleSubmit = () => {
    updateSubmit(true);

    if (uploadedDocs && Object.keys(uploadedDocs).length === 0) {
      setResponseError({
        mbsUploadError: "Please upload a PDF file to proceed",
      });
      return;
    } else {
      updateContextState({ page: "confirmRequest" });
      getApplicantCreditScore();
    }
  };

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        {responseError && responseError.length !== 0 && (
          <Fragment>
            {responseError.mbsUploadError ? <Alert classes="error">{responseError.mbsUploadError}</Alert> : ""}

            {responseError.mbsUploadSuccess ? <Alert classes="success">{responseError.mbsUploadSuccess}</Alert> : ""}
          </Fragment>
        )}
        <div className="form_content mbs_upload_container">
          <label
            className={cx({
              "document-upload": item,
            })}>
            <Input
              name={item.name}
              type="file"
              classes={`border-bottom`}
              onChange={(e) => handleDocumentUpload(e)}
              accept="application/pdf"
            />
            <section>
              <div
                className={`${cx({
                  "document-upload__label": item,
                })} margin-top`}
              />
              <div
                className={cx({
                  title: item.title,
                  has_file: uploadedDocs.fileName,
                })}>
                {Object.keys(uploadedDocs).length !== 0
                  ? `File ${uploadedDocs.fileName} of Size: ${uploadedDocs.fileSize}`
                  : item.title}
              </div>
            </section>
          </label>
        </div>

        <div className="form-content margin-top">
          <FormGroup classes="one-grid">
            <PasswordInput
              required={false}
              placeholder="Bank Statement Password"
              name="statementPassword"
              readOnly={false}
              value={statementPassword}
              onChange={baseInputChangeHandler}
              classes={`border-bottom`}
            />
          </FormGroup>
        </div>
        <div className="space-apart single right">
          {isReady() && <Button click_event={handleSubmit}>Continue</Button>}
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default TicketNumber;
