import React, { Fragment, useState, useEffect } from 'react';
import { ViewerProvider, UpdateUserDetailsProvider } from '../../providers';
import {
  FormGroup,
  Loader,
  Alert,
  Button,
  Input,
  Card,
  Radio,
  RadioGroup,
} from '../../components';
import NaijaStates from 'naija-xbystate';

import './_Settings.scss';
import moment from 'moment';
import { DisplayBvn } from './DisplayBvn';

export const ProfileSettings = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const states = NaijaStates.states();
    updateNaijaStates(states);
  }, []);

  const [formParams, updateFormParams] = useState({
    employmentStatus: '',
    screen: 'profileSetting',
  });
  const [naijaStates, updateNaijaStates] = useState([]);

  return (
    <ViewerProvider>
      {({ user, businessInfo }) => (
        <section className={'container'}>
          <Card title={'Edit Full Name'} subtitle={'Your Name'}>
            <FormGroup classes="two-grid">
              <Input
                label="First Name"
                classes="border-bottom"
                placeholder="First Name"
                name="firstName"
                readOnly
                value={user.firstName}
              />
              <Input
                label="Last Name"
                classes="border-bottom"
                placeholder="Last Name"
                name="lastName"
                readOnly
                value={user.lastName}
              />
            </FormGroup>
          </Card>

          <Card title={'Edit Full Name'} subtitle={'Your Name'}>
            <FormGroup classes="two-grid">
              <Input
                label="Marital Status"
                classes="border-bottom"
                placeholder="Marital Statue"
                name="maritalStatus"
                readOnly
                value={user.firstName}
              />
              <Input
                label="Last Name"
                classes="border-bottom"
                placeholder="Last Name"
                name="lastName"
                readOnly
                value={user.lastName}
              />
            </FormGroup>
          </Card>

          <Card
            title={'Email Address'}
            subtitle={
              'We send notifications and other account updates to your confirmed email address.'
            }
          >
            <FormGroup classes="two-grid">
              <Input
                label="Email address"
                classes="border-bottom"
                placeholder="Email Address"
                name="email"
                readOnly
                value={user.email}
              />
            </FormGroup>
          </Card>

          <Card
            title={'Phone Number'}
            subtitle={'We send sms verification messages to your phone number.'}
          >
            <FormGroup classes="two-grid">
              <Input
                label="Phone Number"
                classes="border-bottom"
                placeholder="Phone Number"
                name="phone"
                phoneField
                readOnly
                defaultValue={user.phone}
              />
            </FormGroup>
          </Card>

          <DisplayBvn bvnStatus={user?.bvnStatus} />

          <Card title={'Personal Tax ID No'} subtitle={'Personal Tax ID No'}>
            <FormGroup classes="two-grid">
              <Input
                label="Personal Tax ID No"
                classes="border-bottom"
                placeholder="e.g. N-123456"
                name="personalTin"
                readOnly
                defaultValue={businessInfo.taxIdNo}
              />
            </FormGroup>
          </Card>

          <Card
            title={'Education Level'}
            subtitle={'What is your highest educational level'}
          >
            <FormGroup classes="two-grid">
              <Input
                label="Education Level"
                classes="border-bottom"
                placeholder="Education Level"
                name="educationLevel"
                readOnly
                defaultValue={businessInfo.highestEducationalLevel}
              />
            </FormGroup>
          </Card>

          <Card
            title={'Has Registered Business?'}
            subtitle={'Do you have a registered business?'}
          >
            <RadioGroup classes="two-grid">
              <Radio
                label="Yes"
                name="hasBusiness"
                value="true"
                readOnly
                checked={businessInfo.hasBusiness.toLowerCase() === 'yes'}
              />
              <Radio
                label="No"
                name="hasBusiness"
                value="false"
                readOnly
                checked={businessInfo.hasBusiness.toLowerCase() === 'no'}
              />
            </RadioGroup>
          </Card>

          <Card
            title={'Cooperative Membership?'}
            subtitle={'Do you belong to a corporative society?'}
          >
            <RadioGroup classes="two-grid">
              <Radio
                label="Yes"
                name="cooperativeMember"
                value="true"
                readOnly
                checked={
                  businessInfo.cooperativeMember.toLowerCase() === 'true'
                }
              />
              <Radio
                label="No"
                name="cooperativeMember"
                value="false"
                readOnly
                checked={
                  businessInfo.cooperativeMember.toLowerCase() === 'false'
                }
              />
            </RadioGroup>
          </Card>

          {businessInfo.cooperativeMember.toLowerCase() === 'false' && (
            <Card
              title={'Willing To Join Cooperative?'}
              subtitle={
                'Are you willing to join a Business Cluster or Cooperative?'
              }
            >
              <RadioGroup classes="two-grid">
                <Radio
                  label="Yes"
                  name="willingToJoinCooperative"
                  value="true"
                  readOnly
                  checked={
                    businessInfo.willingToJoinCooperative.toLowerCase() ===
                    'true'
                  }
                />
                <Radio
                  label="No"
                  name="willingToJoinCooperative"
                  value="false"
                  readOnly
                  checked={
                    businessInfo.willingToJoinCooperative.toLowerCase() ===
                    'false'
                  }
                />
              </RadioGroup>
            </Card>
          )}

          {businessInfo.cooperativeMember.toLowerCase() === 'false' && (
            <Card
              title={'Cooperative Details'}
              subtitle={'Enter your Cooperative Membership Detail'}
            >
              <FormGroup classes="two-grid">
                <Input
                  label="Cooperative or Association Name"
                  classes="border-bottom"
                  placeholder="Cooperative or Association Name"
                  name="corporativeName"
                  readOnly
                  defaultValue={businessInfo.cooperativeName}
                />
                <Input
                  label="How long have you been a Member?"
                  classes="border-bottom"
                  placeholder="How long have you been a Member?"
                  name="durationInAssociation"
                  readOnly
                  defaultValue={
                    businessInfo.durationInCooperative &&
                    moment(
                      businessInfo.durationInCooperative,
                      'DD/MM/YYYY',
                    ).fromNow(Boolean)
                  }
                />
              </FormGroup>

              <FormGroup>
                <Input
                  label="Cooperative or Association Address"
                  classes="border-bottom"
                  placeholder="Cooperative or Association Address"
                  name="corporativeName"
                  readOnly
                  defaultValue={businessInfo.cooperativeAddress}
                />
              </FormGroup>
            </Card>
          )}
          <UpdateUserDetailsProvider user={user} formParams={formParams}>
            {({ form, onSubmit, loading, errorMessage, successMessage }) => {
              let employmentStatus = '';

              const handleRadioChange = (e) => {
                const { value, name } = e.target;

                updateFormParams((prevState) => ({
                  ...prevState,
                  [name]: value,
                }));

                employmentStatus = value;
              };

              return (
                <Fragment>
                  <Card
                    title={'Residential Address'}
                    subtitle={'Where do you live?'}
                  >
                    <FormGroup classes="two-grid">
                      <Input
                        label="Residential Address"
                        classes="border-bottom"
                        readOnly
                        value={user.metadata.address}
                        name="address"
                      />
                      <Input
                        label="Senatorial District"
                        name="senatorialDistrict"
                        classes="border-bottom"
                        readOnly
                        defaultValue={businessInfo.senatorialDistrict}
                      />
                    </FormGroup>

                    <FormGroup classes="two-grid">
                      <Input
                        label="Local Govt Area"
                        name="lga"
                        classes="border-bottom"
                        readOnly
                        value={user.metadata.localGovernment}
                      />
                      <Input
                        label="LCDA"
                        name="lcda"
                        classes="border-bottom"
                        readOnly
                        defaultValue={businessInfo.lcda}
                      />
                    </FormGroup>

                    <FormGroup classes="two-grid">
                      <Input
                        label="Type of Residence"
                        name="residenceType"
                        classes="border-bottom"
                        readOnly
                        value={businessInfo.residenceType}
                      />
                      <Input
                        label="When did you start living there"
                        name="durationInResidence"
                        classes="border-bottom"
                        readOnly
                        defaultValue={moment(user.metadata.dateMovedIn).format(
                          'll',
                        )}
                      />
                    </FormGroup>
                  </Card>

                  <Card>
                    {errorMessage && (
                      <Alert classes="error">{errorMessage}</Alert>
                    )}
                    {successMessage && (
                      <Alert classes="success">{successMessage}</Alert>
                    )}
                    {loading ? (
                      <Loader />
                    ) : (
                      <Button inactive click_event={onSubmit}>
                        Update Profile
                      </Button>
                    )}
                  </Card>
                </Fragment>
              );
            }}
          </UpdateUserDetailsProvider>
        </section>
      )}
    </ViewerProvider>
  );
};

export default ProfileSettings;
