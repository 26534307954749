import React from 'react';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { TRIGGER_PASSWORD_RESET_MUTATION } from '././queries';
import { useFormInput, useFlashMessage } from '../../containers/Form';
import {getGlobalClientId} from "../../lib/utils";

const TriggerResetPwdProvider = ({ children, history }) => {
  const inputs = {
    email: useFormInput()
  };

  const [ errorMessage, setErrorMessage ] = useFlashMessage();
  const [ successMessage ] = useFlashMessage();

  const handleTriggerResetPwd = (e, runMutation) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');
    inputs.email.setErrorMessage('');

    if (inputs.email.value) {
      localStorage.setItem('resetPasswordEmail', inputs.email.value);

      runMutation({
        variables: {
          input: {
            email: inputs.email.value,
            clientId: getGlobalClientId()
          }
        }
      });
    } else {
      if (!inputs.email.value) inputs.email.setErrorMessage('Email Address is required');
    }
  };

  return (
    <Mutation
      mutation={TRIGGER_PASSWORD_RESET_MUTATION}
      onCompleted={async ({ triggerPasswordReset: { ok } }) => {
        if (ok) history.push('/reset-password-confirmation');
      }}
      onError={(error) => {
        if (error.message) history.push('/reset-password-confirmation');
      }}
    >
      {(runMutation, { error, loading }) =>
        children({
          onSubmit: (e) => handleTriggerResetPwd(e, runMutation),
          loading,
          error,
          errorMessage,
          successMessage,
          form: inputs
        })}
    </Mutation>
  );
};

export default withRouter(TriggerResetPwdProvider);
