import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';
import Context from '../../context';
import { hexToRGB } from '../../lib/utils';

import './_Loader.scss';

const Loader = (props) => {
  const client = useApolloClient();
  const { state } = useContext(Context);
  const clientTheme = state && state.clientInfo ? state.clientInfo.clientTheme : { primaryColor: '#000000' };

  const dynamicStyle = {
    background: hexToRGB(clientTheme.primaryColor, 0.6)
  };

  return (
    <div style={dynamicStyle} className={`loader ${props.classes == null ? '' : props.classes}`}>
      {props.no_spin ? (
        ''
      ) : (
        <div className="loader__content">
          <span className="loader__content-text">{props.content}</span>
        </div>
      )}
    </div>
  );
};

Loader.propTypes = {
  content: PropTypes.string,
  no_spin: PropTypes.bool
};

export default Loader;
