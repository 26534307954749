import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { BackArrow as BackArrowIcon } from '../Icon/';

import './_BackArrow.scss';

const BackArrow = (props) => {
  return (
    <div
      className={cx({
        'back-arrow': props
      })}
      onClick={props.onClick}
    >
      <BackArrowIcon {...props} /> <span>{props.text}</span>
    </div>
  );
};

BackArrow.propTypes = {
  classes: PropTypes.string
};

export default BackArrow;
