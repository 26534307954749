import React from 'react';
import PropTypes from 'prop-types';
import { DocumentIcon } from '../Icon';
import './_DocumentBox.scss';
import { CdnFileProvider } from '../../providers';
import { Button } from '..';

const DocumentBox = ({ document }) => (
  <section className="document-box">
    <div className="icon">
      <DocumentIcon />
    </div>

    <div className="doc-name">
      {document ? document.documentName : 'Document'}
    </div>

    <CdnFileProvider file={document.file}>
      {({ openFile }) => (
        <Button
          secondary
          className="doc-link small"
          click_event={openFile}
        >
          View Offer Letter
        </Button>
      )}
    </CdnFileProvider>
  </section>
);

DocumentBox.propTypes = {
  documentName: PropTypes.string,
  documentLink: PropTypes.string,
};

export default DocumentBox;
