export const formSteps = [
  // Please and pls and pls, don't change id for any reason.
  {
    id: 'beforeYouBegin',
    formInfo: {
      title: 'Before you begin',
      subTitle: 'Kindly confirm the following:'
    },
    title: 'Loan Requirement'
  },
  {
    id: 'personalDetails',
    formInfo: {
      title: 'Personal Details',
      subTitle: 'Let’s get you started. Please enter the information below to know you.'
    },
    title: 'Personal Information'
  },
  {
    id: 'otpVerification',
    formInfo: {
      title: 'Verify Phone Number',
      subTitle: 'We sent a verification code to <b>********5515</b>. Please enter the 6-digit OTP below.'
    },
    title: 'Verification'
  },
  {
    id: 'emailVerification',
    formInfo: {
      title: 'Verify Personal Email',
      subTitle: 'We sent you a verification code to xxxx@email.com. Please enter the 6-digit OTP below.'
    },
    title: 'Verify Personal Email'
  },
  {
    id: 'bvnVerification',
    formInfo: {
      title: 'BVN Phone Verification',
      subTitle: 'Please enter the last 4 digits of the phone number attached to your BVN'
    },
    title: 'BVN Phone Verification'
  },
  {
    id: 'socialMediaDetails',
    formInfo: {
      title: 'Connect to Social Media',
      subTitle:
        'To continue with your loan application, kindly provide the profile links for the requested social media accounts.'
    },
    title: 'Employment'
  },
  {
    id: 'loanSelection',
    formInfo: {
      title: 'Select a Loan Product',
      subTitle: 'Please select a loan product below to continue your application'
    },
    title: 'Select a Loan Product'
  },
  {
    id: 'businessInformation',
    formInfo: {
      title: 'Business Information',
      subTitle: 'Please enter your business details below.'
    },
    title: 'Business Information'
  },
  {
    id: 'supportingDocuments',
    formInfo: {
      title: 'Supporting Documents',
      subTitle:
        'Please upload all required documents to continue your application. Only PNG, JPG, JPEG, PDF files are allowed (maximum file size is 10MB)'
    },
    title: 'Supporting Documents'
  },
  {
    id: 'loanInformation',
    formInfo: {
      title: 'Loan Information',
      subTitle: 'To get started, tell us how much you need and how long you fully intend to pay back'
    },
    title: 'Loan Information'
  },
  {
    id: 'addDebitCard',
    formInfo: {
      title: 'Add Debit Card',
      subTitle:
        'Please add the card that is linked to your main income account to improve your chances of getting a loan.'
    },
    title: 'Add Debit Card'
  },
  {
    id: 'okraPage',
    formInfo: {
      title: 'Bank Statement',
      subTitle: `As part of your loan application, we connect to Okra to fetch your
        bank statement. This improves your chances in getting a loan. We value
        security therefore transfer of your information is encrypted and your
        login credentials will never be made accessible to us by Okra.`
    },
    title: 'Bank Statement'
  },
  {
    id: 'bankStatement',
    formInfo: {
      title: 'Enter your Bank Account Details',
      subTitle: 'Enter your bank account number so we can request for your  bank statement'
    },
    title: 'Bank Statement'
  },
  {
    id: 'mbsInstructions',
    formInfo: {
      title: 'Please follow the instructions below to retrieve your bank statement',
      subTitle: 'Your bank may charge you separately for this statement'
    },
    title: 'MBS Instructions'
  },
  {
    id: 'ticketNumber',
    formInfo: {
      title: 'Ticket Number',
      subTitle: 'Enter your ticket number and OTP sent by your bank to your email'
    },
    title: 'Ticket Number'
  },
  {
    id: 'mbsUpload',
    formInfo: {
      title: 'Bank Statement',
      subTitle: ''
    },
    title: 'Bank Statement'
  },
  {
    id: 'confirmRequest',
    formInfo: {
      title: 'Confirm your loan request',
      subTitle: ''
    },
    title: 'Confirm your request'
  },
  {
    id: 'loanConfirmation',
    formInfo: {
      title: '',
      subTitle: ''
    },
    title: 'Loan Confirmation'
  }
];

export const excludedStepIds = [
  'otpVerification',
  'emailVerification',
  'bvnVerification',
  'loanSelection',
  'loanRequest',
  'addDebitCard',
  'bankStatement',
  'mbsUpload',
  'ticketNumber',
  'loanConfirmation',
  'refereeDetails',
  'mbsInstructions'
];

export const removeProgressBarOn = [ 'loanConfirmation' ];
export const removeProgressBoxOn = [ 'loanConfirmation' ];

export const noop = () => {
  return () => {};
};

export const loanRequest = [
  {
    id: '7syd7sdt7d',
    text: 'Request Amount',
    value: '15000000.23',
    repayment: '450000.23',
    interest: '20%',
    loan_tenor: '13 Months'
  }
];

export const maxLoanableAmount = 500000000;

export const defaultRepaymentInfo = {
  title: 'Monthly Repayment',
  amount: 'NGN 200,000',
  info:
    'The offer above is an initial estimate based on the information you have provided. The final offer will be determined by the financial profile of your facility.'
};

export const repaymentBreakdown = [
  { date: '12 May, 2020', principal: '10500.22', interest: '500.12', totalPayment: '11000.34' },
  { date: '15 May, 2020', principal: '22500.22', interest: '1200.12', totalPayment: '23700.34' },
  { date: 'Total', principal: '435400.44', interest: '5440.62', totalPayment: '93884.43' }
];

export const repaymentBreakdownHeader = [ 'Repayment Date', 'Principal', 'Interest', 'Total Payment' ];

export const loanDurationType = 'MONTHLY';
export const loanDurationList = [ { name: '1 Month', value: 1 }, { name: '2 Months', value: 2 } ];

export const loanRequestReasons = [ { name: 'Personal' }, { name: 'Bill' } ];

export const inputOptionTypes = [ 'text', 'tel', 'number', 'email', 'select-one', 'password' ];

export const loanProductCategories = [
  {
    title: 'Micro Enterprise - Start up',
    rules: [
      'Maximum loan amount N250,000',
      'Up to 12 months tenor',
      'Requires training certificate from a registered TVET institute',
      'Requires one guarantor'
    ],
    value: 'MES'
  },
  {
    title: 'Micro Enterprise',
    rules: [
      'Maximum loan amount N500,000',
      'Maximum 12 months tenor',
      'Requires membership of Cooperative Association',
      'Requires one guarantor'
    ],
    value: 'ME'
  },
  // Commented out the the SME loan selection option
  // {
  //   title: 'Small & Medium Enterprise',
  //   rules: [
  //     'Maximum Loan amount N5,000,000',
  //     'Maximum 36 months tenor',
  //     'Must have a registered business with CAC, MEMART and TIN number',
  //     'Requires two guarantors'
  //   ],
  //   value: 'SME'
  // }
];

export const lgas = [
  'ajeromi-ifelodun',
  'badagry',
  'ojo',
  'oshodi-isolo',
  'kosofe',
  'ikorodu',
  'alimosho',
  'lagos island',
  'eti-osa',
  'apapa',
  'mushin',
  'ikeja',
  'surulere',
  'ibeju-Lekki',
  'epe',
  'amuwo-odofin',
  'ifako-ijaye',
  'shomolu',
  'agege'
];
