module.exports = {
  status: {
    ERROR: 'error',
    SUCCESS: 'success',
    INFO: 'info',
  },
  duration: {
    SHORT: 4000,
    LONG: 7000,
  },
  validation: {
    PHONE_LENGTH: 11,
    BVN_LENGTH: 11,
    ACCOUNT_NUMBER_LENGTH: 10,
    EMAIL_TYPE: "Invalid email. Example Email 'johndoe@email.com'",
    code: 'please enter code sent to your phone',
  },
  emitted_events: {
    RAVE_3D_SUCCESS: 'Rave-3DSecure-Response',
    RAVE_3D_FAILURE: 'Rave-3DSecure-Failure',
    RAVE_3D_ERROR: 'Rave-3DSecure-Error',
  },
  rave: {
    PIN: 'PIN',
    SECURE_3D: 'VBVSECURECODE',
  },
  facebook: {
    APP_ID: process.env.FACEBOOK_APP_ID,
  },
  authTypes: {
    TEMPORARY: 'TEMPORARY',
    FIXED: 'FIXED',
  },
  nigeriaStates: [
    'Abia',
    'Adamawa',
    'Anambra',
    'Akwa Ibom',
    'Bauchi',
    'Bayelsa',
    'Benue',
    'Borno',
    'Cross River',
    'Delta',
    'Ebonyi',
    'Enugu',
    'Edo',
    'Ekiti',
    'FCT - Abuja',
    'Gombe',
    'Imo',
    'Jigawa',
    'Kaduna',
    'Kano',
    'Katsina',
    'Kebbi',
    'Kogi',
    'Kwara',
    'Lagos',
    'Nasarawa',
    'Niger',
    'Ogun',
    'Ondo',
    'Osun',
    'Oyo',
    'Plateau',
    'Rivers',
    'Sokoto',
    'Taraba',
    'Yobe',
    'Zamfara',
  ],
  transactType: { transactionType: 'ADD_CARD' },
  CLIENT_ID: 'clientId',
  APPLICATION_CLOSED: false,
};
