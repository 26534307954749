import React from 'react';
import PropTypes from 'prop-types';
import './_Input.scss';
import NumberFormat from 'react-number-format';

const Input = (props) => {
  return (
    <label className={`input__group ${props.amountField || props.phoneField ? 'has-append' : ''} ${props.hasCheckmark ? 'has-check-mark' : ''}
    `}>
      {props.label == null ? (
        ''
      ) : (
        <span className={'input__label'}>
          {props.label}
          {props.required ? <span className="input__label-required">*</span> : ''}
        </span>
      )}

      <div className="input-holder">
        {props.amountField && <span className="append">NGN</span>}
        {props.phoneField && <span className="append">+234</span>}

        {props.amountField || props.numberField || props.phoneField ? (
          <NumberFormat
            thousandSeparator={props.amountField}
            allowLeadingZeros={props.numberField || props.phoneField}
            type="tel"
            allowNegative={props.allowNegative ? props.allowNegative : false}
            autoComplete={props.autoComplete}
            autoFocus={props.autoFocus}
            className={`input ${props.classes == null ? '' : props.classes}`}
            defaultValue={props.defaultValue}
            disabled={props.disabled ? 'disabled' : ''}
            min={props.min}
            max={props.max}
            minLength={props.minlength}
            maxLength={props.maxlength}
            name={props.name}
            onBlur={props.onBlur}
            onKeyUp={props.onKeyUp}
            onKeyDown={props.onKeyDown}
            onKeyPress={props.onKeyPress}
            onChange={props.onChange}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            value={props.value}
            required={props.required}
          />
        ) : (
          <input
            autoComplete={props.autoComplete}
            autoFocus={props.autoFocus}
            className={`input ${props.classes == null ? '' : props.classes}`}
            defaultValue={props.defaultValue}
            disabled={props.disabled ? 'disabled' : ''}
            min={props.min}
            max={props.max}
            minLength={props.minlength}
            maxLength={props.maxlength}
            name={props.name}
            onBlur={props.onBlur}
            onKeyUp={props.onKeyUp}
            onKeyDown={props.onKeyDown}
            onKeyPress={props.onKeyPress}
            onChange={props.onChange}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            type={props.type == null ? 'text' : props.type}
            value={props.value}
          />
        )}

        {props.hasCheckmark &&
          <span className="checkmark">
            <CheckIcon />
          </span>
        }
      </div>
      {props.errorMessage && <span className={'input__error'}>{props.errorMessage}</span>}
    </label>
  );
};

Input.propTypes = {
  amountField: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  numberField: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  classes: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  min: PropTypes.number,
  max: PropTypes.number,
  minlength: PropTypes.number,
  maxlength: PropTypes.number,
  phoneField: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  type: PropTypes.string,
  allowNegative: PropTypes.bool,
  value: PropTypes.any
};

export default Input;

function CheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px" height="20px" fill="#9DD858">
      <path d="M256 512a256 256 0 100-512 256 256 0 100 512zm113-303L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
    </svg>
  );
}
