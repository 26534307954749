import React from 'react';
import { DataCard, Button } from "../../components";
import {Link} from "react-router-dom";

import './_Style.scss';

const index = () => {
    return(
        <section className="container sign-in">
            <DataCard title="Password Reset Complete">
                <h5 className={'center-text'}>Your password has been reset successfully. You can now sign in by clicking the button below</h5>
                <Button className="back-to-login"><Link to="/sign-in">Sign In</Link></Button>
            </DataCard>
        </section>
    )
};

export default index;