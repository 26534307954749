import React, { Fragment, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import NaijaStates from 'naija-xbystate';
import { AuthService } from '../../../../services';
import {
  DataCardV2,
  BackArrow,
  Button,
  FormGroup,
  Input,
  RadioGroup,
  Radio,
  Loader,
  Alert,
  Select
} from '../../../../components';
import { convertDate, getErrorMessages, authGetter, removeCommas, maxSelectableDate, capitalizeInitialLetter } from '../../../../lib/utils';
import { lgas } from '../../constants';
import './_Styles.scss';
import Notifications, { notify } from 'react-notify-toast';
import { check } from 'prettier';

const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  updatePBStepCount,
  formParams,
  updateFormParams,
  baseInputChangeHandler,
  userData,
  setUserData,
  updateContextState
}) => {
  updatePBStepCount(stepIndex + 1);

  const [ isLoading, setLoadingState ] = useState(false);
  const [ naijaLgas, setNaijaLgas ] = useState([]);
  const [ isSubmitted, setSubmissionState ] = useState(false);
  const [ inputErrors, setInputErrors ] = useState([]);
  const [ responseError, setResponseError ] = useState([]);
  const [ companyStartDate, setCompanyStartDate ] = useState('');
  const [ loanProductCategory, setLoanProduct ] = useState('');
  const [ monthlyTurnover, updateMonthlyTurnover ] = useState(null);
  const [ monthlyExpenses, updateMonthlyExpenses ] = useState(null);
  const [ monthlySavings, updateMonthlySavings ] = useState(null);
  const { title, subTitle } = formInfo;
  const { next, previous } = navigation;
  const {
    companyName,
    companyAddress,
    typeOfBusiness,
    companyLga,
    cacRegNo,
    companyTaxId,
    businessStartDate,
    businessSector,
    noOfEmployees,
    employees,
    directors,
    hasBusiness,
  } = formParams;
  let myColor = { background: '#0E1717', text: '#FFFFFF' };
  let requiredFormFields = [
    ...(hasBusiness === 'Yes'
      ? [
          'companyName',
          'companyAddress',
          'typeOfBusiness',
          'companyLga',
          'businessStartDate',
          'businessSector',
          'monthlyTurnover',
          'monthlyExpenses',
          'monthlySavings',
          'noOfEmployees'
        ]
      : [
          'companyName',
          'companyAddress',
          'companyLga',
          'businessSector',
          'monthlyTurnover',
          'monthlyExpenses',
          'monthlySavings',
          'noOfEmployees'
        ]),
    ...(loanProductCategory === 'SME' ? [ 'cacRegNo', 'companyTaxId', 'employees', 'directors' ] : [])
  ];
  const businessClassifications = [
    'Agriculture',
    'Entertainment',
    'Manufacturing',
    'Trading',
    'Services',
    'ICT',
    'Consulting',
    'Fashion & Beauty',
    'Food Processing',
    'Hotel & Leisure',
    'Transportation',
    'Health Services',
    'Education',
    'E-Commerce'
  ];
  const businessTypes = [ 'Enterprise', 'Limited Liability Company', 'Sole Proprietorship' ];

  const getPreviouslySavedData = async () => {
  
    const authentication = authGetter();

    setLoadingState(true);
    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);
    setLoadingState(false);

    const response = viewer.data.data.viewer;
    if (response) {
      setUserData(response);
      checkLoanProduct(response);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
    setNaijaLgas(lgas.sort());
  }, []);

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case 'companyName':
        if (value.length === 0 || fieldName.length < 2) {
          setInputErrors(prevState => ({
            ...prevState,
            companyName: 'Company Name must be at least 2 characters long.'
          }));
        } else {
          setInputErrors(prevState => ({ ...prevState, companyName: '' }));
        }
        break;
      case 'companyAddress':
        if (value.length === 0) {
          setInputErrors(prevState => ({ ...prevState, companyAddress: 'Company address is required.' }));
        } else {
          setInputErrors(prevState => ({ ...prevState, companyAddress: '' }));
        }
        break;
      case 'companyLga':
      case 'businessStartDate':
      case 'businessSector':
      case 'typeOfBusiness':
      case 'cacRegNo':
      case 'companyTaxId':
        if (!value) {
          setInputErrors(prevState => ({ ...prevState, [fieldName]: 'This field is required.' }));
        } else {
          setInputErrors(prevState => ({ ...prevState, [fieldName]: '' }));
        }
        break;
      case 'monthlyTurnover':
        if (!value || value.length === 0 || value < 0) {
          setInputErrors((prevState) => ({ ...prevState, monthlyTurnover: 'Kindly enter a value.' }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, monthlyTurnover: '' }));
        }
        break;
      case 'monthlyExpenses':
        if (!value || value.length === 0 || value < 0) {
          setInputErrors((prevState) => ({ ...prevState, monthlyExpenses: 'Kindly enter a value.' }));
        } else if (value > monthlyTurnover) {
          setInputErrors((prevState) => ({ ...prevState, monthlyExpenses: 'Monthly Expense can not be greater than Monthly Turnover' }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, monthlyExpenses: '' }));
        }
        break;
      case 'monthlySavings':
        if (!value) {
          setInputErrors((prevState) => ({ ...prevState, monthlySavings: 'Kindly enter a value.' }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, monthlySavings: '' }));
        }
        break;
      case 'noOfEmployees':
        if (!value || value < 1) {
          setInputErrors(prevState => ({ ...prevState, [fieldName]: 'Kindly enter the no of people you employ' }));
        } else {
          setInputErrors(prevState => ({ ...prevState, [fieldName]: '' }));
        }
        break;
      case 'employees':
        const employeeErrors = value.filter(employee => !employee.employeeName);
        if (employeeErrors.length) {
          const errors = value.map(employee => {
            let errorObject = { employeeName: '', employeeTaxId: '' };
            Object.entries(employee).forEach(([ key, value ]) => {
              if (key === 'employeeName' && !value.length) {
                errorObject.employeeName = 'Employee name is required.';
              }
            });
            return errorObject;
          });
          setInputErrors(prevState => ({ ...prevState, [fieldName]: errors }));
        } else {
          setInputErrors(prevState => ({ ...prevState, [fieldName]: '' }));
        }
        break;
      case 'directors':
        const directorErrors = value.filter(director => !director.directorName || !director.directorTaxId);
        if (directorErrors.length) {
          const errors = value.map(director => {
            let errorObject = { directorName: '', directorTaxId: '' };
            Object.entries(director).forEach(([ key, value ]) => {
              if (key === 'directorName' && !value.length) {
                errorObject.directorName = "Director's name is required.";
              } else if (!value.length) {
                errorObject.directorTaxId = "Director's tax id is required.";
              }
            });
            return errorObject;
          });
          setInputErrors(prevState => ({ ...prevState, [fieldName]: errors }));
        } else {
          setInputErrors(prevState => ({ ...prevState, [fieldName]: '' }));
        }
        break;
        default:
          break;
    }
  };

  const checkValidation = () => {
    requiredFormFields.map(item => {
      validateForm(item, formParams[item]);
    });
    validateForm('monthlyTurnover', monthlyTurnover);
    validateForm('monthlySavings', monthlySavings);
    validateForm('monthlyExpenses', monthlyExpenses)
  };

  const handleMonthlyTurnoverInput = e => {
    const formatMonthlyTurnover = +(removeCommas(e.target.value));
    updateMonthlyTurnover(formatMonthlyTurnover);
    updateMonthlySavings(formatMonthlyTurnover - monthlyExpenses);
  };

  const handleMonthlyExpensesInput = e => {
    const formatMonthlyExpenses = +(removeCommas(e.target.value));
    updateMonthlyExpenses(formatMonthlyExpenses);
    updateMonthlySavings((monthlyTurnover) - (formatMonthlyExpenses));
  };

  useEffect(
    () => {
      checkValidation();
    },
    [ formParams, isSubmitted, monthlyTurnover, monthlySavings, monthlyExpenses ]
  );

  const setDate = date => {
    setCompanyStartDate(date);
    updateFormParams(prevState => ({
      ...prevState,
      businessStartDate: convertDate(date)
    }));
  };

  const checkLoanProduct = response => {
    const businessInformation = response.account.accountAttribute.find(
      account => account.attribute.name === 'businessInformation'
    ).data;

    setLoanProduct(businessInformation.loanProduct);
  };

  const addEmployee = () => {
    updateFormParams(prevState => ({
      ...prevState,
      employees: [ ...employees, { employeeName: '', employeeTaxId: '' } ]
    }));
  };

  const addDirector = () => {
    updateFormParams(prevState => ({
      ...prevState,
      directors: [ ...directors, { directorName: '', directorTaxId: '' } ]
    }));
  };

  const removeField = e => {
    const name = `${e.target.id.split('_')[0]}s`;
    const index = Number(e.target.id.split('_')[1]);

    const updatedEntry = name === 'employees' ? [ ...employees ] : [ ...directors ];
    updatedEntry.splice(index, 1);
    updateFormParams(prevState => ({
      ...prevState,
      [name]: updatedEntry
    }));
  };

  const updateEmployeeInput = (index, e) => {
    const name = e.target.name.split('_')[0];
    const updatedData = name.includes('director') ? [ ...directors ] : [ ...employees ];
    updatedData[index][name] = e.target.value ? e.target.value : '';
    const key = name.includes('director') ? 'directors' : 'employees';
    updateFormParams(prevState => ({
      ...prevState,
      [key]: updatedData
    }));
  };

  const addBusiness = async () => {
    setLoadingState(true);

    const businessInfo = userData.account.accountAttribute.find(attr => attr.attribute.name === 'businessInformation')
      .data;

    const businessInformation = {
      ...businessInfo,
      nextStage: 'supportingDocuments',
      companyName,
      companyAddress,
      typeOfBusiness,
      companyLga,
      cacRegNo,
      companyTaxId,
      businessStartDate,
      businessSector,
      monthlyTurnover: removeCommas(monthlyTurnover),
      monthlyExpenses: removeCommas(monthlyExpenses),
      monthlySavings: removeCommas(monthlySavings),
      noOfEmployees,
      employees,
      ...(loanProductCategory === 'SME' ? { directors } : {})
    };
    const response = await AuthService.updateUserDataV2({ businessInformation });
    setLoadingState(false);

    let error = 'Error adding business information to your account. Please, try again later.';
    if (!response) {
      window.scroll(0, 0);
      setResponseError({ addBusiness: error });
      return;
    }

    if (response.data.errors) {
      window.scroll(0, 0);
      error = getErrorMessages(response.data.errors);
      notify.show(error, 'error', 5000, myColor);
      return;
    }

    if (!response.data || !response.data.data || !response.data.data.updateUserData) {
      window.scroll(0, 0);
      setResponseError({ addBusiness: error });
      return;
    }

    const { success } = response.data.data.updateUserData;

    if (success) {
      updateContextState({ page: 'supportingDocuments' });
      next();
    }
  };

  const nextStep = () => {
    setSubmissionState(true);
    updateFormParams((prevState) => ({
      ...prevState,
      monthlyTurnover: monthlyTurnover,
      monthlyExpenses: monthlyExpenses,
      monthlySavings: monthlySavings,
    }));
    const errors = Object.keys(inputErrors).filter(item => inputErrors[item] !== '');
    if (!errors.length) {
      addBusiness();
    } else {
      window.scroll(0, 100);
    }
  };

  return (
    <Fragment>
      {isLoading && <Loader />}

      <DataCardV2 title={title} subTitle={subTitle} centeralize hasPadding>
        <Notifications />
        {responseError &&
        responseError.length !== 0 && (
          <Fragment>
            {responseError.addBusiness ? <Alert classes='margin-top error'>{responseError.addBusiness}</Alert> : ''}
          </Fragment>
        )}
        <div className='form_content personal_details'>
          <section className=''>
            <p className='section-title'>Please enter your Business Information</p>

            <FormGroup classes='one-grid'>
              <Input
                required
                type='text'
                placeholder='Company Name'
                label='Company Name'
                name='companyName'
                value={companyName}
                onChange={baseInputChangeHandler}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.companyName ? inputErrors.companyName : ''}
              />
            </FormGroup>

            <FormGroup classes='two-grid'>
              <Input
                required
                type='text'
                placeholder='Company Address'
                label='Company Address'
                name='companyAddress'
                value={companyAddress}
                onChange={baseInputChangeHandler}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.companyAddress ? inputErrors.companyAddress : ''}
              />

              <Select
                name='companyLga'
                label='Local Govt Area'
                placeholder='Local Govt Area'
                classes='border-bottom reduced'
                autoComplete='companyLga'
                onChange={baseInputChangeHandler}
                defaultValue={companyLga}
                errorMessage={isSubmitted && inputErrors.companyLga ? inputErrors.companyLga : ''}
                required
              >
                <option disabled value=''>
                  -- Select --
                </option>
                {naijaLgas && naijaLgas.map((value, idx) => <option key={`${value}_${idx}`} value={`${value}`}>{capitalizeInitialLetter(value)}</option>)}
              </Select>
            </FormGroup>
            {hasBusiness === 'Yes' && (
              <>
              <FormGroup classes='two-grid'>
                <Select
                  name='typeOfBusiness'
                  label='What type of business is it'
                  classes='border-bottom reduced'
                  autoComplete='typeOfBusiness'
                  onChange={baseInputChangeHandler}
                  defaultValue={typeOfBusiness}
                  errorMessage={isSubmitted && inputErrors.typeOfBusiness ? inputErrors.typeOfBusiness : ''}
                  required
                >
                  <option disabled value=''>
                    What type of business is it
                  </option>
                  {businessTypes && businessTypes.map((value, idx) => <option key={`${value}_${idx}`}>{value}</option>)}
                </Select>

                <Input
                  required={requiredFormFields.includes('cacRegNo')}
                  type='text'
                  placeholder='CAC Registration No'
                  label='CAC Registration No'
                  name='cacRegNo'
                  value={cacRegNo}
                  onChange={baseInputChangeHandler}
                  classes={`border-bottom`}
                  errorMessage={isSubmitted && inputErrors.cacRegNo ? inputErrors.cacRegNo : ''}
                />
              </FormGroup>
            
            <FormGroup classes='two-grid'>
              <Input
                required={requiredFormFields.includes('companyTaxId')}
                type='text'
                placeholder='Company Tax Identification Number'
                label='Company Tax Identification Number'
                name='companyTaxId'
                value={companyTaxId}
                onChange={baseInputChangeHandler}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.companyTaxId ? inputErrors.companyTaxId : ''}
              />

              <div>
                <div className='dob-input'>
                  <label htmlFor=''>
                    When did you start the business?
                    <span className='required'>*</span>
                  </label>
                  <DatePicker
                    placeholderText='(Jan/2000)'
                    name='businessStartDate'
                    selected={companyStartDate}
                    onChange={date => setDate(date)}
                    dateFormat='MMMM/yyyy'
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    maxDate={maxSelectableDate}
                  />
                </div>
                {isSubmitted &&
                inputErrors.businessStartDate && <span className='custom_error'>{inputErrors.businessStartDate}</span>}
              </div>
            </FormGroup>
            </>)}
            <FormGroup classes='two-grid'>
              <Select
                name='businessSector'
                label='What sector would you classify your business'
                classes='border-bottom reduced'
                autoComplete='businessSector'
                onChange={baseInputChangeHandler}
                defaultValue={businessSector}
                errorMessage={isSubmitted && inputErrors.businessSector ? inputErrors.businessSector : ''}
                required
              >
                <option disabled value=''>
                  What sector would you classify your business
                </option>
                {businessClassifications &&
                  businessClassifications.map((value, idx) => <option key={`${value}_${idx}`}>{value}</option>)}
              </Select>

              <Input
                required
                type='text'
                placeholder='What is your Total Monthly Turnover'
                label='What is your Total Monthly Turnover'
                name='monthlyTurnover'
                amountField
                value={monthlyTurnover}
                onChange={handleMonthlyTurnoverInput}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.monthlyTurnover ? inputErrors.monthlyTurnover : ''}
              />
            </FormGroup>

            <FormGroup classes='two-grid'>
              <Input
                required
                type='text'
                placeholder='What is your Total Monthly Expenses'
                label='What is your Total Monthly Expenses'
                name='monthlyExpenses'
                amountField
                value={monthlyExpenses}
                onChange={handleMonthlyExpensesInput}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.monthlyExpenses ? inputErrors.monthlyExpenses : ''}
              />

              <Input
                required
                type='text'
                placeholder='What is your Total Monthly Savings'
                label='What is your Total Monthly Savings'
                name='monthlySavings'
                amountField
                value={monthlySavings}
                onChange={baseInputChangeHandler}
                readOnly={true}
                allowNegative={true}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.monthlySavings ? inputErrors.monthlySavings : ''}
              />
            </FormGroup>

            <FormGroup classes='one-grid'>
              <Input
                required
                type="number"
                placeholder="How many people do you currently employ?"
                label="How many people do you currently employ?"
                name="noOfEmployees"
                value={noOfEmployees}
                onChange={baseInputChangeHandler}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.noOfEmployees ? inputErrors.noOfEmployees : ''}
              />
            </FormGroup>

            {loanProductCategory === 'SME' && (
              <div>
                <p className='section-title'>Please enter your Director Information</p>
                <p>State all company directors and provide their Personal Tax Identification No.</p>

                {directors.map((director, directorIndex) => (
                  <div className='with_delete' key={`director_${directorIndex}`}>
                    <FormGroup classes='two-grid'>
                      <Input
                        required
                        type='text'
                        placeholder='Director&#39;s Name'
                        label='Director&#39;s Name'
                        name={`directorName_${directorIndex}`}
                        value={director.directorName}
                        onChange={e => updateEmployeeInput(directorIndex, e)}
                        classes={`border-bottom`}
                        errorMessage={
                          isSubmitted && inputErrors.directors && inputErrors.directors[directorIndex] ? (
                            inputErrors.directors[directorIndex].directorName
                          ) : (
                            ''
                          )
                        }
                      />

                      <Input
                        required
                        type='text'
                        placeholder='e.g. N-123456'
                        label='Personal Tax ID No.'
                        name={`directorTaxId_${directorIndex}`}
                        value={director.directorTaxId}
                        onChange={e => updateEmployeeInput(directorIndex, e)}
                        classes={`border-bottom`}
                        errorMessage={
                          isSubmitted && inputErrors.directors && inputErrors.directors[directorIndex] ? (
                            inputErrors.directors[directorIndex].directorTaxId
                          ) : (
                            ''
                          )
                        }
                      />
                    </FormGroup>

                    {directors.length > 1 && (
                      <div className='remove_field_btn'>
                        <span id={`director_${directorIndex}`} onClick={e => removeField(e)}>
                          _
                        </span>
                      </div>
                    )}
                  </div>
                ))}

                <div className='add_field_btn'>
                  <button onClick={addDirector}>
                    <span className='add-icon'>+</span> Add Text Field
                  </button>
                </div>
              </div>
            )}

            { hasBusiness === 'Yes' && 
            <Fragment>
            <p className='section-title'>Please enter your Employee Information</p>
            <p>State all company employees and provide their Personal Tax Identification No.</p>

            {employees.map((employee, employeeIndex) => (
              <div className='with_delete' key={`employee_${employeeIndex}`}>
                <FormGroup classes='two-grid'>
                  <Input
                    required
                    type='text'
                    placeholder='Employee Name'
                    label='Employee Name'
                    name={`employeeName_${employeeIndex}`}
                    value={employee.employeeName}
                    onChange={e => updateEmployeeInput(employeeIndex, e)}
                    classes={`border-bottom`}
                    errorMessage={
                      isSubmitted && inputErrors.employees && inputErrors.employees[employeeIndex] ? (
                        inputErrors.employees[employeeIndex].employeeName
                      ) : (
                        ''
                      )
                    }
                  />

                  <Input
                    type='text'
                    placeholder='e.g. N-123456'
                    label='Personal Tax ID No.'
                    name={`employeeTaxId_${employeeIndex}`}
                    value={employee.employeeTaxId}
                    onChange={e => updateEmployeeInput(employeeIndex, e)}
                    classes={`border-bottom`}
                  />
                </FormGroup>

                {employees.length > 1 && (
                  <div className='remove_field_btn'>
                    <span id={`employee_${employeeIndex}`} onClick={e => removeField(e)}>
                      _
                    </span>
                  </div>
                )}
              </div>
            ))}

            <div className='add_field_btn'>
              <button onClick={addEmployee}>
                <span className='add-icon'>+</span> Add Text Field
              </button>
            </div>
            </Fragment>}
          </section>
        </div>

        <div className='space-apart'>
          <BackArrow height='24' onClick={previous} text='Back' />
          <Button click_event={nextStep}>Continue</Button>
        </div>
      </DataCardV2>
    </Fragment>
  );
};

export default Index;
