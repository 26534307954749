import React from 'react';
import { NewCheck } from '../../Icon/index';
import { Popup } from '../../index';

const OfferLetterDeclined = () => {
  return (
    <Popup classes="offer-letter">
      <div className="response-icon-holder">
        <NewCheck />
      </div>

      <h4 className={'data-card__title'}>Offer Letter Declined</h4>

      <p className="popup-text center-text">
        You have declined to sign the offer letter. Your loan has been
        cancelled.
      </p>
    </Popup>
  );
};

export default OfferLetterDeclined;
