import React from 'react'

const NewLoanIcon = props => (
    <svg viewBox={"0 0 18 18"}  fill="none" {...props}>
        <path
            d="M17.25 5.399a9.01 9.01 0 0 0-1.136-1.912.62.62 0 0 0-.978.76 7.763 7.763 0 0 1 .98 1.648c.415.95.645 1.999.645 3.105a7.734 7.734 0 0 1-2.273 5.488A7.735 7.735 0 0 1 9 16.761a7.736 7.736 0 0 1-5.488-2.273A7.735 7.735 0 0 1 1.24 9c0-2.145.867-4.082 2.273-5.488A7.734 7.734 0 0 1 9 1.239a7.8 7.8 0 0 1 1.515.147.62.62 0 0 0 .24-1.215 9 9 0 1 0 6.495 5.227zm-4.6-3.25c.379.202.738.434 1.076.694a.616.616 0 0 0 .869-.114.62.62 0 0 0-.114-.868c-.392-.301-.808-.57-1.247-.804a.62.62 0 1 0-.583 1.092z"
        />
        <path
            d="M12.286 8.262H9.738V5.714a.737.737 0 0 0-1.476 0v2.548H5.714a.737.737 0 0 0 0 1.475h2.548v2.549a.737.737 0 0 0 1.476 0V9.737h2.548a.738.738 0 0 0 0-1.475z"
        />
    </svg>
);

export default NewLoanIcon;
