import React from 'react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { split } from 'apollo-link';
import { createUploadLink } from "apollo-upload-client";
import { setContext } from 'apollo-link-context';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloClient } from "apollo-boost";
import { ApolloProvider} from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

const config = require('../config/config');

const httpLink = createUploadLink({
  uri: config.ignite.api_url,
  credentials: 'same-origin'
});

const authLink = setContext((_, { headers }) => {
  const _headers = {
    ...headers,
    'client-name': 'OriginateCP [web]',
    'client-version': '1.0.0',
  };

  const authCreds = JSON.parse(localStorage.getItem('Auth'));
  if (authCreds != null && typeof authCreds !== 'undefined') {
    _headers['Authorization'] = `Bearer ${authCreds.apiKey}`;
  }

  return {
    headers: _headers,
  }
});


const link = split(
  ({ query }) => {
    const { kind} = getMainDefinition(query);
    return (
      kind === 'OperationDefinition' 
    );
  },
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),  
  connectToDevTools: true,
});

const ApolloWrapper = ({ children }) => {
    return(
        <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
                {children}
            </ApolloHooksProvider>
        </ApolloProvider>
    )
};

export default ApolloWrapper;