import React from 'react'
import {ClientInformationProvider} from '../../providers';

const Logo = () => (

	<ClientInformationProvider>{
		({logoUrl}) =>
			<img alt="Logo" src={logoUrl} />
	}
	</ClientInformationProvider>
);

export default Logo;
