import React, { Fragment, useState, useEffect } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import { DataCardV2, Button, Input, FormGroup, PopupV2, Alert, Loader } from '../../../../components';
import { Close } from '../../../../components/Icon';
import { AuthService } from '../../../../services';
import { getErrorMessages, authGetter, validateEmail } from '../../../../lib/utils';


const Index = ({
  stepIndex,
  navigation,
  formInfo = {},
  updatePBStepCount,
  formParams,
  userData,
  setUserData,
  requiredSocialMedia,
  baseInputChangeHandler,
  updateContextState
}) => {
  updatePBStepCount(stepIndex + 1);

  const [showEmailVerification, switchEmailVerification] = useState(false);
  const [responseError, setResponseError] = useState([]);
  const [inputErrors, setInputErrors] = useState([]);
  const [isSubmitted, updateSubmit] = useState(false);
  const [isHeaderTextUpdated, updateHeaderText] = useState(false);
  const [isLoading, updateLoadingState] = useState(false);
  const requiredFormFields = ['emailOtp'];
  const { emailOtp, email, userId } = formParams;
  const { title } = formInfo;
  const { next, go } = navigation;
  let myColor = { background: '#0E1717', text: '#FFFFFF' };
  let mySuccessColor = { background: '#095712', text: '#FFFFFF' };
  let subTitle = '';

  if (userData || email) {
    const emailToDisplay = isHeaderTextUpdated
      ? email
      : userData && userData.me ? userData.me.email : email;
    subTitle = `We sent you a verification code to <b>${emailToDisplay}</b>. Please enter the 6-digit OTP below.`
  }

  const triggerEmailUpdate = async () => {
    updateLoadingState(true);

    const userId = userData && userData.me ? userData.me.id : null;
    const response = await AuthService.updateUserEmail(userId, email);
    updateLoadingState(false);

    if (response.status === 200 && response.data.data.updateUserEmail && response.data.data.updateUserEmail.ok) {
      resendOtp();
      updateHeaderText(true);
      handlePopupSwitch();
    } else {
      notify.show('Error! Unable to update your email', 'error', 6000, myColor);
      handlePopupSwitch();
    }
  };

  const handlePopupSwitch = () => {
    switchEmailVerification(!showEmailVerification);
  };

  const updatePersonalEmail = () => {
    if (!email.length || !validateEmail(email)) {
      const errorMsg =
        !email.length
          ? 'Kindly enter your personal email.'
          : 'Kindly enter a valid email.';
      notify.show(errorMsg, 'error', 4000, myColor);
    } else {
      triggerEmailUpdate();
    }
  };

  const resendOtp = async () => {
    updateLoadingState(true);

    const response = await AuthService.triggerPersonalEmailConfirmation();

    updateLoadingState(false);

    if (response == null || typeof response === 'undefined') {
      const error = 'Unable to Resend OTP.';
      setResponseError({ resendOtp: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      setResponseError({ resendOtp: error });
      return;
    }

    const { ok } = response.data.data.triggerPersonalEmailConfirmation;
    notify.show('OTP resent successfully', 'success', 4000, mySuccessColor);

    if (!ok) {
      const error = 'Unable to Resend OTP.';
      setResponseError({ resendOtp: error });
      return;
    }
  };

  const confirmEmail = async () => {
    // get value
    if (!userData || Object.keys(userData).length === 0) {
      window.location.href = 'sign-up';
    }

    let customerId;
    updateLoadingState(true);
    if (userData && Object.keys(userData).length !== 0) {
      customerId = userData.me.id;
    } else {
      customerId = userId;
    }

    const response = await AuthService.confirmPersonalEmail(emailOtp);

    updateLoadingState(false);

    if (response == null || typeof response === 'undefined') {
      const error = 'Unable to verify personal email. Please try again';
      setResponseError({ verifyOtp: error });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);

      if (error.trim() == 'user email already confirmed') {
        const nextStep = requiredSocialMedia.length ? 'socialMediaDetails' : 'loanSelection';
        updateContextState({ page: nextStep });
        go(nextStep);
      } else {
        setResponseError({ verifyOtp: `${error}. Please enter a valid code` });
        return;
      }
    }

    const { ok } = response.data.data.confirmPersonalEmail;

    if (!ok) {
      const error = 'Unable to verify personal email. Please try again';
      setResponseError({ verifyOtp: error });
      return;
    } else {
      const nextStep = requiredSocialMedia.length ? 'socialMediaDetails' : 'loanSelection';
      updateContextState({ page: nextStep });
      go(nextStep);
    }
    return;
  };

  const validateForm = (fieldName, value) => {
    switch (fieldName) {
      case 'emailOtp':
        if (value.length === 0 || value.length < 6) {
          setInputErrors((prevState) => ({ ...prevState, emailOtp: 'Please enter the 6-digit otp sent to your email.' }));
        } else {
          setInputErrors((prevState) => ({ ...prevState, emailOtp: '' }));
        }
        break;
    }
    return true;
  };

  const checkValidation = () => {
    requiredFormFields.map((item) => {
      validateForm(item, formParams[item]);
    });
  };

  useEffect(
    () => {
      checkValidation();
    },
    [formParams, isSubmitted]
  );

  const nextStep = () => {
    updateSubmit(true);

    const errors = Object.keys(inputErrors).filter((item) => inputErrors[item] !== '');

    if (errors.length < 1 && userData) {
      confirmEmail();
    }
  };

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);

    if (viewer && viewer.data && viewer.data.data) {
      const response = viewer.data.data.viewer;
      setUserData(response);

      if (response.me.isEmailConfirmed) {
        const nextStep = requiredSocialMedia.length ? 'socialMediaDetails' : 'loanSelection';
        updateContextState({ page: nextStep });
        go(nextStep);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPreviouslySavedData();
  }, []);

  return (
    <Fragment>
      {isLoading && <Loader />}
      <DataCardV2 title={title} subTitle={subTitle} centeralize>
        {responseError &&
          responseError.length !== 0 && (
            <Fragment>{responseError.verifyOtp ? <Alert classes="error">{responseError.verifyOtp}</Alert> : ''}</Fragment>
          )}

        <div className="form_content">
          <Notifications />
          <section>
            <FormGroup>
              <Input
                required
                numberField
                placeholder="Enter OTP"
                label="Enter OTP"
                name="emailOtp"
                value={emailOtp}
                minlength={4}
                maxlength={6}
                autoFocus
                readOnly={false}
                disabled={false}
                onChange={baseInputChangeHandler}
                classes={`border-bottom`}
                errorMessage={isSubmitted && inputErrors.emailOtp ? inputErrors.emailOtp : ''}
              />
            </FormGroup>
            <p className="center-text">
              Didn't get OTP? <br />
              <span className="resend-otp" onClick={resendOtp}>
                Resend Code
              </span>
              <span className="resend-otp spacer">/</span>
              <span className="resend-otp" onClick={handlePopupSwitch}>
                Edit Personal Email
              </span>
            </p>
            <p className='center-text bold-text'> NOTE: Please check your spam/junk folder if you don’t find the email in your inbox.</p>
          </section>
        </div>

        <div className="space-apart single right">
          <Button inactive={emailOtp === ''} click_event={nextStep}>
            Continue
          </Button>
        </div>
      </DataCardV2>

      {showEmailVerification && (
        <PopupV2 classes="modal-confirm-phone-wrapper" title="Edit Personal Email">
          <Close className="close-icon" onClick={() => handlePopupSwitch()} />
          <div className="modal-confirm-phone">
            <Input
              type="text"
              placeholder="Enter your email"
              label="Email"
              name="email"
              value={email}
              readOnly={false}
              onChange={baseInputChangeHandler}
              classes={`border-bottom`}
              required
              errorMessage={isSubmitted && inputErrors.email ? inputErrors.email : ''}
            />

            <div className="space-apart single center">
              <Button click_event={updatePersonalEmail}>Save</Button>
            </div>
          </div>
        </PopupV2>
      )}
    </Fragment>
  );
};

export default Index;
