import gql from "graphql-tag";

export const fragments = {};

export const LOGIN_MUTATION = gql`
mutation Login($input: AccountLoginInput!) {
  login(input: $input) {
    token
    user {
      id
      status
    }
  }
}
`;

export const RESET_PASSWORD_MUTATION = gql `
    mutation ResetPasswordMutation($input: ResetPasswordInput!) {
      resetPassword(input: $input) {
        ok
      }
    }
`;


export const TRIGGER_PASSWORD_RESET_MUTATION = gql`
    mutation TriggerPasswordResetMutation($input: TriggerPasswordResetInput!) {
      triggerPasswordReset(input: $input) {
        ok
      }
    }
`;