import React, { useEffect, useState } from 'react';
import {
  useConsentCallbackProvider,
  useRequestConsentProvider,
} from '../../../providers/index';
import { toTitleCase } from '../../../lib/utils';
import { Alert, Button, Input, Popup } from '../../index';
import { useFormState } from 'react-use-form-state';

const SignWithOtp = ({ popupToggle, application, setLoading, refetch }) => {
  const [formState, { tel }] = useFormState();
  const [resendOtp, setResendOtp] = useState(false);
  const [OtpSuccess, setOtpSuccess] = useState(false);
  const [OtpError, setOtpError] = useState(false);

  const {
    success: requestConsentSuccess,
    loading: requestConsentLoading,
    errorMessage: requestConsentErrorMessage,
    requestConsent,
  } = useRequestConsentProvider();

  const {
    loading: consentCallbackLoading,
    success: consentCallbackSuccess,
    errorMessage: consentCallbackErrorMessage,
    consentCallback,
  } = useConsentCallbackProvider();

  const signNow = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setOtpSuccess(false);
    setOtpError(false);

    consentCallback({
      applicationNumber: application.applicationNumber,
      providerName: 'OTP',
      otp: formState.values.otp,
    });
  };

  const sendOtp = (type) => {
    setOtpSuccess(false);
    setOtpError(false);

    requestConsent({
      applicationNumber: application.applicationNumber,
      providerName: 'OTP',
    });

    if (type === 'resendOtp') {
      setResendOtp(true);
    }
  };

  useEffect(() => {
    sendOtp();
  });

  useEffect(() => {
    if (!requestConsentLoading && requestConsentSuccess) {
      setOtpSuccess(true);
    }
  }, [requestConsentLoading, requestConsentSuccess]);

  useEffect(() => {
    if (!consentCallbackLoading && consentCallbackErrorMessage) {
      setOtpError(true);
    }
  }, [consentCallbackLoading, consentCallbackErrorMessage]);

  useEffect(() => {
    if (requestConsentLoading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [requestConsentLoading]);

  useEffect(() => {
    if (consentCallbackLoading) {
      setLoading(true);
    }
    return () => {
      setLoading(false);
    };
  }, [consentCallbackLoading]);

  useEffect(() => {
    if (consentCallbackSuccess) {
      popupToggle('OfferLetterSigned');
      setTimeout(() => {
        refetch();
      }, 4000);
    }
  }, [consentCallbackSuccess]);

  return (
    <Popup classes="offer-letter">
      <h4 className={'data-card__title'}>Sign With OTP</h4>

      {!requestConsentLoading && requestConsentErrorMessage ? (
        <Alert classes="warning">
          {toTitleCase(requestConsentErrorMessage)}
        </Alert>
      ) : (
        ''
      )}

      {OtpError && consentCallbackErrorMessage ? (
        <Alert classes="warning">
          {toTitleCase(consentCallbackErrorMessage)}
        </Alert>
      ) : (
        ''
      )}

      {OtpSuccess && resendOtp ? (
        <Alert classes="success">We have resent your OTP.</Alert>
      ) : (
        ''
      )}

      <p className="center-text">
        We sent a 6 digit verification code to your registered phone number.
        Please enter the code below.
      </p>

      <form className="otpsign-form" onSubmit={signNow}>
        <Input
          type="tel"
          classes="border-bottom"
          placeholder="Enter OTP"
          name="otp"
          numberField
          minlength={6}
          maxlength={6}
          required
          {...tel('otp')}
        />

        <Button
          type="button"
          classes="small button-link-otp resend-otp"
          click_event={() => sendOtp('resendOtp')}
        >
          Resend OTP
        </Button>

        <Button type="submit">Sign Offer Letter</Button>
      </form>

      <div className="otpsign-footer">
        <p>
          I acknowledge that I have read the content of this document, and I’m
          entering into a legally binding agreement by clicking the Sign Offer
          Letter button above. I give my consent to the use of electronic
          communication and records related to this agreement.{' '}
          <Button
            click_event={(e) => popupToggle('DeclineOfferLetter')}
            classes="button-link-otp"
          >
            Click here to decline.
          </Button>
        </p>
      </div>
    </Popup>
  );
};

export default SignWithOtp;
