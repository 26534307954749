const graphQlRequest = require('../lib/requestClient');

export default class CardService {

    static getAddCardReference() {
        const query = `
            query {
                getAddCardReference {
                    id
                    reference
                    status
                }
            }
        `;

        return graphQlRequest().post('graphql', { query }).catch(() => {});
    }

    static getCardReferenceStatus({ reference, loanDuration }) {
        const query = `
            query($reference: String!, $loanDuration: String) {
                getCardReferenceStatus(input: { reference: $reference, loanDuration: $loanDuration }) {
                    reason
                    status
                    bank
                }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: { reference, loanDuration } }).catch(() => {});
    }

    static setDefaultAccountCard(cardId, accountId) {
        const query = `
            mutation($cardId: ID!, $accountId: ID!) {
              setDefaultAccountCard(input: { cardId: $cardId, accountId: $accountId}) {
                isDefault
                status
                bankName
                id
                maskedPan
                expiryDate
              }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: { cardId, accountId } }).catch(() => {});
    }

    static saveAccountCard(txnRef) {
        const query = `
            mutation($txnRef: String!) {
              saveAccountCard(txnRef: $txnRef) {
                card {
                  status
                  isDefault
                }
                bank
              }
            }
        `;

        return graphQlRequest().post('graphql', { query, variables: { txnRef } }).catch(() => {});
    }
}
