import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_SUPPORTING_DOCUMENT } from './queries';

const UploadSupportingDocumentProvider = ({ children }) => {
  const [ mutate ] = useMutation(UPLOAD_SUPPORTING_DOCUMENT, {
    onError(...error) {
      return error;
    }
  });

  const uploadSupportingDocumentFile = ({ selectedFile, documentName, userId }) => {
    const { validity, files } = selectedFile;
    const file = files[0];

    return (
      validity.valid &&
      mutate({
        variables: { file, documentName, userId }
      })
    );
  };

  const props = {
    uploadSupportingDocumentFile,
  }

  return children(props)
};

export default UploadSupportingDocumentProvider;
