import React from 'react';
import PropTypes from 'prop-types';
import './_Tag.scss';

const Tag = (props) => {
    return(
        <span className={`badge ${props.status == null ? "" : props.status}`}>{props.status}</span>
    )
};

Tag.propTypes = {
    status: PropTypes.string,
};

export default Tag;