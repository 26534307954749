import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './_Select.scss';

const Select = (props) => {
  const [value, setValue] = useState();
  const updateValue = (e) => {
    setValue(e.target.value);
    if (props.onChange) props.onChange(e);
  };

  return (
    <label className={'select__group'}>
      {props.label == null ? (
        ''
      ) : (
        <span className={'select__label'}>
          {props.label}
          {props.required ? <span className="input__label-required">*</span> : ''}
        </span>
      )}
      <select
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        className={`select ${props.classes == null ? '' : props.classes}`}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        name={props.name}
        onBlur={props.onBlur}
        onChange={updateValue}
        value={value}
        required={props.required}
        readOnly={props.readOnly}
      >
        {props.children}
      </select>
      {props.errorMessage && <span className={'input__error'}>{props.errorMessage}</span>}
    </label>
  );
};

Select.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  classes: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  type: PropTypes.string,
  value: PropTypes.string
};

export default Select;
