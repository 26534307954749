import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Context from '../../context';
import { LoginProvider } from '../../providers';
import { Button, Input, Alert, DataCardV2, PasswordInput } from '../../components';

import './_Styles.scss';

const Index = () => {
  const { state } = useContext(Context);
  const clientName = state.clientInfo ? state.clientInfo.name : 'LSETF';
  
  return (
    <section className="container welcome-screen">
      <DataCardV2>
        <section className="welcome-screen-step-grid">
          <div className="welcome-screen-step-grid_left">
            <h2>New Customer</h2>
            <div className="list-items">
              <p>New to {clientName}? Click on the button below to start applying for a loan.</p>
            </div>
            <div>
              <Link to="/sign-up" className="link_sign-up">
                <Button>Start Application</Button>
              </Link>
              <div className="how-to-apply">
                <a href='https://youtu.be/zN0Q-s9nn-Q' target='_blank'>How To Apply</a>
              </div>
            </div>
          </div>
          <div className="welcome-screen-step-grid_right">
            <h3>Do you have an account? Sign in now.</h3>
            <LoginProvider>
              {({ onSubmit, loading, errorMessage, successMessage, form }) => (
                <div>
                  <form onSubmit={onSubmit}>
                    {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
                    {successMessage && <Alert classes="success">{successMessage}</Alert>}
                    <Input
                      type="email"
                      placeholder="Enter your Email Address"
                      label="Email Address"
                      name="email"
                      autoFocus
                      required
                      readOnly={false}
                      classes={`border-bottom ${form.email.input.errorMessage ? 'error' : ''}`}
                      {...form.email.input}
                    />
                    <PasswordInput
                      placeholder="Password"
                      label="Password"
                      name="password"
                      required
                      readOnly={false}
                      classes={`border-bottom ${form.password.input.errorMessage ? 'error' : ''}`}
                      {...form.password.input}
                    />
                    <Button type="submit" classes={`center ${loading ? 'loading' : ''}`}>
                      Sign In
                    </Button>
                    <div className="forgot-password">
                      <Link to="/forgot-password">Forgot password?</Link>
                    </div>
                  </form>
                </div>
              )}
            </LoginProvider>
          </div>
        </section>
      </DataCardV2>
    </section>
  );
};

export default Index;
