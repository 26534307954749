import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm, useStep } from "react-hooks-helper";
import { AuthService, BankService } from "../../services";
import {
  authGetter,
  clientInfoRequestDetails,
  CONTEXTS,
} from "../../lib/utils";
import Context from "../../context";

import {
  BeforeYouBegin,
  LassraVerification,
  PersonalDetails,
  OtpVerification,
  BvnPhoneVerification,
  LoanSelection,
  BusinessInformation,
  LoanRequest,
  AddDebitCard,
  BankStatement,
  MbsInstructions,
  TicketNumber,
  MbsUpload,
  SupportingDocuments,
  ConfirmRequest,
  LoanConfirmation,
  EmailVerification,
  OkraStep,
  SocialMediaDetails,
} from "./Steps";

import { ProgressBar, ProgressBox } from "../../components";
import {
  formSteps as steps,
  excludedStepIds,
  removeProgressBarOn,
  removeProgressBoxOn,
  inputOptionTypes,
  loanDurationType,
} from "./constants";

import "./_Styles.scss";
import { APPLICATION_CLOSED } from "../../lib/constants";
import { ApplicationClosed } from "../../components/ApplicationClosed";

const defaultData = {
  // others
  documentList: [
    {
      name: "govtId",
      title: "Upload Govt-issued ID",
      type: "default",
      requiredBy: ["ME", "MES", "SME"],
    },
    {
      name: "lassraId",
      title: "Upload LASSRA ID Card",
      type: "default",
      requiredBy: ["ME", "MES", "SME"],
    },
    {
      name: "passportPhoto",
      title: "Upload your Passport Photograph",
      type: "default",
      requiredBy: ["ME", "MES", "SME"],
    },
    {
      name: "cacCertForm",
      title: "Upload CAC Certificate",
      type: "default",
      requiredBy: ["SME"],
    },
    {
      name: "taxIdCert",
      title: "Upload Personal Tax ID Card",
      type: "default",
      requiredBy: ["SME", "MES", "ME"],
    },
    {
      name: "tinCert",
      title: "Upload TIN Certificate",
      type: "default",
      requiredBy: ["SME"],
    },
    {
      name: "trainingCert",
      title: "Upload Training Certificate",
      type: "default",
      requiredBy: ["MES"],
    },
    {
      name: "memArt",
      title: "Memorandum and Articles of Association",
      type: "default",
      requiredBy: ["SME"],
    },
  ],
};

const stepsLength = steps.length;

const Index = () => {
  let history = useHistory();

  const { state } = useContext(Context);
  const clientTheme =
    state && state.clientInfo ? state.clientInfo.clientTheme : {};

  const [jumpScreenTo, setScreenToJumpTo] = useState();

  let activeView = {};
  let counter = 0;

  const [pbStepCount, updatePBStepCount] = useState(0); //pb - Progress Bar
  const [userData, setUserData] = useState(); // user info [global data]
  const [documentList, updateDocumentList] = useState(defaultData.documentList);
  const [appClientInfo, setAppClientInfo] = useState();
  const [completeApplicationInfo, setCompleteApplicationInfo] = useState();
  const [initialApplication, setInitialApplication] = useState(); // holds the application created during signup
  const [formData, setForm] = useForm(defaultData);
  const {
    index: stepIndex,
    step,
    navigation,
  } = useStep({ initialStep: 0, steps });
  const [bankList, setBankList] = useState();
  const [contextState, setContextState] = useState();
  const [requiredSocialMedia, updateRequiredSocialMedia] = useState([]);

  const [formParams, updateFormParams] = useState({
    // before you begin
    tNc: false,

    // LASSRA Verification - just listing attributes expected from this screen
    // lassraId: '',
    lassra_firstName: "",
    lassra_lastName: "",
    lassra_middleName: "",

    // bvn details
    bvn: "",
    bvnPhoneDigits: "",
    dob: "",
    verificationCode: "",

    // personal details
    firstName: "",
    lastName: "",
    phoneNumber: "",
    maritalStatus: "",
    gender: "",
    address: "",
    state: "Lagos",
    lga: "",
    email: "",
    password: "",
    confirmPassword: "",

    lassraId: "",
    taxIdNo: "",
    highestEducationalLevel: "",
    senatorialDistrict: "",
    residenceType: "",
    dateMovedIn: "",

    // referee
    refereeName: "",
    refereePhone: "",
    refereeEmail: "",
    refereeWorkPlace: "",

    // otp verifications
    otp: "",
    userId: "",
    emailOtp: "",

    // Loan selection
    loanProduct: "",
    hasBusiness: "",

    // business details
    employmentStatus: "",
    companyName: "",
    companyAddress: "",
    workEmail: "",
    employmentDuration: "",
    netMonthlyIncome: "",
    businessRegistration: "",
    typeOfBusiness: "",

    companyLga: "",
    cacRegNo: "",
    companyTaxId: "",
    businessStartDate: "",
    businessSector: "",
    monthlyTurnover: "",
    monthlyExpenses: "",
    monthlySavings: "",
    noOfEmployees: "",

    employees: [
      {
        employeeName: "",
        employeeTaxId: "",
      },
    ],
    directors: [
      {
        directorName: "",
        directorTaxId: "",
      },
    ],

    // loan request
    amount: "",
    loanDuration: "",
    expectedMonthlyTurnover: "",
    expectedMonthlyExpenses: "",
    expectedMonthlySavings: "",
    expectedNewHires: "",
    cooperativeMember: "",
    willingToJoinCooperative: "",
    durationInCooperative: "",
    cooperativeName: "",
    cooperativeAddress: "",

    // Bank statement
    bankId: "",
    accountName: "",
    accountNumber: "",

    hasInternetBanking: "",

    applicationId: "",
    statementPassword: "",
    skipStep: false,

    tNcFinal: false,

    okraCustomerId: "",
    okraRecordId: "",

    // Confirm Request
    loanRequestStatus: "",
    loanAgreement: false,
  });

  const { id, formInfo } = step;
  const getClientInfo = async () => {
    const { slug, clientUrl } = clientInfoRequestDetails();
    const response = await AuthService.clientInfo({ slug, clientUrl });

    if (response && response.data && response.data.data) {
      const { clientInfo } = response.data.data;
      const clientRequiredSocialMedia = clientInfo.requiredSocialMediaAccounts;

      setAppClientInfo(clientInfo);
      updateRequiredSocialMedia(clientRequiredSocialMedia);
      getBanks();
    }
  };

  const getBanks = async () => {
    const response = await BankService.getBanks();

    const error = "Error fetching bank list";
    if (response == null || typeof response === "undefined") {
      return;
    }

    if (response.data.errors) {
      return;
    }

    if (response.data.data && response.data.data.getBanks) {
      const banks = response.data.data.getBanks;
      setBankList(banks);

      getPreviouslySavedData();
    } else {
      setBankList([]);
    }
    return;
  };

  useEffect(() => {
    getClientInfo();

    // logic used to handle when login and data imcomplete
    const { state } = history.location;
    if (state) {
      setScreenToJumpTo(state.jumpToScreen);
    }
  }, []);

  const getPreviouslySavedData = async () => {
    const authentication = authGetter();

    const viewer = await AuthService.viewerQueryClone(authentication.apiKey);

    if (viewer && viewer.data && viewer.data.data) {
      const response = viewer.data.data.viewer;
      setUserData(response);

      if (response?.account?.contextStates?.length) {
        setContextState(response.account.contextStates[0]);
      }
    }
  };

  const updateNextStage = (step) => {
    const businessInfo = userData.account.accountAttribute.find(
      (attr) => attr.attribute.name === "businessInformation"
    ).data;
    const businessInformation = { ...businessInfo, nextStage: step };
    history.replace("sign-up", { jumpToScreen: step });
    AuthService.updateUserDataV2({ businessInformation });
  };

  const updateContextState = async ({ page, state }) => {
    let currentContext = contextState;
    if (stepIndex > 2 && !contextState) {
      currentContext = userData && userData.account.contextStates[0];
    }

    const payload = {
      context: CONTEXTS.SIGN_UP,
      page,
      ...(state ? { state } : {}),
      ...(currentContext ? { id: currentContext.id } : {}),
    };

    history.replace("sign-up", {
      jumpToScreen: page !== "dashboard" ? page : "loanConfirmation",
    });

    payload.state = payload.id
      ? { ...(currentContext.state || {}), ...payload.state }
      : payload.state;

    const response =
      !payload.id && stepIndex < 2
        ? await AuthService.createContextState(payload)
        : await AuthService.updateContextState(payload);

    if (response && response.data.data) {
      setContextState(
        response.data.data.createContextState ||
          response.data.data.updateContextState
      );
    }
  };

  const updateLocationState = (step) => {
    history.replace("sign-up", { jumpToScreen: step });
  };

  const baseInputChangeHandler = (e) => {
    let name, value;
    let type = e.target.type;

    if (inputOptionTypes.includes(type)) {
      name = e.target.name;
      value = e.target.value;
    }

    updateFormParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (userData?.account?.contextStates) {
      const currentContext = userData?.account?.contextStates[0];
      setContextState(currentContext);
    }
  }, [userData]);

  const documentProps = { documentList, updateDocumentList };
  const props = {
    stepIndex,
    formData,
    setForm,
    navigation,
    formInfo,
    updatePBStepCount,
    userData,
    setUserData,
    initialApplication,
    setInitialApplication,
    appClientInfo,
    completeApplicationInfo,
    setCompleteApplicationInfo,

    jumpScreenTo,
    formParams,
    updateFormParams,
    baseInputChangeHandler,
    loanDurationType,

    getPreviouslySavedData,
    updateNextStage,
    updateLocationState,
    updateContextState,
    contextState,
    bankList,
    requiredSocialMedia,
    ...documentProps,
  };

  switch (id) {
    case "beforeYouBegin":
      activeView = <BeforeYouBegin {...props} />;
      break;
    case "personalDetails":
      activeView = <PersonalDetails {...props} />;
      break;
    case "otpVerification":
      activeView = <OtpVerification {...props} />;
      break;
    case "emailVerification":
      activeView = <EmailVerification {...props} />;
      break;
    case "bvnVerification":
      activeView = <BvnPhoneVerification {...props} />;
      break;
    case "socialMediaDetails":
      activeView = <SocialMediaDetails {...props} />;
      break;
    case "loanSelection":
      activeView = <LoanSelection {...props} />;
      break;
    case "businessInformation":
      activeView = <BusinessInformation {...props} />;
      break;
    case "supportingDocuments":
      activeView = <SupportingDocuments {...props} />;
      break;
    case "loanInformation":
      activeView = <LoanRequest {...props} />;
      break;
    case "addDebitCard":
      activeView = <AddDebitCard {...props} />;
      break;
    case "okraPage":
      activeView = <OkraStep {...props} />;
      break;
    case "bankStatement":
      activeView = <BankStatement {...props} />;
      break;
    case "mbsInstructions":
      activeView = <MbsInstructions {...props} />;
      break;
    case "ticketNumber":
      activeView = <TicketNumber {...props} />;
      break;
    case "mbsUpload":
      activeView = <MbsUpload {...props} />;
      break;
    case "confirmRequest":
      activeView = <ConfirmRequest {...props} />;
      break;
    case "loanConfirmation":
      activeView = <LoanConfirmation {...props} />;
      break;
    default:
      activeView = null;
  }

  return (
    <Fragment>
      {APPLICATION_CLOSED ? (
        <ApplicationClosed />
      ) : (
        <div className="container">
          {steps && removeProgressBoxOn.includes(id) ? (
            ""
          ) : (
            <div className="progressBox">
              {steps.map((step, idx) => {
                if (!excludedStepIds.includes(step.id)) {
                  counter++;
                  return (
                    <ProgressBox
                      key={`${step.title}_${idx}`}
                      title={step.title}
                      current={counter}
                    />
                  );
                }
                return;
              })}
            </div>
          )}

          {steps && removeProgressBarOn.includes(id) ? (
            ""
          ) : (
            <div className="progressBar">
              <ProgressBar
                fillColor={clientTheme ? clientTheme.secondaryColor : ""}
                current={pbStepCount}
                limit={stepsLength}
              />
            </div>
          )}

          <div className="viewWrapper">{activeView}</div>
        </div>
      )}
    </Fragment>
  );
};

export default Index;
