import React from "react"
import Helmet from "react-helmet";

class Page extends React.Component {
    render() {
        const PageComponent = this.props.component;

        return (
          <>
            <Helmet>
              <title>
                {this.props.title ? `${this.props.title} - `: ''}
                LSETF Loan Application Portal
              </title>
            </Helmet>
            <PageComponent />
          </>
        );
    }
}

export default Page;
