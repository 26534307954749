/* eslint-disable no-useless-escape */
import uuidV1 from 'uuid/v1';
import { authTypes, CLIENT_ID } from './constants';
import moment from 'moment';
import { web } from "../config/config";

export const getErrorMessages = (arr) => {
  let errorText = '';

  for (let errorMsg = 0; errorMsg < arr?.length; errorMsg++) {
    errorText += `${arr[errorMsg].message}\n`;
  }

  return errorText;
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const generateRandomCharacter = (length) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const generateTransactionReference = () => {
  const date = new Date();
  const dateFormat = [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ].join('');

  return `IND-PAYSTACK-${dateFormat}${generateRandomCharacter(4)}`;
};

export const generateCardReference = () => {
  const date = new Date();
  const dateFormat = [
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ].join('');

  return `IND-CARD${dateFormat}${generateRandomCharacter(4)}`;
};

export const numberWithCommas = (e) => {
  if (typeof e !== 'number') {
    return;
  }
  return e.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeCommas = (e) => {
  const hasComma = /,/;

  return hasComma.test(e) ? e.replace(/,/g, '') : e;
};

export const removeUnderscore = (e) => {
  return e.replace(/[_-]/g, ' ');
};

export const validatePasswordStrength = (password) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,20})/;

  return re.test(password);
};

export const validateMBSTicket = (mbsTicketNo) => {
  const re = /^(\d{6,8})-(\d{1,3})/;

  return re.test(mbsTicketNo);
};

export const generateUUID = () => uuidV1();

export const authGetter = () => {
  const authCreds = JSON.parse(localStorage.getItem('Auth'));
  const defaultCreds = {
    authenticationType: 'unauthenticated',
    hasApiKey: false,
  };

  if (typeof authCreds === 'undefined' || authCreds == null) {
    return defaultCreds;
  }

  if (typeof authCreds.apiKey !== 'undefined' && authCreds.apiKey != null) {
    const isTempApiKey = authCreds.keyType === authTypes.TEMPORARY;

    return {
      ...authCreds,
      isTempApiKey,
      hasApiKey: true,
      authenticationType: isTempApiKey ? 'unauthenticated' : 'authenticated',
    };
  }

  return defaultCreds;
};

export const loanTenorOptions = [
  { name: '3 days', value: 3 },
  { name: '7 days', value: 7 },
  { name: '14 days', value: 14 },
];

export const formatPhone = (phone) => {
  return (
    phone &&
    phone.substr(0, 4) + ' ' + phone.substr(4, 3) + ' ' + phone.substr(7)
  );
};

export const splitCommaToArray = (string) => {
  return string && string.split(',');
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / 1000 ** i).toFixed(0)} ${sizes[i]}`;
};

export const addUnderscore = (e) => e.replace(/ /g, '_');

export const convertDate = (value) => {
  const newDate = moment(value).format('DD/MM/YYYY');
  return newDate;
};

export const sumColumn = (array, value) => {
  if (array && Object.entries(array).length !== 0) {
    return array
      .filter((item) => Object.keys(item).includes(value))
      .map((item) => item[value])
      .reduce((sum, current) => sum + current);
  }
  return 'n/a';
};

export const hexToRGB = (h = '#000000', percent) => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length == 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length == 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return `rgb(${+r},${+g},${+b},${percent ? percent : ''})`;
};

export const durationTypeMapping = (type, value) => {
  let response;

  switch (type) {
    case 'DAILY':
      response = value && value == 1 ? 'Day' : 'Days';
      break;
    case 'WEEKLY':
      response = value && value == 1 ? 'Week' : 'Weeks';
      break;
    case 'MONTHLY':
      response = value && value == 1 ? 'Month' : 'Months';
      break;
    case 'YEARLY':
      response = value && value == 1 ? 'Year' : 'Years';
      break;
    default:
      response = 'One Off';
      break;
  }

  return response;
};

export const durationTypeCasing = (type, value) => {
  let response;

  switch (type.toLowerCase()) {
    case 'days':
      response = value && value == 1 ? 'Day' : 'Days';
      break;
    case 'weeks':
      response = value && value == 1 ? 'Week' : 'Weeks';
      break;
    case 'months':
      response = value && value == 1 ? 'Month' : 'Months';
      break;
    case 'years':
      response = value && value == 1 ? 'Year' : 'Years';
      break;
    default:
      response = 'One Off';
      break;
  }

  return response;
};

export const calcTransferCharges = (amount) => {
  let response;

  if (amount > 50000) {
    response = 50;
  } else if (amount > 5000 && amount <= 50000) {
    response = 25;
  } else {
    response = 10;
  }

  return response;
};

export const getLoanPolicy = (generatedOffer) => {
  const { chargesAmount, noOfRepayments, baseAmount, repaymentBreakdown } =
    generatedOffer;
  const interestRate =
    Math.round((((chargesAmount / baseAmount) * 100) / noOfRepayments) * 10) /
    10;
  let repaymentType = '';
  if (repaymentBreakdown && repaymentBreakdown.length > 1) {
    const duration = moment.duration(
      moment(repaymentBreakdown[1].dueDate).diff(
        moment(repaymentBreakdown[0].dueDate),
      ),
    )._data;
    if (duration.years || duration.months >= 11) {
      repaymentType = 'Annually';
    } else if (duration.months || duration.days >= 28) {
      repaymentType = 'Monthly';
    } else if (duration.days >= 6) {
      repaymentType = 'Weekly';
    } else if (duration.days === 1) {
      repaymentType = 'Daily';
    }
  } else {
    repaymentType = 'One-off';
  }
  return { interestRate, repaymentType };
};

export const round = (value, precision) => {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const camelize = (text) => {
  return text.replace(
    /^([A-Z])|[\s-_]+(\w)/g,
    function (match, p1, p2, offset) {
      if (p2) return p2.toUpperCase();
      return p1.toLowerCase();
    },
  );
};

export const toTitleCase = (str) => {
  return str
    ? str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    : '';
};

export const CONTEXTS = {
  SIGN_UP: 'SIGN_UP',
  NEW_LOAN: 'NEW_LOAN',
  DASHBOARD: 'REGISTERED_USER',
};

export const unCamelize = (str) => {
  return str
    .replace(/(?=[A-Z][a-z]|ID)/, ' ')
    .replace(/^./, (first) => first.toUpperCase())
    .replace(/'S/, (second) => second.toLowerCase());
};

const today = new Date();
export const dobMaxSelectableDate = today.setFullYear(today.getFullYear() - 12);
export const maxSelectableDate = new Date();

export const validateSocialMediaUrl = (name, url) => {
  let re;
  switch (name) {
    case 'twitter':
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w*]*)/;
      return re.test(url);
    case 'facebook':
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w]*)/;
      return re.test(url);
    case 'linkedin':
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?linkedin\.com\/((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))/;
      return re.test(url);
    case 'instagram':
      re =
        /^(http(s)?:\/\/)?(?:[\w]+\.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im;
      return re.test(url);
  }
};

export const handleGraphQLErrors = (error) => {
  let errorMessages = '';
  const { graphQLErrors } = error;
  if (graphQLErrors) {
    for (let index = 0; index < graphQLErrors.length; index++) {
      if (typeof graphQLErrors[index] === 'string') {
        errorMessages += `${graphQLErrors[index]}\n`;
      } else {
        errorMessages += `${graphQLErrors[index].message}\n`;
      }
    }
  }
  return (errorMessages.length && errorMessages) || null;
};

export const capitalizeInitialLetter = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

export const validateLassraId = (str) => {
  const re = /^([l]){1}([r,a]){1}([a-z 0-9]){10}/;
  return re.test(str);
};

export const replaceTideSymbol = e => e && e.split("~");

export const setGlobalClientId = clientId => {
  localStorage.setItem(CLIENT_ID, clientId);
};

export const getGlobalClientId = () => {
  return localStorage.getItem(CLIENT_ID);
};

export const clientInfoRequestDetails = () => {
  let slug, clientUrl;
  if (['staging', 'development', 'demo', 'local'].includes(web.app_env)) {
    slug = window.location.host.split('.')[0];
  } else {
    clientUrl = window.location.origin.toLowerCase();
  }

  return {slug, clientUrl}
}

export const getLoanDurationName = durationName => {
  switch (durationName) {
    case 'days':
      return 'DAILY';
    case 'weeks':
      return 'WEEKLY';
    case 'months':
      return 'MONTHLY';
    case 'years':
      return 'ANNUALLY';
    case 'day':
      return 'ONE-OFF';
    default:
      return 'MONTHLY';
  }
};

export const resolveLoanTenor = generatedOffer => {
  const { noOfRepayments, repaymentBreakdown } = generatedOffer;
  let loanTenor;
  if (repaymentBreakdown && repaymentBreakdown.length) {
    const duration = moment.duration(
      moment(repaymentBreakdown[0].dueDate).diff(
        moment(new Date()),
      ),
    )._data;
    loanTenor = `${noOfRepayments} `;
    if (duration.years || duration.months >= 11) {
      loanTenor += noOfRepayments === 1 ? 'Year' : 'Years';
    } else if (duration.months || duration.days >= 28) {
      loanTenor += noOfRepayments === 1 ? 'Month' : 'Months';
    } else if (duration.days >= 6) {
      loanTenor += noOfRepayments === 1 ? 'Week' : 'Weeks';
    } else if (duration.days === 1) {
      loanTenor += noOfRepayments === 1 ? 'Day' : 'Days';
    }
  }

  return loanTenor ;
};

export const maskPhoneNumber = phone =>
  phone?.replace(phone.substring(5, 9), '****');
  