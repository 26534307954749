import React from "react";
import { Link } from "react-router-dom";
import { SadSmiley } from "../Icon";
import "./_ApplicationClosed.scss";

const ApplicationClosed = ({ isLoggedIn }) => {
  return (
    <section className="container">
      <section className="application-closed no-loan">
        <div className="smiley-holder">
          <SadSmiley />
        </div>

        <h2>Application Closed for 2022</h2>

        <h3>
          Sorry, application has been closed for the year. Please, check back in
          January 2023
        </h3>

        {isLoggedIn ? (
          <aside className="link">
            Go back to your <Link to="/dashboard">Dashboard</Link>
          </aside>
        ) : (
          <aside className="link">
            Already have an account? <Link to="/sign-in">Sign In</Link>
          </aside>
        )}
      </section>
    </section>
  );
};

export default ApplicationClosed;
