import React from 'react';
import PropTypes from 'prop-types';
import './_RepaymentBreakdown.scss';

const RepaymentBreakdown = (props) => {
    return(
        <ul className="repayment-breakdown">
            {props.children}
        </ul>
    )
};

RepaymentBreakdown.propTypes = {
    classes: PropTypes.string,
};

export default RepaymentBreakdown;
