import moment from "moment";
import React, { Component, Fragment } from "react";

import {
  Alert,
  Button,
  DataCardV2,
  Loader,
  OfferLetter,
  PopupV2,
  RepaymentCard,
  ReviewFeedback,
  Tag,
  TransactionTable,
} from "../../components";

import { Link } from "react-router-dom";
import {
  Close,
  FailureIcon,
  NewLoanIcon,
  SuccessIcon,
} from "../../components/Icon";
import { logEvent } from "../../lib/GAHelper";
import {
  calcTransferCharges,
  getErrorMessages,
  numberWithCommas,
  removeUnderscore,
} from "../../lib/utils";
import { ViewerProvider } from "../../providers";
import { AuthService, LoanService } from "../../services";

import ProdCampNPSWidget from "../../components/ProdCampNPSWidget/ProdCampNPSWidget";
import "./_styles.scss";
import { BvnVerificationMessage } from "../../components/BvnVerificationMessage";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      emailConfirmationMessage: "",
      errors: {
        emailConfirmation: "",
        loanModification: "",
      },
      showLoanModificationModal: false,
      showRepaymentBreakdown: false,
    };

    this.triggerEmailConfirmation = this.triggerEmailConfirmation.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
    this.activateLoanModificationModal =
      this.activateLoanModificationModal.bind(this);
    this.approveLoanModification = this.approveLoanModification.bind(this);
  }

  reloadPage() {
    window.location.reload();
  }

  activateLoanModificationModal() {
    setTimeout(() => {
      this.setState({ showLoanModificationModal: true });
    }, 100);
  }

  toggleRepaymentDetails() {
    setTimeout(() => {
      this.setState({
        showRepaymentBreakdown: !this.state.showRepaymentBreakdown,
      });
    }, 100);
  }

  async approveLoanModification(data, approve, refetch) {
    const payload = { ...data, approve };
    this.setState({ loading: true });

    const response = await LoanService.approveLoanModification(payload);

    this.setState({ loading: false });

    let error = `There was an error processing your loan modification ${
      approve ? "approval" : "denial"
    }`;
    if (!response) {
      this.setState({
        errors: { ...this.state.errors, loanModification: error },
      });
    }

    if (response && response.data.errors) {
      this.setState({
        errors: {
          ...this.state.errors,
          loanModification: getErrorMessages(response.data.errors),
        },
      });
    }

    if (response.data && response.data.data.approveUpdatedLoanAmount) {
      refetch();
    }

    if (this.state.errors.loanModification) {
      setTimeout(() => {
        this.setState({
          errors: { ...this.state.errors, loanModification: "" },
        });
      }, 2000);
    }
  }

  async triggerEmailConfirmation() {
    logEvent("Dashboard", "Resend Verification Email", true);
    this.setState({ loading: true });

    const response = await AuthService.triggerEmailConfirmation();
    const error = "Unable to send verification email.";
    this.setState({ loading: false });

    if (response == null || typeof response === "undefined") {
      this.setState({ errors: { emailConfirmation: error } });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      this.setState({ errors: { emailConfirmation: error } });
      return;
    }

    const { ok } = response.data.data.triggerEmailConfirmation;

    if (!ok) {
      this.setState({ errors: { emailConfirmation: error } });
      return;
    }

    const confirmEmailNotification =
      "A verification link has been sent to your email. Please, click on the link to verify your email.";

    this.setState({ emailConfirmationMessage: confirmEmailNotification });
  }

  render() {
    return (
      <Fragment>
        <ViewerProvider>
          {({
            currentLoan,
            applications,
            latestLoan,
            user,
            defaultBankAccount,
            handleFundTransfer,
            isLoading,
            failureReason,
            showCombineModal,
            setCombineModal,
            triggerFundTransfer,
            showFundTransferModal,
            showFundTransferSuccessModal,
            refetch,
          }) => {
            const betaAccountNumberArray =
              user.userMetadata &&
              user.userMetadata.filter(
                (item) => item.name === "betaLoanAccountNumber"
              );

            const betaLoanAccountNumber =
              betaAccountNumberArray && betaAccountNumberArray.length != 0
                ? betaAccountNumberArray[0].value
                : "";

            let headerTitle = [
              "Date",
              "Status",
              "Loan ID",
              "Loan Amount",
              "Repayment Amount",
              "Repayment Date",
            ];

            const pendingApplication = applications?.find((data) => data?.status.name === "PENDING");
            const emptyHeader=pendingApplication && !pendingApplication?.completedSteps?.includes('COMPLETE_APPLICATION');

            if(emptyHeader) {
              headerTitle.push("");
            }

            return applications.length !== 0 ? (
              <Fragment>
                {isLoading && <Loader />}

                <section className={"container _handle_fund_transfer"}>
                  {this.state.emailConfirmationMessage ? (
                    <Alert classes="success">
                      {this.state.emailConfirmationMessage}
                    </Alert>
                  ) : !user.isEmailConfirmed ? (
                    <Alert classes="warning">
                      Your loans will not be disbursed until you have verified
                      your email. A verification link has been sent to{" "}
                      {user.email}, please click on the link to verify your
                      email.{" "}
                      <Button
                        click_event={this.triggerEmailConfirmation}
                        classes="resend-vericode"
                        button_text="Resend verification code"
                      />
                    </Alert>
                  ) : this.state.errors.emailConfirmation ? (
                    <Alert classes="warning">
                      {this.state.errors.emailConfirmation}!{" "}
                      <Button
                        click_event={this.triggerEmailConfirmation}
                        classes="resend-vericode"
                        button_text="Retry"
                      />
                    </Alert>
                  ) : (
                    ""
                  )}

                  {!user?.bvnStatus?.isVerified ? (
                    <BvnVerificationMessage
                      bvnStatus={user?.bvnStatus?.isVerified}
                    />
                  ) : null}

                  {currentLoan.id ||
                  (latestLoan && latestLoan.status.name !== "DENIED" && latestLoan.status.name !== "APPROVED") ? (
                    ""
                  ) : (
                    <Link
                      to="/loans/new"
                      className="button icon-left visible-mobile"
                    >
                      <span className="icon">
                        <NewLoanIcon />
                      </span>
                      <span className={"nav-text"}>New Loan</span>
                    </Link>
                  )}

                  <ReviewFeedback
                    application={latestLoan}
                    user={user}
                    refetch={refetch}
                  />

                  <OfferLetter application={latestLoan} refetch={refetch} />

                  {showFundTransferSuccessModal && (
                    <PopupV2>
                      <div className="closeBtn" onClick={this.reloadPage}>
                        <Close />
                      </div>
                      <div className="details _success_modal">
                        <div>
                          <SuccessIcon />
                        </div>
                        Hurray! Fund Successfully Transfered.
                      </div>
                    </PopupV2>
                  )}

                  {showCombineModal && failureReason.length != 0 && (
                    <PopupV2>
                      <div
                        className="closeBtn"
                        onClick={() => setCombineModal(false)}
                      >
                        <Close />
                      </div>
                      <div className="details _error_modal">
                        <div>
                          <FailureIcon />
                        </div>
                        {failureReason}
                      </div>
                    </PopupV2>
                  )}

                  {showFundTransferModal && (
                    <PopupV2
                      title="Transfer Fund"
                      subTitle="If you want to add a new account, go to the payment option to add a new account. Before your new account can be used it has to be verified."
                    >
                      <div
                        className="closeBtn"
                        onClick={(e) => triggerFundTransfer(e)}
                      >
                        <Close />
                      </div>
                      <div className="details">
                        <div className="_balance">
                          Balance:{" "}
                          <span>{numberWithCommas(latestLoan.amount)}</span>
                        </div>

                        {defaultBankAccount &&
                          defaultBankAccount.length !== 0 && (
                            <div className="account_info">
                              <h3>Transfer to:</h3>
                              <div className="_title">
                                Account Name:{" "}
                                <h3 className="small">
                                  {defaultBankAccount[0].accountName}
                                </h3>
                              </div>
                              <div className="_title">
                                Account Number:{" "}
                                <h3 className="small">
                                  {defaultBankAccount[0].accountNumber}
                                </h3>
                              </div>
                              <div className="_title">
                                Bank Name:{" "}
                                <h3 className="small">
                                  {defaultBankAccount[0].bank.name}
                                </h3>
                              </div>
                            </div>
                          )}

                        <div className="tranfer_charges">
                          Please note that the transfer charge of N
                          {calcTransferCharges(latestLoan.amount)} will be
                          deducted from your account.
                        </div>

                        <div className="tranfer_cta">
                          <Button
                            click_event={(e) =>
                              handleFundTransfer(e, {
                                sourceAccountNumber:
                                  defaultBankAccount[0].accountNumber,
                                sourceAccountName:
                                  defaultBankAccount[0].accountName,
                                amount: latestLoan.amount,
                                description: `Transfer from portfolio id: ${latestLoan.portfolio.id}`,
                                portfolioId: latestLoan.portfolio.id,
                              })
                            }
                          >
                            Transfer Fund
                          </Button>
                        </div>
                      </div>
                    </PopupV2>
                  )}

                  <div className="transfer_fund_bar">
                    {betaLoanAccountNumber != "" && (
                      <div className="_desc">
                        Account Number{" "}
                        <span className="account_number">
                          - {betaLoanAccountNumber}{" "}
                        </span>
                      </div>
                    )}

                    {latestLoan.status.name === "APPROVED" &&
                      latestLoan.portfolio.status.name === "DISBURSED" && (
                        <Button click_event={(e) => triggerFundTransfer(e)}>
                          Transfer Fund
                        </Button>
                      )}
                  </div>

                  {(latestLoan && latestLoan.status.name !== 'DENIED' && latestLoan.status.name !== 'APPROVED') && (
                    <DataCardV2 repaymentCard>
                      <RepaymentCard
                        amountPaid="0.00"
                        fullAmount={parseFloat(latestLoan.fullAmount).toFixed(
                          2
                        )}
                        percent={0}
                        repaymentAmount="n/a"
                        repaymentDate={latestLoan.dateOfRepayment}
                        overlay={true}
                        status={removeUnderscore(latestLoan.status.name)}
                      />
                    </DataCardV2>
                  )}

                  {currentLoan.id && (
                    <DataCardV2 repaymentCard>
                      <RepaymentCard
                        amountPaid={currentLoan.amountPaid}
                        fullAmount={parseFloat(
                          currentLoan.fullAmount.toFixed(2)
                        )}
                        percent={currentLoan.percent}
                        repaymentAmount={currentLoan.fullAmount}
                        repaymentDate={currentLoan.dateOfRepayment}
                        portfolioNumber={currentLoan.portfolioNumber}
                      />
                    </DataCardV2>
                  )}
                  <DataCardV2 repaymentCard>
                    <TransactionTable txTableHeaders={headerTitle}>
                      {applications.map((data, index) => {
                      const continueApplication = data?.status?.name === "PENDING" &&
                          !data?.completedSteps?.includes('COMPLETE_APPLICATION')

                        return (
                          <li key={index}>
                            <div
                              className="tx-column transaction-date"
                              data-header="Date"
                            >
                              {moment(data.createdAt).format("ll")}
                            </div>
                            <div className="tx-column" data-header="Status">
                              <Tag
                                status={
                                  data.portfolio
                                    ? data.portfolio.status.name.toLowerCase() ===
                                      "closed"
                                      ? "paid"
                                      : data.portfolio.status.name.toLowerCase() ===
                                        "disbursed"
                                      ? "active"
                                      : data.portfolio.status.name.toLowerCase() ===
                                        "pending_disbursement"
                                      ? "approved"
                                      : removeUnderscore(
                                          data.portfolio.status.name
                                        ).toLowerCase()
                                    : removeUnderscore(
                                        data.status.name
                                      ).toLowerCase()
                                }
                              />
                            </div>
                            <div
                              className="tx-column loan-id"
                              data-header="Loan ID"
                            >
                              <span>
                                {data.portfolio
                                  ? data.portfolio.portfolioNumber
                                  : data.applicationNumber}
                              </span>
                            </div>
                            <div
                              className="tx-column full"
                              data-header="Loan Amount"
                            >
                              <span>NGN {numberWithCommas(data.amount)}</span>
                            </div>
                            <div
                              className="tx-column"
                              data-header="Repayment Amount"
                            >
                              <span>
                                NGN {numberWithCommas(data.fullAmount)}
                              </span>
                            </div>
                            <div
                              className="tx-column"
                              data-header="Repayment Date"
                            >
                              <span>
                                {moment(data.dateOfRepayment).format("ll")}
                              </span>
                            </div>

                            {continueApplication && (
                              <div div className="tx-column full">
                                <Link
                                  to={`loans/new`}
                                  data-testid="view-details"
                                  className="button block active small"
                                >
                                  Complete Application
                                </Link>
                              </div>)

                          }

                          {emptyHeader && !continueApplication &&
                            <div div className="tx-column full"></div>
                          }
                          </li>
                        );
                      })}
                    </TransactionTable>
                  </DataCardV2>
                </section>
              </Fragment>
            ) : (
              <section className="container no-loan">
                <DataCardV2 repaymentCard>
                  <TransactionTable
                    txTableHeaders={[
                      "Transactions",
                      "Status",
                      "Amount",
                      "Total Amount Paid",
                      "Payment Method",
                    ]}
                  >
                    <h3 className="blank_transaction">
                      {" "}
                      You have no transactions{" "}
                    </h3>
                  </TransactionTable>
                </DataCardV2>
              </section>
            );
          }}
        </ViewerProvider>

        <ProdCampNPSWidget />
      </Fragment>
    );
  }
}

export default Dashboard;
