import React from 'react';
import { NewCheck } from '../../Icon';
import { Popup } from '../../index';

const DocusignSent = () => {
  return (
    <Popup classes="offer-letter">
      <div className="response-icon-holder">
        <NewCheck />
      </div>

      <h4 className={'data-card__title'}>Sign with DocuSign</h4>

      <p className="popup-text center-text">
        We have sent you an email with the offer letter via DocuSign. Please
        review the document and sign it.
      </p>
      <p className="popup-text center-text">
        Please, reload this page once the document is signed. Thank you.
      </p>
    </Popup>
  );
};

export default DocusignSent;
