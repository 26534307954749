import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { useApolloClient } from '@apollo/react-hooks';
import Context from '../../context';
import { Button } from '../index';
import { Logo } from '../Icon';
import { hexToRGB } from '../../lib/utils';
import './_Header.scss';

const Header = (props) => {
  const client = useApolloClient();
  const { state } = useContext(Context);
  const clientTheme = state && state.clientInfo ? state.clientInfo.clientTheme : {};

  const logout = async () => {
    window.location.href = '/sign-in';
    localStorage.clear();
    await client.resetStore();
  };

  const signin = async () => {
    window.location.href = '/';
    localStorage.clear();
    await client.resetStore();
  };

  const dynamicStyle = {
    boxShadow: clientTheme ? `0px 4px 26px ${hexToRGB(clientTheme.primaryColor, 0.3)}` : ''
  };

  return (
    <header className="header" style={dynamicStyle}>
      <div className="container">
        {props.authentication && props.authentication.hasApiKey && !props.authentication.isTempApiKey ? (
          <Fragment>
            <div className="header__left">
              <Link to="/dashboard">
                <Logo />
              </Link>
            </div>
            <div className="header__right">
              <Button click_event={logout}>Logout</Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="header__left">
              <Link to="/">
                <Logo />
              </Link>
            </div>

            {props.authentication && props.authentication.hasApiKey ? (
              <div className="header__right">
                <Button click_event={logout}>Logout</Button>
              </div>
            ) : props.location.state && props.location.state.isRegisteredUser ? (
              <div className="header__right">
                <Button click_event={logout}>Logout</Button>
              </div>
            ) : (
              <div className="header__right">
                <Button click_event={signin}>Sign in</Button>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  notAuthenticated: PropTypes.bool
};

export default withRouter(Header);
